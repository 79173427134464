export enum CampaignHtmlAttributeKeys {
  Component = "data-component",
  Board = "data-board",
  CtaOptIn = "data-cta-opt-in",
  Limit = "data-limit",
  UrlToLeaderBoard = "data-leaderboard-url",
  LeaderBoard = "data-leaderboard",
  XMas = "data-xmas",
}

export enum XmasCampaignHtmlAttributeValues {
  Component = "campaign"
}