import { Component, OnDestroy } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

// Services
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-limits-regulatory",
  templateUrl: "./limits-regulatory.component.html",
  styleUrls: ["./limits-regulatory.component.scss"],
})
export class LimitsRegulatoryComponent implements OnDestroy {
  openLimitsRegulatoryPopup: boolean = false;
  isLoggedIn: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private utility: UtilityService,
    private commonService: CommonService,
    private emitterService: EmitterService
  ) {
    this.commonService.loginComplete$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      });
    this.commonService.logOutComplete$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isLoggedIn = false;
      });
    this.emitterService.openLimitsRegulatoryPopup$
      .pipe(takeUntil(this.destroy$))
      .subscribe((flag) => {
        this.openLimitsRegulatoryPopup = flag;
      });
  }

  navigateToLimists() {
    this.utility.openAccountComponent("limits");
    this.closeRegulatoryPopup();
  }

  closeRegulatoryPopup() {
    this.openLimitsRegulatoryPopup = false;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
