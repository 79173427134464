import {
  ApiLeaderBoardGetBoardBodyParams,
  ApiLeaderBoardGetBoardResponse,
  ApiLeaderBoardUser,
  LeaderBoardUser,
} from "src/app/modules/shared/models/leader-board/leader-board.model";
import { FlowsProxyResponse } from "src/app/modules/shared/models/flows-proxy/flows-proxy.model";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { orderBy } from "lodash";
import {
  ApiLeaderBoardEnrolUserBodyParams,
  ApiLeaderBoardEnrolUserResponse,
  ApiLeaderBoardEvents,
  ApiLeaderBoardQueryParams,
  ApiLeaderBoardValidUserBodyParams,
  ApiLeaderBoardValidUserResponse,
  LeaderBoardUserStatus,
} from "src/app/modules/shared/models/leader-board/leader-board.model";
import { MainService } from "src/app/modules/shared/services/main.service";
import { LEADER_BOARD_DEFAULT_LIMIT } from "src/app/modules/shared/constants/leader-board.constant";

@Injectable({
  providedIn: "root",
})
export class LeaderBoardService {
  userStatus$ = new BehaviorSubject<{ userId: string; status: LeaderBoardUserStatus } | undefined>(undefined);

  constructor(private mainService: MainService) {}

  /**
   * Fetch user's status in leader board campaign
   *
   * @param board - name of the board
   * @param userId
   * @returns Observable with user's status
   */
  getUserStatus(board: string, userId: string): Observable<LeaderBoardUserStatus> {
    return this.mainService
      .flowsProxyPost<
        FlowsProxyResponse<ApiLeaderBoardValidUserResponse>,
        ApiLeaderBoardValidUserBodyParams,
        ApiLeaderBoardQueryParams
      >(
        {
          Board: board,
          UserID: userId,
        },
        {
          event: ApiLeaderBoardEvents.validUser,
          async: "false",
        }
      )
      .pipe(
        map((response) => response["Flow_#1"].status),
        tap((status) => this.userStatus$.next({ userId, status }))
      );
  }

  /**
   * Enrol user to leader board campaign
   *
   * @param board - name of the board
   * @param userId
   * @param userName - displayed name of the user in leader board
   * @returns Observable with user's status
   */
  enrolUser(board: string, userId: string, userName: string): Observable<LeaderBoardUserStatus> {
    return this.mainService
      .flowsProxyPost<
        FlowsProxyResponse<ApiLeaderBoardEnrolUserResponse>,
        ApiLeaderBoardEnrolUserBodyParams,
        ApiLeaderBoardQueryParams
      >(
        {
          Board: board,
          UserID: userId,
          Username: userName,
        },
        {
          event: ApiLeaderBoardEvents.enrolUser,
          async: "false",
        }
      )
      .pipe(
        map((response) => response["Flow_#1"].status),
        tap((status) => this.userStatus$.next({ userId, status }))
      );
  }

  /**
   * Get board with list of top users and record of current user
   *
   * @param board - name of the board
   * @param userId
   * @returns Observable with top users and current user's points
   */
  getBoard(
    board: string,
    userId: string,
    limit: number = LEADER_BOARD_DEFAULT_LIMIT
  ): Observable<{ topUsers: LeaderBoardUser[]; userPoints: LeaderBoardUser }> {
    return this.mainService
      .flowsProxyPost<
        FlowsProxyResponse<ApiLeaderBoardGetBoardResponse>,
        ApiLeaderBoardGetBoardBodyParams,
        ApiLeaderBoardQueryParams
      >(
        {
          Board: board,
          UserID: userId,
        },
        {
          event: ApiLeaderBoardEvents.getBoard,
          async: "false",
        }
      )
      .pipe(
        map((response) => {
          const allUsersPoints = this.orderLeaderBoardUsers(
            response["Flow_#1"].results.map(this.convertLeaderBoardUserFromApi)
          );
          const topUsers = allUsersPoints.slice(0, limit);

          const userPoints = allUsersPoints.find((userPoints) => userPoints.id === userId);
          return {
            topUsers: topUsers,
            userPoints: userPoints,
          };
        })
      );
  }

  /**
   * Convert leaderBoardUser object from API to object used in the App
   *
   * @param leaderBoardUser - object from API
   * @param index - index in leader board
   * @returns LeaderBoardUser object used in the App
   */
  private convertLeaderBoardUserFromApi(leaderBoardUser: ApiLeaderBoardUser, index: number): LeaderBoardUser {
    return (
      leaderBoardUser && {
        id: leaderBoardUser.Record,
        points: leaderBoardUser.Points ? +leaderBoardUser.Points : 0,
        userName: leaderBoardUser.UserName,
      }
    );
  }

  /**
   * Order users on leader board
   *
   * @param leaderBoardUsers - list of users on leader board
   * @returns Sorted list of users on leader board with assigned position
   */
  private orderLeaderBoardUsers(leaderBoardUsers: LeaderBoardUser[]): LeaderBoardUser[] {
    return orderBy(leaderBoardUsers, ["points"], ["desc"]).map((leaderBoardUser, index) => ({
      ...leaderBoardUser,
      position: index + 1,
    }));
  }
}
