import { map, catchError } from "rxjs/operators";
import { throwError, Subject } from "rxjs";
import { Injectable } from "@angular/core";

// Libraries
import * as _ from "underscore";
import * as $ from "jquery";

// Services
import { MainService } from "src/app/modules/shared/services/main.service";

@Injectable({
  providedIn: "root",
})
export class CashierService {
  activeDepositBonus;
  constructor(private mainService: MainService) {}

  private transactionSuccessStatus = new Subject<boolean>();
  public transactionSuccessStatus$ = this.transactionSuccessStatus.asObservable();

  broadCastTransactionSuccessStatus(typeOfTransaction) {
    this.transactionSuccessStatus.next(typeOfTransaction);
  }

  tokenCallQueue = [];
  getToken(getTokenObject) {
    if (!getTokenObject || _.isEmpty(getTokenObject) || !getTokenObject.token) {
      return throwError("RequiredDetails Not available");
    }
    return new Promise((resolve, reject) => {
      if (this.tokenCallQueue.length > 0) {
        this.tokenCallQueue.push(resolve);
      } else {
        this.tokenCallQueue.push(resolve);
        Promise.resolve(this.mainService.getToken(getTokenObject)).then(
          (tokenData) => {
            for (let callback in this.tokenCallQueue) {
              this.tokenCallQueue[callback](tokenData);
            }
            this.tokenCallQueue = [];
          }
        );
      }
    });
  }

  getUserUsedPIQAccounts(findAccountObject) {
    if (
      !findAccountObject ||
      _.isEmpty(findAccountObject) ||
      !findAccountObject.merchantId ||
      !findAccountObject.userId ||
      !findAccountObject.sessionId
    ) {
      return throwError("RequiredDetails Not available");
    }
    return this.mainService.getUserUsedPIQAccounts(findAccountObject).pipe(
      map((usedPIQAccountData) => {
        return usedPIQAccountData;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getEligibleBonus(bonusObject?) {
    return this.mainService.getEligibleBonus(bonusObject).pipe(
      map((usedPIQAccountData) => {
        return usedPIQAccountData;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getUserPIQMethods(findPaymentMethods) {
    if (
      !findPaymentMethods ||
      _.isEmpty(findPaymentMethods) ||
      !findPaymentMethods.merchantId ||
      !findPaymentMethods.userId ||
      !findPaymentMethods.sessionId
    ) {
      return throwError("RequiredDetails Not available");
    }
    return this.mainService.getUserPIQMethods(findPaymentMethods).pipe(
      map((userPIQMethods) => {
        return userPIQMethods;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  makePayment(paymentRequestObj) {
    return this.mainService.makePayment(paymentRequestObj).pipe(
      map((paymentResponse) => {
        return paymentResponse;
      }),
      catchError((error) => {
        return throwError(`payment Reqiest failed for provider-{{paymentRequestObj.providerType}}
      & method-{{ paymentRequestObject.paymentMethod}}`);
      })
    );
  }

  getPIQTransactionStatus(requestObj) {
    return this.mainService.getPIQTransactionStatus(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(
          "something went wrong in fetch piq transaction status"
        );
      })
    );
  }

  getIframe(redirectOutput) {
    let $content;
    let $form;
    if (!redirectOutput["html"] && _.isEmpty(redirectOutput["parameters"])) {
      if (redirectOutput["container"] !== "window") {
        $content = $(
          "<" +
            redirectOutput["container"] +
            ' name="cciFrame" id="loaderIframe" src="' +
            redirectOutput["url"] +
            '" frameborder="0"   ></' +
            redirectOutput["container"] +
            ">"
        );
      } else {
        window.location.href = redirectOutput["url"];
      }
    } else {
      $content = $(
        "<" +
          redirectOutput["container"] +
          ' name="cciFrame" id="loaderIframe" src="" frameborder="0"  ></' +
          redirectOutput["container"] +
          ">"
      );
      if (redirectOutput["html"]) {
        $content = redirectOutput["html"];
      } else {
        $form =
          '<form id="proxy_redirect" method="' +
          redirectOutput["method"] +
          '" action="' +
          redirectOutput["url"] +
          '" class="hide" target="_self">';
        _.forEach(redirectOutput["parameters"], function (value, key) {
          if (value != null) {
            $form =
              $form +
              ('<input type="hidden" name="' +
                key +
                '" value="' +
                value +
                '" />');
          }
        });
        $form = $form + "</form>";
        if (!redirectOutput["container"].includes("window")) {
          $form = $($form);
        }
      }
    }

    return { content: $content, form: $form };
  }

  getPaymentMethodsBasedOnBonus(selectedBonusData, paymentIQMethods) {
    const filteredMethods = {
      piqMethods: [],
      non_applicable_bonus_piqMethods: [],
    };
    if (
      _.isEmpty(selectedBonusData) ||
      selectedBonusData.depositOptions[0] === "ANY"
    ) {
      filteredMethods.piqMethods = paymentIQMethods;
      return filteredMethods;
    }
    if (!_.isEmpty(selectedBonusData) && selectedBonusData.depositOptions) {
      paymentIQMethods.forEach((method, index) => {
        if (
          selectedBonusData.depositOptions.indexOf(method.providerType) > -1 ||
          selectedBonusData.depositOptions.indexOf(
            method.providerType + "_HOSTED"
          ) > -1
        ) {
          filteredMethods.piqMethods.push(paymentIQMethods[index]);
        } else {
          filteredMethods.non_applicable_bonus_piqMethods.push(
            paymentIQMethods[index]
          );
        }
      });
      return filteredMethods;
    }
  }

  getBonusDescription(
    bonusData,
    inputAmount,
    currencyCode,
    currencySymbol,
    selectedPaymentMethod
  ) {
    /* checking whther this bonus is eligible for this selected payment method or not
     * Here we add _HOSTED at end of payment selected Method while comparing..because we get PIQ methods as _hosted at end
     * to differentiate from PS payment methods.
     */
    if (
      bonusData.depositOptions.indexOf("ANY") ||
      bonusData.depositOptions.indexOf(selectedPaymentMethod + "_HOSTED")
    ) {
      /* Fetching cuurencyCode bases currencytier
       * & sending message
       * */
      if (bonusData && currencyCode && bonusData.currencyTier) {
        const bonusCurrencyTier = bonusData.currencyTier[currencyCode][0];
        if (bonusCurrencyTier) {
          const minDepositValue = bonusCurrencyTier.minDepositValue / 100;
          const maxDepositValue = bonusCurrencyTier.maxDepositValue / 100;
          const maxOfferValue = bonusCurrencyTier.maxOfferValue / 100;
          if (
            bonusData.amountType === "ABS" &&
            inputAmount >= minDepositValue
          ) {
            const data = {
              offerType: bonusData.amountType,
              maxOfferValue,
              amountAfterBonusApplied:
                Number(maxOfferValue) + Number(inputAmount),
            };
            return data;
          } else if (
            bonusData.amountType === "PCT" &&
            inputAmount >= minDepositValue
          ) {
            const percentageValue = bonusCurrencyTier.percentageValue;
            let offeredValue = (inputAmount * percentageValue) / 100;
            if (offeredValue > maxOfferValue) {
              offeredValue = maxOfferValue;
            }
            const data = {
              offerType: bonusData.amountType,
              maxOfferValue: offeredValue,
              amountAfterBonusApplied:
                Number(offeredValue) + Number(inputAmount),
            };
            return data;
          } else if (inputAmount <= maxDepositValue) {
            return "remove-bonus";
          } else {
            return {};
          }
        }
      }
    } else {
      return "";
    }
  }

  deleteSavedPaymentMethod(deleteAccountObject) {
    return this.mainService.deletePayamenMethod(deleteAccountObject).pipe(
      map((deleteResponse) => {
        return deleteResponse;
      }),
      catchError((error) => {
        return throwError(`Unable to delete the payment Method`);
      })
    );
  }

  getPendingWithdrawals(pendingWithdrawalObject) {
    return this.mainService.getPendingWithdrawal(pendingWithdrawalObject).pipe(
      map((pendingWithdrawalResponse) => {
        return pendingWithdrawalResponse;
      }),
      catchError((error) => {
        return throwError(`Unable to get pending withdrawals`);
      })
    );
  }

  cancelTransaction(cancelTransactionObject) {
    return this.mainService.cancelTransaction(cancelTransactionObject).pipe(
      map((pendingWithdrawalResponse) => {
        return pendingWithdrawalResponse;
      }),
      catchError((error) => {
        return throwError(`Unable to get pending withdrawals`);
      })
    );
  }
}
