import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { Subscription } from "rxjs";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Constants
import { UserFlowTypes } from "src/app/constants/costants";

// Environments
import { environment } from "src/environments/environment";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { AccountService } from "src/app/modules/auth/services/account.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { CustomValidators } from "src/app/utility/custom-validator";

@Component({
  selector: "app-self-exclusion",
  templateUrl: "./self-exclusion.component.html",
  styleUrls: ["./self-exclusion.component.scss"],
  providers: [DatePipe],
})
export class SelfExclusionComponent extends FormValidationComponent
  implements OnInit {
  suspendAccountForm = this.formBuilder.group({
    rgCoolOffPeriodInDays: ["", [this.customValidators.required]],
    rgClose: ["false"],
    reason: ["Self Exclusion", [this.customValidators.required]],
    password: ["", [this.customValidators.required]],
  });
  serverError: any;
  startDate: Date | string;
  endDate: Date | string;
  isPassword: boolean = false;
  selfExclusionPopup: boolean = false;
  zimplerMarkets: string[];
  langCode;
  langCodeSubscription: Subscription;
  UserFlowTypes: typeof UserFlowTypes;
  constructor(
    public utils: UtilityService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private accountService: AccountService,
    private customValidators: CustomValidators,
    private translationService: TranslationService
  ) {
    super(utils);
    this.zimplerMarkets = environment.zimplerMarkets;
    this.UserFlowTypes = UserFlowTypes;
    this.langCode = this.utils.getLangCode();
    this.langCodeSubscription = this.translationService.langCode$.subscribe(
      (langcode) => {
        this.langCode = langcode;
      }
    );
  }

  ngOnInit() {}

  getExclusionPeriod() {
    return this.suspendAccountForm.controls["rgCoolOffPeriodInDays"].value;
  }

  setExclusionPeriod(period) {
    if (
      period !== this.suspendAccountForm.controls["rgCoolOffPeriodInDays"].value
    ) {
      this.calculateDateRange(period);
      this.suspendAccountForm.controls["rgCoolOffPeriodInDays"].setValue(
        period
      );
    } else {
      this.clearExclusionPeriod();
    }
  }

  openConfirmationDailogue() {
    if (this.suspendAccountForm.controls["rgCoolOffPeriodInDays"].value) {
      this.selfExclusionPopup = true;
    }
  }

  closeConfirmationDailogue() {
    this.clearExclusionPeriod();
    this.selfExclusionPopup = false;
  }

  clearExclusionPeriod() {
    this.suspendAccountForm.controls["password"].setValue("");
    this.suspendAccountForm.controls["rgCoolOffPeriodInDays"].setValue("");
    this.suspendAccountForm.controls["password"].markAsUntouched({
      onlySelf: true,
    });
    this.suspendAccountForm.controls["password"].markAsPristine({
      onlySelf: true,
    });
    this.startDate = undefined;
    this.endDate = undefined;
  }

  calculateDateRange(period) {
    let days = period;
    this.startDate = this.datePipe.transform(new Date(), "dd/MM/yyyy HH:mm");
    this.endDate = new Date();
    this.endDate.setDate(this.endDate.getDate() + days);
    this.endDate = this.datePipe.transform(this.endDate, "dd/MM/yyyy HH:mm");
  }

  confirmSuspendAccount() {
    if (this.suspendAccountForm.valid || this.utils.isPnpFlow()) {
      this.selfExclusionPopup = false;
      let data = this.utils.formControlToParams(this.suspendAccountForm, {});
      this.accountService.suspendAccount(data).subscribe((data) => {
        if (
          data &&
          data["success"] &&
          data["success"]["status"] === "SUCCESS"
        ) {
          this.utils.closeAccountComponent();
          this.commonService.doLogout();
        } else {
          this.clearExclusionPeriod();
          this.serverError = data["errors"]["password"];
        }
      });
    }
    setTimeout(() => {
      this.serverError = undefined;
    }, 5000);
  }

  isButtonDisabled(formName) {
    if (!formName.controls["rgCoolOffPeriodInDays"].value) {
      return this.utils.isButtonDisabled(formName);
    }
  }
}
