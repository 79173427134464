export interface LeaderBoardUser {
  id: string;
  userName: string;
  points: number;
  position?: number;
}

export interface ApiLeaderBoardUser {
  Record: string;
  UserName: string;
  Points: number;
  Position?: number;
}

export enum LeaderBoardUserStatus {
  notvalid = "notvalid",
  valid = "valid",
  enrolled = "enrolled",
}

export enum ApiLeaderBoardEvents {
  validUser = "LeaderBoard Valid User",
  enrolUser = "LeaderBoard Enrol User",
  getBoard = "LeaderBoard Get Board",
}

export interface ApiLeaderBoardQueryParams {
  event: ApiLeaderBoardEvents;
  async: "true" | "false";
}

export interface ApiLeaderBoardValidUserResponse {
  status: LeaderBoardUserStatus;
}

export interface ApiLeaderBoardValidUserBodyParams {
  Board: string;
  UserID: string;
}

export interface ApiLeaderBoardEnrolUserResponse {
  status: LeaderBoardUserStatus;
}

export interface ApiLeaderBoardEnrolUserBodyParams {
  Board: string;
  UserID: string;
  Username: string;
}

export interface ApiLeaderBoardGetBoardResponse {
  results: ApiLeaderBoardUser[];
  userpoints: ApiLeaderBoardUser[];
}

export interface ApiLeaderBoardGetBoardBodyParams {
  Board: string;
  UserID: string;
}

export enum EnrolledDisplayMode {
  Table = "table",
  Link = "link",
}
