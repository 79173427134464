import { Component, ViewEncapsulation, OnInit, OnDestroy } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute, Params } from "@angular/router";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";

// Configurations
import { endpointConfigurations } from "src/app/configurations/endpoint.configurations";

// Libraries
import { LivespinsSDK } from "livespins/@livespins";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { LivespinsService } from "src/app/modules/livespins/services/livespins-service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { MainService } from "src/app/modules/shared/services/main.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-livespins-page",
  templateUrl: "./livespins-page.component.html",
  styleUrls: ["livespins-page.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LiveSpinsPageComponent implements OnInit, OnDestroy {
  private params: Params;

  isLoggedIn: boolean = false;
  isLoading: boolean = true;

  urlSafe: SafeResourceUrl = "";

  originalThemeColor: string;
  noGameUrlError: string;
  authToken: string;
  sessionId: string;
  iframeURL: string;
  langCode: string;
  shareURL: string;
  language: string;
  backURL: string;
  
  livespinsSDKSubscription: Subscription;
  livespinsBetSubscription: Subscription;
  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private livespinsService: LivespinsService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private mainService: MainService,
    private utility: UtilityService
  ) {}

  ngOnInit(): void {
    // We only want a passive initialisation here to set the correct service
    // urls, we do not need the websocket active for live updates.
    this.livespinsService.initialise();

    this.isLoggedIn = this.utility.isUserLoggedIn();

    this.subscriptions = [
      this.activatedRoute.params.subscribe((params: Params) => {
        this.params = params;
      }),
      this.commonService.loginComplete$.subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      }),
      this.commonService.logOutComplete$.subscribe(() => {
        this.isLoggedIn = false;
      }),
    ];

    this.langCode = this.utility.getLangCode();

    this.originalThemeColor = document
      .querySelector('meta[name="theme-color"]')
      .getAttribute("content");

    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", "#0D0D10");
    document.querySelector("body").classList.add("livespins");

    // @ts-ignore
    window.prerenderReady = false;

    if (this.isLoggedIn) {
      this.initializeLoggedInArea();
    } else {
      this.commonService.broadCastActiveAcountMenu("login");
    }
  }

  initializeLoggedInArea(): void {
    this.livespinsBetSubscription = this.mainService
      .psGet(endpointConfigurations.getRealgame_url, {
        gameSymbol: "livespinsBetBehind",
      })
      .subscribe((realGameResponse) => {
        if (!realGameResponse.gameDetails.url) {
          this.noGameUrlError = this.translationService.instant(
            "gameplay.gamenot_found"
          );
          return;
        }

        this.language =
          LivespinsService.langCodeToLivespinsLanguageCode[this.langCode] ||
          "en";
        this.backURL = `${window.location.origin}/game-redirect.html?to=${this.langCode}/casino`;
        this.shareURL = `${window.location.origin}/${this.langCode}/livespins/:sessionId`;
        this.authToken = realGameResponse.gameDetails.url
          .split("&")[2]
          .replace("token=", "");
        this.sessionId = this.params.id;

        this.isLoading = false;

        this.invokeLivespinsSDK();
      });
  }

  invokeLivespinsSDK(): void {
    this.livespinsSDKSubscription = this.livespinsService.sdkSubject
      .pipe(filter((x: LivespinsSDK | null) => x !== null))
      .subscribe((value: LivespinsSDK | null) => {
        this.iframeURL = value.convert.toPlayURL({
          authToken: this.authToken,
          backURL: this.backURL,
          shareURL: this.shareURL,
          sessionId: this.sessionId,
        });
      });
  }

  ngOnDestroy(): void {
    if (this.livespinsSDKSubscription) this.livespinsSDKSubscription.unsubscribe();
    if (this.livespinsBetSubscription) this.livespinsBetSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());

    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", this.originalThemeColor);

    document.querySelector("body").classList.remove("livespins");
  }
}
