import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  EventEmitter,
  SimpleChange,
  Component,
  OnChanges,
  OnInit,
  Output,
  Input,
} from "@angular/core";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-sidemenu-modal",
  templateUrl: "./sidemenu-modal.component.html",
  styleUrls: ["./sidemenu-modal.component.scss"],
})
export class SidemenuModalComponent implements OnInit, OnChanges {
  @Input() customClass: string = "";
  @Input() sideMenuTitle: string = "";
  @Input() callingFrom;
  @Input() showBackBtn: boolean = true;
  @Input() showFooter: boolean = false;
  @Input() show2LineHeaderTitle: boolean = false;
  @Output() callBackButton: EventEmitter<any> = new EventEmitter();
  langCode: string;
  langCodeSubscription: Subscription;
  constructor(
    private utils: UtilityService,
    private router: Router,
    private translationService: TranslationService
  ) {
    this.langCode = this.utils.getLangCode();
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
  }

  ngOnInit(): void {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["callingFrom"]) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
    if (changes["showBackBtn"]) {
      this.showBackBtn = changes["showBackBtn"].currentValue;
    }
    if (changes["sideMenuTitle"]) {
      this.sideMenuTitle = changes["sideMenuTitle"].currentValue;
    }
  }

  closeComponent(navigateTo?) {
    if (navigateTo && this.callingFrom === "betHistory") {
      this.callBackButton.emit();
    } else if (this.callingFrom === "resetPassword") {
      this.router.navigate([this.langCode + "/casino"]);
    } else {
      this.utils.closeAccountComponent(navigateTo);
    }
  }
}
