import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, Subscription } from "rxjs";
import {
  HostListener,
  SimpleChange,
  Component,
  OnDestroy,
  OnChanges,
  OnInit,
  Input,
} from "@angular/core";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Configurations
import { withdrawalMethodsConfigurations } from "src/app/configurations/cashier-config/withdrawal-method-input-fields.configurations";
import { depositMethodsConfigurations } from "src/app/configurations/cashier-config/deposit-method-input-fields.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";
import * as $ from "jquery";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { CustomValidators } from "src/app/utility/custom-validator";

declare let encryptData: any;
interface PIQObject {
  sessionId: string;
  userId: string;
  merchantId: string;
  method: string;
}

/**
 * We are not using this component now but we may use it later
 * when we plane to convert hosted cashier to our own custom cashier
 * so please don't delete this
 *
 * may be useful later..
 */

@Component({
  selector: "app-cashier",
  templateUrl: "./cashier.component.html",
  styleUrls: ["./cashier.component.scss"],
})
export class CashierComponent extends FormValidationComponent
  implements OnInit, OnChanges, OnDestroy {
  filteredPaymentPIQMethods: {
    piqMethods: any[];
    non_applicable_bonus_piqMethods: any[];
  };
  depositLimitWarningMsg = {
    warningMessage: "",
    amount: 0,
    providerType: "",
    service: "",
  };
  selectedPIQMethod: any;
  redirectionTimer: number;
  currencySymbolSub: Subscription;
  currencyCodeSub: Subscription;
  makePaymentAjaxSub: Subscription;
  piqTransactionStatusSub: Subscription;
  forceGetUserprofileSub: Subscription;
  deleteSavedPaymentSub: Subscription;
  timerInstance: any;
  readonly DEFAULT_MIN_PAYMENT_AMOUNT = 1;
  isAmountFocused = false;
  mediaUrlPath: string = environment.mediaUrlPath;
  isFrontEndMethodConfigExist;
  activeDepositBonus: any;
  imgixParams: string;
  changeMethodBonusWarningDialog = false;
  depositLimitWarningDialog = false;
  bonusAmountWarningDialog = false;

  constructor(
    private cashierService: CashierService,
    private userDetailsService: UserDetailsService,
    private commonService: CommonService,
    private customValidators: CustomValidators,
    public utils: UtilityService,
    private fb: FormBuilder,
    private currencyFormatPipe: CurrencyFormatPipe,
    private translate: TranslateService
  ) {
    super(utils);
    this.currencySymbolSub = this.userDetailsService.currencySymbolSb$.subscribe(
      (currencySymbol) => {
        this.currencySymbol = currencySymbol;
      }
    );
    this.currencyCodeSub = this.userDetailsService.currencyCodeSb$.subscribe(
      (curreny) => {
        this.currencyCode = curreny;
      }
    );
    this.getWindowType();

    this.imgixParams = this.utils.getImgixParams();
  }

  @Input() cashierType;
  @Input() cashierStep;
  form: FormGroup;
  piqObject: PIQObject = {
    sessionId: "",
    userId: environment.paymentIqUID,
    merchantId: environment.paymentIqMID,
    method: "",
  };
  PIQMethods;
  ispaymentMethodsFecting = false;
  loadingMessage;
  currencySymbol: string;
  cashierDetailsForm = this.fb.group({
    // 'amount': ['', [this.customValidators.minValueNumber(50, 1000, "amount")]]
    amount: [
      0,
      [
        this.customValidators.minValueNumber(
          this.DEFAULT_MIN_PAYMENT_AMOUNT,
          1000,
          ""
        ),
      ],
    ],
  });
  cashierAttributesForm = this.fb.group({
    successUrl: [""],
    failureUrl: [""],
    pendingUrl: [""],
    cancelUrl: [""],
    labelId: [""],
    productId: [""],
    ipAddr: [""],
    bnsCode: [""],
  });
  txnId: any;
  txnRefId: any;
  txnStatus: boolean;
  redirection: boolean;
  selectedPaymentMethods: any;
  selectedPaymentInputs: any;
  avialableDepositBonusDetails: any;
  transactionStatusData: { status: string; successAmount: any };
  windowType: string;
  selectedBonus: any;
  currencyCode: string;
  stepType = "step1";
  clearSelectedBonus: boolean = false;

  isShowAllpaymentMethods = false;

  bonusOfferData;
  transactionStatusInterval;
  transactionStatusIntervalCounter = 0;

  userUsedPaymentMethods = [];
  selectedUsedPaymentMethod;
  providerTypeGroupedPaymentmethods;
  isSavedPaymentListOpen = false;
  flipToShowUsedFormFields = false;
  fee;
  balanceDetails;
  viewBalInfo = false;

  bonusWarningMessage = {
    message: "",
    amount: 0,
    isEligible: false,
    providerType: "",
    service: "",
  };

  @HostListener("window:resize") onResize() {
    this.getWindowType();
  }

  @HostListener("window:message", ["$event"]) onMessage(e) {
    if (e && e.data && e.data.message === "deposit-transactionStatus") {
      this.getUserCurrentTransactionstatus();
    }
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["cashierType"]) {
      this.cashierType = changes["cashierType"].currentValue;
      if (this.cashierType === "deposit") {
        this.isFrontEndMethodConfigExist = depositMethodsConfigurations;
      } else if (this.cashierType === "withdrawal") {
        this.isFrontEndMethodConfigExist = withdrawalMethodsConfigurations;
      }
    }
  }

  ngOnInit() {
    const profileDetails = this.userDetailsService.getUserProfileDetails();
    this.ispaymentMethodsFecting = true;
    this.loadingMessage = this.translate.instant(
      "cashier.fetchingPymentMethods"
    );
    this.balanceDetails = this.userDetailsService.getUserBalanceDetails();
    if (!_.isEmpty(profileDetails) && !_.isEmpty(this.balanceDetails)) {
      this.piqObject.userId = profileDetails.playerID;
      this.getCashierToken();
    } else {
      const getTokenObservable = this.cashierService.getToken({
        token: "cashier",
      });
      const getProfileDataObservable = this.commonService.getProfileBalanceCurrency();
      const getUserbalanceBypockets = this.commonService.getUserBalanceByPockets();
      Promise.all([
        getTokenObservable,
        getProfileDataObservable,
        getUserbalanceBypockets,
      ]).then((results) => {
        const profileData: any = results[1];
        if (
          profileData &&
          profileData.profile &&
          profileData.profile.playerID &&
          results[2]
        ) {
          this.balanceDetails = this.userDetailsService.getUserBalanceDetails();
          this.piqObject.userId = profileData.profile.playerID;
          const tokenData = results[0];
          this.parseTokenData(tokenData);
        }
      });
    }
  }

  getCashierToken() {
    Promise.resolve(this.cashierService.getToken({ token: "cashier" })).then(
      (tokenDetails: any) => {
        if (
          tokenDetails.status === "SUCCESS" &&
          tokenDetails.token &&
          tokenDetails.token !== ""
        ) {
          this.parseTokenData(tokenDetails);
          this.piqObject.sessionId = tokenDetails.token;
          /**
           * payment related redirection url are fetch from CMS & passed to
           * paymentIQ so that they rediect back to this url after succes or failure
           * any of above cases..
           */
          this.cashierAttributesForm.controls.successUrl.setValue(
            tokenDetails.cashierCallbackUrl
          );
          this.cashierAttributesForm.controls.failureUrl.setValue(
            tokenDetails.cashierCallbackUrl
          );
          this.cashierAttributesForm.controls.pendingUrl.setValue(
            tokenDetails.cashierCallbackUrl
          );
          this.cashierAttributesForm.controls.cancelUrl.setValue(
            tokenDetails.cashierCallbackUrl
          );
          this.cashierAttributesForm.controls.labelId.setValue(
            tokenDetails.labelId
          );
          this.cashierAttributesForm.controls.productId.setValue(
            tokenDetails.productId
          );
          this.cashierAttributesForm.controls.ipAddr.setValue(
            tokenDetails.ipAddr
          );
        } else {
          this.ispaymentMethodsFecting = false;
          this.autoFillMinimunDepositeAmount();
          this.PIQMethods = [];
        }
      },
      (usedAccountError) => {}
    );
  }

  parseTokenData(tokenDetails) {
    if (
      tokenDetails.status === "SUCCESS" &&
      tokenDetails.token &&
      tokenDetails.token !== ""
    ) {
      this.piqObject.sessionId = tokenDetails.token;
      this.piqObject.merchantId = environment.paymentIqMID;
      const forkDetailsArray = [];
      if (!this.cashierStep || this.cashierStep !== "transaction") {
        if (this.cashierType === "deposit") {
          this.piqObject.method = "Deposit";
        } else if (this.cashierType === "withdrawal") {
          this.piqObject.method = "Withdrawal";
        }
        forkDetailsArray.push(
          this.cashierService.getUserUsedPIQAccounts(_.clone(this.piqObject))
        );
        forkDetailsArray.push(
          this.cashierService.getUserPIQMethods(_.clone(this.piqObject))
        );
        if (this.cashierType === "deposit") {
          forkDetailsArray.push(
            this.cashierService.getEligibleBonus({ criteriaType: "DEPOSIT" })
          );
        }
        forkJoin(forkDetailsArray).subscribe((results) => {
          let eligibleBonusDetails = {};
          if (forkDetailsArray.length === 2) {
            this.setPaymentmethods(results[1], results[0]);
          } else if (
            forkDetailsArray.length === 3 &&
            results[2]["status"] === "SUCCESS"
          ) {
            this.setPaymentmethods(results[1], results[0]);
            eligibleBonusDetails = results[2]["eligibleBonusList"];
            this.processEligibleBonusDetails(eligibleBonusDetails);
          }
        });
      } else if (this.cashierStep && this.cashierStep === "transaction") {
        this.getUserCurrentTransactionstatus();
      }
    } else {
      this.PIQMethods = [];
    }
  }

  setPaymentmethods(paymentmethods, usedAccounts) {
    this.autoFillMinimunDepositeAmount();
    if (paymentmethods.success && paymentmethods.methods.length > 0) {
      this.PIQMethods = paymentmethods.methods;
      this.providerTypeGroupedPaymentmethods = {}; // _.groupBy(this.PIQMethods, 'providerType');
      this.PIQMethods.forEach((method) => {
        if (method.service) {
          this.providerTypeGroupedPaymentmethods[
            method.providerType + "_" + method.service.toUpperCase()
          ] = method;
        } else {
          this.providerTypeGroupedPaymentmethods[method.providerType] = method;
        }
      });
      if (this.PIQMethods) {
        this.filterPaymentMethodsBasedOnBonus();
      }
      this.setUserUsedPaymentMethods(usedAccounts);
      let type = "";
      let usedAccount = false;
      let service = "";
      if (
        this.userUsedPaymentMethods &&
        this.userUsedPaymentMethods.length > 0
      ) {
        type = this.userUsedPaymentMethods[0].type.toUpperCase();
        usedAccount = true;
      } else {
        type = this.PIQMethods[0].providerType;
        service = this.PIQMethods[0].service;
        usedAccount = false;
      }
      this.selectMethod(true, type, service, usedAccount, 0);
    } else {
      this.PIQMethods = [];
    }
    if (
      this.selectedPaymentMethods &&
      this.selectedPaymentMethods.limit &&
      this.selectedPaymentMethods.limit.min &&
      this.selectedPaymentMethods.limit.min > this.DEFAULT_MIN_PAYMENT_AMOUNT
    ) {
      this.autoFillMinimunDepositeAmount(this.selectedPaymentMethods.limit.min);
    }
    this.ispaymentMethodsFecting = false;
    setTimeout(() => {
      this.setInputWidthResize();
      this.setAmountFocus();
    });
  }

  cashierInputWidth;

  setUserUsedPaymentMethods(usedAccounts) {
    if (
      usedAccounts &&
      usedAccounts.success &&
      usedAccounts.accounts.length > 0
    ) {
      this.userUsedPaymentMethods = _.filter(
        usedAccounts.accounts,
        (account) => {
          if (
            account.status === "ACTIVE" &&
            account.visible &&
            !account.expired
          ) {
            if (
              account.service &&
              this.providerTypeGroupedPaymentmethods[
                account.type.toUpperCase() + "_" + account.service.toUpperCase()
              ]
            ) {
              return true;
            } else if (
              this.providerTypeGroupedPaymentmethods[account.type.toUpperCase()]
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      );
    } else {
      this.userUsedPaymentMethods = [];
    }
  }

  processEligibleBonusDetails(bonusDeatils) {
    this.avialableDepositBonusDetails = _.filter(bonusDeatils, {
      criteriaType: "DEPOSIT",
    });
    this.activeDepositBonus = this.commonService.getActiveDepositBonus();
    if (this.activeDepositBonus) {
      this.selectedBonusHandler(this.activeDepositBonus);
    }
  }

  selectMethod(initial, type, service, usedAccount, usedAccountIdex?) {
    this.flipToShowUsedFormFields = false;
    let inputFieldReturn;
    if (usedAccount) {
      this.selectedUsedPaymentMethod = usedAccountIdex
        ? this.userUsedPaymentMethods[usedAccountIdex]
        : this.userUsedPaymentMethods[0];
      type = this.selectedUsedPaymentMethod.type.toUpperCase();
      service = this.selectedUsedPaymentMethod.service;
    } else {
      this.selectedUsedPaymentMethod = undefined;
    }
    if (this.PIQMethods && type) {
      const findPaymentMethodObject = { providerType: type };
      if (service) {
        findPaymentMethodObject["service"] = service;
      }
      const paymentData = _.findWhere(this.PIQMethods, findPaymentMethodObject);
      this.selectedPaymentMethods = paymentData;
      const providerType = paymentData.service
        ? paymentData.providerType + "_" + paymentData.service + "_PIQ"
        : paymentData.providerType + "_PIQ";
      if (
        (this.cashierType === "deposit" && depositMethodsConfigurations[providerType]) ||
        (this.cashierType === "withdrawal" && withdrawalMethodsConfigurations[providerType])
      ) {
        inputFieldReturn = this.selectInputFields(
          providerType,
          this.cashierType,
          usedAccount
        );
        this.selectedPaymentInputs = inputFieldReturn.selectedInputs;
      } else {
        this.selectedPaymentInputs = undefined;
      }
      if (this.selectedPaymentMethods && this.selectedPaymentMethods.limit) {
        let minLimit = this.selectedPaymentMethods.limit.min;
        let maxLimit = this.selectedPaymentMethods.limit.max;
        if (minLimit && maxLimit) {
          this.cashierDetailsForm.controls.amount.setValidators([
            this.customValidators.minValueNumber(minLimit, maxLimit, ""),
          ]);
        }
      }
      this.form = this.createGroup(true);
      if (inputFieldReturn && inputFieldReturn.accountIdAvailable) {
        this.form.controls.accountId.setValue(
          this.selectedUsedPaymentMethod.accountId
        );
      }
    }

    if (!usedAccount && this.stepType === "step1" && !initial) {
      if (!this.cashierDetailsForm.controls.amount.value) {
        this.autoFillMinimunDepositeAmount();
      }
      this.nextStep("step1");
    }
    this.setFeeForPaymentMethod();
    if (
      !initial &&
      !usedAccount &&
      this.selectedPaymentInputs &&
      this.selectedPaymentInputs.length === 0
    ) {
      this.makeTransaction();
    }
  }

  selectInputFields(providerType, txnType, usedAccount) {
    const selectedInputs = [];
    let selectedMethod = {};
    let accountIdAvailable = false;
    if (txnType === "deposit") {
      /**we need this condition for deep cloning of nested object,
       * To break the reference
       */
      selectedMethod = JSON.parse(JSON.stringify(depositMethodsConfigurations[providerType]));
    } else if (txnType === "withdrawal") {
      selectedMethod = withdrawalMethodsConfigurations[providerType];
    }

    if (selectedMethod && !_.isEmpty(selectedMethod)) {
      _.each(selectedMethod["inputs"], (input) => {
        if (
          !usedAccount &&
          (input.requiredNewPayment === true ||
            (input.requiredNewPayment === "basedOnCountry" &&
              _.contains(
                input.countries,
                this.userDetailsService.getUserProfileDetails().country
              )))
        ) {
          selectedInputs.push(input);
        } else if (usedAccount && input.requiredRepeatPayment === true) {
          if (input.key === "accountId") {
            accountIdAvailable = true;
          }
          selectedInputs.push(input);
        }
      });
    }
    return { selectedInputs, accountIdAvailable };
  }

  setFeeForPaymentMethod() {
    if (
      this.selectedPaymentMethods &&
      this.selectedPaymentMethods.fees &&
      this.selectedPaymentMethods.fees.length > 0
    ) {
      const feeObject = this.selectedPaymentMethods.fees[0];
      if (feeObject.percentageFee) {
        const amount = this.cashierDetailsForm.controls.amount.value;
        if (amount) {
          const calculatedFee = amount * feeObject.percentageFee;
          if (feeObject.fixedFeeMin && calculatedFee < feeObject.fixedFeeMin) {
            this.fee = feeObject.fixedFeeMin;
          } else if (
            feeObject.fixedFeeMax &&
            calculatedFee > feeObject.fixedFeeMax
          ) {
            this.fee = feeObject.fixedFeeMax;
          } else {
            this.fee = calculatedFee;
          }
        } else {
          this.fee = 0;
        }
      } else {
        this.fee = feeObject.fixedFee;
      }
    } else {
      this.fee = 0;
    }
  }

  getWindowType() {
    const ww = document.body.clientWidth;
    if (ww <= 1024) {
      this.windowType = "mobile";
    } else {
      this.windowType = "device";
    }
  }

  changePaymentMethodHandler(paymentMethodsData) {
    this.selectedPaymentMethods = paymentMethodsData;
  }
  /**Here we validate bonus code eligiblity criteria & before jumping
   * into next to show some confirmation pop=up's
   */
  nextStep(callingFromStep) {
    if (
      this.cashierType === "withdrawal" &&
      (this.cashierDetailsForm.controls.amount.value <= 0 ||
        this.cashierDetailsForm.controls.amount.value >
          this.balanceDetails.withdrawableBalance)
    ) {
      this.cashierDetailsForm.controls.amount.setErrors({
        message: this.translate.instant(
          "cashier.insufficent_fund_for_withdraw"
        ),
      });
    } else {
      if (this.cashierAttributesForm.controls.bnsCode.value) {
        if (this.checkForAppliedBonus("continue-btn")) {
          this.jumpToNextstep();
        }
      } else {
        this.jumpToNextstep();
      }
    }
  }

  jumpToNextstep() {
    if (this.cashierDetailsForm.controls.amount.value) {
      if (this.selectedUsedPaymentMethod) {
        if (this.form.valid) {
          this.makeTransaction();
        } else {
          this.flipToShowUsedFormFields = true;
        }
      } else {
        this.stepType = "step2";
        this.viewBalInfo = false;
        /* Condition to check whther we have active bonus
         * If yes we will filtered applicable bonus methods &
         * non applicable bonus methods in order to display to user...
         */
        if (
          this.cashierAttributesForm.controls.bnsCode.value &&
          this.selectedBonus &&
          this.selectedBonus.depositOptions[0] !== "ANY"
        ) {
          this.cashierService.getPaymentMethodsBasedOnBonus(
            this.selectedBonus,
            this.PIQMethods
          );
        }
        if (this.PIQMethods) {
          this.filterPaymentMethodsBasedOnBonus();
        }
      }
    }
  }

  filterPaymentMethodsBasedOnBonus() {
    this.filteredPaymentPIQMethods = this.cashierService.getPaymentMethodsBasedOnBonus(
      this.selectedBonus,
      this.PIQMethods
    );
  }

  makeTransaction() {
    this.viewBalInfo = false;
    const requestObj = this.processPaymentRequest();
    if (
      this.cashierType === "deposit" ||
      (this.cashierType === "withdrawal" &&
        requestObj.amount > 0 &&
        requestObj.amount <= this.balanceDetails.withdrawableBalance)
    ) {
      this.ispaymentMethodsFecting = true;
      this.loadingMessage = this.translate.instant(
        "cashier.transactionProcess"
      );
      this.makePaymentAjaxSub = this.cashierService
        .makePayment(requestObj)
        .subscribe((cashierResp) => {
          this.txnId = cashierResp.txRefId.substr(
            cashierResp.txRefId.indexOf("A") + 1
          );
          this.txnRefId = cashierResp.txRefId;
          sessionStorage.setItem("_userId", this.piqObject.userId);
          sessionStorage.setItem("_sessionId", this.piqObject.sessionId);
          sessionStorage.setItem("_tid", this.txnId);
          sessionStorage.setItem("_trid", btoa(this.txnRefId));
          sessionStorage.setItem("_tam", btoa(requestObj.amount));

          if (cashierResp && cashierResp.success) {
            if (
              cashierResp.success &&
              cashierResp.txState &&
              cashierResp.txState !== "WAITING_INPUT"
            ) {
              this.getUserCurrentTransactionstatus();
            } else if (
              cashierResp.success &&
              cashierResp.txState &&
              cashierResp.txState === "WAITING_INPUT"
            ) {
              this.ispaymentMethodsFecting = false;
              this.stepType = "step3";
              const contentAndForm = this.cashierService.getIframe(
                cashierResp.redirectOutput
              );
              const $content = contentAndForm.content;
              const $form = contentAndForm.form;
              this.txnStatus = false;
              this.redirection = true;
              setTimeout(() => {
                $("#redirectionDiv").append($content);
                const $iframe = $("#loaderIframe");
                $iframe.ready(() => {
                  $iframe.contents().find("body").length > 0
                    ? $iframe.contents().find("body").append($form)
                    : $iframe.append($form);
                  if (
                    !cashierResp.redirectOutput.html &&
                    ($("#loaderIframe").contents().find("#proxy_redirect")
                      .length ||
                      $("#loaderIframe").find("#proxy_redirect").length)
                  ) {
                    if (
                      $("#loaderIframe").contents().find("#proxy_redirect")
                        .length > 0
                    ) {
                      $("#loaderIframe")
                        .contents()
                        .find("#proxy_redirect")
                        .submit();
                    } else {
                      $("#loaderIframe").find("#proxy_redirect").submit();
                    }
                  }
                });
              }, 1);
            } else {
              let errorMessage = "invalid details";
              if (
                cashierResp.errors &&
                cashierResp.errors[0] &&
                cashierResp.errors[0].msg
              ) {
                errorMessage = cashierResp.errors[0].msg;
              }
              this.showTransactionStatus("failure", undefined);
            }
          } else {
            this.getUserCurrentTransactionstatus();
          }
        });
    } else {
      this.cashierDetailsForm.controls.amount.setErrors({
        message: this.translate.instant(
          "cashier.insufficent_fund_for_withdraw"
        ),
      });
    }
  }

  processPaymentRequest() {
    let dynamicFormValues = {};
    const selectPaymentMethods = this.selectedPaymentMethods.providerType;
    const requestObject = {
      paymentMethod: this.cashierType,
      methodType: "process",
      merchantId: this.piqObject.merchantId
        ? this.piqObject.merchantId
        : environment.paymentIqMID,
      sessionId: this.piqObject.sessionId,
      userId: this.piqObject.userId,
      amount: this.utils.formControlToParams(this.cashierDetailsForm, {})
        .amount,
      attributes: this.utils.formControlToParams(
        this.cashierAttributesForm,
        {}
      ),
      provider: selectPaymentMethods.toLocaleLowerCase(),
    };
    if (this.selectedPaymentMethods.service) {
      requestObject["service"] = this.selectedPaymentMethods.service;
    }
    dynamicFormValues = this.utils.formControlToParams(this.form, {});
    if (selectPaymentMethods === "CREDITCARD") {
      if (dynamicFormValues["cvv"]) {
        dynamicFormValues["encCvv"] = encryptData(dynamicFormValues["cvv"]);
        delete dynamicFormValues["cvv"];
      }

      if (dynamicFormValues["creditcardNumber"]) {
        dynamicFormValues["encCreditcardNumber"] = encryptData(
          dynamicFormValues["creditcardNumber"].replace(" ", "")
        );
        delete dynamicFormValues["creditcardNumber"];
      }

      if (dynamicFormValues["expiryMonthYear"]) {
        const expriyDate = dynamicFormValues["expiryMonthYear"];
        dynamicFormValues["expiryMonth"] = expriyDate.split("/")[0];
        dynamicFormValues["expiryYear"] = "20" + expriyDate.split("/")[1];
        delete dynamicFormValues["expiryMonthYear"];
      }
    }
    if (
      dynamicFormValues["phoneNumber"] &&
      selectPaymentMethods === "MUCHBETTER"
    ) {
      dynamicFormValues["phoneNumber"] = dynamicFormValues["phoneNumber"]
        .trim()
        .replace("+", "")
        .replace(/^0+/, "");
    }
    _.each(dynamicFormValues, (value, key) => {
      if (value === "") {
        delete dynamicFormValues[key];
      }
    });
    return { ...requestObject, ...dynamicFormValues };
  }

  processPaymentResponse() {}

  getButtonDisabled(form) {
    if (
      (form && form.valid) ||
      (this.selectedPaymentInputs && this.selectedPaymentInputs.length === 0)
    ) {
      return false;
    } else {
      return true;
    }
  }

  dynamicFormButtonClicked(message) {
    this.makeTransaction();
  }

  createGroup(isforNewCard) {
    const group = this.fb.group({});
    if (this.selectedPaymentInputs) {
      this.selectedPaymentInputs.forEach((control) => {
        let defaultValue = "";
        if (
          (control.key === "cardHolder" || control.key === "beneficiaryName") &&
          this.userDetailsService.getUserProfileDetails()
        ) {
          const profileDetails = this.userDetailsService.getUserProfileDetails();
          defaultValue =
            profileDetails.firstName + " " + profileDetails.lastName;
        }
        if (
          control.key === "phoneNumber" &&
          this.selectedPaymentMethods.providerType === "MUCHBETTER" &&
          this.userDetailsService.getUserProfileDetails()
        ) {
          var usedCardData;
          if (
            this.userUsedPaymentMethods &&
            this.userUsedPaymentMethods.length > 0
          ) {
            usedCardData = _.find(this.userUsedPaymentMethods, {
              type: this.selectedPaymentMethods.providerType.toLowerCase(),
            });
          }
          if (usedCardData && Object.keys(usedCardData).length > 0) {
            defaultValue = usedCardData["maskedAccount"];
            this.selectedPaymentInputs[0]["readonly"] = true;
          } else {
            const profileDetails = this.userDetailsService.getUserProfileDetails();
            defaultValue =
              profileDetails["cellPhoneAreaCode"] + profileDetails["cellPhone"];
            this.selectedPaymentInputs["readonly"] = false;
          }
        }
        const formControl = new FormControl(
          defaultValue,
          this.getFormControlValidators(control.key)
        );
        group.addControl(control.key, formControl);
      });
    }
    return group;
  }

  getFormControlValidators(fieldName: any) {
    let validator;
    switch (fieldName) {
      case "expiryMonth":
      case "expiryYear":
      case "accountId":
      case "account":
      case "secureID":
      case "countryCode":
      case "voucherNumber":
      case "clearingNumber":
      case "beneficiaryName":
      case "bankName":
      case "bic":
      case "iban":
      case "beneficiaryStreet":
      case "beneficiaryZip":
      case "beneficiaryCity":
      case "beneficiaryState":
      case "beneficiaryCountry":
      case "branchAddress":
      case "branchCode":
      case "bankName":
      case "bankState":
      case "instrumentId":
      case "password":
      case "accountNumber":
      case "ecoBanqUserId":
      case "ecoBanqPassword":
      case "secureId":
      case "walletAddress":
      case "cryptocurrency":
        validator = this.customValidators.required;
        break;
      case "expiryMonthYear":
        validator = this.customValidators.expCardData();
        break;
      case "cvv":
        validator = this.customValidators.exactNumberMatch(3);
        break;
      case "cardHolder":
        validator = this.customValidators.validName(2, 50);
        break;
      case "emailAddress":
        validator = this.customValidators.validateUniqueness(
          "txtEmail",
          null,
          false
        );
        break;
      // validator = this.customValidators.exactNumberMatch(12);
      case "userID":
        validator = this.customValidators.reqMin(7);
        break;
      case "creditcardNumber":
        validator = this.customValidators.exactNumberMatch(16, true);
        break;
    }
    return validator;
  }
  showAllPaymentMethods() {
    this.isShowAllpaymentMethods = !this.isShowAllpaymentMethods;
  }

  getUserCurrentTransactionstatus() {
    this.loadingMessage = this.translate.instant(
      "cashier.transactionStatusFetch"
    );
    const requestObj = {
      sessionId: this.piqObject.sessionId,
      userId: this.piqObject.userId,
      merchantId: environment.paymentIqMID,
      txRefId: sessionStorage.getItem("_tid"),
    };
    this.piqTransactionStatusSub = this.cashierService
      .getPIQTransactionStatus(requestObj)
      .subscribe(
        (response) => {
          this.ispaymentMethodsFecting = false;
          if (
            response &&
            (response.statusCode === "SUCCESS" ||
              response.statusCode === "WAITING_WITHDRAWAL_APPROVAL" ||
              response.statusCode === "SUCCESS_WAITING_AUTO_CAPTURE") &&
            (response.txState === "SUCCESSFUL" ||
              response.txState === "WAITING_APPROVAL")
          ) {
            this.commonService.getUserBalanceByPockets().then();
            this.showTransactionStatus(
              "Success",
              atob(sessionStorage.getItem("_tam"))
            );
          } else if (response.statusCode === "WAITING_NOTIFICATION") {
            this.transactionStatusInterval = setInterval(() => {
              if (this.transactionStatusIntervalCounter <= 3) {
                this.transactionStatusIntervalCounter++;
                this.getUserCurrentTransactionstatus();
              } else {
                this.showTransactionStatus("failure", undefined);
              }
            }, 2000);
          } else {
            this.showTransactionStatus("failure", undefined);
          }
        },
        (errorResponse) => {
          this.showTransactionStatus("failure", undefined);
        }
      );
  }

  showTransactionStatus(status, successAmount) {
    clearInterval(this.transactionStatusInterval);
    this.transactionStatusIntervalCounter = 0;
    this.transactionStatusData = {
      status,
      successAmount,
    };

    this.stepType = "step4";
    let count = 6;
    this.timerInstance = setInterval(() => {
      this.redirectionTimer = --count;
      if (this.redirectionTimer === 0) {
        clearInterval(this.timerInstance);
        this.closeComponent();
      }
    }, 1000);
    sessionStorage.removeItem("_userId");
    sessionStorage.removeItem("_sessionId");
    sessionStorage.removeItem("_tid");
    sessionStorage.removeItem("_trid");
    sessionStorage.removeItem("_tam");

    if (status === "Success") {
      this.cashierService.broadCastTransactionSuccessStatus(this.cashierType);
    }
  }

  setInputWidthResize() {
    const self = this;
    $(".setInputWidth")
      .on("input", function () {
        self.checkForAppliedBonus();
        $(this).css("width", $(this).val().length + 0.1 + "ch");
      })
      .trigger("input");
  }

  autoFillMinimunDepositeAmount(amount?) {
    const setAmount = amount ? amount : this.DEFAULT_MIN_PAYMENT_AMOUNT;
    if (
      this.cashierType === "withdrawal" &&
      setAmount > this.balanceDetails.withdrawableBalance
    ) {
      this.cashierDetailsForm.controls.amount.setValue(
        this.balanceDetails.withdrawableBalance
      );
    } else {
      this.cashierDetailsForm.controls.amount.setValue(setAmount);
    }
  }

  updateQuickAmount(value) {
    this.cashierDetailsForm.controls.amount.setValue(value);
    this.setInputWidthResize();
    this.setFeeForPaymentMethod();
  }

  gobackToCashier() {
    this.utils.resetFormFields(this.cashierDetailsForm);
    if (this.form) {
      this.utils.resetFormFields(this.form);
    }
    if (this.cashierAttributesForm.controls.bnsCode.value) {
      this.cashierAttributesForm.controls.bnsCode.setValue("");
      this.clearSelectedBonus = true;
    }

    this.closeComponent();
  }
  closeComponent(navigateTo?) {
    if (this.timerInstance) {
      clearInterval(this.timerInstance);
    }
    this.utils.closeAccountComponent(navigateTo);
  }

  /** Bonus related functionality... */

  selectedBonusHandler(data) {
    this.selectedBonus = data;
    if (data && data.bonusCode) {
      this.cashierAttributesForm.controls.bnsCode.setValue(data.bonusCode);
      this.clearSelectedBonus = false;
    } else {
      this.cashierAttributesForm.controls.bnsCode.setValue("");
      this.clearSelectedBonus = true;
    }
    this.filterPaymentMethodsBasedOnBonus();
    /* after bonus is seleted we call this method to check the eligiblity of bonus
     * & display message
     */
    this.checkForAppliedBonus();
  }

  getMinMaxDepositAmount() {
    if (
      this.selectedBonus &&
      this.selectedBonus.currencyTier &&
      this.currencyCode
    ) {
      const bonusCurrencyTier = this.selectedBonus.currencyTier[
        this.currencyCode
      ][0];
      if (bonusCurrencyTier) {
        return {
          minAmmount: bonusCurrencyTier.minDepositValue / 100,
          maxAmount: bonusCurrencyTier.maxDepositValue / 100,
        };
      }
    }
  }

  checkForAppliedBonus(callingfrom?: string) {
    const amount = this.cashierDetailsForm.controls.amount.value;
    const bonus = this.cashierAttributesForm.controls.bnsCode.value;
    if (amount && bonus && bonus.length > 0) {
      this.bonusOfferData = this.cashierService.getBonusDescription(
        this.selectedBonus,
        amount,
        this.currencyCode,
        this.currencySymbol,
        this.selectedPaymentMethods.providerType
      );
      /**condition to check & clear the bonus code from attribute from...
       * If it's not eligible before jumping to next step
       */
      if (this.bonusOfferData === "remove-bonus") {
        this.cashierAttributesForm.controls["bnsCode"].setValue("");
        this.clearSelectedBonus = true;
        this.bonusOfferData = {};
      }
      if (callingfrom === "continue-btn" && _.isEmpty(this.bonusOfferData)) {
        this.openAmountBonusCheckPopup(amount);
        return false;
      } else {
        return true;
      }
    } else {
      this.bonusOfferData = {};
    }
  }

  amountBonusWarningMessage = {
    message: "",
    amountVal: 0,
  };
  openAmountBonusCheckPopup(amount) {
    const minDepositAmount = this.getMinMaxDepositAmount()["minAmmount"];
    this.amountBonusWarningMessage = {
      message:
        this.translate.instant("cashier.bonus_eligible_min_dopt") +
        this.currencyFormatPipe.transform(
          minDepositAmount,
          this.currencySymbol
        ),
      amountVal: minDepositAmount,
    };
    this.bonusAmountWarningDialog = true;
  }

  closeAmountBonusCheckPopup(callingfrom, amount?) {
    if (callingfrom === "cancel") {
      this.cashierAttributesForm.controls.bnsCode.setValue("");
      this.clearSelectedBonus = true;
    } else if (callingfrom === "change-amount" && amount) {
      this.cashierDetailsForm.controls["amount"].setValue(amount);
    } else {
      this.cashierAttributesForm.controls.bnsCode.setValue("");
      this.clearSelectedBonus = true;
    }
    this.bonusAmountWarningDialog = false;

    this.jumpToNextstep();
  }

  checkDepositLimitsOnChangeMethod(providerType, service) {
    const findPaymentMethodObject = { providerType: providerType };
    if (service) {
      findPaymentMethodObject["service"] = service;
    }
    const paymentMethodData = _.findWhere(
      this.PIQMethods,
      findPaymentMethodObject
    );
    const minlimit = Number(paymentMethodData["limit"]["min"]);
    const maxlimit = Number(paymentMethodData["limit"]["max"]);
    const enterAmount = Number(
      this.cashierDetailsForm.controls["amount"].value
    );
    if (
      enterAmount &&
      !isNaN(enterAmount) &&
      enterAmount >= minlimit &&
      enterAmount <= maxlimit
    ) {
      this.selectMethod(false, providerType, service, false);
    } else if (
      enterAmount &&
      (enterAmount < minlimit || enterAmount > maxlimit)
    ) {
      if (enterAmount && enterAmount < minlimit) {
        const message = this.translate.instant(
          "cashier." + this.cashierType + "LimitsMinWarning",
          {
            amount: this.currencyFormatPipe.transform(
              minlimit,
              this.currencySymbol
            ),
          }
        );
        this.depositLimitWarningMsg = {
          warningMessage: message,
          amount: minlimit,
          providerType: providerType,
          service: service,
        };
      } else if (enterAmount > maxlimit) {
        const message = this.translate.instant(
          "cashier." + this.cashierType + "LimitsMaxWarning",
          {
            amount: this.currencyFormatPipe.transform(
              maxlimit,
              this.currencySymbol
            ),
          }
        );
        this.depositLimitWarningMsg = {
          warningMessage: message,
          amount: maxlimit,
          providerType: providerType,
          service: service,
        };
      }
      this.openDepositLimiWarningPopup();
    } else {
      this.selectMethod(false, providerType, service, false);
    }
  }

  openDepositLimiWarningPopup() {
    this.depositLimitWarningDialog = true;
  }

  closeDepositLimiWarningPopup(callingFrom, providerType, service, amount?) {
    if (callingFrom === "cancel-method") {
      this.depositLimitWarningDialog = false;
    } else if (callingFrom === "change-deposit" && amount) {
      this.cashierDetailsForm.controls["amount"].setValue(amount);
      this.depositLimitWarningDialog = false;
      this.selectMethod(false, providerType, service, false);
    }
  }

  checkBonusOnChangPaymentMethod(providerType, service, callingFrom?) {
    if (
      callingFrom === "non-bonus-mtds" &&
      this.cashierAttributesForm.controls["bnsCode"].value
    ) {
      this.bonusWarningMessage = {
        message: this.translate.instant("cashier.not_eligible_for_payment"),
        amount: 0,
        isEligible: false,
        providerType: providerType,
        service: service,
      };
      this.openBonusLimitWarningPopup();
    } else if (this.cashierAttributesForm.controls["bnsCode"].value) {
      const enterAmount = this.cashierDetailsForm.controls["amount"].value;
      if (
        enterAmount &&
        this.selectedBonus &&
        this.selectedBonus["currencyTier"][this.currencyCode]
      ) {
        const bounusCurrencyTier = this.selectedBonus["currencyTier"][
          this.currencyCode
        ][0];
        const minDepositValue = bounusCurrencyTier["minDepositValue"] / 100;
        const maxDepositValue = bounusCurrencyTier["maxDepositValue"] / 100;
        if (enterAmount < minDepositValue) {
          this.bonusWarningMessage = {
            message:
              this.translate.instant("cashier.only_applicable_with_min_dept") +
              this.currencyFormatPipe.transform(
                minDepositValue,
                this.currencySymbol
              ),
            amount: minDepositValue,
            isEligible: true,
            providerType: providerType,
            service: service,
          };
          this.openBonusLimitWarningPopup();
        } else if (enterAmount > maxDepositValue) {
          this.cashierAttributesForm.controls["bnsCode"].setValue("");
          this.checkDepositLimitsOnChangeMethod(providerType, service);
        } else {
          this.checkDepositLimitsOnChangeMethod(providerType, service);
        }
      }
    } else {
      this.checkDepositLimitsOnChangeMethod(providerType, service);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  openBonusLimitWarningPopup() {
    this.changeMethodBonusWarningDialog = true;
  }

  closeBonusLimitWarningPopup(callingFrom, providerType, service, amount?) {
    if (callingFrom === "change-method") {
      this.cashierAttributesForm.controls["bnsCode"].setValue("");
      this.changeMethodBonusWarningDialog = false;
      this.checkDepositLimitsOnChangeMethod(providerType, service);
    } else if (callingFrom === "change-deposit" && amount) {
      this.cashierDetailsForm.controls["amount"].setValue(amount);
      this.changeMethodBonusWarningDialog = false;
      this.checkDepositLimitsOnChangeMethod(providerType, service);
    } else {
      this.changeMethodBonusWarningDialog = false;
    }
  }

  editPaymetMethod(type) {
    if (type === "open") {
      this.stepType = "edit";
    } else {
      this.stepType = "step1";
    }
  }

  deleteSavedPaymentMethod(accountId) {
    this.ispaymentMethodsFecting = true;
    this.loadingMessage = this.translate.instant(
      "cashier.deletingSavedPaymentMethod"
    );
    const piqObject = _.clone(this.piqObject);
    piqObject.accountId = accountId;
    this.deleteSavedPaymentSub = this.cashierService
      .deleteSavedPaymentMethod(piqObject)
      .subscribe(
        (resposne) => {
          this.cashierService
            .getUserUsedPIQAccounts(_.clone(this.piqObject))
            .subscribe(
              (usedAccounts) => {
                this.setUserUsedPaymentMethods(usedAccounts);
                this.ispaymentMethodsFecting = false;
              },
              (error) => {
                this.userUsedPaymentMethods = [];
              }
            );
        },
        (error) => {
          console.log("Unable to delete pyament Method.");
        }
      );
  }

  backToStep(step) {
    this.selectMethod(false, undefined, undefined, true, 0);
    this.stepType = step;
    setTimeout(() => {
      this.setInputWidthResize();
    });
  }

  setAmountFocus() {
    $("#priceInput").focus();
  }

  clearAmountInputField() {
    this.cashierDetailsForm.controls.amount.reset();
    $("#priceInput").focus();
    $(".setInputWidth").css(
      "width",
      $(".setInputWidth").val().length + 0.1 + "ch"
    );
    this.setInputWidthResize();
    this.setFeeForPaymentMethod();
  }

  preventBlur(event) {
    event.preventDefault();
  }

  ngOnDestroy() {
    this.activeDepositBonus = undefined;
    this.currencySymbolSub.unsubscribe();
    this.currencyCodeSub.unsubscribe();
    if (this.makePaymentAjaxSub) {
      this.makePaymentAjaxSub.unsubscribe();
    }
    if (this.piqTransactionStatusSub) {
      this.piqTransactionStatusSub.unsubscribe();
    }
    if (this.forceGetUserprofileSub) {
      this.forceGetUserprofileSub.unsubscribe();
    }
    if (this.deleteSavedPaymentSub) {
      this.deleteSavedPaymentSub.unsubscribe();
    }
    if (this.commonService.getActiveDepositBonus()) {
      this.commonService.setActiveDepositBonus(undefined);
    }
  }
}
