import { map, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";

// Libraries
import * as _ from "underscore";

// Services
import { MainService } from "src/app/modules/shared/services/main.service";

@Injectable()
export class StaticPageService {
  staticPagesDataByUrlPath = {};
  constructor(private mainService: MainService) {}

  staticPageCallQueue = [];
  getStaticPagep(urlPath, isForce: boolean) {
    return Promise.resolve(
      this.mainService.getStaticPagep({ urlPath: urlPath })
    )
      .then((responseData) => {
        return responseData;
      })
      .catch((error) => {
        return {};
      });
  }

  getFaqQuestionAndCategories() {
    return this.mainService.getFaqQuestionAndCategories().pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
