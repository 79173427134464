import { PnPMethodLogo } from "src/app/modules/account/models/pay-n-play-method-logo.model";

export const pnpMethodLogos: { [key: string]: PnPMethodLogo[] } = {
  // Finland
  FI: [
    { alt: "Nordea", src: "/assets/img/pnp-methods/nordea.svg", class: "nordea" },
    { alt: "OP pohjola", src: "/assets/img/pnp-methods/op-pohjola.svg", class: "oppohjola" },
    { alt: "aktia", src: "/assets/img/pnp-methods/aktia.svg", class: "aktia" },
    { alt: "Alandsbanken", src: "/assets/img/pnp-methods/alandsbanken.svg", class: "alandsbanken" },
    { alt: "sasstopankii", src: "/assets/img/pnp-methods/saastopankki.svg", class: "saastopankki" },
    { alt: "S-PAnkki", src: "/assets/img/pnp-methods/s-pankki.svg", class: "spankki" },
    { alt: "OmaSP", src: "/assets/img/pnp-methods/OmaSP.svg", class: "omasp" },
    { alt: "Danske Bank", src: "/assets/img/pnp-methods/Danske-Bank.svg", class: "danskebank" },
    { alt: "POP Pankki", src: "/assets/img/pnp-methods/pop-pankki.svg", class: "poppankki" },
    { alt: "Handelsbanken", src: "/assets/img/pnp-methods/handelsbanken.svg", class: "handelsbanken" },
  ],
  // Estonia
  EE: [
    { alt: "Swedbank", src: "/assets/img/pnp-methods/swedbank.png", class: "swedbank" },
    { alt: "SEB", src: "/assets/img/pnp-methods/seb.png", class: "seb" },
    { alt: "LHV", src: "/assets/img/pnp-methods/lhv.png", class: "lhv" },
    { alt: "Luminor Bank", src: "/assets/img/pnp-methods/luminor.svg", class: "luminor" },
  ],
};

export const quickPickPnpMethodLogos: { [key: string]: PnPMethodLogo[] } = {
  // Finland
  FI: [
    { src: "/assets/img/pnp/nordea.svg", alt: "Nordea" },
    { src: "/assets/img/pnp/op-pohjola.svg", alt: "OP pohjola" },
    { src: "/assets/img/pnp/Muut-pankit.svg", alt: "Muut pankit" },
  ],
  // Estonia
  EE: [
    { alt: "Swedbank", src: "/assets/img/pnp/swedbank.png", class: "swedbank" },
    { alt: "SEB", src: "/assets/img/pnp/seb-white.png", class: "seb" },
    { alt: "LHV", src: "/assets/img/pnp/lhv-white.png", class: "lhv" },
    { alt: "Luminor Bank", src: "/assets/img/pnp/luminor-white.svg", class: "luminor" },
  ],
};
