import { cmsToLocalLanguageConfigurations, countriesInEurope, countryLanguageConfigurations } from "src/app/configurations/main.configurations";
import { CountryCodes } from "src/app/models/configurations/enums/country-code.enum";
import { LanguageCodes } from "src/app/models/configurations/enums/language-codes.enum";
import { environment } from "src/environments/environment";

export const supportedLanguagesList = (): string[] => {
  let languages: string[] = [
    "en-row",
    "nb-no",
    "fi-fi",
    "en-ca",
    "en-nz",
    "en-ie",
    ...(environment.features.hungarian ? ["hu-hu"] : []),
    ...(environment.features.markets.estonia ? ["et-ee"] : []),
    ...(environment.features.language.europe ? ["en-eu"] : []),
    ...(environment.features.markets.austria ? ["de-eu"] : []),
  ];

  return languages;
};

export const getLanguageForCountry = (countryCode: CountryCodes | undefined): LanguageCodes => {
  if (!countryCode) return environment.defaultLang as LanguageCodes;

  const languageConfigurationForCountry = countryLanguageConfigurations[countryCode];
  if (languageConfigurationForCountry) return cmsToLocalLanguageConfigurations[languageConfigurationForCountry];

  const isCountryFromEurope = countriesInEurope.includes(countryCode);
  if (isCountryFromEurope) return environment.defaultLangEurope as LanguageCodes;

  return environment.defaultLang as LanguageCodes;
}
