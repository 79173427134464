import { Observable, of } from "rxjs";

// Libraries
import { take } from "livespins/@livespins";

// Utilities
import { livespinsByLanguage } from "src/app/modules/livespins/utilities/livespins-filter-by-language.utilities";

export const livespinsFilter = <T>(
  getScheduledStreams: T[],
  languageCode: string,
  top: number = 4
): Observable<T[]> => {
  return of(take(livespinsByLanguage<T>(getScheduledStreams, languageCode), top));
};
