import {
  SimpleChange,
  Component,
  OnChanges,
  Input
} from "@angular/core";

// Services
import { CommonService } from "src/app/modules/shared/services/common.service";

@Component({
  selector: "app-upcoming-reward-card",
  templateUrl: "./upcoming-reward-card.component.html",
  styleUrls: ["./upcoming-reward-card.component.scss"],
})
export class UpcomingRewardCardComponent implements OnChanges {
  @Input() bonusDetails;
  bonusType: string;
  constructor(private commonService: CommonService) {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes["bonusDetails"]) {
      this.bonusDetails = changes["bonusDetails"].currentValue;
      this.bonusType = this.bonusDetails["bonusTypeFD"];
    }
  }
}
