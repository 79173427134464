import { Component, OnInit, EventEmitter, Output } from "@angular/core";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent implements OnInit {
  isLoading: boolean = false;
  showNotification: boolean = true;
  @Output() closeNotifications: EventEmitter<any> = new EventEmitter();

  constructor(private translationService: TranslationService) {}

  ngOnInit() {}

  closeNotificationsHandler(event) {
    this.closeNotifications.emit(event);
  }
}
