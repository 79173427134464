import { UrlSegment } from "@angular/router";
import { URLS_TRANSLATIONS } from "src/app/modules/shared/constants/urls.constant";

export function extractPathUtility(url: UrlSegment[]): string {
  return url[1].path;
}

export function lobbyRouteMatcher(
  url: UrlSegment[]
): {
  consumed: UrlSegment[];
} {
  if (url.length === 2) {
    const path: string = extractPathUtility(url);

    let translationList: string[] = ["casino", "kaszino"];

    if (translationList.includes(path)) {
      return { consumed: url };
    }
  }
  return null;
}

export function liveLobbyRouteMatcher(
  url: UrlSegment[]
): {
  consumed: UrlSegment[];
} {
  if (url.length === 2) {
    const path: string = extractPathUtility(url);

    let translationList: string[] = ["live-casino", "elo-kaszino"];

    if (translationList.includes(path)) {
      return { consumed: url };
    }
  }

  return null;
}

export function allGamePageRouteMatcher(
  url: UrlSegment[]
): {
  consumed: UrlSegment[];
} {
  if (url.length === 3 || url.length === 4) {
    const path: string = extractPathUtility(url);

    let translationList: string[] = ["casino"];

    if (translationList.includes(path)) {
      return { consumed: url };
    }
  }

  return null;
}

export function allGameComponentRouteMatcher(
    url: UrlSegment[]
  ): {
    consumed: UrlSegment[];
  } {
    if (url.length === 2) {
      const path: string = extractPathUtility(url);
  
      let translationList: string[] = ["all", "alle", "kaikki", "osszes"];
  
      if (translationList.includes(path)) {
        return { consumed: url };
      }
    }
    return null;
  };
  
export function gamePageRouteMatcher(
    url: UrlSegment[]
  ): {
    consumed: UrlSegment[];
    posParams: {
      gameId: UrlSegment;
    };
  } {
    if (url.length === 3) {
      const path: string = extractPathUtility(url);
  
      let translationList: string[] = [ "game", "spiel", "spill", "peli", "jatek"];
  
      if (translationList.includes(path)) {
        return {
          consumed: url,
          posParams: {
            gameId: new UrlSegment(url[2].path, {}),
          },
        };
      }
    }
  
    return null;
  };
  
export function gamePlayFullPageRouteMatcher(
    url: UrlSegment[]
  ): {
    consumed: UrlSegment[];
    posParams: {
      gameId: UrlSegment;
    };
  } {
    if (url.length === 3) {
      const path: string = extractPathUtility(url);
  
      if (path === "gamefull") {
        return {
          consumed: url,
          posParams: {
            gameId: new UrlSegment(url[2].path, {}),
          },
        };
      }
    }
  
    return null;
  };
  
export function promotionPageRouteMatcher(
    url: UrlSegment[]
  ): {
    consumed: UrlSegment[];
  } {
    if (url.length === 2) {
      const path: string = extractPathUtility(url);
  
      let translationList: string[] = Object.values(URLS_TRANSLATIONS.promotions);
  
      if (translationList.includes(path)) {
        return {
          consumed: url,
        };
      }
    }
  
    return null;
  };
  
export function promotionContentRouteMatcher(
    url: UrlSegment[]
  ): {
    consumed: UrlSegment[];
    posParams: {
      promoUrl: UrlSegment;
    };
  } {
    if (url.length === 3) {
      const path: string = extractPathUtility(url);
  
      let translationList: string[] = Object.values(URLS_TRANSLATIONS.promotions);
  
      if (translationList.includes(path)) {
        return {
          consumed: url,
          posParams: {
            promoUrl: new UrlSegment(url[2].path, {}),
          },
        };
      }
    }
  
    return null;
  };
  