import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

// Configurations
import { mixPanelEventsConfigurations } from "src/app/configurations/main.configurations";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { MixPanelService } from "src/app/modules/shared/services/mix-panel.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Injectable({
  providedIn: "root",
})
export class DeepLinksService {
  serverError;
  langCode: string;
  isPassword: boolean = true;
  langCodeSubscription: Subscription;
  doLoginSubScription: Subscription;
  isButtonLoader: boolean = false;
  userKycLevelSub: Subscription;
  isLoggedIn;
  loginCompleteSubscription: Subscription;
  constructor(
    private commonService: CommonService,
    private gamePlayService: GamePlayService,
    private router: Router,
    private translationService: TranslationService,
    private mixpanelService: MixPanelService,
    private utils: UtilityService,
    private paynplayCashierService: PayNPlayCashierService
  ) {
    this.langCode = this.utils.getLangCode();
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
    this.isLoggedIn = this.utils.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
  }

  /**
   * Below complete functioanlity deals with deep links before login
   * basically when user try to access few part of product without login
   * we useful ask user to force login before we ask for force login
   * we store it's related data in service data layer & reuse it after successful
   * login so that user lands on exact place where we preferred to be before login
   */

  navigateAfterLogin() {
    const navigateAfterLogin = this.commonService.getNavigateAfterLogin();
    if (navigateAfterLogin && navigateAfterLogin["type"] === "url") {
      this.navigateByUrlData(navigateAfterLogin);
    } else if (navigateAfterLogin && navigateAfterLogin["type"] === "view") {
      this.navigateByViewData(navigateAfterLogin);
    } else if (window.location.pathname) {
      this.naviagateOnCurrentPath();
    }
  }

  navigateByUrlData(navigateAfterLogin) {
    if (navigateAfterLogin["data"]["navigateTo"] === "lobby") {
      this.commonService.broadCastUpdateActiveLobby(navigateAfterLogin["data"]);
      if (navigateAfterLogin["data"]["group"]) {
        this.router.navigate([
          this.langCode + "/casino/" + navigateAfterLogin["data"]["group"],
        ]);
      }
    }

    if (navigateAfterLogin["data"]["navigateTo"] === "gameWindow") {
      this.gamePlayService.setGameCalledfrom("realgame");
      this.sendMixpanelEvent(navigateAfterLogin["data"]);
      this.router.navigate([
        this.langCode +
          "/" +
          this.translationService.instant("url.game") +
          "/" +
          navigateAfterLogin["data"]["path"],
      ]);
    }
  }

  navigateByViewData(navigateAfterLogin) {
    if (navigateAfterLogin["path"] === "confirmidentity") {
      this.checkKycLevelDetails(navigateAfterLogin);
    } else {
      if (navigateAfterLogin["path"] === "rewards") {
        this.utils.updateActiveLeftMenu("rewards");
      }
      if (
        navigateAfterLogin["path"] === "deposit" &&
        navigateAfterLogin["data"]
      ) {
        this.commonService.setActiveDepositBonus({
          bonusCode: navigateAfterLogin["data"]["bonusCode"],
        });
      }
      this.utils.openAccountComponent(navigateAfterLogin["path"]);
    }
  }

  checkKycLevelDetails(navigateAfterLogin) {
    this.userKycLevelSub = this.commonService
      .getUserKycLevelDetails()
      .subscribe((data) => {
        if (!data["enableKyc"]) {
          this.commonService.broadCastActiveAcountMenu("menuOptions");
        } else {
          this.utils.openAccountComponent(navigateAfterLogin["path"]);
        }
      });
  }

  naviagateOnCurrentPath() {
    const pathName = window.location.pathname;
    if (pathName.length <= 7) {
      this.router.navigate([this.langCode + "/casino"]);
    } else if (pathName.includes(this.translationService.instant("url.game"))) {
      let data = this.gamePlayService.getCurrentGameData();
      if (data && data["gameType"]) {
        data["gameType"] = "realgame";
      }
      this.gamePlayService.setGameCalledfrom("realgame");
      this.gamePlayService.setCurrentGameData(data);
      this.gamePlayService.broadCastRelanuchGame(true);
    }
  }

  sendMixpanelEvent(gameDetails) {
    if (Object.keys(gameDetails).length > 0) {
      let eventProperties = {
        name: gameDetails["path"],
        playType: "realgame",
        location: gameDetails["gameGroupName"],
        category: gameDetails["lobbyName"],
      };
      if (this.mixpanelService.getVerticalByUrl()) {
        eventProperties["vertical"] = this.mixpanelService.getVerticalByUrl();
      }
      this.mixpanelService.trackMixpanelEvents(
        mixPanelEventsConfigurations.launched_game,
        eventProperties
      );
    }
  }

  /**
   * Below handler will be called from deep with deposit as tab value and
   * navigate to deposit based on user market where he is..
   */
  navigateToDeposit(queryParams) {
    if (this.utils.isPnpFlow()) {
      this.paynplayCashierService.broadCastInitiateDeposit({
        callingFrom: "deepLink",
      });
    } else {
      this.utils.openAccountComponent("login");
      let navigationData = {
        type: "view",
        path: queryParams["tab"],
      };
      if (
        queryParams["tab"] === "deposit" &&
        queryParams.hasOwnProperty("bonusCode")
      ) {
        navigationData["data"] = { bonusCode: queryParams["bonusCode"] };
      }
      this.commonService.setNavigateAfterLogin(navigationData);
    }
  }

  navigatePromotionQueryParams(path) {
    let navigationData = {
      type: "url",
      data: {
        navigateTo: "promotions",
        path: path,
      },
    };
    this.commonService.setNavigateAfterLogin(navigationData);
    this.router.navigate([
      this.langCode + "/" + this.translationService.instant("url.promotions"),
    ]);
  }

  navigateGamePlayWindowQP(path) {
    let navigationData = {
      type: "url",
      data: {
        navigateTo: "gameWindow",
        path: path.split("/")[3],
      },
    };
    this.commonService.setNavigateAfterLogin(navigationData);
    this.router.navigate([this.langCode + "/casino"]);
  }

  navigateKycLevelQueryParams(queryParams) {
    this.commonService.getUserKycLevelDetails().subscribe((data) => {
      if (!data["enableKyc"]) {
        this.commonService.broadCastActiveAcountMenu("menuOptions");
      } else {
        this.utils.openAccountComponent(queryParams["tab"]);
      }
    });
  }

  navigateLoggedInQP(queryParams) {
    if (
      queryParams["tab"] === "deposit" &&
      queryParams.hasOwnProperty("bonusCode")
    ) {
      this.commonService.setActiveDepositBonus({
        bonusCode: queryParams["bonusCode"],
      });
    }
    if (queryParams["tab"] === "deposit") {
      this.utils.initiateDeposit();
    } else if (queryParams["tab"] === "withdrawal") {
      this.utils.initiateWithdraw();
    } else {
      this.utils.openAccountComponent(queryParams["tab"]);
    }
  }

  navigateLoggoutQP(queryParams) {
    if (queryParams["tab"] === "deposit") {
      this.navigateToDeposit(queryParams);
    } else {
      this.utils.initiateLogin();
      let navigationData = {
        type: "view",
        path: queryParams["tab"],
      };
      this.commonService.setNavigateAfterLogin(navigationData);
    }
  }

  navigateLobbyGameGroupsQuery(queryParams) {
    let navigationData = {
      type: "url",
      data: {
        navigateTo: "lobby",
      },
    };
    navigationData["data"]["lobby"] = queryParams["lobby"];
    if (queryParams.hasOwnProperty("group")) {
      navigationData["data"]["group"] = queryParams["group"];
    }
    if (!this.isLoggedIn) {
      this.utils.initiateLogin();
      this.commonService.setNavigateAfterLogin(navigationData);
    } else {
      this.commonService.broadCastUpdateActiveLobby(navigationData["data"]);
      if (navigationData["data"]["group"]) {
        this.router.navigate([
          this.langCode + "/casino/" + queryParams["group"],
        ]);
      }
    }
  }
}
