import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-redirect",
  templateUrl: "./../../../../../redirection.html",
  styleUrls: ["./redirect.component.scss"],
})
export class RedirectComponent implements OnInit {
  constructor() {
    let postMessageData = {
      message: "deposit-transactionStatus",
    };
    window.parent.postMessage(postMessageData, window.location.origin);
  }

  ngOnInit() {}
}
