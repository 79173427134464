import { Injectable } from "@angular/core";

// Libraries
import * as _ from "underscore";

// Services
import { CommonService } from "src/app/modules/shared/services/common.service";
import { MainService } from "src/app/modules/shared/services/main.service";

@Injectable({
  providedIn: "root",
})
export class PromotionsService {
  allPromotionsData: any;

  constructor(
    private commonService: CommonService,
    private mainService: MainService
  ) {
    this.commonService.logOutComplete.subscribe((isloggedOut) => {
      this.allPromotionsData = undefined;
    });
  }

  promotionCallQueue = [];
  getAllPromotionsp(isForce: boolean = false) {
    if (!_.isEmpty(this.allPromotionsData) && !isForce) {
      return this.allPromotionsData;
    } else {
      return new Promise((resolve, reject) => {
        if (this.promotionCallQueue.length > 0) {
          this.promotionCallQueue.push(resolve);
        } else {
          this.promotionCallQueue.push(resolve);
          Promise.resolve(this.mainService.getAllPromotionsp())
            .then((data) => {
              if (data && data.length > 0) {
                this.allPromotionsData = data;
              }
              for (let callback in this.promotionCallQueue) {
                this.promotionCallQueue[callback](data);
              }
              this.promotionCallQueue = [];
            })
            .catch((error) => {
              return [];
            });
        }
      });
    }
  }
}
