import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { Subscription, BehaviorSubject, Observable } from "rxjs";
import { Meta } from "@angular/platform-browser";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

// Configurations
import { menuConfigurations } from "src/app/configurations/menu.configurations";
import {
  localToCmsLanguageConfigurations,
  accountViewConfigurations,
  bannerCTAConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { MenuConfigurationType } from "src/app/models/configurations/menu/menu-configuration-type.model";
import { AffiliateData } from "src/app/modules/shared/models/affiliate-data/affiliate-data.model";
import { MenuConfigurations } from "src/app/models/configurations/menu/menu-configuration.model";
import { LobbyGameGroup } from "src/app/modules/game-groups/models/lobby/lobby-game-group.model";
import { GameProviders } from "src/app/modules/game-groups/models/game-providers.model";
import { LobbyPregmatic } from "src/app/modules/game-groups/models/lobby/lobby.model";
import { ProviderPregmatic } from "src/app/modules/game-groups/models/provider.model";
import { SeoRequest } from "src/app/modules/shared/models/seo/seo-request.model";
import { GameGroup } from "src/app/modules/game-groups/models/game-group.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { CommonService } from "src/app/modules/shared/services/common.service";

// Utilities
import { supportedLanguagesList } from "src/app/modules/shared/utilities/languages.utilities";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  // Booleans
  isLoggedIn: boolean = false;

  // Strings
  affiliateLangKey: string = `${environment.websiteCode}_affiliateLang`;
  trackerIdKey: string = `${environment.websiteCode}_trackerId`;
  bannerIdKey: string = `${environment.websiteCode}_bannerId`;
  referrerKey: string = `${environment.websiteCode}_referrer`;
  affliateIdKey: string = `${environment.websiteCode}_affId`;
  activeLeftMenuOption: string = "";
  previousContent: string = "";
  currencyCode: string = "";
  languageCode: string = "";

  // Numbers
  userTotalBalance: number = 0;

  // Arrays
  acceptedLanguages: string[] = [];

  // Subject and BehaviorSubject

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private paynplayCashierService: PayNPlayCashierService,
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    private emitterService: EmitterService,
    private commonService: CommonService,
    private metaService: Meta,
    private router: Router
  ) {
    this.acceptedLanguages = supportedLanguagesList();
    this.isLoggedIn = this.isUserLoggedIn();

    this.subscriptions = [
      this.translationService.langCodeSb$.subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      }),
      this.commonService.loginComplete$.subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      }),
      this.commonService.logOutComplete$.subscribe(() => {
        this.isLoggedIn = false;
      }),
      this.userDetailsService.currencyCodeSb$.subscribe(
        (currencyCode: string) => {
          this.currencyCode = currencyCode;
        }
      ),
    ];
  }

  // -----------------------------------------------------------------
  // Get Methods
  getQueryParams() {
    if (window.location.search) {
      return JSON.parse(
        '{"' +
          decodeURI(
            window.location.search
              .substring(1)
              .replace(/&/g, '","')
              .replace(/=/g, '":"')
          ) +
          '"}'
      );
    } else {
      return {};
    }
  }

  creditCardValidation(ccNum: string): boolean {
    const arr: number[] = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];

    let len = ccNum.length;

    let bit: number = 1;
    let sum: number = 0;

    let val: number = 0;

    if (len >= 13 && len <= 19) {
      while (len) {
        val = parseInt(ccNum.charAt(--len), 10);
        sum += (bit ^= 1) ? arr[val] : val;
      }
    }

    return sum && sum % 10 === 0;
  }

  getBrandBasedConfig(key: string): MenuConfigurationType {
    const menu: MenuConfigurations = menuConfigurations();

    return _.clone(menu[key]);
  }

  replaceAll(
    value: string,
    mapper: {
      [key: string]: string;
    }
  ): string {
    return value.replace(
      new RegExp(Object.keys(mapper).join("|"), "gi"),
      (matched: string) => mapper[matched]
    );
  }

  // -----------------------------------------------------------------
  // Set Methods
  addClassToAppBody(className: string): void {
    const documentBody: HTMLElement = document.body;

    if (documentBody && !documentBody.classList.contains(className)) {
      documentBody.classList.add(className);
    }
  }

  removeClassFromAppBody(className: string): void {
    const documentBody: HTMLElement = document.body;

    if (documentBody && documentBody.classList.contains(className)) {
      documentBody.classList.remove(className);
    }
  }

  /*
    All the navigation related to cms banner will be handler here Itself
    irrespective of where it loads on website...
  */
  cmsRoutingHandler(
    routerPath: string,
    data?: {
      bonusCode: string;
    }
  ): void {
    const accountRoutingConfig: string[] = accountViewConfigurations;

    if (routerPath && routerPath === "login") {
      if (!this.isLoggedIn) {
        this.initiateLogin();
      }
    } else if (
      routerPath &&
      accountRoutingConfig &&
      accountRoutingConfig.indexOf(routerPath) > -1
    ) {
      if (this.isLoggedIn) {
        if (routerPath === "deposit") {
          this.initiateDeposit();
        } else if (routerPath === "withdrawal") {
          this.initiateWithdraw();
        } else {
          this.openAccountComponent(routerPath);
        }

        if (data && data.bonusCode) {
          this.commonService.setActiveDepositBonus({
            bonusCode: data.bonusCode,
          });
        }
      } else if (!this.isLoggedIn && this.isPnpFlow()) {
        if (routerPath === "deposit") {
          this.paynplayCashierService.broadCastInitiateDeposit({
            callingFrom: "deepLink",
          });
        } else if (routerPath === "withdrawal") {
          this.paynplayCashierService.broadCastInitiateWithdraw();
        }
      } else {
        this.initiateLogin();

        this.commonService.setNavigateAfterLogin(
          data
            ? { type: "view", path: routerPath, data: data }
            : { type: "view", path: routerPath }
        );
      }
    } else if (
      routerPath &&
      this.getActiveLobby(routerPath.split("/")[1]) === "game"
    ) {
      setTimeout(() => {
        if (this.isLoggedIn) {
          this.router.navigate([`${this.languageCode}/${routerPath}`]);
        } else {
          this.commonService.setNavigateAfterLogin({
            type: "url",
            data: { navigateTo: "gameWindow", path: routerPath.split("/")[2] },
          });

          this.initiateLogin();
        }
      });
    } else if (routerPath) {
      this.router.navigate([`${this.languageCode}/${routerPath}`]);
    } else {
      this.router.navigate([`${this.languageCode}/casino`]);
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  toggleCMSButtons(): void {
    const loggedIn: NodeListOf<Element> = document.querySelectorAll(
      ".logged-in-user"
    );
    const loggedOut: NodeListOf<Element> = document.querySelectorAll(
      ".logged-out-user"
    );

    if (this.isLoggedIn) {
      loggedIn.forEach((val: Element) => val.classList.remove("hide"));

      loggedOut.forEach((val: Element) => val.classList.add("hide"));
    } else {
      loggedIn.forEach((val: Element) => val.classList.add("hide"));

      loggedOut.forEach((val: Element) => val.classList.remove("hide"));
    }
  }

  isUserLoggedIn(): boolean {
    const user: boolean = JSON.parse(localStorage.getItem("hir_user"));

    return user ? true : false;
  }

  getGameGroupsByLobby(
    data: LobbyPregmatic[],
    lobbyName: string
  ): LobbyGameGroup[] {
    let filterredData: LobbyPregmatic[] = data.filter(
      (item: LobbyPregmatic) => {
        return item.name.toLowerCase() === lobbyName.toLowerCase();
      }
    );

    return filterredData.length > 0
      ? this.sortGameGroupsByCountryOrder(filterredData[0].gameGroupList)
      : [];
  }

  getLobbyDataByLobbyName(
    lobbys: LobbyPregmatic[],
    lobbyName: string
  ): LobbyPregmatic | null {
    let filterredDataList: LobbyPregmatic[] = [];

    if (lobbys && lobbys.length > 0) {
      filterredDataList = lobbys.filter((item: LobbyPregmatic) => {
        return item.name.toLowerCase() === lobbyName.toLowerCase();
      });
    }

    return filterredDataList.length > 0 ? filterredDataList[0] : null;
  }

  navigateAllGamesPage(
    gameGroupData: ProviderPregmatic,
    gameProvider?: string
  ): void {
    if (
      gameGroupData &&
      this.languageCode &&
      gameGroupData.multilingualUrlGameGroup &&
      gameGroupData.multilingualUrlGameGroup[
        localToCmsLanguageConfigurations[this.languageCode]
      ]
    ) {
      window.scrollTo(0, 0);

      let multilingualUrlGameGroupName: string =
        gameGroupData.multilingualUrlGameGroup[
          localToCmsLanguageConfigurations[this.languageCode]
        ];

      let allgamesPath: string = multilingualUrlGameGroupName
        .toLowerCase()
        .trim();

      if (gameProvider) {
        this.router.navigate([
          `${this.languageCode}/${this.translationService.instant(
            `url.casino`
          )}/${allgamesPath}/${this.convertGameNameToUrl(gameProvider)}`,
        ]);
      } else {
        this.router.navigate([`${this.languageCode}/${allgamesPath}`]);
      }
    }
  }

  getSortedUniqProviderList(games: GamePregmatic[]): GameProviders[] {
    /*
      This condition " game['gameGroupList'] ? game['gameGroupList']['provider_order'] : 0" is not needed
      here...because gameGroupList is already filter to only single object & it exist all time...
      But for double check we have added it.
    */
    if (games && games.length > 0) {
      let data: GameProviders[] = _.map(games, (game: GamePregmatic) => {
        return {
          vendorCode: game.vendorCode,
          vendorDisplayName: game.vendorDisplayName,
          vendorName: game.vendorName,
          provider_order: game.gameGroupList
            ? (game.gameGroupList as GameGroup).provider_order
            : 0,
        };
      });

      if (data) {
        let providerList: GameProviders[] = _.uniq(
          data,
          false,
          (provider: GameProviders) => {
            return provider.vendorCode;
          }
        );

        providerList = _.sortBy(providerList, "provider_order").reverse();

        return providerList;
      } else {
        return [];
      }
    }
  }

  getProviderList(games: GamePregmatic[]): GameProviders[] {
    let venders: GameProviders[] = [];

    if (games && games.length > 0) {
      let data: GameProviders[] = _.map(games, (game: GamePregmatic) => {
        return {
          vendorCode: game.vendorCode,
          vendorDisplayName: game.vendorDisplayName,
          vendorName: game.vendorName,
          provider_order: game.gameGroupList
            ? (game.gameGroupList as GameGroup).provider_order
            : 0,
          isChecked: true,
        };
      });
      if (data) {
        venders = _.uniq(data, false, (provider: GameProviders) => {
          return provider.vendorCode;
        });
      } else {
        venders = [];
      }
    }

    return venders;
  }

  getGamesByProviderName(
    games: GamePregmatic[],
    providerName: string
  ): GamePregmatic[] {
    if (games) {
      return _.filter(games, { vendorCode: providerName });
    } else {
      return [];
    }
  }

  /**Sort game name by alphabet(A-Z)  */
  sortByGameName(games: GamePregmatic[], key: string): GamePregmatic[] {
    let sortedGames: GamePregmatic[] = _.sortBy(games, key);

    return sortedGames;
  }

  /**Sort game by Release Date */
  sortByReleaseDate(games: GamePregmatic[], key: string): GamePregmatic[] {
    let orderGames: GamePregmatic[] = _.sortBy(games, (game: GamePregmatic) => {
      return game[key];
    });

    return orderGames;
  }

  sortGameGroupsByCountryOrder(gameGrops: LobbyGameGroup[]): LobbyGameGroup[] {
    let orderedList: LobbyGameGroup[] = _.sortBy(
      gameGrops,
      (group: LobbyGameGroup) => {
        return group.game_group_order;
      }
    );

    return orderedList.reverse();
  }

  sortGameByCountryOrder(games: GamePregmatic[]): GamePregmatic[] {
    let orderGamesList: GamePregmatic[] = _.sortBy(
      games,
      (game: GamePregmatic) => {
        return game.gameGroupList
          ? (game.gameGroupList as GameGroup).game_order
          : 0;
      }
    );

    return orderGamesList.reverse();
  }

  openAccountComponent(viewName: string): void {
    if (viewName) {
      this.commonService.broadCastActiveAcountMenu(viewName);
    } else {
      this.closeAccountComponent();
    }
  }

  closeAccountComponent(navigateTo?: string): void {
    this.removeUrlQueryParams();

    if (navigateTo === "menuOptions") {
      this.commonService.broadCastActiveAcountMenu("menuOptions");
    } else {
      this.commonService.broadCastActiveAcountMenu("");
    }
  }

  isControlValid(formControlName: string, formGroup: FormGroup): boolean {
    let control: AbstractControl = formGroup.controls[formControlName];

    return control && !control.errors && (control.dirty || control.touched);
  }

  shouldShowErrors(formControlName: string, formGroup: FormGroup): boolean {
    let control: AbstractControl = formGroup.controls[formControlName];

    return control && control.errors && (control.dirty || control.touched);
  }

  getErrorMessage(formControlName: string, formGroup: FormGroup): string {
    let control: AbstractControl = formGroup.controls[formControlName];

    return control.errors.message;
  }

  isButtonDisabled(formControl: FormControl): boolean {
    return formControl.invalid || formControl.pending;
  }

  formControlToParams(formGroup: FormGroup, data) {
    Object.keys(formGroup.controls).forEach((field: string) => {
      const control: AbstractControl = formGroup.get(field);

      if (control instanceof FormControl) {
        data[field] = control.value;
      } else if (control instanceof FormGroup) {
        this.formControlToParams(control, data);
      }
    });

    return data;
  }

  resetFormFields(formGroup: FormGroup, isForceReset?: boolean): void {
    if (isForceReset) {
      formGroup.reset();
    } else {
      Object.keys(formGroup.controls).forEach((field: string) => {
        const control: AbstractControl = formGroup.get(field);

        if (control instanceof FormControl) {
          control.setValue(null);
        } else if (control instanceof FormGroup) {
          this.resetFormFields(control);
        }
      });
    }
  }

  removeAllFormFieldsvalidation(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field: string) => {
      const control: AbstractControl = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsUntouched({ onlySelf: true });
        control.markAsPristine({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.removeAllFormFieldsvalidation(control);
      }
    });
  }

  setAffCookies(
    affiliateId?: string,
    trackerId?: string,
    bannerId?: string
  ): void {
    const date: Date = new Date();
    date.setDate(date.getDate() + 30);

    const expires: string = `expires=${date.toUTCString()}`;

    if (affiliateId) {
      document.cookie = `${this.affliateIdKey}=${affiliateId};${expires};path=/`;
    }

    if (trackerId) {
      document.cookie = `${this.trackerIdKey}=${trackerId};${expires};path=/`;
    }

    if (bannerId) {
      document.cookie = `${this.bannerIdKey}=${bannerId};${expires};path=/`;
    }

    if (
      document.referrer &&
      !document.referrer.includes(window.location.origin)
    ) {
      document.cookie = `${this.referrerKey}=${document.referrer};${expires};path=/`;
    }
  }

  setAffiliateCookie(affiliateId: string, affilateLanguage?: string): void {
    const date: Date = new Date();
    date.setDate(date.getDate() + 30);

    const expires: string = `expires=${date.toUTCString()}`;

    if (affiliateId) {
      document.cookie = `${this.affliateIdKey}=${affiliateId};${expires};path=/`;
    }

    if (affilateLanguage) {
      document.cookie = `${this.affiliateLangKey}=${affilateLanguage};${expires};path=/`;
    }
  }

  getAffCookies(cookiekey: string): string {
    const name: string = `${environment.websiteCode}_${cookiekey}=`;

    const ca: string[] = document.cookie.split(";");

    for (let i: number = 0; i < ca.length; i++) {
      let c: string = ca[i];

      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }

    return "";
  }

  deleteAffCookies(): void {
    let expiration: string = "; expires=Thu, 01 Jan 1970 00:00:01 GMT ;path=/";

    this.setCookie(this.affliateIdKey, expiration);
    this.setCookie(this.bannerIdKey, expiration);
    this.setCookie(this.trackerIdKey, expiration);
    this.setCookie(this.referrerKey, expiration);
    this.setCookie(this.affiliateLangKey, expiration);
  }

  setCookie(key: string, value: string): void {
    document.cookie = `${key}=${value}`;
  }

  getSessionTimeText(minutes: number): string {
    let sessionTimeText: string = "";

    if (minutes <= 60) {
      sessionTimeText = `${minutes} ${this.translationService.instant(
        "limits.minutes"
      )}`;
    } else {
      let hours: number = Math.floor(minutes / 60);
      let mins: number | string = minutes % 60;

      if (hours && mins && mins !== 0) {
        mins = mins < 10 ? "0" + mins : mins;

        sessionTimeText = `${hours} ${this.translationService.instant(
          "limits.hours"
        )} ${mins} ${this.translationService.instant("limits.minutes")}`;
      } else {
        sessionTimeText = `${hours} ${this.translationService.instant(
          "limits.hours"
        )}`;
      }
    }

    return sessionTimeText;
  }

  addMetaTag(metaTag: string, metaDescription: string): void {
    if (metaDescription && metaDescription !== this.previousContent) {
      this.removeMetaTag();

      this.previousContent = metaDescription;

      this.metaService.addTag({
        name: "description",
        content: metaDescription,
      });
    }
  }

  removeMetaTag(): void {
    this.metaService.removeTag("name='description'");
  }

  scrollAddClass(): boolean {
    const doc: HTMLElement = document.documentElement;

    if (doc) {
      const top: number =
        (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

      return top > 25 ? true : false;
    } else {
      return false;
    }
  }

  getActiveLobby(path: string): string {
    let activeLobby: string = "";

    switch (path) {
      case "spielautomaten":
      case "slots":
      case "spilleautomater":
      case "kolikkopelit":
      case "nyerogepek":
        activeLobby = "slots";
        break;
      case "table-games":
      case "tischspiele":
      case "bordspill":
      case "kolikkopelit":
      case "asztali-jatekok":
        activeLobby = "table-games";
        break;
      case "all":
      case "alle":
      case "kaikki":
      case "osszes":
        activeLobby = "all";
        break;
      case "game":
      case "spiel":
      case "spill":
      case "peli":
      case "jatek":
        activeLobby = "game";
        break;
      case "promotions":
      case "aktionen":
      case "kampanjer":
      case "kampanjat":
      case "promociok":
      case "müügiedendused":
        activeLobby = "promotions";
        break;
      case "rewards":
      case "boni":
      case "premier":
      case "palkinnot":
      case "jutalmak":
        activeLobby = "rewards";
        break;
      default:
        activeLobby = path;
        break;
    }

    return activeLobby;
  }

  convertGameNameToUrl(gameName: string): string {
    return gameName
      .replace(/[\W_]+/g, " ")
      .trim()
      .toLowerCase()
      .split(" ")
      .join("-");
  }

  convertSpecialCharactersWithSpace(gameName: string): string {
    return gameName
      .toUpperCase()
      .replace(/[\W_]+/g, " ")
      .trim();
  }

  capitalizeFirstLetter(providerName: string): string {
    return (
      providerName.charAt(0).toUpperCase() +
      providerName
        .slice(1)
        .replace(/[\W_]+/g, " ")
        .trim()
    );
  }

  getLangCode(): string {
    if (localStorage.getItem("langCode")) {
      return localStorage.getItem("langCode");
    } else {
      const urlSegments: string[] = this.getDecodedCurrentPath().split("/");

      let languageCode: string =
        urlSegments && urlSegments.length >= 2 ? urlSegments[1] : "";

      if (languageCode && _.contains(this.acceptedLanguages, languageCode)) {
        languageCode = urlSegments[1];
      } else {
        languageCode = environment.defaultLang;
      }

      return languageCode;
    }
  }

  backNavigationURlHandler(): void {
    if (!this.commonService.previousComponentURL) {
      this.router.navigate([`${this.languageCode}/casino`]);
    } else {
      this.router.navigate([this.commonService.previousComponentURL]);
    }
  }

  scrollToFragment(location: string): void {
    const element: Element = document.querySelector(`#${location}`);

    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }, 1000);
    }
  }

  updateActiveLeftMenu(name?: string): void {
    let segment: string = "";

    if (["rewards"].indexOf(name) > -1) {
      segment = name;
    } else if (!this.commonService.getActiveAccountMenu()) {
      let urlSegments: string[] = this.getDecodedCurrentPath().split("/");

      if (urlSegments && urlSegments.length >= 3) {
        segment = this.getActiveLobby(urlSegments[2]);
      }
    } else if (this.commonService.getActiveAccountMenu() === "rewards") {
      segment = "rewards";
    }

    this.commonService.broadCastActiveLeftMenu(segment);
  }

  /**open terms & conditions page in new tab */
  toggleRewardTCPopUp(flag: boolean): void {
    if (flag) {
      document.body.classList.add("overflow-hidden");
    }

    this.emitterService.broadCastRewardTC(flag);
  }

  fullScreenInit(targetElement: Element): void {
    let fullscreenPromise;

    if (targetElement) {
      if (targetElement["requestFullscreen"]) {
        fullscreenPromise = targetElement["requestFullscreen"]();
      } else if (targetElement["webkitRequestFullscreen"]) {
        fullscreenPromise = targetElement["webkitRequestFullscreen"]();
      } else if (targetElement["mozRequestFullScreen"]) {
        fullscreenPromise = targetElement["mozRequestFullScreen"]();
      } else if (targetElement["msRequestFullscreen"]) {
        fullscreenPromise = targetElement["msRequestFullscreen"]();
      }
    }

    if (fullscreenPromise) {
      fullscreenPromise
        .catch(() => {})
        .then(() => {
          fullscreenPromise.done = true;
        });
    }
  }

  exitFullScreen(): void {
    let exitPromise;

    if (document && document["exitFullscreen"]) {
      exitPromise = document["exitFullscreen"]();
    } else if (document && document["mozCancelFullScreen"]) {
      exitPromise = document["mozCancelFullScreen"]();
    } else if (document && document["webkitExitFullscreen"]) {
      exitPromise = document["webkitExitFullscreen"]();
    } else if (document && document["msExitFullscreen"]) {
      exitPromise = document["msExitFullscreen"]();
    }

    if (exitPromise) {
      exitPromise
        .catch(() => {})
        .then(() => {
          exitPromise.done = true;
        });
    }
  }

  getTranslatedText(value: string, languageCode: string): string {
    switch (languageCode) {
      case "en-row":
      case "en-ez":
      case "en-ie":
        if (value === "promotions") {
          return "promotions";
        }
        break;
      case "de-eu":
        if (value === "promotions") {
          return "aktionen";
        }
        break;
      case "fi-fi":
        if (value === "promotions") {
          return "kampanjat";
        }
        break;
      case "en-ca":
        if (value === "promotions") {
          return "promotions";
        }
        break;
      case "nb-no":
        if (value === "promotions") {
          return "kampanjer";
        }
        break;
      case "et-ee":
        if (value === "promotions") {
          return "müügiedendused";
        }
        break;
      default:
        if (value === "promotions") {
          return "promotions";
        }
    }
  }

  /*
    Code to remove query params from url if we have any on closing of component
    because we have chance of opening account component from query params,
    which we need to remove before close.
  */
  removeUrlQueryParams(): void {
    if (window.location.search.length > 0) {
      this.router.navigate([window.location.pathname]);
    }
  }

  public gameplayFooterHover: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);
  public gameplayFooterHover$: Observable<
    boolean
  > = this.gameplayFooterHover.asObservable();

  public isGamesToasterOpen: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);
  public isGamesToasterOpen$: Observable<
    boolean
  > = this.isGamesToasterOpen.asObservable();

  public closeGame: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public closeGame$: Observable<boolean> = this.closeGame.asObservable();

  gameplayFooterMouseOver(val: boolean): void {
    this.gameplayFooterHover.next(val);
  }

  GamesToasterOpen(val: boolean): void {
    this.isGamesToasterOpen.next(val);
  }

  getFooterHeight(): number {
    const footerContainerDom: HTMLCollectionOf<Element> = document.getElementsByClassName(
      "footer-conatiner"
    );

    if (footerContainerDom && footerContainerDom.length > 0) {
      return (footerContainerDom[0] as HTMLElement).offsetHeight;
    } else {
      return 0;
    }
  }

  setSEO(contents: SeoRequest, isGame: boolean = false): void {
    if (contents.metaTitle || contents.metatitle) {
      document
        .querySelector("meta[name=title]")
        .setAttribute(
          "content",
          contents.metaTitle ? contents.metaTitle : contents.metatitle
        );

      document
        .querySelector('meta[property="og:title"]')
        .setAttribute(
          "content",
          contents.metaTitle ? contents.metaTitle : contents.metatitle
        );
    } else {
      document
        .querySelector("meta[name=title]")
        .setAttribute("content", "highroller");

      document
        .querySelector('meta[property="og:title"]')
        .setAttribute("content", "Highroller.com – Roll with us.");
    }

    if (contents.metaDescription || contents.metadescription) {
      document
        .querySelector("meta[name=description]")
        .setAttribute(
          "content",
          contents.metaDescription
            ? contents.metaDescription
            : contents.metadescription
        );

      document
        .querySelector('meta[property="og:description"]')
        .setAttribute(
          "content",
          contents.metaDescription
            ? contents.metaDescription
            : contents.metadescription
        );
    } else {
      document
        .querySelector("meta[name=description]")
        .setAttribute(
          "content",
          "No hassle, no lag, no delays - just a smooth ride to exclusivity. HighRoller Casino - #Roll with us"
        );
      document
        .querySelector('meta[property="og:description"]')
        .setAttribute("content", "100% No-Sticky Bonus up to €500");
    }

    if (contents.metaTags || contents.metatags) {
      if (document.querySelectorAll("meta[name=keywords]").length > 0) {
        document
          .querySelector("meta[name=keywords]")
          .setAttribute(
            "content",
            contents.metaTags ? contents.metaTags : contents.metatags
          );
      } else {
        let meta: HTMLMetaElement = document.createElement("meta");
        meta.name = "keywords";
        meta.content = contents.metaTags;

        document.getElementsByTagName("head")[0].appendChild(meta);
      }
    } else if (document.querySelectorAll("meta[name=keywords]").length > 0) {
      document.querySelector("meta[name=keywords]").setAttribute("content", "");
    } else {
      let meta: HTMLMetaElement = document.createElement("meta");
      meta.name = "keywords";
      meta.content = "";

      document.getElementsByTagName("head")[0].appendChild(meta);
    }

    if (contents.title) {
      document.querySelector("title").textContent = contents.title;
    } else {
      document.querySelector("title").textContent = "highroller";
    }

    if (isGame && contents.name) {
      document.querySelector("title").textContent = contents.name;
    }
  }

  getDecodedCurrentPath(): string {
    return decodeURIComponent(window.location.pathname);
  }

  /*
    In hosted cashier we have a property called channel
    where we have to pass what kind of device types it is, Below logic
    detect & pass it.
  */
  getDeviceType(): "Android" | "iOS" | "windows" | "Mac" | "Linux" | "unknown" {
    const userDeviceInfo: string = navigator.userAgent;

    if (/android/i.test(userDeviceInfo)) {
      return "Android";
    } else if (/iPad|iPhone|iPod/.test(userDeviceInfo)) {
      return "iOS";
    } else if (/Windows/.test(userDeviceInfo)) {
      return "windows";
    } else if (/ Mac/.test(userDeviceInfo)) {
      return "Mac";
    } else if (/Linux/.test(userDeviceInfo)) {
      return "Linux";
    } else {
      return "unknown";
    }
  }

  getImgixParams(opts?: { [key: string]: string }): string {
    // https://docs.imgix.com/apis/rendering
    const defaults: {
      auto: string;
    } = {
      auto: "format,compress",
    };

    const options: {
      [key: string]: string;
    } = opts ? { ...defaults, ...opts } : defaults;

    const params: string = `?${Object.keys(options)
      .map((k) => `${k}=${options[k]}`)
      .join("&")}`;

    return params;
  }

  getUserCountryCode(): string {
    return this.userDetailsService.getUserCountryCode()
      ? this.userDetailsService.getUserCountryCode()
      : this.commonService.getCountryCode();
  }

  initiateDeposit(): void {
    if (this.isPnpFlow()) {
      this.paynplayCashierService.broadCastInitiateDeposit({
        callingFrom: "leftMenu",
      });
    } else {
      this.commonService.broadCastActiveAcountMenu("deposit");
    }
  }

  initiateLogin(): void {
    if (this.isPnpFlow()) {
      this.paynplayCashierService.broadCastInitiateLogin("LOGIN");
    } else {
      this.commonService.broadCastActiveAcountMenu("login");
    }
  }

  initiateWithdraw(): void {
    if (this.isPnpFlow()) {
      this.paynplayCashierService.broadCastInitiateWithdraw();
    } else {
      this.commonService.broadCastActiveAcountMenu("withdrawal");
    }
  }

  getAffiliateData(): AffiliateData {
    let affiliateData: AffiliateData = {};

    if (this.getAffCookies("bannerId")) {
      affiliateData.bannerId = this.getAffCookies("bannerId");
    }

    if (this.getAffCookies("affId")) {
      affiliateData.affiliateId = this.getAffCookies("affId");
    }

    if (this.getAffCookies("trackerId")) {
      affiliateData.trackerId = this.getAffCookies("trackerId");
    }

    return affiliateData;
  }

  isPnpFlow(lang?: string): boolean {
    return environment.zimplerMarkets.indexOf(lang || this.getLangCode()) > -1
      ? true
      : false;
  }

  /*
    Functionality to hide & show "Despoit & Game play" (If they configured on banner)
    Based on banner CTA Config vs user total balance
  */
  toggleBannerDepositBtn(userTotalBalance?: number): void {
    const isDepositBtnAvailable: NodeListOf<Element> = document.querySelectorAll(
      ".show-banner-cta-deposit"
    );

    const isGamePlayBtnAvailable: NodeListOf<Element> = document.querySelectorAll(
      ".show-banner-cta-play"
    );

    if (userTotalBalance) {
      this.userTotalBalance = userTotalBalance;
    }

    if (isDepositBtnAvailable.length > 0 && isGamePlayBtnAvailable.length > 0) {
      if (this.isLoggedIn) {
        if (
          this.currencyCode &&
          this.userTotalBalance < bannerCTAConfigurations[this.currencyCode]
        ) {
          this.removeClassFromDomElements(isDepositBtnAvailable, "hide");
          this.addHideClassToDomElements(isGamePlayBtnAvailable, "hide");
        } else {
          this.addHideClassToDomElements(isDepositBtnAvailable, "hide");
          this.removeClassFromDomElements(isGamePlayBtnAvailable, "hide");
        }
      } else {
        this.addHideClassToDomElements(isDepositBtnAvailable, "hide");
        this.removeClassFromDomElements(isGamePlayBtnAvailable, "hide");
      }
    }
  }

  addHideClassToDomElements(
    domElements: NodeListOf<Element>,
    className: string
  ): void {
    domElements.forEach((val: Element) => val.classList.add(className));
  }

  removeClassFromDomElements(
    domElements: NodeListOf<Element>,
    className: string
  ): void {
    domElements.forEach((val: Element) => val.classList.remove(className));
  }

  getTimeFormat(expiryDate: number): string {
    let translated_days: string = this.translationService.instant(
      "rewards.days"
    );

    let translated_day: string = this.translationService.instant("rewards.day");

    let date_future: Date = new Date(expiryDate);

    let date_now: Date = new Date();
    let seconds: number = 0;
    let minutes: number = 0;
    let hours: number = 0;
    let days: number = 0;

    let durationTime: string = "";

    if (date_future > date_now) {
      seconds = Math.floor((date_future.getTime() - date_now.getTime()) / 1000);

      minutes = Math.floor(seconds / 60);
      hours = Math.floor(minutes / 60);

      days = Math.floor(hours / 24);
      hours = hours - days * 24;

      minutes = minutes - days * 24 * 60 - hours * 60;
      seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

      if (days > 30) {
        durationTime = `>30 ${translated_days}`;
      } else if (days > 0) {
        /**Logic to round up days if left time is 2 days 23h 10mins 1 sec/23 days 0h 0mins 1sec is 3 days
         * (2 days plus X we always display as 3 days)
         * below 24h will have a timer in that format: 23h 59m 20s*/
        if (days > 0 && (hours || minutes || seconds)) {
          days = days + 1;
        }

        durationTime = `${days} ${
          days === 1 ? translated_day : translated_days
        }`;
      } else if (hours > 0) {
        durationTime =
          hours +
          this.translationService.instant("common.hours") +
          minutes +
          this.translationService.instant("common.minutes");
      } else if (minutes > 0) {
        durationTime =
          minutes +
          this.translationService.instant("common.minutes") +
          seconds +
          this.translationService.instant("common.seconds");
      } else if (seconds > 0) {
        durationTime =
          seconds + this.translationService.instant("common.seconds");
      } else {
        durationTime =
          seconds + this.translationService.instant("common.seconds");
      }
    }

    return durationTime;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
