import { Component } from "@angular/core";

// Services
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-form-validation",
  templateUrl: "./form-validation.component.html",
  styleUrls: ["./form-validation.component.scss"],
})
export class FormValidationComponent {
  focusedElement;

  constructor(private utility: UtilityService) {
  }

  onFocusForElement(element) {
    if (this.focusedElement != element) {
      this.focusedElement = element;
    }
  }

  onFocusOutForElement(): void {
    this.focusedElement = undefined;
  }

  onKeyPress(): void {
    this.focusedElement = undefined;
  }

  getFormFiledClass(fieldName: string, formName): "" | "has-error" | "has-success" {
    if (this.focusedElement && this.focusedElement == fieldName) {
      return "";
    } else {
      return this.shouldShowErrorsInverted(fieldName, formName)
        ? "has-error"
        : this.isControlValid(fieldName, formName)
        ? "has-success"
        : "";
    }
  }

  isControlValid(fieldName: string, formName) {
    return this.utility.isControlValid(fieldName, formName);
  }

  shouldShowErrors(fieldName, formName) {
    if (this.focusedElement && this.focusedElement == fieldName) {
      return false;
    } else {
      return this.utility.shouldShowErrors(fieldName, formName);
    }
  }

  shouldShowErrorsInverted(fieldName, formName) {
    if (this.focusedElement && this.focusedElement == fieldName) {
      return false;
    } else {
      return this.utility.shouldShowErrors(fieldName, formName);
    }
  }

  getErrorMessage(fieldName, formName) {
    return this.utility.getErrorMessage(fieldName, formName);
  }

  isButtonDisabled(from) {
    return this.utility.isButtonDisabled(from);
  }
}
