import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import {
  ViewEncapsulation,
  HostListener,
  ElementRef,
  Component,
  ViewChild,
  ViewContainerRef,
  Renderer2
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Services
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { PromotionsService } from "src/app/modules/promotions/services/promotions.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";
import { CampaignsService } from "src/app/modules/promotions/services/campaigns.service";

// Utilities
import { supportedLanguagesList } from "src/app/modules/shared/utilities/languages.utilities";

@Component({
  selector: "app-promotion-content-page",
  templateUrl: "./promotion-content-page.component.html",
  styleUrls: ["./promotion-content-page.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PromotionContentPageComponent {
  @ViewChild("pageHeader", { static: false }) pageHeader: ElementRef;
  @ViewChild("popupOver", { static: false }) popupOver: ElementRef;
  isLoading;
  isLoggedIn: any;
  langCode: string;
  langCodeSubscription: Subscription;
  logOutCompleteBsSubscription: Subscription;
  loginCompleteBsSubscription: Subscription;
  promotionURL;
  promotions;
  activePromotionPage;
  promotionPageClassName: string;
  isPageAvailable;
  findPromotionContentSubscription: Subscription;
  activatedRoutePromotionubscription: Subscription;
  promotionContentSubscription: Subscription;
  pageHeaderBg: boolean = false;
  @HostListener("window:scroll") onScroll() {
    this.pageHeaderBg = this.utility.scrollAddClass();
  }
  constructor(
    private router: Router,
    public translationService: TranslationService,
    private registrationService: RegistrationService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private utility: UtilityService,
    private promotionsService: PromotionsService,
    private gamePlayService: GamePlayService,
    private element: ElementRef,
    private campaignsService: CampaignsService,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2
  ) {
    this.campaignsService.initService(this.renderer, this.viewContainerRef);

    this.langCode = this.utility.getLangCode();
    this.langCodeSubscription = this.translationService.langCode$.subscribe((langCode) => {
      this.langCode = langCode;
      this.setPromotionsContent(true);
    });
    /**this.goBackToAllPromotions() call is not required bacause on logout ,
     * we navigate user back to home page
     * */
    this.loginCompleteBsSubscription = this.commonService.loginComplete.subscribe((isloggedIn) => {
      this.isLoggedIn = true;
      this.setPromotionsContent(true);
    });
    this.logOutCompleteBsSubscription = this.commonService.logOutComplete.subscribe((isloggedOut) => {
      this.isLoggedIn = false;
    });
    this.activatedRoutePromotionubscription = this.activatedRoute.params.subscribe((params) => {
      /**need to remove this appended ''promotions/' after CMS change */
      let languageCodeFromURL = this.utility.getDecodedCurrentPath().split("/")[1];
      if (
        languageCodeFromURL &&
        languageCodeFromURL !== this.langCode &&
        _.contains(supportedLanguagesList(), languageCodeFromURL)
      ) {
        this.langCode = languageCodeFromURL;
      }
      if (this.utility.getTranslatedText("promotions", this.langCode) !== "promotions") {
        this.promotionURL =
          "promotions/" + this.utility.getTranslatedText("promotions", this.langCode) + "/" + params["promoUrl"];
      } else {
        this.promotionURL = "promotions/" + params["promoUrl"];
      }

      this.promotionPageClassName = params["promoUrl"];
      this.setPromotionsContent();
    });
  }

  setPromotionsContent(isFroce?) {
    this.isLoading = true;
    this.isPageAvailable = false;
    Promise.resolve(this.promotionsService.getAllPromotionsp()).then((promoData) => {
      this.isLoading = false;
      this.promotions = promoData;
      this.activePromotionPage = _.findWhere(this.promotions, {
        url: this.promotionURL,
      });
      if (!_.isEmpty(this.activePromotionPage)) {
        this.isPageAvailable = true;
        this.activePromotionPage.content = this.utility.replaceAll(this.activePromotionPage.content, {
          "{{site_url}}": environment.siteUrl,
          "{{media_url}}": environment.mediaUrl,
        });

        setTimeout(() => {
          this.campaignsService.generateLeaderBoardIfNeeded(this.element);

          this.utility.toggleCMSButtons();
          this.utility.setSEO(this.activePromotionPage);
          this.commonService.setCanonicalURL(this.activePromotionPage["multilingualUrl"]);
        }, 100);
      } else {
        this.isPageAvailable = false;
      }
    });
  }

  openContent(event) {
    let data,
      target = <HTMLInputElement>event.target,
      routerLink = "";
    routerLink = target.getAttribute("routerLink");
    if (routerLink === "deposit" && target.getAttribute("bonusCode")) {
      data = { bonusCode: target.getAttribute("bonusCode") };
    }
    /**we are handling this registration related here instead of cmsRouting handler
     * because of circular dependances..
     */
    if (routerLink && (routerLink === "registration" || routerLink === "register")) {
      if (!this.isLoggedIn && !this.utility.isPnpFlow()) {
        this.registrationService.openRegistrationHanlder();
      } else {
        this.utility.initiateDeposit();
      }
    } else if (routerLink) {
      if (
        !_.isEmpty(this.gamePlayService.getCurrentGameData()) &&
        this.utility.getActiveLobby(routerLink.split("/")[1]) === "game"
      ) {
        this.gamePlayService.clearGameWindowData();
      }
      this.utility.cmsRoutingHandler(routerLink, data);
    }
  }

  goBackToAllPromotions() {
    this.router.navigate([this.langCode + "/" + this.translationService.instant("url.promotions")]);
  }

  ngOnDestroy() {
    this.activatedRoutePromotionubscription.unsubscribe();
    this.utility.removeClassFromAppBody("overflow-hidden");
    if (this.findPromotionContentSubscription) {
      this.findPromotionContentSubscription.unsubscribe();
    }

    if (this.promotionContentSubscription) {
      this.promotionContentSubscription.unsubscribe();
    }
  }
}
