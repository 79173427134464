import { EventEmitter, Input, Output } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-note-field',
  templateUrl: './note-field.component.html',
  styleUrls: ['./note-field.component.scss']
})
export class NoteFieldComponent {
  @Input() title?: string;
  @Input() text?: string;
  @Input() iconClass?: string;
  @Input() iconSrc?: string;
  @Input() buttonText?: string;
  @Input() buttonAriaLabel?: string;
  @Output() buttonClick = new EventEmitter<void>();

  onButtonClick(): void {
    this.buttonClick.emit();
  }
}
