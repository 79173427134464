import { Component, OnDestroy, ViewEncapsulation } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Subscription } from "rxjs";

// Configurations
import { time24FormatConfigurations } from "src/app/configurations/main.configurations";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";

@Component({
  selector: "app-time",
  templateUrl: "./time.component.html",
  styleUrls: ["./time.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],
})
export class TimeComponent implements OnDestroy {
  formattedDate;
  langCodeSubscription: Subscription;
  langCode: string;
  timeFormat: string;
  timerInstance;
  constructor(
    private datePipe: DatePipe,
    private translationService: TranslationService
  ) {
    this.startTimer();
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
        if (time24FormatConfigurations.indexOf(this.langCode) !== -1) {
          this.timeFormat = "HH:mm";
        } else {
          this.timeFormat = "hh:mm a";
        }
      }
    );
  }

  startTimer() {
    this.timerInstance = setInterval(() => {
      const todayDate = new Date();
      this.formattedDate = this.datePipe.transform(todayDate, this.timeFormat);
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.timerInstance);
    this.langCodeSubscription.unsubscribe();
  }
}
