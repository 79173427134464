import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

// Libraries
import * as _ from "underscore";

// Services
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { MainService } from "src/app/modules/shared/services/main.service";

@Injectable()
export class CustomBannerService {
  bannerDataByZoneId: any = {};
  isProfileRefreshRequired: boolean = false;
  constructor(
    private mainService: MainService,
    private emitterService: EmitterService
  ) {
    this.emitterService.depositSuccessful$.subscribe((data) => {
      this.bannerDataByZoneId = {};
    });
  }

  private isBannerAvailable = new Subject<any>();
  public isBannerAvailable$ = this.isBannerAvailable.asObservable();

  broadCastIsBannerAvialable(flag) {
    this.isBannerAvailable.next(flag);
  }

  setIsProfileRefreshRequired(flag) {
    this.isProfileRefreshRequired = flag;
  }

  getIsProfileRefreshRequired() {
    return this.isProfileRefreshRequired;
  }

  bannerCallQueue = [];
  previousBannerRequest;
  getBannersp(bannerReqData, isForce) {
    if (isForce) {
      this.bannerDataByZoneId = {};
    }
    if (this.bannerDataByZoneId[bannerReqData.zoneId] && !isForce) {
      return this.bannerDataByZoneId[bannerReqData.zoneId];
    } else {
      return new Promise((resolve, reject) => {
        /**Below is to cancel or empty query request if they is any difference
         * in zoneId when quing is active
         */
        if (
          this.bannerCallQueue.length > 0 &&
          this.previousBannerRequest &&
          this.previousBannerRequest["zoneId"] !== bannerReqData["zoneId"]
        ) {
          this.bannerCallQueue = [];
        }
        this.previousBannerRequest = bannerReqData;
        if (bannerReqData.zoneId)
          if (this.bannerCallQueue.length > 0) {
            this.bannerCallQueue.push(resolve);
          } else {
            this.bannerCallQueue.push(resolve);
            Promise.resolve(this.mainService.getBannersp(bannerReqData))
              .then((data) => {
                if (data && data.length > 0) {
                  this.bannerDataByZoneId[
                    bannerReqData.zoneId.toString()
                  ] = data;
                }
                for (let callback in this.bannerCallQueue) {
                  this.bannerCallQueue[callback](data);
                }
                this.bannerCallQueue = [];
                this.previousBannerRequest = "";
              })
              .catch((error) => {
                return [];
              });
          }
      });
    }
  }

  // bannerCallQueue={};
  // getBannersp(bannerReqData, isForce){
  //   if(isForce){
  //     this.bannerDataByZoneId={};
  //   }
  //   if(this.bannerDataByZoneId[bannerReqData.zoneId] && !isForce){
  //     return this.bannerDataByZoneId[bannerReqData.zoneId]
  //   }else{
  //     return new Promise((resolve,reject)=>{
  //       if(Object.keys(this.bannerCallQueue).length>0 && !this.bannerCallQueue[bannerReqData.zoneId]){
  //         this.bannerCallQueue[bannerReqData.zoneId.toString()]=[];
  //         this.bannerCallQueue[bannerReqData.zoneId.toString()].push(resolve);
  //       }else{
  //         this.bannerCallQueue[bannerReqData.zoneId.toString()]=[];
  //         this.bannerCallQueue[bannerReqData.zoneId.toString()].push(resolve);
  //         Promise.resolve(this.mainService.getBannersp(bannerReqData)).then(data=>{
  //           if(data && data.length>0){
  //             this.bannerDataByZoneId[bannerReqData.zoneId.toString()] = data;
  //           }
  //           for(let zoneQueues in this.bannerCallQueue){
  //              for(let callback in this.bannerCallQueue[zoneQueues]){
  //               this.bannerCallQueue[bannerReqData.zoneId][callback](data);
  //              }
  //            }
  //           this.bannerCallQueue={};
  //           resolve(data);
  //         }).catch(error=>{
  //           return [];
  //         })
  //       }
  //     });
  //   }
  // }
}
