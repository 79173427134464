import { UtilityService } from "src/app/utility/utility.service";
import { Component, Input, OnInit } from "@angular/core";
import { StaticPageService } from "src/app/modules/static-pages/services/static-page.service";
import { environment } from "src/environments/environment";
import { TranslationService } from "src/app/modules/shared/services/translation.service";

@Component({
  selector: "app-seo-content",
  templateUrl: "./seo-content.component.html",
  styleUrls: ["./seo-content.component.scss"],
})
export class SeoContentComponent implements OnInit {
  @Input() set url(url: string) {
    if (this._url !== url) {
      this._url = url;
      this.loadSEOContent();
    }
  }
  get url(): string {
    return this._url;
  }
  private _url: string;

  staticContent: string;
  languageCode: string;

  constructor(
    private staticPageService: StaticPageService,
    private utilityService: UtilityService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.observeTranslations();
  }

  observeTranslations(): void {
    this.translationService.langCode$.subscribe((languageCode: string) => {
      if (this.languageCode !== languageCode) {
        this.loadSEOContent(true);
      }
      this.languageCode = languageCode;
    });
  }

  loadSEOContent(isForce?: boolean): void {
    Promise.resolve(this.staticPageService.getStaticPagep(this.url, isForce)).then((footerData) => {
      if (footerData && !footerData["errorCode"] && footerData["content"]) {
        this.staticContent = this.utilityService.replaceAll(footerData.content, {
          "{{site_url}}": environment.siteUrl,
          "{{media_url}}": environment.mediaUrl,
        });
      }
    });
  }
}
