import { Component, OnDestroy, HostListener } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { Subscription } from "rxjs";

// Components
import { SharedTransactionComponent } from "src/app/modules/transactions/components/shared-transaction/shared-transaction.component";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { AccountService } from "src/app/modules/auth/services/account.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { CustomValidators } from "src/app/utility/custom-validator";

@Component({
  selector: "app-transaction-history",
  templateUrl: "./transaction-history.component.html",
  styleUrls: ["./transaction-history.component.scss"],
})
export class TransactionHistoryComponent extends SharedTransactionComponent
  implements OnDestroy {
  isLoading: boolean = false;
  transactionHistoryRecords: any;
  isdropdownOpen: boolean = false;
  activeTransactionType: any;
  isButtonLoader: boolean = false;
  requestData = {
    size: 1000,
    index: 0,
    order: true,
    interval: {
      end: undefined,
      start: undefined,
    },
    transactionTypes: [],
  };

  transactionTypes: Array<{
    transactionText: string;
    transactionType: string;
  }> = [
    {
      transactionText: this.translationService.instant(
        "transactionhistory.deposit"
      ),
      transactionType: "deposit",
    },
    {
      transactionText: this.translationService.instant(
        "transactionhistory.withdraw"
      ),
      transactionType: "withdraw",
    },
  ];

  transactionHistorySub: any;
  currencysymbol: string;
  userCurrencySymbolSub: Subscription;
  transactionSub: Subscription;
  windowType: string;
  @HostListener("window:resize") onResize() {
    this.getWindowType();
  }

  constructor(
    public utility: UtilityService,
    public formBuilder: FormBuilder,
    private translationService: TranslationService,
    private accountService: AccountService,
    public customValidators: CustomValidators,
    private userDetailsService: UserDetailsService,
    private commonService: CommonService
  ) {
    super(utility, formBuilder, customValidators);
    this.getWindowType();
    this.activeTransactionType = this.transactionTypes[0];
    this.userCurrencySymbolSub = this.userDetailsService.currencySymbolSb$.subscribe(
      (symbol) => {
        this.currencysymbol = symbol;
      }
    );
    /**we need one extra  control on top of common shared form builder..so we are create dynamically here */
    let formControl = new FormControl(
      this.transactionTypes[0]["transactionType"],
      [this.customValidators.required]
    );
    this.txnDetailsFrom.addControl("transactionTypes", formControl);
  }

  openTransTypeList() {
    this.isdropdownOpen = !this.isdropdownOpen;
  }

  getWindowType() {
    var ww = document.body.clientWidth;
    if (ww <= 1024) {
      this.windowType = "mobile";
    } else {
      this.windowType = "device";
    }
  }

  onTransactionTypeChange() {
    this.transactionHistoryRecords = undefined;
  }

  proccessRequest() {
    this.requestData["interval"] = {
      start: new Date(
        Math.floor(this.txnDetailsFrom.controls["startDate"].value)
      ),
      end: new Date(
        Math.floor(
          this.txnDetailsFrom.controls["endDate"].value.setHours(
            23,
            59,
            59,
            999
          )
        )
      ),
    };
    let trasnactionType = this.txnDetailsFrom.controls["transactionTypes"]
      .value;
    if (trasnactionType === "deposit") {
      this.requestData["transactionTypes"] = ["REAL_CASH_DEPOSIT"];
    } else if (trasnactionType === "withdraw") {
      this.requestData["transactionTypes"] = ["REAL_CASH_WITHDRAW"];
    } else {
      this.requestData["transactionTypes"] = [
        "REAL_CASH_DEPOSIT",
        "REAL_CASH_WITHDRAW",
        "PLAYER_SUBSCRIPTION",
        "REAL_CASH_ADDITION_BY_CS",
        "REAL_CASH_REMOVAL_BY_CS",
        "NEGATIVE_BALANCE_ADDITION_FOR_WIN_REVERSE",
        "REAL_CASH_ADJUSTMENT_FOR_WIN_REVERSE_CAPTURE",
        "POSITIVE_ADJUSTMENT",
        "NEGATIVE_ADJUSTMENT",
        "NEGATIVE_BALANCE_ADDITION_FOR_FRAUD",
        "REAL_CASH_ADJUSTMENT_FOR_FRAUD_CAPTURE_BY_CS",
        "REAL_CASH_CASHOUT_REVERSAL",
      ];
    }
    return this.requestData;
  }

  filterTransactionHistory() {
    if (this.txnDetailsFrom.valid) {
      let trasnactionType = this.txnDetailsFrom.controls["transactionTypes"]
        .value;
      if (trasnactionType === "deposit") {
        this.getCashierDespoitWithdrawalHistory(trasnactionType);
      } else if (trasnactionType === "withdraw") {
        this.getCashierDespoitWithdrawalHistory(trasnactionType);
      } else {
        /*As part of PROD-198 we are not using All from transaction history*/
        this.getTransactionHistory();
      }
    }
  }

  getCashierDespoitWithdrawalHistory(trasnactionType) {
    this.isButtonLoader = true;
    this.transactionHistoryRecords = undefined;
    this.isLoading = true;
    let requestobj = this.proccessRequest();
    this.accountService
      .getCashierTransactionHistory(requestobj)
      .subscribe((responseData) => {
        this.isButtonLoader = false;
        this.isLoading = false;
        if (
          responseData &&
          responseData["status"] === "SUCCESS" &&
          ((responseData["depositDetails"] &&
            responseData["depositDetails"].length > 0) ||
            (responseData["cashoutDetails"] &&
              responseData["cashoutDetails"].length > 0))
        ) {
          this.transactionHistoryRecords =
            trasnactionType === "deposit"
              ? responseData["depositDetails"]
              : responseData["cashoutDetails"];
        } else {
          this.transactionHistoryRecords = [];
        }
      });
  }

  /*As part of PROD-198 we are not using All from transaction history so below 
  transaction history function is not required for now*/
  getTransactionHistory() {
    this.transactionHistoryRecords = undefined;
    this.isLoading = true;
    this.isButtonLoader = true;
    let requestobj = this.proccessRequest();
    this.transactionSub = this.transactionHistorySub = this.accountService
      .getTransactionHistory(requestobj)
      .subscribe((responseData) => {
        this.isButtonLoader = false;
        this.isLoading = false;
        if (responseData && responseData.length > 0) {
          this.transactionHistoryRecords = responseData;
        } else {
          this.transactionHistoryRecords = [];
        }
      });
  }

  isButtonDisabled(from) {
    return this.utility.isButtonDisabled(from);
  }

  ngOnDestroy() {
    this.userCurrencySymbolSub.unsubscribe();
    this.activeTransactionType = this.transactionTypes[0];
    this.transactionHistoryRecords = undefined;
    if (this.transactionSub) {
      this.transactionSub.unsubscribe();
    }
  }
}
