import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Configurations
import { cryptoCurrencyListConfigurations } from "src/app/configurations/main.configurations";

// Services
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-form-select",
  templateUrl: "./form-select.component.html",
  styleUrls: ["./form-select.component.scss"],
})
export class FormSelectComponent extends FormValidationComponent
  implements OnInit {
  config;
  group: FormGroup;
  selectedMethodData: any;
  callingFrom: any;
  usedAccount: boolean;
  monthRange: any = [];
  yearRange: any = [];
  constructor(private utils: UtilityService) {
    super(utils);
  }

  ngOnInit() {
    if (this.config.key === "expiryMonth") {
      for (let i = 1; i <= 12; i++) {
        this.monthRange.push(i);
      }
      this.config["options"] = this.monthRange;
      this.config["placeholder"] = "MM";
    }
    if (this.config.key === "expiryYear") {
      let currentYear = new Date().getFullYear();
      for (let i = currentYear; i < currentYear + 10; i++) {
        this.yearRange.push(i);
      }
      this.config["options"] = this.yearRange;
      this.config["placeholder"] = "YYYY";
    }
    if (this.config.key === "cryptoCurrency") {
      this.config["options"] = cryptoCurrencyListConfigurations;
    }
  }
}
