// Models
import { DepositMethodConfigurations } from "src/app/models/configurations/deposit-method-configurationmodel";
import { InputType } from "src/app/models/configurations/enums/input-type.enum";

export let depositMethodsConfigurations: {
  [key: string]: DepositMethodConfigurations;
} = {
  CREDITCARD_PIQ: {
    txType: "CreditcardDeposit",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.ccNumberPH",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        placeholderText: "cashier.mmyy",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-50",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cvv",
        placeholderText: "cashier.cvv",
        key: "cvv",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-50",
        lableDisplay: true,
        lableDisplayText: "cashier.cvv",
      },
      {
        id: "recvv",
        placeholderText: "cashier.cvv",
        key: "cvv",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: true,
        isEncrypted: true,
        type: InputType.tel,
        class: "cvv-width",
        lableDisplayText: "cashier.cvv",
      },
      {
        id: "cardHolder",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100 text-capitalize",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  NETELLER_PIQ: {
    txType: "NetellerDeposit",
    displayName: "Neteller",
    providerName: "NETELLER",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: true,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
      {
        id: "account",
        key: "account",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.text,
        class: "col-100",
        placeholderText: "cashierFormFields.netellerAccountIdPH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.netellerAccountIdLabel",
        logoDisplayOnInput: true,
      },
      {
        id: "secureId",
        key: "secureId",
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        type: InputType.text,
        class: "col-100",
        placeholderText: "cashierFormFields.netellerSecureIdPH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.netellerSecureIdLabel",
      },
    ],
  },
  SKRILL_PIQ: {
    txType: "SkrillDeposit",
    displayName: "Skrill",
    providerName: "SKRILL",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: true,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
      {
        id: "emailAddress",
        key: "emailAddress",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.email,
        class: "col-100",
        placeholderText: "cashierFormFields.skrillEmailAddressPH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.skrillEmailAddressLabel",
        logoDisplayOnInput: true,
      },
    ],
  },
  ECOPAYZ_PIQ: {
    txType: "EcopayzDeposit",
    displayName: "Ecopayz",
    providerName: "ECOPAYZ",
    inputs: [],
  },
  SOFORT_PIQ: {
    txType: "SofortDeposit",
    displayName: "Sofort",
    providerName: "SOFORT",
    inputs: [],
  },
  PAYSAFECARD_PIQ: {
    txType: "PaysafecardDeposit",
    displayName: "Paysafecard",
    providerName: "PAYSAFECARD",
    inputs: [],
  },
  TRUSTLY_PIQ: {
    txType: "TrustlyDeposit",
    displayName: "Trustly",
    providerName: "TRUSTLY",
    inputs: [],
  },
  GIROPAY_PIQ: {
    txType: "GiropayDeposit",
    displayName: "Giropay",
    providerName: "GIROPAY",
    inputs: [],
  },
  CRYPTOCURRENCY_PIQ: {
    txType: "CryptoCurrencyDeposit",
    displayName: "Cryptocurrency",
    providerName: "CRYPTOCURRENCY",
    inputs: [
      {
        id: "cryptocurrency",
        name: "paymentFieldsComp.cryptoCurrency",
        key: "cryptoCurrency",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        requestParam: true,
        type: InputType.select,
        class: "form-group",
        placeholderText: "paymentFieldsComp.select_currency",
        lableDisplay: true,
        lableDisplayText: "paymentFieldsComp.select_currency",
      },
    ],
  },
  PPRO_SOFORT_PIQ: {
    txType: "PProDeposit",
    displayName: "PPRO",
    providerName: "PPRO",
    inputs: [],
  },
  ZIMPLER_PIQ: {
    txType: "ZimplerDeposit",
    displayName: "Zimpler",
    providerName: "ZIMPLER",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
      {
        id: "phoneNumber",
        key: "phoneNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.zimplerPhoneNumberPH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.zimplerPhoneNumberLabel",
        logoDisplayOnInput: true,
      },
    ],
  },
  MUCHBETTER_PIQ: {
    txType: "MuchBetterDeposit",
    displayName: "MuchBetter",
    providerName: "MUCHBETTER",
    inputs: [
      {
        id: "phoneNumber",
        key: "phoneNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.muchbetterPhoneNumberPH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.muchbetterPhoneNumberLabel",
        logoDisplayOnInput: true,
        readonly: false,
      },
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  EUTELLER_PIQ: {
    txType: "EutellerDeposit",
    displayName: "Euteller",
    providerName: "EUTELLER",
    inputs: [],
  },
  SIRU_PIQ: {
    txType: "SiruDeposit",
    displayName: "Siru",
    providerName: "SIRU",
    inputs: [
      {
        id: "phoneNumber",
        key: "phoneNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.siruPhoneNumberPH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.siruPhoneNumberLabel",
        logoDisplayOnInput: true,
      },
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
    ],
  },
  IDEBIT_PIQ: {
    txType: "IDebitDeposit",
    displayName: "iDebit",
    providerName: "IDEBIT",
    inputs: [],
  },
  INSTADEBIT_PIQ: {
    txType: "InstadebitDeposit",
    displayName: "Instadebit",
    providerName: "INSTADEBIT",
    inputs: [],
  },
  BANK_INTERAC_PIQ: {
    txType: "BankDeposit",
    displayName: "Interac",
    providerName: "BANK",
    service: "INTERAC",
    inputs: [],
  },
  SKRILLQCO_RAPIDTRANSFER_PIQ: {
    txType: "SkrillQCODeposit",
    displayName: "Skrill",
    providerName: "SKRILL",
    inputs: [],
  },
  JETON_JETGO_PIQ: {
    txType: "JetonDeposit",
    displayName: "Jeton",
    providerName: "JETON",
    inputs: [
      {
        id: "customerNumber",
        key: "customerNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.jetoncustomerNumberPH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.jetonCustomerNumberLabel",
        logoDisplayOnInput: true,
      },
    ],
  },
  JETON_CHECKOUT_PIQ: {
    txType: "JetonDeposit",
    displayName: "Jeton",
    providerName: "JETON",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
      {
        id: "customerNumber",
        key: "customerNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.jetoncustomerNumberPH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.jetonCustomerNumberLabel",
        logoDisplayOnInput: true,
      },
    ],
  },
  ENVOY_SOFORT_PIQ: {
    txType: "EnvoyDeposit",
    displayName: "Envoy",
    providerName: "ENVOY",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
      {
        id: "country",
        key: "country",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.text,
        class: "col-100",
        placeholderText: "cashierFormFields.envoyCountryPH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.envoyCountryLabel",
        logoDisplayOnInput: true,
      },
    ],
  },
  ENVOY_GIROPAY_PIQ: {
    txType: "EnvoyDeposit",
    displayName: "Envoy",
    providerName: "ENVOY",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
      {
        id: "country",
        key: "country",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.text,
        class: "col-100",
        placeholderText: "cashierFormFields.envoyCountryPH",
        labelDisplay: true,
        lableDisplayText: "cashierFormFields.envoyCountryLabel",
        logoDisplayOnInput: true,
      },
    ],
  },
  SKRILLQCO_SOFORT_PIQ: {
    txType: "SkrillQCODeposit",
    displayName: "Skrill",
    providerName: "SKRILL",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: true,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
      {
        id: "email",
        key: "email",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.email,
        class: "col-100",
        placeholderText: "cashierFormFields.skrillQCOEmailPH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.skrillQCOEmailLabel",
        logoDisplayOnInput: true,
      },
      {
        id: "account",
        key: "account",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.skrillQCOAccountPH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.skrillQCOAccountLabel",
      },
      {
        id: "secureId",
        key: "secureId",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.skrillQCOSecureIdPH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.skrillQCOSecureIdLabel",
      },
    ],
  },
  SKRILLQCO_GIROPAY_PIQ: {
    txType: "SkrillQCODeposit",
    displayName: "Skrill",
    providerName: "SKRILL",
    inputs: [
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: true,
        type: InputType.select,
        class: "form-group",
        requestParam: true,
      },
      {
        id: "email",
        key: "email",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.email,
        class: "col-100",
        placeholderText: "cashierFormFields.skrillQCOEmailPH",
        displaylable: true,
        lableDisplayText: "cashierFormFields.skrillQCOEmailLabel",
        logoDisplayOnInput: true,
      },
      {
        id: "account",
        key: "account",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.skrillQCOEmailPH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.skrillQCOAccountLabel",
      },
      {
        id: "secureId",
        key: "secureId",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: false,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.skrillQCOSecureIdPH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.skrillQCOSecureIdLabel",
      },
    ],
  },
  VENUSPOINT_PIQ: {
    txType: "VenusPointDeposit",
    displayName: "VenusPoint",
    providerName: "VENUSPOINT",
    inputs: [
      {
        id: "username",
        key: "username",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100",
        placeholderText: "cashierFormFields.venuspointUsernamePH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.venuspointUsernameLabel",
        logoDisplayOnInput: true,
      },
      {
        id: "password",
        key: "password",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.password,
        class: "col-100",
        placeholderText: "cashierFormFields.venuspointPasswordPH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.venuspointPasswordLabel",
      },
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: true,
        type: InputType.select,
        class: "col-100",
      },
    ],
  },
  IWALLET_PIQ: {
    txType: "IWalletDeposit",
    displayName: "IWallet",
    providerName: "IWALLET",
    inputs: [],
  },
  APCO_APG_PIQ: {
    txType: "ApcoDeposit",
    displayName: "Apco",
    providerName: "APCO",
    inputs: [],
  },
  BANK_VOLT_PIQ: {
    txType: "BankDeposit",
    displayName: "Bank",
    providerName: "BANK",
    inputs: [],
  },
  CREDITCARD_KWICKGO_PIQ: {
    txType: "CreditcardDeposit",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.ccNumberPH",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-50",
        placeholderText: "cashier.mmyy",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cvv",
        key: "cvv",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-50",
        placeholderText: "cashier.3_digits",
        lableDisplay: true,
        lableDisplayText: "cashier.cvv",
      },
      {
        id: "recvv",
        key: "cvv",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: true,
        isEncrypted: true,
        type: InputType.tel,
        class: "cvv-width",
        placeholderText: "cashier.cvv",
        lableDisplay: false,
      },
      {
        id: "cardHolder",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  CREDITCARD_PASSNGO_PIQ: {
    txType: "CreditcardDeposit",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.ccNumberPH",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-50",
        placeholderText: "cashier.mmyy",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cvv",
        key: "cvv",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-50",
        placeholderText: "cashier.3_digits",
        lableDisplay: true,
        lableDisplayText: "cashier.cvv",
      },
      {
        id: "recvv",
        key: "cvv",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: true,
        isEncrypted: true,
        type: InputType.tel,
        class: "cvv-width",
        placeholderText: "cashier.cvv",
        lableDisplay: false,
      },
      {
        id: "cardHolder",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100 text-capitalize",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  CREDITCARD_CRYPTOGO_PIQ: {
    txType: "CreditcardDeposit",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.ccNumberPH",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-50",
        placeholderText: "cashier.mmyy",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cvv",
        key: "cvv",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-50",
        placeholderText: "cashier.3_digits",
        lableDisplay: true,
        lableDisplayText: "cashier.cvv",
      },
      {
        id: "recvv",
        key: "cvv",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: true,
        isEncrypted: true,
        type: InputType.tel,
        class: "cvv-width",
        placeholderText: "cashier.cvv",
        lableDisplay: false,
      },
      {
        id: "cardHolder",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  CREDITCARD_PURPLEPAY_PIQ: {
    txType: "CreditcardDeposit",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.ccNumberPH",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-50",
        placeholderText: "cashier.mmyy",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cvv",
        key: "cvv",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-50",
        placeholderText: "cashier.3_digits",
        lableDisplay: true,
        lableDisplayText: "cashier.cvv",
      },
      {
        id: "recvv",
        key: "cvv",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: true,
        isEncrypted: true,
        type: InputType.tel,
        class: "cvv-width",
        placeholderText: "cashier.cvv",
        lableDisplay: false,
      },
      {
        id: "cardHolder",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100 text-capitalize",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  CREDITCARD_CLEARHAUS_PIQ: {
    txType: "CreditcardDeposit",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.ccNumberPH",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-50",
        placeholderText: "cashier.mmyy",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cvv",
        key: "cvv",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-50",
        placeholderText: "cashier.3_digits",
        lableDisplay: true,
        lableDisplayText: "cashier.cvv",
      },
      {
        id: "recvv",
        key: "cvv",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: true,
        isEncrypted: true,
        type: InputType.tel,
        class: "cvv-width",
        placeholderText: "cashier.cvv",
        lableDisplay: false,
      },
      {
        id: "cardHolder",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100 text-capitalize",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  CREDITCARD_KLUWP_PIQ: {
    txType: "CreditcardDeposit",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.ccNumberPH",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-50",
        placeholderText: "cashier.mmyy",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cvv",
        key: "cvv",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-50",
        placeholderText: "cashier.3_digits",
        lableDisplay: true,
        lableDisplayText: "cashier.cvv",
      },
      {
        id: "recvv",
        key: "cvv",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: true,
        isEncrypted: true,
        type: InputType.tel,
        class: "cvv-width",
        placeholderText: "cashier.cvv",
        lableDisplay: false,
      },
      {
        id: "cardHolder",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100 text-capitalize",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  CREDITCARD_APCO_PIQ: {
    txType: "CreditcardDeposit",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.ccNumberPH",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-50",
        placeholderText: "cashier.mmyy",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cvv",
        key: "cvv",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-50",
        placeholderText: "cashier.3_digits",
        lableDisplay: true,
        lableDisplayText: "cashier.cvv",
      },
      {
        id: "recvv",
        key: "cvv",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: true,
        isEncrypted: true,
        type: InputType.tel,
        class: "cvv-width",
        placeholderText: "cashier.cvv",
        lableDisplay: false,
      },
      {
        id: "cardHolder",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100 text-capitalize",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  CREDITCARD_REVOLUT_PIQ: {
    txType: "CreditcardDeposit",
    displayName: "Visa/Mastercard",
    providerName: "CREDITCARD",
    inputs: [
      {
        id: "accountId",
        name: "cashierFormFields.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "",
      },
      {
        id: "creditcardNumber",
        key: "creditcardNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-100",
        placeholderText: "cashierFormFields.ccNumberPH",
        lableDisplay: true,
        lableDisplayText: "cashier.debit_or_card_number",
        logoDisplayOnInput: true,
      },
      {
        id: "expiryMonthYear",
        key: "expiryMonthYear",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.tel,
        class: "col-50",
        placeholderText: "cashier.mmyy",
        lableDisplay: true,
        lableDisplayText: "cashier.expiry_date",
      },
      {
        id: "cvv",
        key: "cvv",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        isEncrypted: true,
        type: InputType.tel,
        class: "col-50",
        placeholderText: "cashier.3_digits",
        lableDisplay: true,
        lableDisplayText: "cashier.cvv",
      },
      {
        id: "recvv",
        key: "cvv",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: true,
        isEncrypted: true,
        type: InputType.tel,
        class: "cvv-width",
        placeholderText: "cashier.cvv",
        lableDisplay: false,
      },
      {
        id: "cardHolder",
        key: "cardHolder",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100 text-capitalize",
        placeholderText: "cashierFormFields.ccHolderNamePH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ccHolderNameLabel",
      },
    ],
  },
  ECOBANQ_PIQ: {
    txType: "EcoBanqDeposit",
    displayName: "EcoBanq",
    providerName: "ECOBANQ",
    inputs: [
      {
        id: "ecoBanqUserId",
        name: "cashierFormFields.ecoBanqUserIdPH",
        key: "ecoBanqUserId",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ecoBanqUserIdLabel",
        logoDisplayOnInput: true,
        requestParam: true,
      },
      {
        id: "ecoBanqPassword",
        name: "cashierFormFields.ecoBanqPasswordPH",
        key: "ecoBanqPassword",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.password,
        class: "col-100",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.ecoBanqPasswordLabel",
      },
      {
        id: "accountId",
        name: "paymentFieldsComp.accountID",
        key: "accountId",
        requiredNewPayment: false,
        visibleOnNewPayment: false,
        requiredRepeatPayment: true,
        visibleOnRepeatPayment: true,
        type: InputType.select,
        class: "col-100",
      },
    ],
  },
  COMMUNITYBANKING_INPAY_PIQ: {
    txType: "Communitybanking",
    displayName: "INPAY",
    providerName: "INPAY",
    inputs: [],
  },
  FUNANGA_PIQ: {
    txType: "FunangaDeposit",
    displayName: "Funanga",
    providerName: "FUNANGA",
    inputs: [],
  },
  COMMUNITYBANK_PIQ: {
    txType: "CommunityBankDeposit",
    displayName: "Communitybank",
    providerName: "COMMUNITYBANK",
    inputs: [],
  },
  NEOSURFVOUCHER_PIQ: {
    txType: "NeosurfVoucherDeposit",
    displayName: "Neosurfvoucher",
    providerName: "NEOSURFVOUCHER",
    inputs: [
      {
        id: "voucherNumber",
        key: "voucherNumber",
        requiredNewPayment: true,
        visibleOnNewPayment: true,
        requiredRepeatPayment: false,
        visibleOnRepeatPayment: false,
        type: InputType.text,
        class: "col-100",
        placeholderText: "cashierFormFields.NeosurfvoucherNumberPH",
        lableDisplay: true,
        lableDisplayText: "cashierFormFields.NeosurfvoucherNumberLabel",
        logoDisplayOnInput: true,
      },
    ],
  },
  APCO_XPATE_PIQ: {
    txType: "ApcoDeposit",
    displayName: "XPATE",
    providerName: "APCO",
    inputs: [],
  },
};
