import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Models
import { UserProfileBalance } from "src/app/modules/shared/models/profiles/user-profile-balance.model";
import { UserBalanceDetails } from "src/app/modules/shared/models/profiles/user-balance-details.model";
import { CurrencyDetails } from "src/app/modules/shared/models/currency/currency-details.model";
import { UserData } from "src/app/modules/user/models/user-data.model";

@Injectable()
export class UserDetailsService {
  // Date
  lastLoginTime: Date;

  // Numbers
  withdrawableBalance: number = 0;
  userTotalBalance: number = 0;
  userTotalBonus: number = 0;

  // Strings
  defaultCurrencySymbol: string = "€";
  userCurrencySymbol: string = "";
  defaultCurrency: string = "EUR";
  userBrowserCountry: string = "";
  userCurrencyCode: string = "";
  userCountryCode: string = "";
  userCountryLang: string = "";
  userLangCode: string = "";
  userCountry: string = "";
  langCode: string = "";

  // Others
  userBalanceDetails: UserBalanceDetails;
  userCurrencyDetails: CurrencyDetails;
  userProfileDetails: UserData;

  // Subjects and BehaviorSubjects
  private userProfileUpdated: Subject<string> = new Subject<string>();
  userProfileUpdated$: Observable<string> = this.userProfileUpdated.asObservable();

  private userBalanceUpdated: Subject<string> = new Subject<string>();
  userBalanceUpdated$: Observable<string> = this.userBalanceUpdated.asObservable();

  private currencySymbolSb: BehaviorSubject<string> = new BehaviorSubject<string>(environment.defaultCurrencySymbol);
  currencySymbolSb$: Observable<string> = this.currencySymbolSb.asObservable();

  private currencyCodeSb: BehaviorSubject<string> = new BehaviorSubject<string>(environment.defaultCurrencyCode);
  currencyCodeSb$: Observable<string> = this.currencyCodeSb.asObservable();

  constructor() {}

  // -----------------------------------------------------------------
  // Get Methods
  getCurrencyCode(): string {
    return this.userCurrencyCode ? this.userCurrencyCode : this.defaultCurrency;
  }

  getUserLangCode(): string {
    return this.userLangCode;
  }

  getCurrencySymbol(): string {
    return this.userCurrencySymbol
      ? this.userCurrencySymbol
      : this.defaultCurrencySymbol;
  }

  getUserCurrencyDetails(): CurrencyDetails {
    return this.userCurrencyDetails;
  }

  getUserBrowserCountry(): string {
    return this.userBrowserCountry;
  }

  getUserProfileDetails(): UserData {
    return this.userProfileDetails;
  }

  getUserTotalBalance(): number {
    return this.userTotalBalance;
  }

  getWithdrawableBalance(): number {
    return this.withdrawableBalance;
  }

  getUserTotalBonus(): number {
    return this.userTotalBonus;
  }

  getUserBalanceDetails(): UserBalanceDetails {
    return this.userBalanceDetails;
  }

  getLastLoginTime(): Date {
    return this.lastLoginTime;
  }

  getUserCountryCode(): string {
    return this.userCountryCode;
  }

  // -----------------------------------------------------------------
  // Set Methods - Set Currency
  setCurrencySymbol(currencySymbol: string): void {
    this.userCurrencySymbol = currencySymbol;

    this.currencySymbolSb.next(currencySymbol);
  }

  setCurrency(currencyCode: string): void {
    this.userCurrencyCode = currencyCode;
  }

  setCurrencyCode(currencyCode: string): void {
    this.userCurrencyCode = currencyCode;

    this.currencyCodeSb.next(currencyCode);
  }

  // -----------------------------------------------------------------
  // Set Methods - Set User Profiles
  setUserProfileDetails(userProfile: UserData): void {
    this.userProfileDetails = userProfile;

    this.userProfileDetails.firstName = this.userProfileDetails.firstName ? this.userProfileDetails.firstName.toLowerCase() : "";

    this.userProfileDetails.lastName = this.userProfileDetails.lastName ? this.userProfileDetails.lastName.toLowerCase() : "";
    
    this.userProfileDetails.affiliateLinkID = this.userProfileDetails.affiliateLinkID 
      ? this.userProfileDetails.affiliateLinkID
      : "";

    this.setUserCountryCode(userProfile.country);

    this.setUserLangCode(userProfile.language);

    this.userProfileUpdated.next("UserProfileUpdated");
  }

  // -----------------------------------------------------------------
  // Set Methods - Set User Lang
  setUserLangCode(countryCode: string): void {
    this.userLangCode = countryCode;
  }

  setUserCurrencyDetails(currencyDetails: CurrencyDetails): void {
    this.userCurrencyDetails = currencyDetails;
  }

  setUserBrowserCountry(userBrowserCountry: string): void {
    this.userBrowserCountry = userBrowserCountry;
  }

  setUserTotalBalance(userTotalBalance: number): void {
    this.userTotalBalance = userTotalBalance;
  }

  setWithdrawableBalance(withdrawableBalance: number): void {
    this.withdrawableBalance = withdrawableBalance;
  }

  setUserTotalBonus(userTotalBonus: number): void {
    this.userTotalBonus = userTotalBonus;
  }

  setUserBalanceDetails(userBalancePocketDetails: UserProfileBalance | any): void {
    this.userTotalBalance =
      userBalancePocketDetails.realCashBalance +
      userBalancePocketDetails.crpBalance +
      userBalancePocketDetails.wrpBalance +
      userBalancePocketDetails.drpBalance;

    this.userTotalBonus = userBalancePocketDetails.crpBalance + userBalancePocketDetails.wrpBalance;
    this.withdrawableBalance = userBalancePocketDetails.realCashBalance + userBalancePocketDetails.drpBalance;
      
    this.userBalanceDetails = {
      userTotalBalance: this.userTotalBalance,
      userTotalBonus: this.userTotalBalance,
      withdrawableBalance: this.withdrawableBalance,
    };

    this.userBalanceUpdated.next("UserBalanceUpdated");
  }

  setLastLoginTime(time: string): void {
    this.lastLoginTime = new Date(parseInt(time) * 1000);
  }

  setUserCountryCode(countryCode: string): void {
    this.userCountryCode = countryCode;
  }

  resetAllDetails(): void {
    this.userCurrencyDetails = undefined;
    this.userProfileDetails = undefined;
    this.userBalanceDetails = undefined;
    this.userBrowserCountry = undefined;
    this.userCurrencySymbol = undefined;
    this.userTotalBalance = undefined;
    this.userCurrencyCode = undefined;
    this.userCountryCode = undefined;
    this.userLangCode = undefined;

    if (window.$zopim) {
      window.$zopim.livechat.clearAll();
    }

    this.userProfileUpdated.next("UserProfileUpdated");
  }
}
