export enum CurrencySymbol {
  "$" = "$",
  "€" = "€",
  "kr" = "kr",
  "R$" = "R$",
  "Ft" = "Ft",
  "KR" = "KR",
  "฿" = "฿",
  "R" = "R"
}
