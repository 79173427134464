import { CountryCodes } from "src/app/models/configurations/enums/country-code.enum";
import { Environment } from "src/app/models/environments/environments.model";

const MEDIA_URL = "https://cdn.highroller.com";

export const environment: Environment = {
  production: false,
  siteUrl: "https://qa-highroller.uatsecure.com",
  apiUrl: "https://qa-highroller.uatsecure.com",
  mediaUrl: `${MEDIA_URL}`,
  mediaUrlPath: `${MEDIA_URL}/uploads/media/CAF`,
  mediaUrlPathHir: `${MEDIA_URL}/uploads/media/HIR`,
  flowsProxyUrl: "https://flows-proxy.qplays.io/highroller-GJ1KL8",
  gamesUrlPath: `${MEDIA_URL}/uploads/games/CAF`,
  vimeoUrl: "https://player.vimeo.com/video/",
  langpath: "/languages/HIR/",
  websiteCode: "HIR",
  websiteName: "highroller",
  defaultLang: "en-row",
  defaultLangEurope: "en-eu",
  langCode: ["en", "no", "fi", "cd", "nz", "at", "ie", "hu", "et"],
  liveGameUrl: "prelive-dga.pragmaticplaylive.net",
  liveGameCasioID: "ppcdk00000004555",
  microGamingJackpotsUrl: "https://api32.gameassists.co.uk/casino/progressive/public/v1",
  paymentIqUrl: "https://test-api.paymentiq.io/paymentiq",
  paymentIqMID: "100425990",
  paymentIqUID: "highroller",
  netentBrand: "oneupent",
  defaultCurrencyCode: "EUR",
  defaultCurrencySymbol: "€",
  netentGameLiveServerUrl: "https://oneupent-livegame-test.casinomodule.com",
  netentGameStaticUrl: "https://oneupent-static-test.casinomodule.com",
  mixpanelProjectToken: "80fc93f621b1883c55734d5d188a447b",
  website: "Highroller.com",
  zimplerMarkets: ["fi-fi", "et-ee"],
  pnpGTM_trackingId: "GTM-KP95LMN",
  classicGTM_trackingId: "GTM-PSM95GV",
  livespins: {
    configurations: {
      language: "en",
      tenant: "HIR",
      serverConfig: {
        api: "https://happyhour-apigateway-stage.a.hpyhr.cloud/stream",
        ui: "https://stream-livespins-stage.a.hpyhr.cloud",
      },
    },
    shareURL: "/livespins/:sessionId/play",
  },
  features: {
    hungarian: true,
    markets: {
      estonia: true,
      austria: false,
    },
    currency: {
      enRow$: true,
    },
    regilyCurrency: {
      huf: false,
    },
    language: {
      norwayEnRow: true,
      europe: true
    },
    showConsoles: {
      regily: false,
    },
    liveCasino: {
      livespins: true
    },
    welcomeBonusInitiallyOff: {
      countries: []
    },
    otp: false,
    game: {
      loggedPlayForFun: false,
    },
  },
};
