import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  HostListener,
  SimpleChange,
  Component,
  OnDestroy,
  OnChanges,
  OnInit,
  Input,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { LobbyGameGroup } from "src/app/modules/game-groups/models/lobby/lobby-game-group.model";
import { GameProviders } from "src/app/modules/game-groups/models/game-providers.model";
import { LobbyPregmatic } from "src/app/modules/game-groups/models/lobby/lobby.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-all-games",
  templateUrl: "./all-games.component.html",
  styleUrls: ["./all-games.component.scss"],
})
export class AllGamesComponent implements OnInit, OnDestroy, OnChanges {
  // Inputs
  @Input() activeLobby: string = ""; 

  // Numbers
  filteredGamesDefaultSize: number = 28;
  loadMoreFilteredGames: number = 0;
  footerHeight: number = 0;

  // Strings
  mediaUrlPath: string = environment.mediaUrlPath;
  searchBy: string = "providers";
  languageCode: string = "";
  providerName: string = "";
  imgixParams: string = "";

  // Booleans
  isProviderGamesEnabled: boolean = false;
  isPageHeaderBackground: boolean = false;
  isprovideratozEnabled: boolean = false;
  isLoggedIn: boolean = false;
  isLoading: boolean = false;

  // Enums
  windowType: "device" | "mobile" | "Tab" = "device";

  // Arrays
  lobbyGroupDataWithGamesList: LobbyGameGroup[] = [];
  beforeSortProviderGamesList: GamePregmatic[] = [];
  lobbyGameGroupDetailsList: LobbyPregmatic[] = [];
  totalFilterGamesList: GamePregmatic[] = [];
  filteredGamesList: GamePregmatic[] = [];
  providerList: GameProviders[] = [];
  gamesList: GamePregmatic[] = [];

  // Subscriptions
  subscription: Subscription;

  constructor(
    private translationService: TranslationService,
    private gameGroupsService: GameGroupsService,
    public utilityService: UtilityService,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.loadMoreFilteredGames = this.filteredGamesDefaultSize;

    this.subscription = this.translationService.langCodeSb$.subscribe(
      (languageCode: string) => {
        this.languageCode = languageCode;
      }
    );

    this.imgixParams = this.utilityService.getImgixParams();

    window["prerenderReady"] = false;

    this.getWindowType();

    this.onGetLobbyGroupsAndGames();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["activeLobby"] &&
      changes["activeLobby"].previousValue !==
        changes["activeLobby"].currentValue
    ) {
      this.activeLobby = changes["activeLobby"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    this.footerHeight = undefined;

    const clientWidth: number = document.body.clientWidth;

    if (clientWidth <= 768) {
      this.windowType = "mobile";
    } else if (clientWidth > 768 && clientWidth <= 1024) {
      this.windowType = "Tab";
    } else {
      this.windowType = "device";
    }
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize") onResize(): void {
    this.getWindowType();
  }

  @HostListener("window:scroll") onScroll(): void {
    this.isPageHeaderBackground = this.utilityService.scrollAddClass();
  }

  @HostListener("window:scroll") onWindowScroll(): void {
    if (this.filteredGamesList && this.filteredGamesList.length > 0) {
      this.onLoadMoreGames();
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  /*
    Function return lobby details & It's inner game group details based
    on provide lobbyName or selected lobbyName
  */
  getGameGroupDetails(
    lobbyPregmaticList: LobbyPregmatic[],
    lobbyName: string
  ): LobbyPregmatic[] {
    return _.filter(lobbyPregmaticList, (lobbyPregmatic: LobbyPregmatic) => {
      return lobbyPregmatic.name.toLowerCase() === lobbyName;
    });
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetLobbyGroupsAndGames(isForce?: boolean): void {
    this.isLoading = true;

    let lobbyGroupApi: Promise<
      LobbyPregmatic[]
    > = this.gameGroupsService.getLobbyListWithGameGroupsp(isForce);

    let groupGamesApi: Promise<
      GamePregmatic[]
    > = this.gameGroupsService.getGameGroupGamesp(isForce);

    Promise.all([lobbyGroupApi, groupGamesApi]).then(
      ([lobbyGameGroupsResponse, gamesResponse]) => {
        let lobbyGameGroups: LobbyPregmatic[] = lobbyGameGroupsResponse;

        let games: GamePregmatic[] = gamesResponse;

        window["prerenderReady"] = true;

        /*
          We persist orginal game data in games variable &
          floating games after filter applied will be stored in
          filteredGames varaiable
        */
        this.gamesList = games;

        if (
          lobbyGameGroups &&
          lobbyGameGroups.length > 0 &&
          games &&
          games.length > 0
        ) {
          this.lobbyGameGroupDetailsList = this.getGameGroupDetails(
            lobbyGameGroups,
            this.activeLobby
          );

          this.lobbyGroupDataWithGamesList = this.gameGroupsService.processGameGroupGames(
            this.lobbyGameGroupDetailsList[0].gameGroupList,
            this.gamesList
          );
        }

        if (
          this.lobbyGroupDataWithGamesList &&
          this.lobbyGroupDataWithGamesList.length > 0
        ) {
          this.onDisplayByFilterType("providers");
        } else {
          this.filteredGamesList = [];

          this.providerList = [];
        }

        this.isLoading = false;
      }
    );
  }

  onDisplayByFilterType(searchBy: string, providerName?: string): void {
    this.searchBy = searchBy;

    this.providerName = providerName;

    if (this.searchBy === "a-z") {
      this.filteredGamesList = this.utilityService.sortByGameName(this.gamesList, "name");

      this.totalFilterGamesList = this.filteredGamesList;

      this.filteredGamesList = this.filteredGamesList.slice(
        0,
        this.filteredGamesDefaultSize
      );
    } else {
      this.providerList = this.utilityService.getSortedUniqProviderList(
        this.lobbyGroupDataWithGamesList[0].games
      );
    }

    window.scrollTo(0, 0);
  }

  onNavigateToStudio(vendorDisplayName: string): void {
    vendorDisplayName = this.utilityService.convertGameNameToUrl(vendorDisplayName);

    this.router.navigate([`${this.languageCode}/studio/${vendorDisplayName}`]);
  }

  onProviderLevelGameSort(): void {
    if (!this.isprovideratozEnabled) {
      this.isprovideratozEnabled = true;

      this.beforeSortProviderGamesList = this.filteredGamesList;

      this.filteredGamesList = this.utilityService.sortByGameName(
        this.filteredGamesList,
        "name"
      );
    } else {
      this.isprovideratozEnabled = false;

      this.filteredGamesList = this.beforeSortProviderGamesList;
    }
  }

  onGobackToProviders(): void {
    this.isprovideratozEnabled = false;

    this.beforeSortProviderGamesList = undefined;

    this.onDisplayByFilterType("providers");
  }

  /*
    Below function is to handle lazy load based on window scroll.
  */
  onLoadMoreGames(): void {
    const footerContainerDom: HTMLCollectionOf<Element> = document.getElementsByClassName(
      "footer-conatiner"
    );

    const gamesConatinerDom: HTMLCollectionOf<Element> = document.getElementsByClassName(
      "vp__game-container"
    );

    const gameCardDom: HTMLCollectionOf<Element> = document.getElementsByClassName("game-card");

    if (this.footerHeight === undefined) {
      this.footerHeight = footerContainerDom[0].clientHeight + 150;
    }

    if (footerContainerDom.length > 0 && gameCardDom.length > 0) {
      const allGamesWrapper: number = gamesConatinerDom[0].clientWidth;

      const gameCard: number = gameCardDom[0].clientWidth;

      const gamesLengthInRow: number = Math.round(allGamesWrapper / gameCard);

      const fillMissingGaps: number = Math.ceil(
        this.filteredGamesDefaultSize -
          Math.floor(this.filteredGamesDefaultSize / gamesLengthInRow) *
            gamesLengthInRow
      );

      if (fillMissingGaps !== 0) {
        this.filteredGamesDefaultSize += Math.ceil(
          gamesLengthInRow - fillMissingGaps
        );

        this.loadMoreFilteredGames = this.filteredGamesDefaultSize;

        this.filteredGamesList = this.totalFilterGamesList.slice(
          0,
          this.loadMoreFilteredGames
        );
      }
    }

    if (
      window.scrollY + window.outerHeight >=
      document.body.scrollHeight - this.footerHeight
    ) {
      this.loadMoreFilteredGames += this.filteredGamesDefaultSize;

      this.filteredGamesList = this.totalFilterGamesList.slice(
        0,
        this.loadMoreFilteredGames
      );
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
