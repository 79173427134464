import { Observable, Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

// Services
import { MainService } from "src/app/modules/shared/services/main.service";

@Injectable({
  providedIn: "root",
})
export class CashbackPromoService {
  promoOptnSuccess: boolean = false;

  constructor(private mainService: MainService) {}

  getUserCampaigns(): Observable<any> {
    return this.mainService.getUserCampaigns().pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getPromoConfigDetails(requestObj): Observable<any> {
    return this.mainService.getPromoConfigDetails(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getOptIn_url(requestObj): Observable<any> {
    return this.mainService.getOptIn_url(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getOptOut_url(requestObj): Observable<any> {
    return this.mainService.getOptOut_url(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getPlayerPromoProgress(requestObj): Observable<any> {
    return this.mainService.getPlayerPromoProgress(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  SetOptnSuccess(flag) {
    this.promoOptnSuccess = flag;
  }

  getOptnSuccess() {
    return this.promoOptnSuccess;
  }

  private promoCashbackOptnSuccess = new Subject<any>();
  public promoCashbackOptnSuccess$ = this.promoCashbackOptnSuccess.asObservable();

  broadCastPromoCounter() {
    this.promoCashbackOptnSuccess.next();
  }

  private StopCbPromoCounter = new Subject<any>();
  public StopCbPromoCounter$ = this.StopCbPromoCounter.asObservable();

  broadCastStopPromoCounter(flag) {
    this.StopCbPromoCounter.next(flag);
  }

  private cashBackOfferAmount = new Subject<any>();
  public cashBackOfferAmount$ = this.cashBackOfferAmount.asObservable();

  broadCastCashBackOfferAmount(data) {
    this.cashBackOfferAmount.next(data);
  }

  userLastAuthTime: any;
  setUserLastAuthTime(lastLoginTimeInMilliseconds) {
    this.userLastAuthTime = lastLoginTimeInMilliseconds * 1000;
  }

  getUserLastAuthTime() {
    return this.userLastAuthTime;
  }

  private cashBackstartedPromoPopup = new Subject<any>();
  public cashBackstartedPromoPopup$ = this.cashBackstartedPromoPopup.asObservable();

  broadCastcashBackstartedPromoPopup(data) {
    this.cashBackstartedPromoPopup.next(data);
  }

  private openCashBackRewardTermsConditions = new Subject<any>();
  public openCashBackRewardTermsConditions$ = this.openCashBackRewardTermsConditions.asObservable();

  broadCastCashBackRewardTC(flag) {
    this.openCashBackRewardTermsConditions.next(flag);
  }

  userCashBackPromoInfo: any;
  setUserCashBackPromoInfo(userCashBackPromoInfo) {
    this.userCashBackPromoInfo = userCashBackPromoInfo;
  }

  getUserCashBackPromoInfo() {
    return this.userCashBackPromoInfo;
  }
}
