import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

// Services
import { MainService } from "src/app/modules/shared/services/main.service";

@Injectable({
  providedIn: "root",
})
export class NewsTickerService {
  bannerDataByZoneId: any = {};

  constructor(private mainService: MainService) {}

  bannerCallQueue = [];
  getStickerBanners(bannerReqData, isForce) {
    if (isForce) {
      this.bannerDataByZoneId = {};
    }
    if (this.bannerDataByZoneId[bannerReqData.zoneId] && !isForce) {
      return this.bannerDataByZoneId[bannerReqData.zoneId];
    } else {
      return new Promise((resolve, reject) => {
        if (this.bannerCallQueue.length > 0) {
          this.bannerCallQueue.push(resolve);
        } else {
          this.bannerCallQueue.push(resolve);
          Promise.resolve(this.mainService.getBannersp(bannerReqData))
            .then((data) => {
              if (data && data.length > 0) {
                this.bannerDataByZoneId[bannerReqData.zoneId.toString()] = data;
              }
              for (let callback in this.bannerCallQueue) {
                this.bannerCallQueue[callback](data);
              }
              this.bannerCallQueue = [];
            })
            .catch((error) => {
              return [];
            });
        }
      });
    }
  }

  private isNewsTickerBannerAvailable = new Subject<any>();
  public isNewsTickerBannerAvailable$ = this.isNewsTickerBannerAvailable.asObservable();

  broadCastIsNewsTickerBannerAvialable(flag) {
    this.isNewsTickerBannerAvailable.next(flag);
  }
}
