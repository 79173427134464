export enum CountryCodes {
  "CA" = "CA",
  "FI" = "FI",
  "NO" = "NO",
  "IN" = "IN",
  "NZ" = "NZ",
  "JP" = "JP",
  "AT" = "AT",
  "BR" = "BR",
  "PE" = "PE",
  "CL" = "CL",
  "PY" = "PY",
  "UY" = "UV",
  "BO" = "BO",
  "HU" = "HU",
  "IE" = "IE",
  "EE" = "EE",
  "TH" = "TH",
  "AL" = "AL",
  "AD" = "AD",
  "AM" = "AM",
  "BY" = "BY",
  "BE" = "BE",
  "BA" = "BA",
  "BG" = "BG",
  "CH" = "CH",
  "CY" = "CY",
  "CZ" = "CZ",
  "DE" = "DE",
  "DK" = "DK",
  "ES" = "ES",
  "FO" = "FO",
  "FR" = "FR",
  "GB" = "GB",
  "GE" = "GE",
  "GI" = "GI",
  "GR" = "GR",
  "HR" = "HR",
  "IS" = "IS",
  "IT" = "IT",
  "LT" = "LT",
  "LU" = "LU",
  "LV" = "LV",
  "MC" = "MC",
  "MK" = "MK",
  "MT" = "MT",
  "NL" = "NL",
  "PL" = "PL",
  "PT" = "PT",
  "RO" = "RO",
  "RU" = "RU",
  "SE" = "SE",
  "SI" = "SI",
  "SK" = "SK",
  "SM" = "SM",
  "TR" = "TR",
  "UA" = "UA",
  "VA" = "VA",
}
