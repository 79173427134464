import { Pipe, PipeTransform } from "@angular/core";

// Methods
import { PaymentMethodName } from "src/app/modules/shared/models/payment-method-name/payment-method-name.model";

@Pipe({
  name: "paymentMethodName",
})
export class PaymentMethodNamePipe implements PipeTransform {
  transform(
    paymentObject: PaymentMethodName,
    isDepositInput?: boolean
  ): string {
    let providerName: string = "";

    const serviceName: string = paymentObject.service;

    if (paymentObject.type) {
      providerName = paymentObject.type;
    } else if (paymentObject.providerType) {
      providerName = paymentObject.providerType;
    } else {
      providerName = "";
    }

    if (serviceName && !isDepositInput) {
      providerName = `${providerName}-${serviceName}`;
    } else if (serviceName && isDepositInput) {
      providerName = `${providerName}_${serviceName}`;
    }

    if (isDepositInput) {
      providerName = `${providerName}_PIQ`.toUpperCase();
    } else {
      providerName = providerName.toLowerCase();
    }

    return providerName;
  }
}
