import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs";
import {
  ViewEncapsulation,
  SimpleChanges,
  HostListener,
  Component,
  OnDestroy,
  Input,
} from "@angular/core";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-rewards-indicator",
  templateUrl: "./rewards-indicator.component.html",
  styleUrls: ["./rewards-indicator.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RewardsIndicatorComponent implements OnDestroy {
  rewardsIndicatorData = {
    levelDetails: {
      oldLevelId: 0,
      currentLevelID: 0,
      gameId: undefined,
    },
    pointsDetails: {
      currentSpinCount: undefined,
      spinsNeededForNextLevel: undefined,
    },
  };
  playButtonCutoffValue = 5;
  progressBarValue: number = 0;
  notificationCount: number = 0;

  currentLevelSub: Subscription;
  currentPointtDetailsSub: Subscription;
  userLevelupDetailsSub: Subscription;
  userProfileSubscription: any;
  username: any;
  windowType;
  userLevelupSub: Subscription;
  isLiveCasinoPage: boolean = false;
  clearNotificationSub: Subscription;

  @Input() value: number;
  radius = 45;
  circumference = 2 * Math.PI * this.radius;
  dashoffset: number;
  isGamePlayWindow: boolean = false;
  isPnpFlow: boolean = false;

  @HostListener("window:orientationchange") onRotate() {
    this.checkIsLiveCasino();
    this.getWindowType();
  }

  constructor(
    private utility: UtilityService,
    private router: Router,
    private commonService: CommonService,
    private rewardsService: RewardsService,
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService
  ) {
    this.isPnpFlow = this.utility.isPnpFlow();
    this.userProfileSubscription = this.userDetailsService.userProfileUpdated$.subscribe(
      (data) => {
        const userProfileData = this.userDetailsService.getUserProfileDetails();
        if (userProfileData) {
          this.username = userProfileData["firstName"];
        }
      }
    );
    this.currentLevelSub = this.rewardsService.userLevelUpDetails$.subscribe(
      (data) => {
        if (data && Object.keys(data).length > 0) {
          this.rewardsIndicatorData["levelDetails"] = {
            oldLevelId: data["oldLevelId"],
            currentLevelID: data["newLevelId"],
            gameId: data["gameId"],
          };
          this.updateNotificationCount();
        } else {
          this.clearNotification();
          this.getUserLevelupDetails();
        }
      }
    );

    this.currentPointtDetailsSub = this.rewardsService.userLevelUpPointDetails$.subscribe(
      (data) => {
        if (data && Object.keys(data).length > 0) {
          this.rewardsIndicatorData["pointsDetails"] = {
            currentSpinCount: data["currentSpinCount"],
            spinsNeededForNextLevel: data["spinsNeededForNextLevel"],
          };
          this.rewardsIndicatorData["levelDetails"]["currentLevelID"] =
            data["levelId"];
          this.calculateNextLevelProgressBarValue(
            this.rewardsIndicatorData["pointsDetails"],
            "push"
          );
        }
      }
    );
    /**Code to check isLiveCasinoPage or Not based on URL segments */
    this.checkIsLiveCasino();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkIsLiveCasino();
      }
    });

    this.clearNotificationSub = this.rewardsService.clearLevelUpNotification$.subscribe(
      (flag) => {
        if (this.notificationCount && flag) {
          this.clearNotification();
        }
      }
    );
    this.initializeProgressCircle();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url &&
          event.url.includes("/" + this.translationService.instant("url.game"))
        ) {
          this.isGamePlayWindow = true;
        } else {
          this.isGamePlayWindow = false;
        }
      }
    });
  }

  getUserLevelupDetails() {
    this.userLevelupSub = this.rewardsService
      .getUserLevelupDetails()
      .subscribe((data) => {
        if (data && data["status"] === "SUCCESS") {
          this.rewardsIndicatorData["levelDetails"]["currentLevelID"] =
            data["levelId"];
          Object.assign(this.rewardsIndicatorData["pointsDetails"], {
            currentSpinCount: data["currentSpinCount"],
            spinsNeededForNextLevel: data["spinsNeededForNextLevel"],
          });
          this.rewardsService.setLevelBeforeLevelUpgraded(
            this.rewardsIndicatorData["levelDetails"]["currentLevelID"]
          );
          this.calculateNextLevelProgressBarValue(
            this.rewardsIndicatorData["pointsDetails"]
          );
        }
      });
  }

  updateNotificationCount() {
    this.rewardsIndicatorData["pointsDetails"] = {
      currentSpinCount: 0,
      spinsNeededForNextLevel: 0,
    };
    this.progressBarValue = 0;

    this.setRewardIndicatorProgressValue(this.progressBarValue * 100);
    this.notificationCount = this.rewardsService.getNotificationCount(
      this.rewardsIndicatorData["levelDetails"]["currentLevelID"]
    );
  }

  calculateNextLevelProgressBarValue(data, callingFrom?: string) {
    if (
      data &&
      data.hasOwnProperty("currentSpinCount") &&
      data.hasOwnProperty("spinsNeededForNextLevel")
    ) {
      let totalSpins =
        data["currentSpinCount"] + data["spinsNeededForNextLevel"];
      if (totalSpins) {
        let percentageOfSpinsNeedForNextLevel =
          (data["currentSpinCount"] / totalSpins) * 100;
        this.progressBarValue = parseFloat(
          (percentageOfSpinsNeedForNextLevel / 100).toFixed(2)
        );
        this.setRewardIndicatorProgressValue(this.progressBarValue * 100);
      }
    }
  }

  checkIsLiveCasino() {
    let segments = window.location.pathname.split("/");
    if (
      segments &&
      segments.length >= 2 &&
      this.utility.getActiveLobby(segments[2]) === "live-casino"
    ) {
      this.isLiveCasinoPage = true;
    } else {
      this.isLiveCasinoPage = false;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getWindowType();
    }, 100);
  }

  initializeProgressCircle() {
    this.dashoffset = this.circumference;
    this.getUserLevelupDetails();
  }

  getWindowType() {
    var ww = document.body.clientWidth;
    if (ww >= 1024) {
      this.windowType = "desktop";
    } else {
      this.windowType = "mobile";
    }
  }

  openCongratsPopUp() {
    this.commonService.broadCastActiveAcountMenu("reward-congratulations");
    if (this.notificationCount) {
      this.clearNotification();
    }
  }

  openAccountComponent(viewname) {
    this.clearNotification();
    this.utility.openAccountComponent(viewname);
  }

  clearNotification() {
    this.rewardsService.setLevelBeforeLevelUpgraded(
      this.rewardsIndicatorData["levelDetails"]["currentLevelID"]
    );
    this.notificationCount = 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value.currentValue !== changes.value.previousValue) {
      this.setRewardIndicatorProgressValue(changes.value.currentValue);
    }
  }

  setRewardIndicatorProgressValue(value: number) {
    const progress = value / 100;
    this.dashoffset = this.circumference * (1 - progress);
  }

  ngOnDestroy() {
    this.currentLevelSub.unsubscribe();
    this.currentPointtDetailsSub.unsubscribe();
    this.clearNotificationSub.unsubscribe();
    if (this.userLevelupDetailsSub) {
      this.userLevelupDetailsSub.unsubscribe();
    }
    if (this.userLevelupSub) {
      this.userLevelupSub.unsubscribe();
    }
  }
}
