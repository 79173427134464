import { Observable, of, Subscription } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpInterceptor,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";

// Configurations
import {
  languageToGameLaunchConfigurations,
  localToCmsLanguageConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { getLanguageForCountry, supportedLanguagesList } from "src/app/modules/shared/utilities/languages.utilities";
import { CountryBlockType } from "src/app/modules/shared/models/country/country-block-type.model";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  langCode: string;
  acceptedLanguages: any;
  isLoggedIn: boolean;
  loginCompleteSubscription: Subscription;
  constructor(
    private utility: UtilityService,
    private router: Router,
    private commonService: CommonService,
    private translationService: TranslationService
  ) {
    this.acceptedLanguages = supportedLanguagesList();
    this.langCode = this.utility.getLangCode();
    this.translationService.langCodeSb$.subscribe((landcode) => {
      this.langCode = landcode;
    });
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
    this.loginCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
      }
    );
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const urlSegments = this.utility.getDecodedCurrentPath().split("/");
    let landcode = urlSegments && urlSegments.length >= 2 ? urlSegments[1] : "";
    let userLangCode = this.utility.getLangCode();
    if (this.isLoggedIn && userLangCode) {
      this.langCode = userLangCode;
    } else if (landcode && _.contains(this.acceptedLanguages, landcode)) {
      this.langCode = urlSegments[1];
    } else {
      this.langCode = environment.defaultLang;
    }
    /**
     * If we want to modify any request object..we can do it there like
     * adding header or modifying header base on endpoint ur or request
     * Method type
     */
    let updatedRequest = request;
    if (
      this.langCode &&
      request.url &&
      (request.url.includes("/ajax/game/") ||
        request.url.includes("/ajax/banner/getBanners") ||
        request.url.includes("/ajax/promotion/getPromotions"))
    ) {
      updatedRequest = request.clone({
        setParams: {
          languageId: localToCmsLanguageConfigurations[this.langCode],
        },
      });
    }

    if (
      this.langCode &&
      request.url &&
      request.url.includes("/ajax/staticPage")
    ) {
      updatedRequest = request.clone({
        setParams: {
          language: localToCmsLanguageConfigurations[this.langCode],
        },
      });
    }

    if (this.langCode && request.url && request.url.includes("/ajax/faq/")) {
      updatedRequest = request.clone({
        setParams: {
          lang: localToCmsLanguageConfigurations[this.langCode],
        },
      });
    }

    if (
      this.langCode &&
      request.url &&
      (request.url.includes("/ajax/launcher/getRealGames") ||
        request.url.includes("/ajax/launcher/getFreeGames"))
    ) {
      updatedRequest = request.clone({
        setParams: {
          languageId: languageToGameLaunchConfigurations[this.langCode],
        },
      });
    }

    return next.handle(updatedRequest).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          /**
           * You will receive every http call response object here
           * we can write some global logic to handle it
           * like navigation to different page based on response.
           */
          if (
            event["body"] &&
            event["body"]["error"] &&
            event["body"]["error"] === 150
          ) {
            this.setLanguage(event["body"]["countryCode"]);
            this.navigatorBasedOnError(event["body"]["error"]);
          } else if (
            event["body"] &&
            event["body"]["error"] &&
            event["body"]["error"]
          ) {
            this.navigatorBasedOnError(event["body"]["error"]);
          } else if (event && event["error"]) {
            this.navigatorBasedOnError(event["error"]);
          } else if (
            event &&
            event["body"]["errors"] &&
            event["body"]["errors"][0]
          ) {
            this.navigatorBasedOnError(event["body"]["errors"][0]["errorCode"]);
          } else if (
            event &&
            event["body"] &&
            event["body"]["is_IpBlocked"] &&
            JSON.parse(event["body"]["is_IpBlocked"]) == true && 
            event["body"]["blockType"] === "Hard"
          ) {
            this.setLanguage(event["body"]["countryCode"]);
            this.commonService.setCountryBlockType(CountryBlockType.Hard);
            this.navigatorBasedOnError("country_blocked");
          } else if (
            event &&
            event["body"] &&
            event["body"]["blockType"] === "Soft"
          ) {
            this.commonService.setCountryBlockType(CountryBlockType.Soft);
            this.setLanguage(event["body"]["countryCode"]);
          } else {
            let pathname = this.utility.getDecodedCurrentPath();
            if (
              pathname.includes("maintenance") ||
              pathname.includes("countryblock")
            ) {
              this.router.navigate([this.langCode + "/casino"]);
            }
          }
        }
        return event;
      }),
      catchError((event: HttpEvent<any>) => {
        return of(event);
      })
    );
  }

  setLanguage(countryCode) {
    this.langCode = getLanguageForCountry(countryCode);
    this.translationService.use(localToCmsLanguageConfigurations[this.langCode]);
  }

  navigatorBasedOnError(code) {
    switch (code) {
      case 150:
        this.router.navigate([this.langCode + "/maintenance"]);
        break;
      case "country_blocked":
        this.router.navigate([this.langCode + "/countryblock"]);
        break;
      case 100187:
        this.commonService.doLogout();
      default:
        return;
    }
  }
}
