import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";

// Constants
import { UserFlowTypes } from "src/app/constants/costants";

// Environments
import { environment } from "src/environments/environment";

// Models
import { MarketBasedConfiguration } from "src/app/modules/shared/models/market-based/market-based-configuration.model";

@Directive({
  selector: "[marketBasedComponentDisplay]",
})
export class MarketBasedComponentDisplayDirective {
  // Arrays
  zimplerMarketsList: string[] = [];

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {
    this.zimplerMarketsList = environment.zimplerMarkets;
  }

  // Set Input
  @Input() set marketBasedComponentDisplay(marketBasedConfiguration: MarketBasedConfiguration) {
    if (marketBasedConfiguration && marketBasedConfiguration.langCode) {
      this.displayComponent(marketBasedConfiguration);
    }
  }

  /*
    Below logic decides whether to display component/template
    based on geoConfigData
  */
  displayComponent(marketBasedConfiguration: MarketBasedConfiguration): void {
    this.viewContainer.clear();

    if (
      marketBasedConfiguration.flowType === UserFlowTypes.ZIMPLER_FLOW &&
      this.zimplerMarketsList.indexOf(marketBasedConfiguration.langCode) > -1
    ) {
      this.viewContainer.createEmbeddedView(this.templateRef);

      return;
    }

    if (
      marketBasedConfiguration.flowType === UserFlowTypes.NORMAL_FLOW &&
      this.zimplerMarketsList.indexOf(marketBasedConfiguration.langCode) === -1
    ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    this.viewContainer.clear();

    return;
  }
}
