import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

// Constants
import { UserFlowTypes } from "src/app/constants/costants";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import * as _ from "underscore";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-pending-withdraw",
  templateUrl: "./pending-withdraw.component.html",
  styleUrls: ["./pending-withdraw.component.scss"],
})
export class PendingWithdrawComponent implements OnInit, OnDestroy {
  piqObject = {};
  balanceDetails;
  pendingWithdrawals = [];
  currencySymbolSub: Subscription;
  currencySymbol;
  pedingWitdrawalSubs;
  cancelWithdrawSubs;
  isLoading = false;
  selectedWithdrawCancelTransaction;
  currencyCodeSub: Subscription;
  currencyCode: string;
  pendingWithdrawConfirmationPopup = false;
  zimplerMarkets: string[];
  langCode;
  langCodeSubscription: Subscription;
  UserFlowTypes: typeof UserFlowTypes;
  constructor(
    private userDetailsService: UserDetailsService,
    private cashierService: CashierService,
    private commonService: CommonService,
    private currencyFormatPipe: CurrencyFormatPipe,
    private paynplayCashierService: PayNPlayCashierService,
    private utility: UtilityService,
    private translationService: TranslationService
  ) {
    this.zimplerMarkets = environment.zimplerMarkets;
    this.UserFlowTypes = UserFlowTypes;
    this.langCode = this.utility.getLangCode();
    this.langCodeSubscription = this.translationService.langCode$.subscribe(
      (langcode) => {
        this.langCode = langcode;
      }
    );
  }

  swiperPendingWithdraw: SwiperConfigInterface = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 8,
    observer: true,
    observeParents: true,
    watchOverflow: true,
    resistanceRatio: 0,
    pagination: {
      el: ".swipper-pagination",
      clickable: true,
    },
  };

  ngOnInit() {
    this.currencySymbolSub = this.userDetailsService.currencySymbolSb$.subscribe(
      (currencySymbol) => {
        this.currencySymbol = currencySymbol;
      }
    );

    this.currencyCodeSub = this.userDetailsService.currencyCodeSb$.subscribe(
      (currencyCode) => {
        this.currencyCode = currencyCode;
      }
    );

    const profileDetails = this.userDetailsService.getUserProfileDetails();
    this.balanceDetails = this.userDetailsService.getUserBalanceDetails();

    if (!_.isEmpty(profileDetails) && !_.isEmpty(this.balanceDetails)) {
      this.piqObject["userId"] = profileDetails.playerID;
      this.getCashierToken();
    } else {
      const getTokenObservable = this.cashierService.getToken({
        token: "cashier",
      });
      const getProfileDataObservable = this.commonService.getProfileBalanceCurrency();
      const getUserbalanceBypockets = this.commonService.getUserBalanceByPockets();
      Promise.all([
        getTokenObservable,
        getProfileDataObservable,
        getUserbalanceBypockets,
      ]).then((results) => {
        const profileData: any = results[1];
        if (
          profileData &&
          profileData.profile &&
          profileData.profile.playerID &&
          results[2]
        ) {
          this.balanceDetails = this.userDetailsService.getUserBalanceDetails();
          this.piqObject["userId"] = profileData.profile.playerID;
          const tokenData = results[0];
          this.parseTokenData(tokenData);
        }
      });
    }
  }

  getCashierToken() {
    this.isLoading = true;
    Promise.resolve(this.cashierService.getToken({ token: "cashier" })).then(
      (tokenDetails: any) => {
        if (
          tokenDetails &&
          tokenDetails.status === "SUCCESS" &&
          tokenDetails.token &&
          tokenDetails.token !== ""
        ) {
          this.parseTokenData(tokenDetails);
          this.piqObject["sessionId"] = tokenDetails.token;
        }
      },
      (usedAccountError) => {
        console.log(usedAccountError);
      }
    );
  }

  parseTokenData(tokenDetails) {
    if (
      tokenDetails.status === "SUCCESS" &&
      tokenDetails.token &&
      tokenDetails.token !== ""
    ) {
      this.piqObject["sessionId"] = tokenDetails.token;
      this.piqObject["merchantId"] = environment.paymentIqMID;
      if (this.utility.isPnpFlow()) {
        this.getPNPPendingWithdrawls();
      } else {
        this.getCashierPendingWithdrawals();
      }
    } else {
      this.pendingWithdrawals = [];
      this.isLoading = false;
    }
  }

  getPNPPendingWithdrawls() {
    this.isLoading = true;
    let pendingWithdrawalObject = {
      cashoutVerifiedtxnRequired: true,
    };
    this.pedingWitdrawalSubs = this.paynplayCashierService
      .getPendingCashOuts(pendingWithdrawalObject)
      .subscribe(
        (pendingWithdrawalData) => {
          this.isLoading = false;
          if (pendingWithdrawalData && pendingWithdrawalData.length > 0) {
            this.pendingWithdrawals = pendingWithdrawalData;
          } else {
            this.pendingWithdrawals = [];
          }
        },
        (rejectError) => {
          this.pendingWithdrawals = [];
          this.isLoading = false;
        }
      );
  }

  getCashierPendingWithdrawals() {
    const getPendingWithdrawalObject = _.clone(this.piqObject);
    getPendingWithdrawalObject["method"] = "Withdrawal";
    getPendingWithdrawalObject["states"] = "WAITING_APPROVAL";
    this.pedingWitdrawalSubs = this.cashierService
      .getPendingWithdrawals(getPendingWithdrawalObject)
      .subscribe(
        (pendingWithdrawalData) => {
          this.isLoading = false;
          if (
            pendingWithdrawalData &&
            pendingWithdrawalData.success &&
            pendingWithdrawalData.transactions
          ) {
            this.pendingWithdrawals = pendingWithdrawalData.transactions;
          } else {
            this.pendingWithdrawals = [];
          }
        },
        (rejectError) => {
          this.pendingWithdrawals = [];
          this.isLoading = false;
        }
      );
  }

  cancelTransaction(transactionId) {
    this.isLoading = true;
    const cancelWithdrawObject = _.clone(this.piqObject);
    cancelWithdrawObject["transactionId"] = transactionId;
    this.closeConfirmationPopup();
    this.cancelWithdrawSubs = this.cashierService
      .cancelTransaction(cancelWithdrawObject)
      .subscribe(
        (cancelWithdraw) => {
          if (cancelWithdraw && cancelWithdraw.success) {
            this.getCashierPendingWithdrawals();
          } else {
            this.isLoading = false;
          }
        },
        (rejectError) => {
          this.isLoading = false;
        }
      );
  }

  cancelPnPTransaction(selectedWithdrawCancelTransaction) {
    if (selectedWithdrawCancelTransaction) {
      let cancelWithdrawObject = {
        txnId: selectedWithdrawCancelTransaction.txnId,
        txnAmount: selectedWithdrawCancelTransaction.amountInEcrCurrency,
      };
      this.closeConfirmationPopup();
      this.cancelWithdrawSubs = this.paynplayCashierService
        .revertWithdrawal(cancelWithdrawObject)
        .subscribe(
          (cancelWithdraw) => {
            if (cancelWithdraw && cancelWithdraw["status"] === "SUCCESS") {
              this.getPNPPendingWithdrawls();
            } else {
              this.isLoading = false;
            }
          },
          (rejectError) => {
            this.isLoading = false;
          }
        );
    }
  }

  openConfirmationPopup(transaction) {
    transaction = { ...transaction };
    if (transaction && transaction["amount"]) {
      transaction["amount"] = this.amountCurrencyFormat(transaction["amount"]);
    }
    this.selectedWithdrawCancelTransaction = transaction;
    this.pendingWithdrawConfirmationPopup = true;
  }

  closeConfirmationPopup() {
    this.pendingWithdrawConfirmationPopup = false;
  }

  amountCurrencyFormat(value) {
    if (value && this.currencyCode) {
      var regex = new RegExp("-|" + this.currencyCode + "| ", "gi");
      return this.currencyFormatPipe.transform(
        value.replace(regex, ""),
        this.currencySymbol,
        false
      );
    } else {
      return value;
    }
  }

  ngOnDestroy() {
    this.currencyCodeSub.unsubscribe();
    if (this.currencySymbolSub) {
      this.currencySymbolSub.unsubscribe();
    }
    if (this.pedingWitdrawalSubs) {
      this.pedingWitdrawalSubs.unsubscribe();
    }
    if (this.cancelWithdrawSubs) {
      this.cancelWithdrawSubs.unsubscribe();
    }
  }
}
