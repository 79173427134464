import { Component, Input, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-csn-livespins-iframe",
  templateUrl: "./csn-livespins-iframe.component.html",
  styleUrls: ["./csn-livespins-iframe.component.scss"],
})
export class CsnLivespinsIFrameComponent {
  @Input() iframeURL: string;
  
  iframe: string;

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes["iframeURL"].currentValue && (changes["iframeURL"].previousValue != changes["iframeURL"].currentValue)) {
      this.iframe = changes["iframeURL"].currentValue;
    }
  }
}
