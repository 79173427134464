import { FormGroup } from "@angular/forms";
import {
  ComponentFactoryResolver,
  ViewContainerRef,
  EventEmitter,
  Directive,
  Output,
  Input,
} from "@angular/core";

// Components
import { FormButtonComponent } from "src/app/modules/dynamic-form/components/form-button/form-button.component";
import { FormSelectComponent } from "src/app/modules/dynamic-form/components/form-select/form-select.component";
import { FormInputComponent } from "src/app/modules/dynamic-form/components/form-input/form-input.component";
import { FormLabelComponent } from "src/app/modules/dynamic-form/components/form-label/form-label.component";
import { FormRadioComponent } from "src/app/modules/dynamic-form/components/form-radio/form-radio.component";

const components = {
  button: FormButtonComponent,
  tel: FormInputComponent,
  password: FormInputComponent,
  text: FormInputComponent,
  email: FormInputComponent,
  select: FormSelectComponent,
  label: FormLabelComponent,
  radio: FormRadioComponent,
};

@Directive({
  selector: "[dynamicField]",
})
export class DynamicFieldDirective {
  @Input() config;
  @Input() group: FormGroup;
  @Input() selectedMethodData: any;
  @Input() callingFrom: any;
  @Output() buttonClicked = new EventEmitter<String>();
  @Input() usedAccount: boolean;
  component;
  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {}

  ngOnInit() {
    const component = components[this.config.type];
    const factory = this.resolver.resolveComponentFactory<any>(component);
    this.component = this.container.createComponent(factory);
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
    this.component.instance.selectedMethodData = this.selectedMethodData;
    this.component.instance.callingFrom = this.callingFrom;
    this.component.instance.usedAccount = this.usedAccount;
    if (this.config.type === "button") {
      this.component.instance.buttonClicked = this.buttonClicked;
    }
  }
}
