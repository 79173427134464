import { Component, EventEmitter, Output } from "@angular/core";

// Configurations
import {
  liveProviderCheckListPreferredOrderConfigurations,
  mixPanelEventsConfigurations,
} from "src/app/configurations/main.configurations";

// Models
import { GameProviders } from "src/app/modules/game-groups/models/game-providers.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Pipes
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { MixPanelService } from "src/app/modules/shared/services/mix-panel.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-provider-filter",
  templateUrl: "./provider-filter.component.html",
  styleUrls: ["./provider-filter.component.scss"],
})
export class ProviderFilterComponent {
  // Output
  @Output() callOnProvidersChanged: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  // Booleans
  isLoading: boolean = true;

  // Arrays
  checkedProviderNamesList: string[] = [];
  liveProviderList: GameProviders[] = [];

  constructor(
    private gameGroupsService: GameGroupsService,
    private gamesFilterPipe: GamesFilterPipe,
    private mixPanelService: MixPanelService,
    private utilityService: UtilityService
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onPrepareLiveProviderList();
  }

  // -----------------------------------------------------------------
  // Get Methods
  getPreferredSortedOrder(providerList: GameProviders[]): GameProviders[] {
    const providerPreferredOrder: string[] = liveProviderCheckListPreferredOrderConfigurations;

    return providerList.sort(
      (object1: GameProviders, object2: GameProviders) => {
        return (
          providerPreferredOrder.indexOf(object1.vendorCode) -
          providerPreferredOrder.indexOf(object2.vendorCode)
        );
      }
    );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onPrepareLiveProviderList(): void {
    Promise.resolve(this.gameGroupsService.getGameGroupGamesp()).then(
      (gamesList: GamePregmatic[]) => {
        this.isLoading = false;

        if (gamesList && gamesList.length > 0) {
          gamesList = JSON.parse(JSON.stringify(gamesList));

          gamesList = this.gamesFilterPipe.transform(gamesList, {
            typeOfGames: "live-game",
          });

          this.liveProviderList = this.getPreferredSortedOrder(
            this.utilityService.getProviderList(gamesList)
          );
        } else {
          this.liveProviderList = [];
        }
      }
    );
  }

  onChangeSelection(providerName: string, isClicked: boolean): void {
    this.checkedProviderNamesList = [];

    this.liveProviderList.forEach((gameProvider: GameProviders) => {
      if (!gameProvider.isChecked) {
        this.checkedProviderNamesList.push(gameProvider.vendorCode);
      }
    });

    this.onSendMixPanelEvent(providerName, isClicked);

    this.callOnProvidersChanged.emit(this.checkedProviderNamesList);
  }

  onSendMixPanelEvent(providerName: string, isClicked: boolean): void {
    this.mixPanelService.trackMixpanelEvents(
      mixPanelEventsConfigurations.clicked_Provider_Filter,
      {
        name: providerName,
        isClicked,
      }
    );
  }
}
