import { UrlLanguagesTranslations } from "src/app/modules/shared/models/urls/url-languages.model";

export const URLS_TRANSLATIONS: Record<string, UrlLanguagesTranslations> = {
  homepage: {
    en: "casino",
    ja: "casino",
    fi: "casino",
    cd: "casino",
    nl: "casino",
    nz: "casino",
    no: "casino",
    at: "casino",
    ie: "casino",
    hu: "casino",
    et: "casino",
  },
  liveCasino: {
    en: "live-casino",
    ja: "live-casino",
    fi: "live-casino",
    cd: "live-casino",
    nl: "live-casino",
    nz: "live-casino",
    no: "live-casino",
    at: "live-casino",
    ie: "live-casino",
    hu: "live-casino",
    et: "live-casino",
  },
  promotions: {
    en: "promotions",
    no: "kampanjer",
    fi: "kampanjat",
    ja: "promotions",
    nz: "promotions",
    nl: "promotions",
    cd: "promotions",
    at: "aktionen",
    ie: "promotions",
    hu: "promociok",
    et: "müügiedendused",
  },
  landingPage: {
    en: "",
    ja: "",
    fi: "",
    cd: "",
    nl: "",
    nz: "",
    no: "",
    at: "",
    ie: "",
    hu: "",
    et: "",
  },
};
