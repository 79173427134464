import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Subscription } from "rxjs";

// Configurations
import { endpointConfigurations } from "src/app/configurations/endpoint.configurations";

// Models
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { MainService } from "src/app/modules/shared/services/main.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-game-play-full-window",
  templateUrl: "./game-play-full-window.component.html",
  styleUrls: ["game-play-full-window.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class GamePlayFullWindowComponent implements OnInit {
  // Strings
  gameCode: string = "";
  gameId: string = "";

  // Subscriptions
  subscriptionRouter: Subscription;
  subscriptionGame: Subscription;

  constructor(
    private gameGroupsService: GameGroupsService,
    private gamePlayService: GamePlayService,
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private mainService: MainService
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.subscriptionRouter = this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.gameId = params.gameId;
      }
    );

    this.gameGroupsService
      .getGameGroupGamesp()
      .then((data: GamePregmatic[]) => {
        const gamePregmatic: GamePregmatic = data.find(
          (gamePregmatic: GamePregmatic) => {
            
            return (
              this.utilityService.convertSpecialCharactersWithSpace(
                gamePregmatic.pristineGameId
              ) ===
              this.utilityService.convertSpecialCharactersWithSpace(this.gameId)
            );
          }
        );

        if (gamePregmatic.gameCode) {
          this.subscriptionGame = this.mainService
            .psGet(endpointConfigurations.getRealgame_url, {
              gameSymbol: gamePregmatic.gameCode,
            })
            .subscribe(() => {
              this.gamePlayService.loadGame({
                gameId: gamePregmatic.gameCode,
                gameType: "realgame",
                hasDemo: gamePregmatic.hasDemo,
              });
            });
        }
      });
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscriptionRouter) this.subscriptionRouter.unsubscribe();

    if (this.subscriptionGame) this.subscriptionGame.unsubscribe();
  }
}
