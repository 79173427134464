import mixpanel from "mixpanel-browser";

// Configurations
import { mixPanelEventsConfigurations } from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Models
import { GameDataTrackGameLaunch } from "src/app/modules/shared/models/mix-panel/game-data-track-game-launch.model";
import { TrackGameEvent } from "src/app/modules/shared/models/mix-panel/track-game-event.model";

export class MixPanelService {
  constructor() {
    this.initiateMixpanel();
  }

  initiateMixpanel(): void {
    mixpanel.init(environment.mixpanelProjectToken, {
      debug: true,
    });
  }

  trackMixpanelEvents(eventName: string, eventProperties: TrackGameEvent): void {
    eventProperties.platfromType = this.getWindowType();

    if (localStorage.getItem("hir_user")) {
      mixpanel.track(eventName, eventProperties);
    }
  }

  getWindowType(): "desktop" | "mobile" {
    let ww: number = document.body.clientWidth;

    if (ww >= 1024) {
      return "desktop";
    } else {
      return "mobile";
    }
  }

  trackGameLanuchEvent(gameLanuchData: GameDataTrackGameLaunch): void {
    let eventProperties: TrackGameEvent = {
      name: gameLanuchData.gameData.name,
      playType: gameLanuchData.gameType,
      vertical: this.getVerticalByGameData(gameLanuchData),
      category: "no category",
    };

    if (gameLanuchData.groupName) {
      eventProperties.location = gameLanuchData.groupName;
    }

    if (gameLanuchData.lobbyName) {
      eventProperties.category = gameLanuchData.lobbyName;
    }

    if (gameLanuchData.callingFrom === "game-window-tag") {
      eventProperties.location = "In-Game";
      eventProperties.Tag = gameLanuchData.activeTag;
    }

    if (gameLanuchData.callingFrom === "recent-search") {
      eventProperties.location = "Search";
    }

    if (gameLanuchData.callingFrom === "studios") {
      eventProperties.location = "Game Studios";
    }

    this.trackMixpanelEvents(mixPanelEventsConfigurations.launched_game, eventProperties);
  }

  getVerticalByGameData(gameLanuchData: GameDataTrackGameLaunch): "Live-casino" | "Casino" {
    let liveGameTypes: string[] = ["live_dealer", "live_casino"];

    return liveGameTypes.indexOf(gameLanuchData["gameData"]["gameType"]) > -1
      ? "Live-casino"
      : "Casino";
  }

  getVerticalByUrl(): string {
    let locationPath: string = window.location.pathname;

    let verticalValues: string = "";

    if (locationPath.includes("/casino")) {
      verticalValues = "Casino";
    } else if (locationPath.includes("/live-casino")) {
      verticalValues = "Live-casino";
    } else if (locationPath.includes("/studio")) {
      verticalValues = "Studio";
    }

    return verticalValues;
  }
}
