import { Component, OnInit, ElementRef, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-limits",
  templateUrl: "./limits.component.html",
  styleUrls: ["./limits.component.scss"],
})
export class LimitsComponent implements OnInit, OnDestroy {
  isOpen: boolean = false;
  accountStatusSub: Subscription;
  userJurisdiction: string = "";
  userJurisdictionSub: Subscription;
  constructor(
    private commonService: CommonService,
    private translationService: TranslationService,
    private utility: UtilityService,
    private eleRef: ElementRef
  ) {
    this.userJurisdiction = this.commonService.getUserJurisdiction();
    this.userJurisdictionSub = this.commonService.userJurisdictionEvent$.subscribe(
      (jurisdiction) => {
        this.userJurisdiction = jurisdiction;
      }
    );
  }
  ngOnInit() {}

  ngOnDestroy() {
    this.userJurisdictionSub.unsubscribe();
  }
}
