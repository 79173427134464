import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Services
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-form-button",
  templateUrl: "./form-button.component.html",
  styleUrls: ["./form-button.component.scss"],
})
export class FormButtonComponent extends FormValidationComponent
  implements OnInit {
  config;
  group: FormGroup;
  selectedMethodData: any;
  callingFrom: any;
  usedAccount: boolean;
  constructor(private utils: UtilityService) {
    super(utils);
  }

  ngOnInit() {}

  @Output() buttonClicked = new EventEmitter<string>();

  clickButton(message) {
    this.buttonClicked.emit(message);
  }
}
