import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";
import {
  SimpleChange,
  ElementRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Input,
} from "@angular/core";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { AccountService } from "src/app/modules/auth/services/account.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { CustomValidators } from "src/app/utility/custom-validator";

@Component({
  selector: "app-rg-limits",
  templateUrl: "./rg-limits.component.html",
  styleUrls: ["./rg-limits.component.scss"],
})
export class RgLimitsComponent extends FormValidationComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() limitType;
  periodTags = ["daily", "weekly", "monthly"];
  currentLimits?: any;
  periodSpecificLimits = {
    currentLimit: 0,
    remainingLimit: 0,
    pendingLimit: 0,
    pendingTime: undefined,
    // hasPendingIncrease: false
  };
  rgLimitsForm = this.formBuilder.group(
    {
      type: ["", [this.customValidators.required]],
      limit: ["", [this.customValidators.required]],
      period: ["", [this.customValidators.required]],
    },
    { validator: this.customValidators.validateRgLimits(this.currentLimits) }
  );
  currencySymbol: string;
  successResponseMessage: string;
  failedResponseMessage: string;
  cancelLimitsErrorMsg: string;
  isButtonLoader: boolean = false;
  settingLoader: boolean = false;
  currencySymbolSubscription: Subscription;
  dailyLimit;
  currencyCode;
  currencyCodeSubscription: Subscription;
  constructor(
    public utils: UtilityService,
    private formBuilder: FormBuilder,
    private translationService: TranslationService,
    private userDetailsService: UserDetailsService,
    private accountService: AccountService,
    private customValidators: CustomValidators,
    private eleRef: ElementRef,
    private commonService: CommonService
  ) {
    super(utils);
    this.userDetailsService.currencySymbolSb$.subscribe((currencySymbol) => {
      this.currencySymbol = currencySymbol;
    });

    this.currencySymbolSubscription = this.userDetailsService.currencySymbolSb$.subscribe(
      (curSymbol) => {
        this.currencySymbol = curSymbol;
      }
    );

    this.currencyCodeSubscription = this.userDetailsService.currencyCodeSb$.subscribe(
      (curCode) => {
        this.currencyCode = curCode;
      }
    );
  }

  ngOnInit() {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["limitType"]) {
      this.limitType = changes["limitType"]
        ? changes["limitType"].currentValue
        : "";
      this.rgLimitsForm.controls["type"].setValue(
        changes["limitType"].currentValue
      );
      /*Here we are using Net Deposit limits for type deposit
      loss or wager limits we use getLimits**/
      if (this.limitType === "deposit") {
        this.rgLimitsForm.controls["period"].setValue(this.periodTags[1]);
        this.getNetDepositLimits();
      } else {
        this.rgLimitsForm.controls["period"].setValue(this.periodTags[0]);
        this.getLimits();
      }
    }
  }

  getActiveSelectedPeriod(): string {
    return this.rgLimitsForm.controls["period"].value;
  }

  clearLimitsFeild() {
    this.rgLimitsForm.controls["limit"].setValue(null);
    this.rgLimitsForm.controls["limit"].markAsUntouched({ onlySelf: true });
    this.rgLimitsForm.controls["limit"].markAsPristine({ onlySelf: true });
  }

  setActivePeriod(period) {
    /**condition to check whether any value exists in limits feild or not
     * if exist...we clear the form control.
     */
    if (this.rgLimitsForm.controls["period"].value) {
      this.clearLimitsFeild();
    }
    if (this.limitType === "deposit") {
      this.setNetDepositPeriodSpecLimits(period + "Limit");
    } else {
      this.setPeriodSpecificLimits(period);
    }
    this.rgLimitsForm.controls["period"].setValue(period);
  }

  setPeriodSpecificLimits(period) {
    /**rereinitialize to default values for every period
     * change...
     */
    this.periodSpecificLimits = {
      currentLimit: 0,
      remainingLimit: 0,
      pendingLimit: 0,
      pendingTime: undefined,
      // hasPendingIncrease: false
    };
    if (this.rgLimitsForm.controls["period"].value) {
      this.clearLimitsFeild();
    }
    /**Here we update period specific changes.. */
    let limits;
    if (this.currentLimits && this.limitType === "deposit") {
      limits = this.currentLimits[period];
    } else if (this.currentLimits && this.currentLimits["limits"]) {
      limits = this.currentLimits["limits"][period];
      if (
        this.currentLimits["pendingLimits"] &&
        this.currentLimits["pendingLimits"][period]
      ) {
        const pendingLimits = this.currentLimits["pendingLimits"][period];
        limits["pending"] = pendingLimits["value"];
        limits["remainingTime"] = pendingLimits["remainingTime"];
      }
    }
    if (period && limits) {
      this.periodSpecificLimits = {
        currentLimit: limits["value"],
        remainingLimit: limits["remaining"],
        pendingLimit: limits["pending"],
        pendingTime: limits["remainingTime"]
          ? new Date(new Date().getTime() + limits["remainingTime"] * 60 * 1000)
          : undefined,
        // hasPendingIncrease: this.limitType === 'deposit' ? limits['hasPendingIncrease'] : false
      };
    }
  }

  prepareLimitsRequestObj() {
    let data = this.utils.formControlToParams(this.rgLimitsForm, {});
    data[data["period"]] = data["limit"];
    delete data["limit"];
    delete data["period"];
    return data;
  }

  updateLimts() {
    if (this.rgLimitsForm.valid) {
      this.isButtonLoader = true;
      let requestObj = this.prepareLimitsRequestObj();
      this.accountService
        .setResponsibleGamingLimits(requestObj)
        .subscribe((data) => {
          this.isButtonLoader = false;
          if (data && data["success"] === true) {
            this.successResponseMessage = this.translationService.instant(
              "limits.limit_updated_success"
            );
            this.clearLimitsFeild();
            this.getLimits();
          } else {
            this.failedResponseMessage = this.translationService.instant(
              "limits.limits_update_falied"
            );
            this.clearLimitsFeild();
          }
        });
      setTimeout(() => {
        this.successResponseMessage = undefined;
        this.failedResponseMessage = undefined;
      }, 5000);
    }
  }

  getLimits() {
    if (this.limitType) {
      this.accountService
        .getResponsibleGamingLimits(this.limitType)
        .subscribe((data) => {
          if (this.limitType === "deposit" && data) {
            this.currentLimits = data["limits"]["deposit"];
            this.setPeriodSpecificLimits(
              this.rgLimitsForm.controls["period"].value
            );
            this.rgLimitsForm.setValidators(
              this.customValidators.validateRgLimits(this.currentLimits)
            );
          } else if (data) {
            this.currentLimits = data;
            this.setPeriodSpecificLimits(
              this.rgLimitsForm.controls["period"].value
            );
            const formattedLimits = this.formateLimits(this.currentLimits);
            this.rgLimitsForm.setValidators(
              this.customValidators.validateRgLimits(formattedLimits["limits"])
            );
          }
        });
    }
  }

  formateLimits(currentLimits) {
    if (this.limitType !== "deposit") {
      var formatData = currentLimits;
      if (formatData["pendingLimits"]) {
        if (formatData["pendingLimits"]["daily"] === "daily") {
          formatData["daily"]["pending"] =
            formatData["pendingLimits"]["daily"]["value"];
          formatData["daily"]["remainingTime"] =
            formatData["pendingLimits"]["daily"]["remainingTime"];
        }
        if (formatData["pendingLimits"]["weekly"] === "weekly") {
          formatData["weekly"]["pending"] =
            formatData["pendingLimits"]["weekly"]["value"];
          formatData["weekly"]["remainingTime"] =
            formatData["pendingLimits"]["weekly"]["remainingTime"];
        }
        if (formatData["pendingLimits"]["monthly"] === "montly") {
          formatData["monthly"]["pending"] =
            formatData["pendingLimits"]["monthly"]["value"];
          formatData["monthly"]["remainingTime"] =
            formatData["pendingLimits"]["monthly"]["remainingTime"];
        }
      }
      return formatData;
    }
  }

  cancelLimits() {
    this.settingLoader = true;
    if (this.limitType === "deposit") {
      let data = {
        limitTypes: this.rgLimitsForm.controls["period"].value.toUpperCase(),
        isConfirmed: 0,
      };
      this.accountService.cancelDepositLimts(data).subscribe((data) => {
        this.settingLoader = false;
        if (data && data["success"]["status"] === "SUCCESS") {
          this.getLimits();
        } else {
          this.cancelLimitsErrorMsg = this.translationService.instant(
            "general.something_went_wrong"
          );
        }
        setTimeout(() => {
          this.cancelLimitsErrorMsg = undefined;
        }, 5000);
      });
    } else {
      let data = {
        limitType:
          this.rgLimitsForm.controls["type"].value.toUpperCase() + "LIMIT",
        isConfirm: 0,
      };
      data[
        this.rgLimitsForm.controls["period"].value
      ] = this.periodSpecificLimits["pendingLimit"];
      this.accountService.cancelLimits(data).subscribe((data) => {
        this.settingLoader = false;
        if (data && data["success"]) {
          this.getLimits();
        } else {
          this.cancelLimitsErrorMsg = this.translationService.instant(
            "general.something_went_wrong"
          );
        }
        setTimeout(() => {
          this.cancelLimitsErrorMsg = undefined;
        }, 5000);
      });
    }
  }

  getNetDepositLimits() {
    if (this.limitType) {
      this.accountService.getNetDepositLimits().subscribe((data) => {
        if (this.limitType === "deposit" && data) {
          this.currentLimits = data;
          this.setNetDepositPeriodSpecLimits(
            this.rgLimitsForm.controls["period"].value + "Limit"
          );
          this.rgLimitsForm.setValidators(
            this.customValidators.validateRgLimits(this.currentLimits)
          );
        }
      });
    }
  }

  cancelNetDepositLimits() {
    if (this.limitType) {
      let data = {
        confirm: false,
      };
      this.accountService.cancelNetDepositLimits(data).subscribe((data) => {
        this.settingLoader = false;
        if (data && data["status"] === "SUCCESS") {
          this.getNetDepositLimits();
        } else {
          this.cancelLimitsErrorMsg = this.translationService.instant(
            "general.something_went_wrong"
          );
        }
        setTimeout(() => {
          this.cancelLimitsErrorMsg = undefined;
        }, 5000);
      });
    }
  }

  setNetDepositPeriodSpecLimits(period) {
    /**rereinitialize to default values for every period
     * change...
     */
    this.periodSpecificLimits = {
      currentLimit: 0,
      remainingLimit: 0,
      pendingLimit: 0,
      pendingTime: undefined,
    };
    if (this.rgLimitsForm.controls["period"].value) {
      this.clearLimitsFeild();
    }
    let limits;
    if (this.currentLimits && this.limitType === "deposit") {
      limits = this.currentLimits;
      if (period === "weeklyLimit") {
        this.periodSpecificLimits = {
          currentLimit: limits["weeklyLimit"] / 100,
          remainingLimit: limits["remainingNetLimitThisWeek"] / 100,
          pendingLimit: limits["pendingWeeklyLimit"] / 100,
          pendingTime: limits["remainigWeeklyNetLimitTime"]
            ? new Date(
                new Date().getTime() +
                  limits["remainigWeeklyNetLimitTime"] * 60 * 1000
              )
            : undefined,
        };
      } else if (period === "monthlyLimit") {
        this.periodSpecificLimits = {
          currentLimit: limits["monthlyLimit"] / 100,
          remainingLimit: limits["remainingNetLimitThisMonth"] / 100,
          pendingLimit: limits["pendingMonthlyLimit"] / 100,
          pendingTime: limits["remainigMonthlyNetLimitTime"]
            ? new Date(
                new Date().getTime() +
                  limits["remainigMonthlyNetLimitTime"] * 60 * 1000
              )
            : undefined,
        };
      }
    }
  }

  prepareNetLimitsReqObj() {
    let data = this.utils.formControlToParams(this.rgLimitsForm, {});
    data["currency"] = this.currencyCode;
    data[data["period"] + "Limit"] = data["limit"];
    delete data["limit"];
    delete data["period"];
    delete data["type"];
    return data;
  }

  updateNetDepositLimits() {
    if (this.rgLimitsForm.valid) {
      this.isButtonLoader = true;
      let requestObj = this.prepareNetLimitsReqObj();
      this.accountService.setNetDepositLimits(requestObj).subscribe((data) => {
        this.isButtonLoader = false;
        if (
          data &&
          (data["status"] === "SUCCESS" || data["status"] === "PENDING")
        ) {
          this.successResponseMessage = this.translationService.instant(
            "limits.limit_updated_success"
          );
          this.clearLimitsFeild();
          this.getNetDepositLimits();
        } else if (data["errorCode"] === 100296) {
          this.failedResponseMessage = this.translationService.instant(
            "limits.error_100296_updateFalied"
          );
        } else {
          this.failedResponseMessage = this.translationService.instant(
            "limits.limits_update_falied"
          );
          this.clearLimitsFeild();
        }
      });
      setTimeout(() => {
        this.successResponseMessage = undefined;
        this.failedResponseMessage = undefined;
      }, 5000);
    }
  }

  ngOnDestroy() {
    this.currencySymbolSubscription.unsubscribe();
  }
}
