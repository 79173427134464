import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CountryCodes } from "src/app/models/configurations/enums/country-code.enum";

@Injectable({
  providedIn: "root",
})
export class EnvironmentFeatureService {
  constructor(private userDetailsService: UserDetailsService) {}

  isWelcomeBonusOffOnInit(): boolean {
    return (
      environment.features.welcomeBonusInitiallyOff &&
      environment.features.welcomeBonusInitiallyOff.countries.includes(
        this.userDetailsService.getUserCountryCode() as CountryCodes
      )
    );
  }
}
