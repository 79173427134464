import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { AccountService } from "src/app/modules/auth/services/account.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { CustomValidators } from "src/app/utility/custom-validator";

@Component({
  selector: "app-session-limits",
  templateUrl: "./session-limits.component.html",
  styleUrls: ["./session-limits.component.scss"],
})
export class SessionLimitsComponent extends FormValidationComponent
  implements OnInit {
  defaultValue = 0;
  activeSessionLimit = {
    remainingTime: 0,
    value: 0,
  };
  sessionLimitForm = this.formBuilder.group(
    {
      limit: [""],
    },
    { validator: this.customValidators.validateNumericValue }
  );
  sessionTimeText: string;
  successResponseMessage: string;
  failedResponseMessage: string;
  constructor(
    private utils: UtilityService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private translationService: TranslationService,
    private customValidators: CustomValidators,
    private accountService: AccountService
  ) {
    super(utils);
    this.getSessionLimits();
    this.sessionTimeText =
      0 + " " + this.translationService.instant("limits.minutes");
  }

  ngOnInit() {}

  setSessionLimit() {
    if (this.sessionLimitForm.valid) {
      let data = {
        type: "session",
        overall: this.sessionLimitForm.controls["limit"].value,
      };
      this.accountService.setResponsibleGamingLimits(data).subscribe((data) => {
        if (data && data["success"] === true) {
          this.successResponseMessage = this.translationService.instant(
            "limits.limit_updated_success"
          );
          let limit = this.sessionLimitForm.controls["limit"].value;
          this.commonService.setSessionVariable("session", {
            sessionLimit: limit,
            availableSessionLimit: limit * 60,
          });
          this.commonService.startLoginSession();
          this.clearLimitsFeild();
          this.getSessionLimits();
        } else {
          this.failedResponseMessage = this.translationService.instant(
            "limits.limits_update_falied"
          );
          this.clearLimitsFeild();
        }
        setTimeout(() => {
          this.successResponseMessage = undefined;
          this.failedResponseMessage = undefined;
        }, 5000);
      });
    }
  }

  getSessionLimits() {
    this.accountService
      .getResponsibleGamingLimits("session")
      .subscribe((data) => {
        if (data && data["limits"] && data["limits"]["overall"]) {
          let limits = data["limits"]["overall"];
          this.activeSessionLimit = {
            remainingTime: limits["remainingTime"],
            value: limits["value"],
          };
          this.setSessionTimeDisplayText();
        }
      });
  }

  clearLimitsFeild() {
    this.sessionLimitForm.controls["limit"].setValue(null);
    this.sessionLimitForm.controls["limit"].markAsUntouched({ onlySelf: true });
    this.sessionLimitForm.controls["limit"].markAsPristine({ onlySelf: true });
  }

  setSessionTimeDisplayText() {
    const activeMinutes = this.activeSessionLimit["value"];
    if (activeMinutes) {
      this.sessionTimeText = this.utils.getSessionTimeText(activeMinutes);
    }
  }
}
