import { Observable, throwError, of, Subject } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";

// Libraries
import * as _ from "underscore";

// Services
import { MainService } from "src/app/modules/shared/services/main.service";

@Injectable()
export class RewardsService {
  // userCurrentLevelUpDetails={
  //     "sessionKey": "d682cc94-acec-4491-a882-de9c0aa46491_SESSIONKEY",
  //     "version": "1.0",
  //     "ecrId": 419431594106553860,
  //     "oldLevelId": 5,
  //     "newLevelId": 6,
  //     "bonusId": "2020071740",
  //     "ecrBonusId": 0,
  //     "gameId": "654",
  //     "spinCount": 21,
  //     "spinValue": 250,
  //     "offerStatus": null,
  //     "vendorId": "654",
  //     "spinTarget": 0,
  //     "upgradeTime": null,
  //     "offerErrorDesc": null,
  //     "comments": null,
  //     "messageName": "LEVELUP_LEVEL_UPGRADE",
  //     "messageType": "LEVELUP",
  //     "headerType": "sessionKey",
  //     "connectionId": "2EmcZoLSnDS9XvNSQCyR"
  //   };
  userCurrentLevelId;
  userCurrentLevelUpDetails;
  userCurrentLevelUpPointDetails;

  levelBeforeLevelUpgradedNumber;
  setLevelBeforeLevelUpgraded(levelNumber) {
    this.levelBeforeLevelUpgradedNumber = levelNumber;
  }

  getLevelBeforeLevelUpgraded() {
    return this.levelBeforeLevelUpgradedNumber;
  }

  getNotificationCount(currentLevelNumber) {
    if (this.levelBeforeLevelUpgradedNumber && currentLevelNumber) {
      return currentLevelNumber - this.levelBeforeLevelUpgradedNumber;
    } else {
      return 0;
    }
  }

  private updateLevelUpNotification = new Subject<any>();
  public updateLevelUpNotification$ = this.updateLevelUpNotification.asObservable();

  broadCastClearLevelupNotifications(flag) {
    if (flag) {
      this.levelBeforeLevelUpgradedNumber = undefined;
    }
    this.clearLevelUpNotification.next(flag);
  }

  constructor(private mainService: MainService) {}

  private userLevelUpDetails = new Subject<any>();
  public userLevelUpDetails$ = this.userLevelUpDetails.asObservable();

  broadLevelUpDetails(data) {
    this.userCurrentLevelUpDetails = data;
    this.setUserCurrentLevelId(data["newLevelId"]);
    this.userLevelUpDetails.next(data);
  }

  setlevelUpDetails(levelData) {
    this.userCurrentLevelUpDetails = levelData;
  }

  getlevelUpDetails() {
    return this.userCurrentLevelUpDetails;
  }

  private clearLevelUpNotification = new Subject<any>();
  public clearLevelUpNotification$ = this.clearLevelUpNotification.asObservable();

  broadCastLevelupNotifications(flag) {
    this.clearLevelUpNotification.next(flag);
  }

  private userLevelUpPointDetails = new Subject<any>();
  public userLevelUpPointDetails$ = this.userLevelUpPointDetails.asObservable();

  broadLevelUpPointDetails(data) {
    this.userCurrentLevelUpPointDetails = data;
    this.setUserCurrentLevelId(data["levelId"]);
    this.userLevelUpPointDetails.next(data);
  }

  setUserLevelUpPointDetails(levelPoints) {
    this.userCurrentLevelUpPointDetails = levelPoints;
  }

  getUserLevelUpPointDetails() {
    return this.userCurrentLevelUpPointDetails;
  }

  setUserCurrentLevelId(levelId) {
    this.userCurrentLevelId = levelId;
  }

  getUserCurrentLevelId() {
    return this.userCurrentLevelId;
  }

  getUserLevelupDetails(): Observable<any> {
    return this.mainService.getUserLevelupDetails().pipe(
      map((levelUpDetails) => {
        if (levelUpDetails && levelUpDetails["levelId"]) {
          this.setUserCurrentLevelId(levelUpDetails["levelId"]);
        }
        if (levelUpDetails) {
          this.setUserLevelUpPointDetails({
            currentSpinCount: levelUpDetails["currentSpinCount"],
            spinsNeededForNextLevel: levelUpDetails["spinsNeededForNextLevel"],
            levelId: levelUpDetails["levelId"],
          });
        }
        return levelUpDetails;
      }),
      catchError((error) => {
        return of(undefined);
      })
    );
  }

  claimLevelupBonus(requestObj): Observable<any> {
    return this.mainService.claimLevelupBonus(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return of(undefined);
      })
    );
  }

  getUnclaimedLevels() {
    return this.mainService.getUnclaimedLevels().pipe(
      map((responseData) => {
        return responseData;
      }),
      catchError((error) => {
        return of(undefined);
      })
    );
  }

  getActiveBonusDetails(): Observable<any> {
    return this.mainService.getActiveBonusDetails().pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getEligibleBonus(bonusObject?) {
    return this.mainService.getEligibleBonus(bonusObject).pipe(
      map((usedPIQAccountData) => {
        return usedPIQAccountData;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /**
   * Here we process our eligible bonus based on
   * 1.bonuscode available or not
   * 2.user currencyTier avilable or not &
   * 3. also we disable cashout bonus of amountType PCT for pnp flow
   */
  processEligibleBonus(bonusDeatils, currencyCode, isPnpFlow) {
    let depositBonusDetails = _.filter(bonusDeatils, {
      criteriaType: "DEPOSIT",
    });
    let filteredBonus = this.filterEligibleBonusBasedOnFlow(
      depositBonusDetails,
      currencyCode,
      isPnpFlow
    );
    return (depositBonusDetails = _.sortBy(filteredBonus, (data) => {
      return data.campaignStartDate;
    }).reverse());
  }

  /**Based on classic casino flow or pnp flow */
  filterEligibleBonusBasedOnFlow(filteredBonus, currencyCode, isPnpFlow) {
    let finalFilteredBonus = _.filter(filteredBonus, (data) => {
      if (
        data["playerTargetType"] !== "LEVELUP" &&
        data["currencyTier"] &&
        data["currencyTier"][currencyCode] &&
        data["currencyTier"][currencyCode].length > 0 &&
        data["bonusCode"] &&
        (data["bonusCategory"] === "RELEASE_RESTRICT" ||
          !isPnpFlow ||
          (isPnpFlow &&
            data["bonusCategory"] === "CASHOUT_RESTRICT" &&
            data["amountType"] === "ABS"))
      ) {
        data["bonusTypeFD"] = "deposit-bonus";
        return data;
      }
    });
    return finalFilteredBonus;
  }
}
