import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import {
  EventEmitter,
  Component,
  OnInit,
  Output,
  Input
} from "@angular/core";

@Component({
  selector: "app-dynamic-form",
  templateUrl: "./dynamic-form.component.html",
  styleUrls: ["./dynamic-form.component.scss"],
})
export class DynamicFormComponent implements OnInit {
  @Input() config: any[] = [];
  @Input() selectedMethodData: any;
  @Input() usedAccount: boolean;
  @Input() callingFrom: any;
  @Input() form: FormGroup;
  @Output() buttonClickedInForm = new EventEmitter<string>();
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    // this.form = this.createGroup();
  }

  createGroup() {
    const group = this.fb.group({});
    this.config.forEach((control) => {
      let formControl = new FormControl("");
      group.addControl(control.key, formControl);
    });
    return group;
  }

  buttonClicked(message) {
    this.buttonClickedInForm.emit(message);
  }
}
