import { Component, Input, SimpleChanges } from "@angular/core";

// Libraries
import { LiveStream } from "livespins/@livespins";

@Component({
  selector: "app-csn-livespins-room",
  templateUrl: "./csn-livespins-room.component.html",
  styleUrls: ["./csn-livespins-room.component.scss"],
})
export class CsnLivespinsRoomComponent {
  @Input() liveStream: LiveStream;
    
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes["liveStream"].currentValue && (changes["liveStream"].previousValue != changes["liveStream"].currentValue)) {
      this.liveStream = changes["liveStream"].currentValue;
    }
  }
}
