import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  EventEmitter,
  SimpleChange,
  Component,
  OnInit,
  Output,
  Input
} from "@angular/core";

// Constrants
import { UserFlowTypes } from "src/app/constants/costants";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import * as _ from "underscore";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { CashbackPromoService } from "src/app/modules/rewards/services/cashback-promo.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";
import { EnvironmentFeatureService } from "src/app/modules/shared/services/environment-feature.service";

@Component({
  selector: "app-payment-bonus",
  templateUrl: "./payment-bonus.component.html",
  styleUrls: ["./payment-bonus.component.scss"],
})
export class PaymentBonusComponent implements OnInit {
  dummy = new Date(new Date().getFullYear() + 1, 0, 1);
  @Output() callOnSelectBonus: EventEmitter<any> = new EventEmitter();
  @Input() clearSelectedBonus;
  @Input() activeBonusData;
  @Input() callingFrom;
  @Input() rewardSize;
  @Output() callOnNavigateToDeposit: EventEmitter<any> = new EventEmitter();
  apiUrlPath: string = environment.apiUrl;
  gameUrlPath: string = environment.gamesUrlPath;
  selectedBonus: any;
  currencyCode: string;
  currencySymbol: string;
  langCode: string;
  langCodeSub;
  selectedBonusIndex: number;
  isRealUser: boolean;
  param = { minDepositValue: 500 };
  userCurrencySymbolSub: Subscription;
  isloading: boolean = false;
  UserFlowTypes: typeof UserFlowTypes;
  zimplerMarkets: string[];
  avialableDepositBonusDetails: any;
  gameGroupGameSub: Subscription;
  games: any;
  depositBonusDetails;
  selectedBonusCode;
  userAffId;
  isAffiliateBonus: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  promoRaceInfoData: any;
  constructor(
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    private utility: UtilityService,
    private commonService: CommonService,
    private paynplayCashierService: PayNPlayCashierService,
    private gameGroupsService: GameGroupsService,
    private rewardsService: RewardsService,
    private cashBackPromoService: CashbackPromoService,
    private environmentFeatureService: EnvironmentFeatureService
  ) {
    this.getUserAffiliateId();
    this.UserFlowTypes = UserFlowTypes;
    this.zimplerMarkets = environment.zimplerMarkets;
    this.langCode = this.utility.getLangCode();
    this.langCodeSub = this.translationService.langCode$.subscribe(
      (langcode) => {
        this.langCode = langcode;
      }
    );

    this.userDetailsService.currencyCodeSb$.subscribe((curreny) => {
      this.currencyCode = curreny;
    });
    this.userCurrencySymbolSub = this.userDetailsService.currencySymbolSb$.subscribe(
      (symbol) => {
        this.currencySymbol = symbol;
      }
    );
    if (this.userDetailsService.getUserProfileDetails()) {
      this.isRealUser =
        this.userDetailsService.getUserProfileDetails().userStatus === "real"
          ? true
          : false;
    }
    this.getPromoCahbackData();
    this.getEligibleBonusDetails();
  }
  swiperPaymentBonus: SwiperConfigInterface = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 8,
    resistanceRatio: 0,
    pagination: {
      el: ".paybonus-pagination",
      clickable: true,
    },
  };

  ngOnInit() {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["clearSelectedBonus"]) {
      if (changes["clearSelectedBonus"].currentValue) {
        this.removeBonusHandler();
      }
    }
    if (changes["activeBonusData"] && changes["activeBonusData"].currentValue) {
      this.selectedBonus = changes["activeBonusData"].currentValue;
      if (this.selectedBonus["bonusCode"]) {
        this.selectedBonusCode = this.selectedBonus["bonusCode"];
      }
    }
    if (changes["callingFrom"]) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
    if (changes["rewardSize"]) {
      this.rewardSize = changes["rewardSize"].currentValue;
    }
  }

  selectBonusHandler(bonusData) {
    if (
      this.selectedBonus &&
      this.selectedBonus["bonusCode"] === bonusData["bonusCode"]
    ) {
      this.removeBonusHandler();
    } else if (bonusData && bonusData["bonusCode"]) {
      this.selectedBonus = bonusData;
      this.callOnSelectBonus.emit(bonusData);
    }
  }

  setSelectedBonusIndex(bonusCode) {
    this.selectedBonusIndex = _.findIndex(this.depositBonusDetails, {
      bonusCode: bonusCode,
    });
  }

  navigateToDeposit() {
    this.callOnNavigateToDeposit.emit();
  }

  initiateDeposit() {
    this.paynplayCashierService.broadCastInitiateDeposit({
      callingFrom: "payment-rewards",
    });
  }

  /**Functionality to check Is user having as welcome bonus, If yes we activate
   * that bonus by default to users
   **/
  checkForWelcomBonus() {
    const isWelcomeBonusOffOnInit = this.environmentFeatureService.isWelcomeBonusOffOnInit();
    if (this.depositBonusDetails && !isWelcomeBonusOffOnInit) {
      let filteredBonus;
      /* Here we are checking Is user having affiliate welcome bonus,
       If yes we activate affilate bonus by default to users*/
      filteredBonus = _.find(this.depositBonusDetails, (data, index) => {
        if (
          data["bonusName"] &&
          data["bonusName"].toLowerCase().startsWith("welcome offer") &&
          this.userAffId &&
          data["affiliateId"] &&
          (data["affiliateId"].includes(this.userAffId) ||
            data["affiliateId"].includes(this.userAffId.split("_")[0]))
        ) {
          this.selectedBonusIndex = index;
          this.isAffiliateBonus = true;
          return true;
        }
      });
      if (!this.isAffiliateBonus) {
        filteredBonus = _.find(this.depositBonusDetails, (data, index) => {
          if (
            data["bonusName"] &&
            data["bonusName"].toLowerCase().startsWith("welcome offer")
          ) {
            this.selectedBonusIndex = index;
            return true;
          }
        });
      }
      if (filteredBonus) {
        this.selectBonusHandler(filteredBonus);
      }
    }
  }

  removeBonusHandler() {
    this.selectedBonus = undefined;
    this.callOnSelectBonus.emit({});
  }
  openRewardTCPopUp(event) {
    if (event) {
      event.stopPropagation();
    }
    this.utility.toggleRewardTCPopUp(true);
  }

  getEligibleBonusDetails() {
    this.isloading = true;
    this.rewardsService
      .getEligibleBonus({ criteriaType: "DEPOSIT" })
      .subscribe((data) => {
        if (data && data["status"] === "SUCCESS") {
          this.isloading = false;
          let eligibleBonusDetails = data["eligibleBonusList"];
          if (eligibleBonusDetails) {
            this.processEligibleBonusDetails(eligibleBonusDetails);
            this.getGameGroupGames(false);
          }
        } else if (
          environment.zimplerMarkets.indexOf(this.utility.getLangCode()) > -1
        ) {
          this.navigateToDeposit();
        }
      });
  }

  processEligibleBonusDetails(bonusDeatils) {
    this.depositBonusDetails = this.rewardsService.processEligibleBonus(
      bonusDeatils,
      this.currencyCode,
      this.utility.isPnpFlow()
    );
    if (this.callingFrom !== "hostedCashier") {
      this.sortBySelectedBonus();
    } else {
      this.setSelectedBonusIndex(this.selectedBonusCode);
    }
    /**Here we filter welcome bonus only user not navigated from banner/promotions/rewards
     * with bonus code first prority goes to bonus code confiured on above then after
     * welcome bonus related stuff
     */
    if (!this.commonService.getActiveDepositBonus()) {
      this.checkForWelcomBonus();
    }
    if (
      environment.zimplerMarkets.indexOf(this.utility.getLangCode()) > -1 &&
      this.depositBonusDetails.length === 0
    ) {
      this.navigateToDeposit();
    }
  }

  //   processEligibleBonusDetails(bonusDeatils) {
  //     this.depositBonusDetails = _.filter(bonusDeatils, { criteriaType: 'DEPOSIT' });
  //       /**Filter to check whether currency tier is available or not..
  //        * we just ignore if their is not currency tier for any bonus...
  //        */
  //     let filteredBonus = this.depositBonusDetails;
  //     this.depositBonusDetails = _.filter(filteredBonus, (data => { return data['currencyTier'] && data['currencyTier'][this.currencyCode] && data['currencyTier'][this.currencyCode].length > 0 }));
  //     this.depositBonusDetails = (_.sortBy(this.depositBonusDetails, (data) => { return data.campaignStartDate })).reverse();
  //     if (this.callingFrom !== "hostedCashier") {
  //       this.depositBonusDetails = this.depositBonusDetails.filter(bonus => {
  //         if (bonus['bonusCategory'] === 'RELEASE_RESTRICT' || (bonus['bonusCategory'] === 'CASHOUT_RESTRICT' && bonus['amountType'] === 'ABS')) {
  //           return bonus;
  //         }
  //       });
  //       this.sortBySelectedBonus();
  //     } else {
  //       this.setSelectedBonusIndex(this.selectedBonusCode);
  //     }
  //     /**Here we filter welcome bonus only user not navigated from banner/promotions/rewards
  //     * with bonus code first prority goes to bonus code confiured on above then after
  //     * welcome bonus related stuff
  //     */
  //     if (!this.commonService.getActiveDepositBonus()) {
  //       this.checkForWelcomBonus();
  //     }
  // }

  sortBySelectedBonus() {
    this.selectedBonusIndex = _.findIndex(this.depositBonusDetails, {
      bonusCode: this.selectedBonusCode,
    });
    if (this.selectedBonusIndex > 0) {
      this.depositBonusDetails.splice(this.selectedBonusIndex, 1);
      this.depositBonusDetails.unshift(this.selectedBonus);
    }
    this.depositBonusDetails = this.depositBonusDetails;
  }

  getGameGroupGames(isForce) {
    this.gameGroupGameSub = this.gameGroupsService
      .getGameGroupGames(isForce)
      .subscribe((games) => {
        if (games && games.length > 0) {
          this.games = games;
          this.depositBonusDetails = this.depositBonusDetails.filter(
            (data, bindex) => {
              if (data["gameIds"] && data["gameIds"].length > 0) {
                let filteredGame = _.findWhere(games, {
                  gameCode: data["gameIds"][0],
                });
                if (filteredGame && Object.keys(filteredGame).length > 0) {
                  data["gameData"] = filteredGame;
                }
              }
              return data;
            }
          );
        }
      });
  }

  getUserAffiliateId() {
    const profileDetails = this.userDetailsService.getUserProfileDetails();
    if (profileDetails && profileDetails["affiliateLinkID"]) {
      this.userAffId = profileDetails["affiliateLinkID"];
    }
  }

  getPromoCahbackData() {
    this.cashBackPromoService
      .getUserCampaigns()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data && data["status"] === "SUCCESS") {
          this.promoRaceInfoData = data["campaignDetails"];
          this.promoRaceInfoData = this.promoRaceInfoData.filter((data) => {
            let currentTime = new Date();
            let promoStartTime = new Date(data["campaignStartDate"]);
            let promoOfferExpiryDate = new Date(data["campaignEndDate"]);
            if (
              data &&
              data["campaignType"] === "CASHBACK_PROMO" &&
              data["optInRequired"] &&
              data["depositRequired"] &&
              !data["minDepositDone"] &&
              currentTime >= promoStartTime &&
              currentTime < promoOfferExpiryDate
            ) {
              if (
                this.rewardSize === "shorter" ||
                (this.rewardSize === "basic" && !data["playerOptinTime"])
              ) {
                data["bonusTypeFD"] = "cashback-promotion";
                return data;
              }
            }
          });
          this.promoRaceInfoData = _.flatten(this.promoRaceInfoData);
          console.log("====>promo race info data", this.promoRaceInfoData);
        }
      });
  }

  ngOnDestory() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.userCurrencySymbolSub.unsubscribe();
    this.langCodeSub.unsubscribe();
  }
}
