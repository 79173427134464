import { Component, Input, SimpleChange } from "@angular/core";

@Component({
  selector: "[setLoader]",
  templateUrl: "./button-loader.component.html",
  styleUrls: ["./button-loader.component.scss"],
})
export class ButtonLoaderComponent {
  // Inputs
  @Input() setLoader: boolean = false;

  // Booleans
  showLoader: boolean = false;

  constructor() {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["setLoader"] &&
      changes["setLoader"].previousValue !== changes["setLoader"].currentValue
    ) {
      this.showLoader = this.setLoader;
    }
  }
}
