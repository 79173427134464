import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, Injector, APP_INITIALIZER } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ServiceWorkerModule } from "@angular/service-worker";
import { BrowserModule } from "@angular/platform-browser";
import { LOCATION_INITIALIZED } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClient,
} from "@angular/common/http";
import {
  TranslateService,
  TranslateModule,
  TranslateLoader,
} from "@ngx-translate/core";

// Components
import { UnclaimedFreeSpinRewardCardComponent } from "src/app/modules/rewards/components/unclaimed-free-spin-reward-card/unclaimed-free-spin-reward-card.component";
import { RewardBonusTermsCondtionsComponent } from "src/app/modules/rewards/components/reward-bonus-terms-condtions/reward-bonus-terms-condtions.component";
import { PromotionContentPageComponent } from "src/app/modules/promotions/components/promotion-content-page/promotion-content-page.component";
import { GamePlayFullWindowComponent } from "src/app/modules/game-groups/components/game-play-full-window/game-play-full-window.component";
import { TransactionHistoryComponent } from "src/app/modules/transactions/components/transaction-history/transaction-history.component";
import { FreeSpinRewardCardComponent } from "src/app/modules/rewards/components/free-spin-reward-card/free-spin-reward-card.component";
import { RecentSearchGamesComponent } from "src/app/modules/game-groups/components/recent-search-games/recent-search-games.component";
import { UpcomingRewardCardComponent } from "src/app/modules/rewards/components/upcoming-reward-card/upcoming-reward-card.component";
import { SharedTransactionComponent } from "src/app/modules/transactions/components/shared-transaction/shared-transaction.component";
import { ConfirmIdentityKycComponent } from "src/app/modules/kyc/components/confirm-identity-kyc/confirm-identity-kyc.component";
import { BottomNavigationComponent } from "src/app/modules/navigation/components/bottom-navigation/bottom-navigation.component";
import { PromoRaceCounterComponent } from "src/app/modules/rewards/components/promo-race-counter/promo-race-counter.component";
import { RewardsIndicatorComponent } from "src/app/modules/rewards/components/rewards-indicator/rewards-indicator.component";
import { ProvideGamesDisplay } from "src/app/modules/game-groups/components/provider-games/provider-games-display.component";
import { GamePlayWindowComponent } from "src/app/modules/game-groups/components/game-play-window/game-play-window.component";
import { PendingWithdrawComponent } from "src/app/modules/account/components/pending-withdrawal/pending-withdraw.component";
import { LimitsRegulatoryComponent } from "src/app/modules/limits/components/limits-regulatory/limits-regulatory.component";
import { LeftNavigationComponent } from "src/app/modules/navigation/components/left-navigation/left-navigation.component";
import { BrandPromotionComponent } from "src/app/modules/promotions/components/brand-promotion/brand-promotion.component";
import { AllPromotionsComponent } from "src/app/modules/promotions/components/all-promotions/all-promotions.component";
import { PromotionCardComponent } from "src/app/modules/promotions/components/promotion-card/promotion-card.component";
import { RewardCongratsComponent } from "src/app/modules/rewards/components/reward-congrats/reward-congrats.component";
import { ProofOfPaymentComponent } from "src/app/modules/kyc/components/proof-of-payment/proof-of-payment.component";
import { HostedCashierComponent } from "src/app/modules/account/components/hosted-cashier/hosted-cashier.component";
import { ChangePasswordComponent } from "src/app/modules/auth/components/change-password/change-password.component";
import { ForgotPasswordComponent } from "src/app/modules/auth/components/forgot-password/forgot-password.component";
import { SessionLimitsComponent } from "src/app/modules/limits/components/session-limits/session-limits.component";
import { SelfExclusionComponent } from "src/app/modules/limits/components/self-exclusion/self-exclusion.component";
import { UpdateProfileComponent } from "src/app/modules/user/components/update-profile/update-profile.component";
import { QuickDepositComponent } from "src/app/modules/account/components/quick-deposit/quick-deposit.component";
import { IdentityProofComponent } from "src/app/modules/kyc/components/identity-proof/identity-proof.component";
import { BetHistoryComponent } from "src/app/modules/transactions/components/bet-history/bet-history.component";
import { SessionTimerComponent } from "src/app/modules/shared/components/session-timer/session-timer.component";
import { SourceOfFundComponent } from "src/app/modules/kyc/components/source-of-fund/source-of-fund.component";
import { NotificationsComponent } from "src/app/modules/chat/components/notifications/notifications.component";
import { LiveCasinoComponent } from "src/app/modules/game-groups/components/live-casino/live-casino.component";
import { AccountMenuComponent } from "src/app/modules/account/components/account-menu/account-menu.component";
import { NextRewardsComponent } from "src/app/modules/rewards/components/next-rewards/next-rewards.component";
import { CookieMsgComponent } from "src/app/modules/country-block/components/cookie-msg/cookie-msg.component";
import { AddressProofComponent } from "src/app/modules/kyc/components/address-proof/address-proof.component";
import { GameLobbyComponent } from "src/app/modules/game-groups/components/game-lobby/game-lobby.component";
import { AllRewardsComponent } from "src/app/modules/rewards/components/all-rewards/all-rewards.component";
import { PreferencesComponent } from "src/app/modules/shared/components/preferences/preferences.component";
import { NewsTickerComponent } from "src/app/modules/news/components/news-ticker/news-ticker.component";
import { TagGameComponent } from "src/app/modules/game-groups/components/tag-game/tag-game.component";
import { LoginFromComponent } from "src/app/modules/auth/components/login-from/login-from.component";
import { RgLimitsComponent } from "src/app/modules/limits/components/rg-limits/rg-limits.component";
import { FooterComponent } from "src/app/modules/static-pages/components/footer/footer.component";
import { RedirectComponent } from "src/app/modules/shared/components/redirect/redirect.component";
import { CashierComponent } from "src/app/modules/account/components/cashier/cashier.component";
import { HeaderComponent } from "src/app/modules/shared/components/header/header.component";
import { LimitsComponent } from "src/app/modules/limits/components/limits/limits.component";
import { HomeComponent } from "src/app/modules/shared/components/home-page/home.component";
import { BonusComponent } from "src/app/modules/rewards/components/bonus/bonus.component";
import { LandingPageComponent } from "src/app/modules/shared/components/landing-page/landing-page.component";
import { TimeComponent } from "src/app/modules/shared/components/time/time.component";
import { ConfirmDialogComponent } from './modules/account/components/confirm-dialog/confirm-dialog.component';
import { AppComponent } from "src/app/app.component";

// Configurations
import { localToCmsLanguageConfigurations } from "src/app/configurations/main.configurations";

// Directives
import { InputCurrencyPlacementDirective } from "src/app/modules/shared/directives/input-currency-placement.directive";

// Environments
import { environment } from "src/environments/environment";

// Guards
import { AuthGaurd } from "src/app/modules/auth/guards/auth.guard";

// Interceptors
import { AuthenticationInterceptor } from "src/app/modules/auth/interceptors/authentication.interceptor";

// Modules
import { MbscModule } from "src/app/modules/shared/libraries/mobiscroll/js/mobiscroll.custom-4.1.0.min";
import { RegistrationModule } from "src/app/modules/registration/registration.module";
import { DynamicFormModule } from "src/app/modules/dynamic-form/dynamic-form.module";
import { GameGroupsModule } from "src/app/modules/game-groups/game-groups.module";
import { LivespinsModule } from "src/app/modules/livespins/livespins.module";
import { SharedModule } from "src/app/modules/shared/shared.module";
import { AppRoutingModule } from "src/app/app-routing.module";

// Pipes
import { PaymentMethodNamePipe } from "src/app/modules/shared/pipes/payment-method-name.pipe";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { ForgotPasswordService } from "src/app/modules/auth/services/forgot-password.service";
import { StaticPageService } from "src/app/modules/static-pages/services/static-page.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { PromotionsService } from "src/app/modules/promotions/services/promotions.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { MixPanelService } from "src/app/modules/shared/services/mix-panel.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { AccountService } from "src/app/modules/auth/services/account.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { LoginService } from "src/app/modules/auth/services/login.service";
import { MainService } from "src/app/modules/shared/services/main.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { CustomValidators } from "src/app/utility/custom-validator";
import { RemainingLimitComponent } from "src/app/modules/account/components/remaining-limit/remaining-limit.component";
import { SeoContentComponent } from "src/app/modules/static-pages/components/seo-content/seo-content.component";

@NgModule({
  declarations: [
    UnclaimedFreeSpinRewardCardComponent,
    RewardBonusTermsCondtionsComponent,
    InputCurrencyPlacementDirective,
    PromotionContentPageComponent,
    TransactionHistoryComponent,
    ConfirmIdentityKycComponent,
    FreeSpinRewardCardComponent,
    UpcomingRewardCardComponent,
    GamePlayFullWindowComponent,
    RecentSearchGamesComponent,
    SharedTransactionComponent,
    BottomNavigationComponent,
    RewardsIndicatorComponent,
    LimitsRegulatoryComponent,
    PromoRaceCounterComponent,
    PendingWithdrawComponent,
    GamePlayWindowComponent,
    LeftNavigationComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    ProofOfPaymentComponent,
    RewardCongratsComponent,
    BrandPromotionComponent,
    NotificationsComponent,
    SelfExclusionComponent,
    UpdateProfileComponent,
    SessionLimitsComponent,
    IdentityProofComponent,
    NotificationsComponent,
    PromotionCardComponent,
    HostedCashierComponent,
    AllPromotionsComponent,
    AddressProofComponent,
    QuickDepositComponent,
    SourceOfFundComponent,
    PaymentMethodNamePipe,
    SessionTimerComponent,
    AccountMenuComponent,
    PreferencesComponent,
    NextRewardsComponent,
    AccountMenuComponent,
    PreferencesComponent,
    LandingPageComponent,
    BetHistoryComponent,
    BetHistoryComponent,
    ProvideGamesDisplay,
    AllRewardsComponent,
    ProvideGamesDisplay,
    LiveCasinoComponent,
    NewsTickerComponent,
    LoginFromComponent,
    GameLobbyComponent,
    CookieMsgComponent,
    RgLimitsComponent,
    RedirectComponent,
    CashierComponent,
    TagGameComponent,
    HeaderComponent,
    FooterComponent,
    LimitsComponent,
    LimitsComponent,
    BonusComponent,
    HomeComponent,
    TimeComponent,
    AppComponent,
    ConfirmDialogComponent,
    RemainingLimitComponent,
    SeoContentComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RegistrationModule,
    DynamicFormModule,
    DynamicFormModule,
    AppRoutingModule,
    HttpClientModule,
    GameGroupsModule,
    LivespinsModule,
    BrowserModule,
    SharedModule,
    FormsModule,
    MbscModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  providers: [
    PayNPlayCashierService,
    ForgotPasswordService,
    TranslationService,
    UserDetailsService,
    GameGroupsService,
    StaticPageService,
    PromotionsService,
    CustomValidators,
    GamePlayService,
    MixPanelService,
    AccountService,
    RewardsService,
    EmitterService,
    UtilityService,
    CommonService,
    LoginService,
    MainService,
    AuthGaurd,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    environment.apiUrl + environment.langpath,
    ".json?cb=" + new Date().getTime()
  );
}

/**This AppInitializer Factory is used for making API call 
 * during app initialization itself
 * Basically Here we apss default language of our application & also 
 * store them in local storage for futher use.. 
//  * */
export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        const defaultLang = environment.defaultLang;
        let langToSet = localStorage.getItem("langCode")
          ? localStorage.getItem("langCode")
          : defaultLang;
        let data = localToCmsLanguageConfigurations[langToSet];
        translate.setDefaultLang(data);
        translate.use(data).subscribe(
          () => {},
          (err) => {},
          () => {
            resolve(null);
          }
        );
      });
    });
}
