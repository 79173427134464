import { Subscription } from "rxjs";
import {
  EventEmitter,
  SimpleChange,
  Component,
  OnChanges,
  Output,
  Input,
} from "@angular/core";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";

@Component({
  selector: "app-release-restriction",
  templateUrl: "./release-restrict-reward.component.html",
  styleUrls: ["./release-restrict-reward.component.scss"],
})
export class ReleaseRestrictionRewardComponent implements OnChanges {
  @Input() bonusDetails;
  @Input() callingFrom;
  @Input() rewardSize;
  @Input() isRealUser;
  @Input() currencyCode;
  @Input() selectedBonus;

  @Output() callOpenRewardTCPopUp: EventEmitter<any> = new EventEmitter();
  @Output() callOnBonusSelected: EventEmitter<any> = new EventEmitter();
  @Output() callOnOpenDeposit: EventEmitter<any> = new EventEmitter();

  currencySymbolSubscription: Subscription;
  currencySymbol: string;

  constructor(private userDetailsService: UserDetailsService) {
    this.currencySymbolSubscription = this.userDetailsService.currencySymbolSb$.subscribe(
      (curSymbol) => {
        this.currencySymbol = curSymbol;
      }
    );
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes["bonusDetails"]) {
      this.bonusDetails = changes["bonusDetails"].currentValue;
    }
    if (changes["callingFrom"]) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
    if (changes["rewardSize"]) {
      this.rewardSize = changes["rewardSize"].currentValue;
    }
    if (changes["isRealUser"]) {
      this.isRealUser = changes["isRealUser"].currentValue;
    }
    if (changes["currencyCode"]) {
      this.currencyCode = changes["currencyCode"].currentValue;
    }
    if (changes["selectedBonus"]) {
      this.selectedBonus = changes["selectedBonus"].currentValue;
    }
  }

  selectBonusHanlder(bonus) {
    this.callOnBonusSelected.emit(bonus);
  }

  openDepositView(bonus) {
    this.callOnOpenDeposit.emit(bonus);
  }

  openRewardTCPopUp(event) {
    this.callOpenRewardTCPopUp.emit(event);
  }

  ngOnDestory() {
    this.currencySymbolSubscription.unsubscribe();
  }
}
