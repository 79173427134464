import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivate,
    Router,
} from "@angular/router";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Injectable()
export class AuthGaurd implements CanActivate {
  langCode;
  queryParams;
  routerEvent;
  
  constructor(
    private utils: UtilityService,
    private commonService: CommonService,
    private translationService: TranslationService,
    private router: Router
  ) {
    this.langCode = this.utils.getLangCode();
    this.translationService.langCode$.subscribe((langCode) => {
      this.langCode = langCode;
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkIsUserVisitedLandingPage();
    //return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkIsUserVisitedLandingPage();
  }

  canActivatewithParams(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (this.utils.isUserLoggedIn()) {
      return true;
    } else {
      this.router.navigate([this.langCode]);
      return false;
    }
  }

  checkIsUserVisitedLandingPage() {
    const path = this.utils.getDecodedCurrentPath();
    const params = this.utils.getQueryParams();
    const regfirst = /^[a-z]{2}-[a-z]{2}$/;
    const regSecond = /^[a-z]{2}-[a-z]{3}$/;
    let UrlSegment = this.utils.getDecodedCurrentPath().split("/");
    let languageCodeFromURL = UrlSegment[1];
    const isPnpFlow = this.utils.isPnpFlow(languageCodeFromURL);
    if (
      path &&
      (path.length === 1 ||
        regfirst.test(languageCodeFromURL) ||
        regSecond.test(languageCodeFromURL))
    ) {
      if (this.utils.isUserLoggedIn()) {
        const lang = languageCodeFromURL ? languageCodeFromURL : this.langCode;
        this.router.navigate([lang + "/casino"], { queryParams: params });
        return false;
      } else if (
        (UrlSegment.length < 3 || UrlSegment[2] === "unsubscribe") &&
        isPnpFlow
      ) {
        /**
         * Markets like finland or estonia (means fi-fi or et-ee language) will not have landing page as
         * It load paynplay flow.
         * so we navigate user to casino page if he tries to land on landing page
         * below condition(isPnpFlow) is for this scenario.
         * and loggedIn user are not allowed to access landing page
         */
        const lang = languageCodeFromURL ? languageCodeFromURL : this.langCode;
        this.router.navigate([lang + "/casino"], { queryParams: params });
        return false;
      }
      return true;
    }
    return true;
  }
}
