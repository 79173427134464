import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Component } from "@angular/core";

// Constants
import { UserFlowTypes } from "src/app/constants/costants";

// Environments
import { environment } from "src/environments/environment";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { LivespinsService } from "src/app/modules/livespins/services/livespins-service";
import { NewsTickerService } from "src/app/modules/news/services/news-ticker.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  langCode: string;
  activeLobby: string;
  routerSub: Subscription;
  allGamesBanner = true;
  showSortByProviders = true;
  showSortByReleaseDate = false;
  filterByProvider = false;
  UserFlowTypes;
  isLoggedIn;
  destroy$: Subject<boolean> = new Subject<boolean>();
  zimplerMarkets = environment.zimplerMarkets;
  isNewsTickerBannerAvailable: any = true;

  constructor(
    private utility: UtilityService,
    private translationService: TranslationService,
    private commonService: CommonService,
    private livespinsService: LivespinsService,
    private newsTickerService: NewsTickerService
  ) {
    this.UserFlowTypes = UserFlowTypes;
    this.langCode = this.utility.getLangCode();
    this.translationService.langCode$
      .pipe(takeUntil(this.destroy$))
      .subscribe((langCode) => {
        this.langCode = langCode;
        this.updateSEOContent();
      });
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.commonService.logOutComplete$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      });
    this.newsTickerService.isNewsTickerBannerAvailable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((flag) => {
        this.isNewsTickerBannerAvailable = flag;
      });
      
    this.livespinsService.initialise();
  }

  onSelectedLobby(name) {
    this.activeLobby = name;
  }

  ngAfterViewInit() {
    this.updateSEOContent();
  }

  updateSEOContent() {
    this.utility.setSEO({
      metaTitle: this.translationService.instant(
        "SEO.casinoHomePage_metaTitle"
      ),
      metaDescription: this.translationService.instant(
        "SEO.casinoHomePage_metaDescription"
      ),
      metaTags: this.translationService.instant("SEO.casinoHomePage_metaTag"),
      title: this.translationService.instant("SEO.casinoHomePage_title"),
    });
    this.commonService.setCanonicalURL("homepage");
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
