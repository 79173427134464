import {
  ComponentFactoryResolver,
  ViewContainerRef,
  AfterViewInit,
  Directive,
  OnInit,
} from "@angular/core";

// Components
import { PayNPlayCashierComponent } from "src/app/modules/account/components/pay-n-play-cashier/pay-n-play-cashier.component";

@Directive({
  selector: "[playnPlayCashierDir]",
})
export class PlayNPlayCashierDirective implements OnInit, AfterViewInit {
  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    this.addDynamicComponent();
  }

  ngAfterViewInit() {}

  addDynamicComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      PayNPlayCashierComponent
    );
    const containerRef = this.viewContainerRef;
    containerRef.clear();
    containerRef.createComponent(componentFactory);
  }
}
