import { Subscription } from "rxjs";
import {
  HostListener,
  SimpleChange,
  Component,
  OnChanges,
  Input,
} from "@angular/core";

// Models
import { ProviderPregmatic } from "src/app/modules/game-groups/models/provider.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Services
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-live-casino-group-display",
  templateUrl: "./live-casino-group-display.component.html",
  styleUrls: ["./live-casino-group-display.component.scss"],
})
export class LiveCasinoGroupDisplayComponent implements OnChanges {
  // Inputs
  @Input() finalLobbyGames: GamePregmatic[] = [];
  @Input() lobbyProviderData: ProviderPregmatic;
  @Input() activeLobbyName: string = "";
  @Input() groupData: ProviderPregmatic;
  @Input() games: GamePregmatic[] = [];

  // Numbers
  providerLobbyCutOffValue: number = 0;
  playAgainCutOffValue: number = 4;
  gamesDefaultSize: number = 15;

  // Booleans
  showProviderLobby: boolean = false;
  islandscape: boolean = false;
  isLoggedIn: boolean = false;

  // Enums
  windowType: "device" | "mobile" = "device";

  // Arrays
  filteredGamesList: GamePregmatic[] = [];

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private utilityService: UtilityService,
    private commonService: CommonService
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.getWindowType();
    
    this.onSetGameCutOffValue();

    this.isLoggedIn = this.utilityService.isUserLoggedIn();

    this.subscriptions = [
      this.commonService.loginComplete$.subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      }),
      this.commonService.logOutComplete$.subscribe(() => {
        this.isLoggedIn = false;
      }),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (
      changes["games"] &&
      changes["games"].previousValue !== changes["games"].currentValue
    ) {
      this.games = changes["games"].currentValue;

      this.onGetLobbyGames();
    }

    if (
      changes["groupData"] &&
      changes["groupData"].previousValue !== changes["groupData"].currentValue
    ) {
      this.groupData = changes["groupData"].currentValue;
    }

    if (
      changes["activeLobbyName"] &&
      changes["activeLobbyName"].previousValue !==
        changes["activeLobbyName"].currentValue
    ) {
      this.activeLobbyName = changes["activeLobbyName"].currentValue;
    }

    if (
      changes["finalLobbyGames"] &&
      changes["finalLobbyGames"].previousValue !==
        changes["finalLobbyGames"].currentValue
    ) {
      this.finalLobbyGames = changes["finalLobbyGames"].currentValue;
    }

    if (
      changes["lobbyProviderData"] &&
      changes["lobbyProviderData"].previousValue !==
        changes["lobbyProviderData"].currentValue
    ) {
      this.lobbyProviderData = changes["lobbyProviderData"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Get Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      this.windowType = "device";
    } else {
      this.windowType = "mobile";
    }

    if (window.matchMedia("(orientation: portrait)").matches) {
      this.islandscape = false;
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      this.islandscape = true;
    }
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize") onWindowResize(): void {
    this.getWindowType();

    this.onSetGameCutOffValue();
  }

  @HostListener("window:orientationchange") onRotate(): void {
    this.getWindowType();

    this.onSetGameCutOffValue();
  }

  @HostListener("window:scroll") onWindowScroll(): void {
    if (
      this.games &&
      this.games.length > 0 &&
      this.filteredGamesList &&
      this.filteredGamesList.length < this.games.length
    ) {
      this.onScroll();
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetLobbyGames(): void {
    if (this.games) {
      this.onInitializeLazyLoading();
    } else {
      this.filteredGamesList = [];
    }
  }

  onInitializeLazyLoading(): void {
    this.gamesDefaultSize = 15;

    if (this.games && this.games.length > 0) {
      this.filteredGamesList = this.games.slice(0, this.gamesDefaultSize);
    }
  }

  onScroll(): void {
    let metaContentHeight: number = 0;

    let footerHeight: number = this.utilityService.getFooterHeight();

    const metaContentDom: HTMLCollectionOf<Element> = document.getElementsByClassName(
      "meta-content"
    );

    if (metaContentDom && metaContentDom.length > 0) {
      metaContentHeight = (metaContentDom[0] as HTMLElement).offsetHeight;
    }

    if (
      window.scrollY + window.innerHeight >=
      document.body.scrollHeight - (footerHeight + metaContentHeight + 250)
    ) {
      this.gamesDefaultSize = this.gamesDefaultSize + 15;

      this.filteredGamesList = this.games.slice(0, this.gamesDefaultSize);
    }
  }

  onSetGameCutOffValue(): void {
    this.onSetPlayAgainGamesCutOff();

    this.onSetProviderLobbyGamesCutOff();
  }

  onSetPlayAgainGamesCutOff(): void {
    const clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1600) {
      this.playAgainCutOffValue = 4;
    } else if (clientWidth <= 480) {
      this.playAgainCutOffValue = 2;
    } else if (clientWidth <= 1599) {
      this.playAgainCutOffValue = 3;
    }
  }

  onSetProviderLobbyGamesCutOff(): void {
    const clientWidth: number = document.body.clientWidth;

    if (clientWidth <= 860 && this.islandscape) {
      this.showProviderLobby = true;

      this.providerLobbyCutOffValue = 9;
    } else if (clientWidth > 481 && !this.islandscape) {
      this.showProviderLobby = false;
    } else if (clientWidth <= 480) {
      this.showProviderLobby = true;

      this.providerLobbyCutOffValue = 8;
    } else {
      this.showProviderLobby = false;
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
