import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class EmitterService {

  private cCCardsAvailableStatus: boolean = false;

  private isMaxBetWarningTriggerred: Subject<boolean> = new Subject<boolean>();
  public isMaxBetWarningTriggerred$: Observable<boolean> = this.isMaxBetWarningTriggerred.asObservable();

  private doLogout: Subject<boolean> = new Subject<boolean>();
  public doLogOut$: Observable<boolean> = this.doLogout.asObservable();

  constructor() {}

  broadCastMaxBetWarningTriggerred(data: boolean): void {
    this.isMaxBetWarningTriggerred.next(data);
  }

  broadCastDoLogout(flag: boolean): void {
    this.doLogout.next(flag);
  }

  private depositSuccessful = new Subject<any>();
  public depositSuccessful$ = this.depositSuccessful.asObservable();

  broadCastDepositSuccesful(data) {
    this.depositSuccessful.next(data);
  }

  private openRewardTermsConditions = new Subject<any>();
  public openRewardTermsConditions$ = this.openRewardTermsConditions.asObservable();

  broadCastRewardTC(flag: boolean): void {
    this.openRewardTermsConditions.next(flag);
  }

  private noCCCardsAvailable: Subject<boolean> = new Subject<boolean>();
  public noCCCardsAvailable$: Observable<boolean> = this.noCCCardsAvailable.asObservable();

  broadCastNoCCCardsAvailable(flag) {
    this.setCCCardsAvailableStatus(flag);
    
    this.noCCCardsAvailable.next(flag);
  }

  private enableBrandPromotionVideo: Subject<boolean> = new Subject<boolean>();
  public enableBrandPromotionVideo$: Observable<boolean> = this.enableBrandPromotionVideo.asObservable();

  broadCastEnablePromotionVideo(flag: boolean): void {
    this.enableBrandPromotionVideo.next(flag);
  }

  setCCCardsAvailableStatus(status: boolean): void {
    this.cCCardsAvailableStatus = status;
  }

  getCCCardsAvailableStatus(): boolean {
    return this.cCCardsAvailableStatus;
  }

  private openLimitsRegulatoryPopup = new Subject<any>();
  public openLimitsRegulatoryPopup$ = this.openLimitsRegulatoryPopup.asObservable();

  broadCastLimitsRegulatoryPopup(flag) {
    this.openLimitsRegulatoryPopup.next(flag);
  }
}
