import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { FilteredGamesBy } from "src/app/modules/game-groups/models/games/filtered-games-by.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Pipes
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-live-casino-play-again",
  templateUrl: "./live-casino-play-again.component.html",
  styleUrls: ["./live-casino-play-again.component.scss"],
})
export class LiveCasinoPlayAgainComponent implements OnDestroy {
  // Strings
  gamesUrlPath: string = environment.gamesUrlPath;
  languageCode: string = "";
  imgixParams: string = "";

  // Booleans
  isLoggedIn: boolean = false;
  isLoading: boolean = true;

  // Arrays
  lastPlayedLiveGamesList: GamePregmatic[] = [];
  lastPlayedGamesList: GamePregmatic[] = [];

  // Others
  filterByData: FilteredGamesBy = { typeOfGames: "live-game" };

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private gameGroupsService: GameGroupsService,
    private gamesFilterPipe: GamesFilterPipe,
    private gamePlayService: GamePlayService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onGetLastPlayedGames();

    this.isLoggedIn = this.utilityService.isUserLoggedIn();

    this.imgixParams = this.utilityService.getImgixParams();

    this.subscriptions = [
      this.translationService.langCodeSb$.subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      }),
      this.commonService.loginComplete$.subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      }),
      this.commonService.logOutComplete$.subscribe(() => {
        this.isLoggedIn = false;
      }),
    ];
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetLastPlayedGames(): void {
    this.lastPlayedGamesList = [];

    Promise.all([
      this.gameGroupsService.getGameGroupGamesp(),
      this.gameGroupsService.getLastedPlayedGamesp(),
    ]).then(([gamesResponse, lastPlayedGameIdsResponse]) => {
      let gamesList: GamePregmatic[] = gamesResponse;

      let lastPlayedGameIds: number[] = lastPlayedGameIdsResponse;

      if (
        gamesList &&
        gamesList.length > 0 &&
        lastPlayedGameIds &&
        lastPlayedGameIds.length > 0
      ) {
        _.each(lastPlayedGameIds, (typeId: number) => {
          let game: GamePregmatic  = _.filter(gamesList, { beGameTypeId: typeId })[0];

          if (game) {
            this.lastPlayedGamesList.push(game);
          }
        });

        this.onApplyLiveCasinoFilter();
      } else {
        this.lastPlayedLiveGamesList = [];
      }

      this.isLoading = false;
    });
  }

  onApplyLiveCasinoFilter(): void {
    if (this.lastPlayedGamesList.length > 0) {
      this.lastPlayedLiveGamesList = this.gamesFilterPipe.transform(
        this.lastPlayedGamesList,
        this.filterByData
      );

      if (this.lastPlayedLiveGamesList && this.lastPlayedLiveGamesList.length > 0) {
        this.gameGroupsService.broadCastIsLastPlayedLiveGamesExist(true);
      }
    }
  }

  onGameLaunch(gameName: string): void {
    if (environment.features.liveCasino.livespins) {
      if (gameName == "Bet Behind") {
        this.router.navigate([`${this.languageCode}/livespins`]);

        return;
      }
    }

    gameName = this.utilityService.convertGameNameToUrl(gameName);

    this.gamePlayService.clearGameWindowData();

    setTimeout(() => {
      if (gameName && this.isLoggedIn) {
        this.router.navigate([`${this.languageCode}/${this.translationService.instant("url.game")}/${gameName}`]);
      } else {
        this.utilityService.initiateLogin();

        this.commonService.setNavigateAfterLogin({
          type: "url",
          data: { navigateTo: "gameWindow", path: gameName },
        });
      }
    });
  }

  onViewAllHandler(): void {
    this.commonService.broadCastUpdateActiveLobby({ lobby: "play-again" });
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
