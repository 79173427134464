export enum CurrencyCode {
  "CAD" = "CAD",
  "EUR" = "EUR",
  "NOK" = "NOK",
  "INR" = "INR",
  "USD" = "USD",
  "NZD" = "NZD",
  "BRL" = "BRL",
  "HUF" = "HUF",
  "THB" = "THB",
  "ZAR" = "ZAR",
}
