import { SimpleChange, Component, OnDestroy, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

// Configurations
import { mixPanelEventsConfigurations } from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Models
import { ProviderLevel } from "src/app/modules/game-groups/models/providers/provider-level.model";
import { ProviderPregmatic } from "src/app/modules/game-groups/models/provider.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { MixPanelService } from "src/app/modules/shared/services/mix-panel.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-provider-lobby",
  templateUrl: "./provider-lobby.component.html",
  styleUrls: ["./provider-lobby.component.scss"],
})
export class ProviderLobbyComponent implements OnDestroy {
  // Inputs
  @Input() lobbyProviderData: ProviderPregmatic;
  @Input() games: GamePregmatic[] = [];

  // Strings
  gamesUrlPath: string = environment.gamesUrlPath;
  languageCode: string = "";
  imgixParams: string = "";

  // Booleans
  isLoggedIn: boolean = false;
  isLoading: boolean = true;

  // Arrays
  providerLevelGamesList: {
    [key: string]: ProviderLevel;
  };

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private gameGroupsService: GameGroupsService,
    private mixpanelService: MixPanelService,
    private gamePlayService: GamePlayService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onGetProviderLevelGames();

    this.isLoggedIn = this.utilityService.isUserLoggedIn();

    this.imgixParams = this.utilityService.getImgixParams();

    this.subscriptions = [
      this.commonService.loginComplete$.subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      }),
      this.commonService.logOutComplete$.subscribe(() => {
        this.isLoggedIn = false;
      }),
      this.translationService.langCodeSb$.subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      }),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["games"] &&
      changes["games"].previousValue !== changes["games"].currentValue
    ) {
      this.games = changes["games"].currentValue;

      this.isLoading = false;
    }

    if (
      changes["lobbyProviderData"] &&
      changes["lobbyProviderData"].previousValue !==
        changes["lobbyProviderData"].currentValue
    ) {
      this.lobbyProviderData = changes["lobbyProviderData"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetProviderLevelGames(): void {
    Promise.resolve(this.gameGroupsService.getProviderLevelGames()).then(
      (data: { [key: string]: ProviderLevel }) => {
        if (data && Object.keys(data).length > 0) {
          this.providerLevelGamesList = data;
        }
      }
    );
  }

  onGameLaunch(gameName: string, providerName: string): void {
    gameName = this.utilityService.convertGameNameToUrl(gameName);

    this.gamePlayService.clearGameWindowData();

    this.onSendMixPanelEvent(providerName);

    if (this.isLoggedIn) {
      this.router.navigate([
        `${this.languageCode}/${this.translationService.instant(
          "url.game"
        )}/${gameName}`,
      ]);
    } else {
      this.commonService.setNavigateAfterLogin({
        type: "url",
        data: { navigateTo: "gameWindow", path: gameName },
      });

      this.utilityService.initiateLogin();
    }
  }

  onSendMixPanelEvent(providerName: string): void {
    this.mixpanelService.trackMixpanelEvents(
      mixPanelEventsConfigurations.clicked_Provider_Lobby,
      {
        name: providerName,
      }
    );
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
