import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

// Environments
import { environment } from "src/environments/environment";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { AccountService } from "src/app/modules/auth/services/account.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-bonus",
  templateUrl: "./bonus.component.html",
  styleUrls: ["./bonus.component.scss"],
})
export class BonusComponent implements OnDestroy {
  bonusForfeit: boolean = false;
  isOpen: boolean = false;
  accountStatusSub: Subscription;
  currencySymbol: string;
  activeBonusSub: Subscription;
  dropBonusSub: Subscription;
  bonusDetails: any;
  isLoading: boolean = false;
  serverError: any;
  selectedBonusId: any;
  currencySymbolSub: Subscription;
  apiUrlPath: string = environment.apiUrl;

  constructor(
    private userDetailsService: UserDetailsService,
    private accountService: AccountService,
    private translationService: TranslationService,
    private utility: UtilityService,
    private commonService: CommonService
  ) {
    this.getActiveBonusDetails();
    this.currencySymbolSub = this.userDetailsService.currencySymbolSb$.subscribe(
      (currenySymbol) => {
        this.currencySymbol = currenySymbol;
      }
    );
  }

  getActiveBonusDetails() {
    this.isLoading = true;
    this.activeBonusSub = this.accountService
      .getActiveBonusDetails()
      .subscribe((response) => {
        if (response) {
          this.bonusDetails = response;
          /**
           * We filtering the bonus & display to user based on
           * Case 1: Bonus shouldn't be freeSpin Bonus & if it's a freeSpin bonus
           * It's completed status should be true.
           * case 2: If bonus wagering percentage reaches 100% also,we shouldn't
           * show this bonus to user(Basically case-2 should be handed from BE..For time been
           * we are handling from front-end).
           * (!bonus.isFreeSpinBonus || (bonus.isFreeSpinBonus && bonus.isFreeSpinsCompleted))
           */
          this.bonusDetails = this.bonusDetails.filter((bonus) => {
            if (
              (!bonus.isFreeSpinBonus ||
                (bonus.isFreeSpinBonus && bonus.isFreeSpinsCompleted)) &&
              bonus["wageredPercent"] < 100
            ) {
              return bonus;
            }
          });
        } else {
          this.bonusDetails = [];
        }
        this.isLoading = false;
      });
  }

  dropBonus() {
    if (this.selectedBonusId) {
      let data = {
        ecrBonusId: this.selectedBonusId,
      };
      this.closeConfirmDialogue();
      this.dropBonusSub = this.accountService
        .dropBonus(data)
        .subscribe((response) => {
          if (
            response["status"] === "SUCCESS" &&
            response["bonusDropped"] === "SUCCESS"
          ) {
            this.selectedBonusId = undefined;
            this.commonService.getUserBalanceByPockets().then();
            this.getActiveBonusDetails();
          } else {
            if (this.selectedBonusId) {
              this.selectedBonusId = undefined;
            }
            this.serverError = this.translationService.instant(
              "errors.dropbonusfail"
            );
          }
        });
    }
  }
  openConfirmDialogue(bonusId) {
    this.selectedBonusId = bonusId;
    this.bonusForfeit = true;
  }
  closeConfirmDialogue() {
    if (this.selectedBonusId) {
      this.selectedBonusId = undefined;
    }
    this.bonusForfeit = false;
  }

  ngOnDestroy() {
    if (this.accountStatusSub) {
      this.accountStatusSub.unsubscribe();
    }
    if (this.dropBonusSub) {
      this.dropBonusSub.unsubscribe();
    }
    this.currencySymbolSub.unsubscribe();
  }
}
