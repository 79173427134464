import { Observable, Subscription } from "rxjs";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";

// Configurations
import { endpointConfigurations } from "src/app/configurations/endpoint.configurations";
import {
  userProfileRegisteredLanguageConfigurations,
  localToCmsLanguageConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Models
import { LoginCredentials } from "src/app/modules/registration/models/login-credential.model";

// Services
import { CashbackPromoService } from "src/app/modules/rewards/services/cashback-promo.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SocketService } from "src/app/modules/shared/services/socket.service";
import { MainService } from "src/app/modules/shared/services/main.service";
import { GtmService } from "src/app/modules/shared/services/gtm.service";
import { UtilityService } from "src/app/utility/utility.service";

@Injectable()
export class LoginService {
  langCode: string;
  langCodeSubcription: Subscription;
  userRegisteredLang;
  userLoggedTime;
  userCountryCode;

  constructor(
    private mainService: MainService,
    private commonService: CommonService,
    private translationService: TranslationService,
    private socketService: SocketService,
    private gtmService: GtmService,
    private userDetailService: UserDetailsService,
    private utility: UtilityService,
    private router: Router,
    private cashBackPromoService: CashbackPromoService
  ) {
    this.translationService.langCodeSb$.subscribe((langCode) => {
      this.langCode = langCode;
    });
  }

  doLogin(credentials: LoginCredentials): Observable<any> {
    return this.mainService.psPost(endpointConfigurations.dologin_url, credentials).pipe(
      map((loginResponse) => {
        if (loginResponse && loginResponse["success"] === true) {
          this.utility.deleteAffCookies();
          this.commonService.setUserJurisdiction(
            loginResponse["userJurisdiction"]
          );
          this.navigateToUserProfileLang();
          this.socketService.connectToScoket(
            loginResponse["pragmaticUrl"],
            loginResponse["pragmaticSessionId"],
            true
          );
          if (loginResponse["sessionLimit"] > 0) {
            this.commonService.setSessionVariable("session", {
              sessionLimit: loginResponse["sessionLimit"],
              availableSessionLimit: loginResponse["sessionLimit"] * 60,
            });
          }
          if (loginResponse["userJurisdiction"] === "estonia") {
            this.userLoggedTime = new Date();
            localStorage.setItem("loggedInTime", this.userLoggedTime);
          }
          localStorage.setItem("hir_user", "true");
          this.commonService.broadCastLoginSuccess(true);
          if (loginResponse && loginResponse["user"]) {
            this.commonService.setSocketDetails(loginResponse["user"]);
          }
          if (loginResponse["lastLoginTime"]) {
            this.cashBackPromoService.setUserLastAuthTime(
              loginResponse["lastLoginTime"]
            );
          }
          this.pushGtmLoginData(loginResponse);
        }
        return loginResponse;
      })
    );
  }

  pushGtmLoginData(loginData) {
    setTimeout(() => {
      this.userCountryCode = this.commonService.getUserCountry();
      if (this.userCountryCode) {
        this.gtmService.gtmDataLayerEvent({
          event: "logged_in",
          userId: loginData["user"]["id"],
          country: this.userCountryCode,
          userType: loginData["ecrCategory"],
        });
      }
    }, 1000);
  }

  navigateToUserProfileLang() {
    Promise.resolve(this.commonService.getProfileBalanceCurrency()).then(
      (data) => {
        if (data["profile"] && data["profile"]["language"]) {
          this.userRegisteredLang =
          userProfileRegisteredLanguageConfigurations[data["profile"]["language"]];
          let userLang = this.userRegisteredLang
            ? this.userRegisteredLang
            : environment.defaultLang;
          const params = this.utility.getQueryParams();
          if (userLang !== this.langCode) {
            this.userDetailService.setUserLangCode(userLang);
            this.translationService.use(localToCmsLanguageConfigurations[this.langCode]);
            localStorage.setItem("langCode", userLang);
            this.navigateToLandingPage(params, userLang);
            setTimeout((_) => {
              window.location.reload();
            });
          }
        }
      }
    );
  }

  navigateToLandingPage(params = {}, userLang) {
    if (this.utility.isUserLoggedIn()) {
      this.router.navigate([userLang + "/casino"], { queryParams: params });
    }
  }
}
