import { Observable, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import {
  AfterViewInit,
  HostListener,
  ElementRef,
  Component,
  OnDestroy,
  ViewChild,
  OnInit,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

// Services
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { MainService } from "src/app/modules/shared/services/main.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { supportedLanguagesList } from "src/app/modules/shared/utilities/languages.utilities";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit, OnDestroy, AfterViewInit {
  jackpotTotalAmount;

  isLoggedIn: boolean = false;
  logOutCompleteSubscription: Subscription;
  loginCompleteSubscription: Subscription;

  langCode;
  langCodeSubscription: Subscription;
  apiUrlPath: string = environment.apiUrl;
  scrollCheckEnabled: boolean = false;
  scrolled: boolean = false;
  currencyCodeSub: Subscription;
  deviceType;
  scroller: boolean = false;
  isFixedBar: boolean = true;
  affixElementPosition: any;
  isJapanRegion: boolean = false;
  isVisible = 0;
  footerHeight;
  VipCharacter;
  footerCharacter;
  bonusRewardsBg;
  isJackpotImage: boolean = false;
  @ViewChild("observerElementHeight", { static: false })
  affixElementHeight: ElementRef;
  currencySymbolSubscription: Subscription;
  currencySymbol: string;
  rewardSpecdeviceType: string;
  siteUrlPath: string;
  mediaUrlPath: string = environment.mediaUrlPath;
  mediaUrlPathHir: string = environment.mediaUrlPathHir;
  imgixParams: string;
  isAffiliate: boolean = false;
  userAffiliateId;
  userLangCode;
  affiliateOfferJsonText;

  hungarianStyle: boolean = false;

  @HostListener("window:resize") onResize() {
    this.checkScrollEnabled();
    this.checkDeviceType();
  }

  @HostListener("window:scroll") onScroll() {
    if (this.scrollCheckEnabled && window.scrollY >= 250) {
      this.scrolled = true;
    } else {
      this.scrolled = false;
    }
    this.loadOnscroll();
  }

  constructor(
    private registrationService: RegistrationService,
    private mainService: MainService,
    private userDetailsService: UserDetailsService,
    private currencyPipe: CurrencyFormatPipe,
    private commonService: CommonService,
    private translationService: TranslationService,
    private utility: UtilityService,
    private router: Router
  ) {
    this.checkScrollEnabled();
    this.commonService.setIsUservisitedLandingPage(true);
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
      }
    );

    this.currencyCodeSub = this.userDetailsService.currencyCodeSb$.subscribe(
      (data) => {
        this.getJackpotTotal();
      }
    );

    this.langCode = this.utility.getLangCode();
    if (this.langCode === "hu-hu") {
      this.hungarianStyle = true;
    }
    this.langCodeSubscription = this.translationService.langCode$.subscribe(
      (langCode) => {
        this.langCode = langCode;
        this.updateSEOContent();
        this.setCountryRegionBackground();
        this.setAffiliateCookie();
        if (this.langCode === "hu-hu") {
          this.hungarianStyle = true;
        }
      }
    );

    this.currencySymbolSubscription = this.userDetailsService.currencySymbolSb$.subscribe(
      (curSymbol) => {
        this.currencySymbol = curSymbol;
      }
    );

    this.imgixParams = this.utility.getImgixParams();
  }
  ngAfterViewInit() {
    this.affixElementPosition =
      this.affixElementHeight.nativeElement.offsetTop - window.innerHeight;
    this.updateSEOContent();
    this.setAffiliateCookie();
  }

  ngOnInit() {
    this.checkDeviceType();
  }

  setAffiliateCookie() {
    const queryParams = this.utility.getQueryParams();
    let userAffilateValue = queryParams["affid"];
    if (!this.isLoggedIn && userAffilateValue) {
      let affiliateValuesList = this.translationService
        .instant("affiliateids.affiliateValue")
        .split(",");
      if (
        affiliateValuesList &&
        affiliateValuesList.indexOf(userAffilateValue.split("_")[0]) > -1
      ) {
        let languageCodeFromURL = this.utility
          .getDecodedCurrentPath()
          .split("/")[1];
        if (
          languageCodeFromURL &&
          _.contains(supportedLanguagesList(), languageCodeFromURL)
        ) {
          this.userLangCode = languageCodeFromURL;
        }
        this.utility.setAffiliateCookie(userAffilateValue, this.userLangCode);
      }
    }
    this.getUserAffiliateValue();
  }

  getUserAffiliateValue() {
    let affilateLang = this.utility.getAffCookies("affiliateLang");
    let affiliateValuesList = this.translationService
      .instant("affiliateids.affiliateValue")
      .split(",");
    if (this.utility.getAffCookies("affId")) {
      this.userAffiliateId = this.utility.getAffCookies("affId").split("_")[0];
    }
    if (
      this.userAffiliateId &&
      affilateLang &&
      affiliateValuesList &&
      affiliateValuesList.indexOf(this.userAffiliateId) > -1 &&
      affilateLang === this.langCode
    ) {
      this.isAffiliate = true;
      this.renderAffiliteJsonOfferTextAsHtml();
    } else {
      this.isAffiliate = false;
    }
  }

  updateSEOContent() {
    this.utility.setSEO({
      metaTitle: this.translationService.instant("SEO.landingPage_metaTitle"),
      metaDescription: this.translationService.instant(
        "SEO.landingPage_metaDescription"
      ),
      metaTags: this.translationService.instant("SEO.landingPage_metaTag"),
      title: this.translationService.instant("SEO.landingPage_title"),
    });
    this.commonService.setCanonicalURL("landingpage");
  }

  getJackpotTotal() {
    let self = this;
    this.getJackpotsTotalAmount().subscribe((jackpotfeed) => {
      if (jackpotfeed && jackpotfeed.jackpotsTotal) {
        self.jackpotTotalAmount = self.currencyPipe.transform(
          jackpotfeed.jackpotsTotal,
          this.currencySymbol,
          true
        );
      }
    });
  }

  getJackpotsTotalAmount(): Observable<any> {
    return this.mainService
      .microGamingTotalJackpotsAmount(this.userDetailsService.getCurrencyCode())
      .pipe(
        map((jackpotFeed) => {
          return jackpotFeed;
        })
      );
  }

  openRegistrationPopup() {
    if (this.utility.isPnpFlow()) {
      this.utility.initiateDeposit();
    } else {
      this.registrationService.openRegistrationHanlder();
    }
  }

  checkDeviceType() {
    this.rewardSpecdeviceType = undefined;
    let ww = document.body.clientWidth;
    if (ww >= 1023) {
      this.deviceType = "desktop";
    } else {
      this.deviceType = "mobile";
    }
    if (
      (ww >= 481 &&
        ww < 1023 &&
        window.matchMedia("(orientation: landscape)").matches) ||
      (ww >= 768 &&
        ww < 1023 &&
        window.matchMedia("(orientation: portrait)").matches)
    ) {
      this.rewardSpecdeviceType = "mobile_landscape";
    }

    this.setCountryRegionBackground();
  }

  checkScrollEnabled() {
    var ww = document.body.clientWidth;
    if (ww >= 1024) {
      this.scrollCheckEnabled = true;
    } else {
      this.scrollCheckEnabled = false;
    }
  }

  loadOnscroll() {
    const footerContainerDom = document.getElementsByClassName(
      "footer-conatiner"
    );
    if (this.footerHeight === undefined) {
      this.footerHeight = footerContainerDom[0].clientHeight + 150;
    }
    if (
      window.scrollY + window.outerHeight >=
        document.body.scrollHeight - this.footerHeight &&
      this.isVisible <= 5
    ) {
      this.isVisible = this.isVisible + 1;
    }

    this.affixElementPosition =
      this.affixElementHeight.nativeElement.offsetTop - window.innerHeight;
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.affixElementPosition) {
      this.isFixedBar = false;
    } else {
      this.isFixedBar = true;
    }
  }
  setCountryRegionBackground() {
    this.bonusRewardsBg =
      this.apiUrlPath +
      "/uploads/media/CAF/LP/HRL/h-rewards-" +
      (this.rewardSpecdeviceType ? "desktop" : this.deviceType) +
      "_" +
      this.langCode +
      ".png";
  }

  renderAffiliteJsonOfferTextAsHtml() {
    this.affiliateOfferJsonText = this.translationService.instant(
      "affiliate." + this.userAffiliateId + "_offer"
    );
  }

  gotoAllProvidersPage() {
    this.router.navigate([
      this.langCode +
        "/casino/" +
        this.translationService.instant("url.allProvider-page"),
    ]);
  }
  ngOnDestroy() {
    this.loginCompleteSubscription.add(this.logOutCompleteSubscription);
    this.currencyCodeSub.unsubscribe();
    this.loginCompleteSubscription.unsubscribe();
    this.langCodeSubscription.unsubscribe();
  }
}
