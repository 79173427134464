import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { NetDepositLimits } from "src/app/modules/account/models/limits.model";
import { Subject } from "rxjs";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { AccountService } from "src/app/modules/auth/services/account.service";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

@Component({
  selector: "app-remaining-limit",
  templateUrl: "./remaining-limit.component.html",
  styleUrls: ["./remaining-limit.component.scss"],
})
export class RemainingLimitComponent implements OnInit, OnDestroy {
  lowestLimit: number;
  isLoading: boolean;
  currencySymbol: string;
  remainingNetDepositLimitText: string;
  hasLimit: boolean;

  destroy$ = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private userDetailsService: UserDetailsService,
    private translateService: TranslateService,
    private currencyFormatPipe: CurrencyFormatPipe
  ) {}

  ngOnInit(): void {
    this.observeNetDepositLimits();
    this.observeCurrencySymbol();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  private observeNetDepositLimits(): void {
    this.isLoading = true;
    this.accountService
      .getNetDepositLimits()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.hasLimit = this.calculateHasLimitFlag(data);
        if (this.hasLimit) {
          this.lowestLimit = this.calculateLowestLimit(data);
          this.generateRemainingNetDepositLimitText();
        }
        this.isLoading = false;
      });
  }

  private calculateHasLimitFlag(currentLimits: NetDepositLimits): boolean {
    return currentLimits.monthlyLimit > 0 || currentLimits.weeklyLimit > 0;
  }

  private calculateLowestLimit(currentLimits: NetDepositLimits): number {
    const allLimits = [
      ...(currentLimits.monthlyLimit > 0 ? [currentLimits.remainingNetLimitThisMonth] : []),
      ...(currentLimits.weeklyLimit > 0 ? [currentLimits.remainingNetLimitThisWeek] : []),
    ]
    if (allLimits.length) {
      return Math.min(...allLimits) / 100;
    }
    return 0;
  }

  private observeCurrencySymbol(): void {
    this.userDetailsService.currencySymbolSb$.pipe(takeUntil(this.destroy$)).subscribe((currencySymbol) => {
      this.currencySymbol = currencySymbol;
      this.generateRemainingNetDepositLimitText();
    });
  }

  private generateRemainingNetDepositLimitText(): void {
    const formattedLimit = this.currencyFormatPipe.transform(this.lowestLimit, this.currencySymbol);
    this.translateService
      .stream("cashier.remaining_net_deposit_limit", {
        limit: formattedLimit,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((translation) => {
        this.remainingNetDepositLimitText = translation;
      });
  }
}
