import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Injectable,
  Renderer2,
  ViewContainerRef,
} from "@angular/core";
import {
  CampaignHtmlAttributeKeys,
  XmasCampaignHtmlAttributeValues,
} from "src/app/modules/promotions/models/campaign.model";
import { LeaderBoardComponent } from "src/app/modules/shared/components/leader-board/leader-board.component";
import { LEADER_BOARD_DEFAULT_LIMIT } from "src/app/modules/shared/constants/leader-board.constant";
import { EnrolledDisplayMode } from "src/app/modules/shared/models/leader-board/leader-board.model";

@Injectable({
  providedIn: "root",
})
export class CampaignsService {
  private renderer: Renderer2;
  private viewContainerRef: ViewContainerRef;

  constructor(private resolver: ComponentFactoryResolver) {}

  initService(renderer: Renderer2, viewContainerRef: ViewContainerRef) {
    this.renderer = renderer;
    this.viewContainerRef = viewContainerRef;
  }

  /**
   * Check if content includes Campaign Elements.
   * If yes then replace them with LeaderBoard components.
   *
   * @param element Element in which LeaderBoard should be generated
   * @returns List of pairs: Campaign Elements and LeaderBoard components
   */
  generateLeaderBoardIfNeeded(element: ElementRef): [HTMLElement, ComponentRef<LeaderBoardComponent>][] {
    const campaignElements = this.getCampaignElements(element);
    if (!campaignElements.length) return;
    const pairs = this.generateLeaderBoardsForCampaignElements(campaignElements);
    this.replaceCampaignElementsWithLeaderBoard(pairs);
    return pairs;
  }

  /**
   * Replace each Campaign Element by LeaderBoard component
   *
   * @param pairs List of pairs: Campaign Elements and LeaderBoard components
   */
  private replaceCampaignElementsWithLeaderBoard(pairs: [HTMLElement, ComponentRef<LeaderBoardComponent>][]): void {
    pairs.forEach(([element, component]) => {
      // Insert LeaderBoard component into the content
      this.renderer.insertBefore(element.parentElement, component.location.nativeElement, element);
      // Remove replaced Campaign Element from the content
      this.renderer.removeChild(element.parentElement, element);
    });
  }

  /**
   * Generate LeaderBoard component for each Campaign Element
   *
   * @param campaignElements
   * @returns List of pairs: Campaign Elements and LeaderBoard components
   */
  private generateLeaderBoardsForCampaignElements(
    campaignElements: HTMLElement[]
  ): [HTMLElement, ComponentRef<LeaderBoardComponent>][] {
    return campaignElements.map((element) => {
      const props = this.extractDataFromCampaignElement(element);
      const leaderBoardComponent = this.createLeaderBoardComponent(props);
      return [element, leaderBoardComponent];
    });
  }

  /**
   * Find Campaign Elements in the content
   *
   * @param element Element in which should search for Campaign Elements
   * @returns List of Campaign Elements
   */
  private getCampaignElements(element: ElementRef): HTMLElement[] {
    return Array.from(
      element.nativeElement.querySelectorAll(
        `[${CampaignHtmlAttributeKeys.Component}="${XmasCampaignHtmlAttributeValues.Component}"]`
      )
    );
  }

  /**
   * Extract data from Campaign Element and converts it to LeaderBoard props
   *
   * @param campaignElement
   * @returns Props to create LeaderBoard component
   */
  private extractDataFromCampaignElement(
    campaignElement: HTMLElement
  ): Pick<
    LeaderBoardComponent,
    "buttonText" | "board" | "isXmas" | "limit" | "enrolledDisplayMode" | "urlToLeaderBoard"
  > {
    const limit = campaignElement.getAttribute(CampaignHtmlAttributeKeys.Limit);
    const showLeaderBoard = campaignElement.hasAttribute(CampaignHtmlAttributeKeys.LeaderBoard);
    const urlToLeaderBoard = campaignElement.getAttribute(CampaignHtmlAttributeKeys.UrlToLeaderBoard);
    return {
      buttonText: campaignElement.getAttribute(CampaignHtmlAttributeKeys.CtaOptIn),
      board: campaignElement.getAttribute(CampaignHtmlAttributeKeys.Board),
      limit: limit ? +limit : LEADER_BOARD_DEFAULT_LIMIT,
      enrolledDisplayMode: showLeaderBoard
        ? EnrolledDisplayMode.Table
        : urlToLeaderBoard
        ? EnrolledDisplayMode.Link
        : undefined,
      urlToLeaderBoard,
      isXmas: campaignElement.hasAttribute(CampaignHtmlAttributeKeys.XMas),
    };
  }

  /**
   * Create new LeaderBoard component
   *
   * @param props Props for LeaderBoard component
   * @returns LeaderBoard component ref
   */
  private createLeaderBoardComponent(
    props: Pick<
      LeaderBoardComponent,
      "buttonText" | "board" | "isXmas" | "limit" | "enrolledDisplayMode" | "urlToLeaderBoard"
    >
  ): ComponentRef<LeaderBoardComponent> {
    const factory: ComponentFactory<LeaderBoardComponent> = this.resolver.resolveComponentFactory(LeaderBoardComponent);
    const componentRef: ComponentRef<LeaderBoardComponent> = this.viewContainerRef.createComponent(factory);
    Object.entries(props).forEach(([key, value]) => {
      componentRef.instance[key] = value;
    });
    return componentRef;
  }
}
