import { Pipe, PipeTransform } from "@angular/core";
import { Subscription } from "rxjs";

// Configurations
import {
  marketLocaleCurrencyMappingConfigurations,
  currencyMappingConfigurations,
  countyToLocaleConfigurations,
  currencySymbolToCodeConfigurations,
} from "src/app/configurations/main.configurations";

// Enums
import { CurrencyPosition } from "src/app/models/configurations/enums/currency-position.enum";
import { CurrencySymbol } from "src/app/models/configurations/enums/currency-symbol.enum";

// Libraries
import * as _ from "underscore";

// Models
import { CurrencyConfigurations } from "src/app/models/configurations/general-configurations/currency-configuration.model";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { supportedLanguagesList } from "src/app/modules/shared/utilities/languages.utilities";
import { environment } from "src/environments/environment";

@Pipe({
  name: "currencyformat",
  pure: true
})
export class CurrencyFormatPipe implements PipeTransform {
  // Strings
  currencySymbol: string = "";
  languageCode: string = "";

  // Booleans
  isLoggedIn: boolean = false;

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    private commonService: CommonService,
    private utility: UtilityService
  ) {
    this.languageCode = this.utility.getLangCode();

    this.isLoggedIn = this.utility.isUserLoggedIn();

    /*
      Here we receive both default currency(if we don't have user currency like
      before logged In state) & user currency
    */
    this.subscriptions = [
      this.userDetailsService.currencySymbolSb$.subscribe((currencySymbol: string) => {
        this.currencySymbol = currencySymbol;
      }),
      this.translationService.langCode$.subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      }),
      this.commonService.loginComplete$.subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      }),
      this.commonService.logOutComplete$.subscribe(() => {
        this.isLoggedIn = false;
      }),
    ];
  }

  transform(
    value: string | number,
    currencySymbol: string,
    isdecimalNeeded: boolean = true
  ): string {
    let countryCode: string = "";

    // Objects
    let marketLocaleCurrencyMappingClone: {
      [key: string]: CurrencyConfigurations;
    } = marketLocaleCurrencyMappingConfigurations;

    let currencyMapConfigClone: {
      [key: string]: CurrencyConfigurations;
    } = currencyMappingConfigurations;

    let data: CurrencyConfigurations;

    let languageCodeFromURL: string = this.utility.getDecodedCurrentPath().split("/")[1];

    if (
      languageCodeFromURL &&
      languageCodeFromURL !== this.languageCode &&
      _.contains(supportedLanguagesList(), languageCodeFromURL)
    ) {
      this.languageCode = languageCodeFromURL;
    }

    if (this.userDetailsService.userProfileDetails) {
      countryCode = this.userDetailsService.userProfileDetails.country;
    } else {
      countryCode = this.commonService.getCountryCode();
    }
    if (!this.isLoggedIn) {
      data = marketLocaleCurrencyMappingClone.hasOwnProperty(this.languageCode)
        ? marketLocaleCurrencyMappingClone[this.languageCode]
        : undefined;
    } else {
      data = currencyMapConfigClone.hasOwnProperty(countryCode) ? currencyMapConfigClone[countryCode] : undefined;
    }

    value = this.formatNumberBasedOnLocale(
      value,
      countryCode,
      currencySymbol,
      isdecimalNeeded
    );

    const isCurrencyAfter = data && data.position === CurrencyPosition.after;
    const splittingSymbol = !data || data.isSpaceRequired ? " " : "";
    const currencySymbolToDisplay = currencySymbol || this.currencySymbol;

    if (isCurrencyAfter) {
      return `${value}${splittingSymbol}${currencySymbolToDisplay}`;
    } else {
      return `${currencySymbolToDisplay}${splittingSymbol}${value}`;
    }
  }

  formatNumberBasedOnLocale(
    value: string | number,
    countryCode: string,
    currencySymbol: string,
    isdecimalNeeded: boolean
  ): string {    
    const locale = countyToLocaleConfigurations[countryCode] || window.navigator.language;
    const currencyCode = currencySymbolToCodeConfigurations[currencySymbol] || environment.defaultCurrencyCode;    
    const formatter = Intl.NumberFormat(locale, {
      currency: currencyCode,
      minimumIntegerDigits: 1,
      minimumFractionDigits: isdecimalNeeded ? 2 : 0,
      maximumFractionDigits: isdecimalNeeded ? 2 : 0,
    });
    const numberValue = typeof value === 'string' ? +value : value;
    return formatter.format(numberValue);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
