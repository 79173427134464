import {
  SimpleChange,
  Component,
  OnChanges,
  OnDestroy,
  Input,
} from "@angular/core";

// Services
import { CashbackPromoService } from "src/app/modules/rewards/services/cashback-promo.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";

@Component({
  selector: "app-countdown-timer",
  templateUrl: "./countdown-timer.component.html",
  styleUrls: ["./countdown-timer.component.scss"],
})
export class CountdownTimerComponent implements OnChanges, OnDestroy {
  @Input() expiryDate;
  @Input() callingFrom;
  @Input() promoRaceId;
  countTimer: string;
  calcNewYear;
  constructor(
    private translationService: TranslationService,
    private CashbackPromoService: CashbackPromoService
  ) {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["expiryDate"]) {
      if (typeof changes["expiryDate"].currentValue === "string") {
        changes["expiryDate"].currentValue = parseInt(
          changes["expiryDate"].currentValue
        );
      }
      this.expiryDate = new Date(changes["expiryDate"].currentValue);
      this.startCountDownTimer();
    }
    if (changes["callingFrom"]) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
    if (changes["promoRaceId"]) {
      this.promoRaceId = changes["promoRaceId"].currentValue;
    }
  }

  /**
   * Below Function will decide left out expire time of reward & also display UI
   * on all the rewards present in system
   *
   * like congrats screen, all rewards page, cashier page etc...
   */

  startCountDownTimer() {
    var translated_days: string = this.translationService.instant(
      "rewards.days"
    );
    var translated_day: string = this.translationService.instant("rewards.day");
    let date_future: any,
      date_now: any,
      seconds: any,
      minutes: any,
      hours: any,
      days: any;
    this.calcNewYear = setInterval(() => {
      date_future = new Date(this.expiryDate);
      date_now = new Date();
      if (date_future > date_now) {
        seconds = Math.floor((date_future - date_now) / 1000);
        minutes = Math.floor(seconds / 60);
        hours = Math.floor(minutes / 60);
        days = Math.floor(hours / 24);
        hours = hours - days * 24;
        minutes = minutes - days * 24 * 60 - hours * 60;
        seconds =
          seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
        if (days > 30) {
          this.countTimer = ">30 " + translated_days;
        } else if (days > 0) {
          /**Logic to round up days if left time is 2 days 23h 10mins 1 sec/23 days 0h 0mins 1sec is 3 days
           * (2 days plus X we always display as 3 days)
           * below 24h will have a timer in that format: 23h 59m 20s*/

          if (days > 0 && (hours || minutes || seconds)) {
            days = days + 1;
          }
          this.countTimer =
            days + " " + (days === 1 ? translated_day : translated_days);
        } else if (hours > 0) {
          this.countTimer = hours + "h " + minutes + "m " + seconds + "s ";
        } else if (minutes > 0) {
          this.countTimer = minutes + "m " + seconds + "s ";
        } else if (seconds > 0) {
          this.countTimer = seconds + "s ";
        } else {
          this.countTimer = seconds + "s ";
          if (
            seconds <= 0 &&
            this.promoRaceId &&
            this.callingFrom === "promoCounter"
          ) {
            clearInterval(this.calcNewYear);
            this.CashbackPromoService.broadCastStopPromoCounter(
              this.promoRaceId
            );
          }
        }
      } else if (this.promoRaceId && this.callingFrom === "promoCounter") {
        clearInterval(this.calcNewYear);
        this.CashbackPromoService.broadCastStopPromoCounter(this.promoRaceId);
      }
    }, 1000);
  }

  ngOnDestroy() {
    if (this.calcNewYear && this.callingFrom !== "promoCounter") {
      clearInterval(this.calcNewYear);
    }
  }
}
