import { Subscription } from "rxjs";
import {
  NavigationStart,
  UrlSegmentGroup,
  PRIMARY_OUTLET,
  NavigationEnd,
  UrlSegment,
  UrlTree,
  Router
} from "@angular/router";
import {
  HostListener,
  Component,
  OnInit,
} from "@angular/core";

// Constants
import { UserFlowTypes } from "src/app/constants/costants";

// Libraries
import * as _ from "underscore";

// Services
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-bottom-navigation",
  templateUrl: "./bottom-navigation.component.html",
  styleUrls: ["./bottom-navigation.component.scss"],
})
export class BottomNavigationComponent implements OnInit {
  isLoggedIn: boolean = false;
  langCode: string;
  langCodeSubscription: Subscription;
  loginCompleteSubscription: Subscription;
  loginStatusSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  currencySymbolSubscription: Subscription;
  currencySymbol: string;
  userBalanceSubscription: Subscription;
  userTotalBalance: any;
  activePage: string;
  isSideMenuOpen: boolean;
  windowType: string;
  routerSub: Subscription;
  isGamePlay: boolean = false;
  favoriteStatusSub: Subscription;
  isQuickDeposit = false;
  quickDepositToggleSubs: Subscription;
  isbalanceLoading: boolean = true;
  UserFlowTypes: typeof UserFlowTypes;

  @HostListener("window:resize") onResize() {
    this.getWindowType();
    this.setBottomNavHeightInMobileLandscape();
  }
  constructor(
    private commonService: CommonService,
    private gamePlayService: GamePlayService,
    private gameGroupsService: GameGroupsService,
    private userDetailsService: UserDetailsService,
    private registrationService: RegistrationService,
    private router: Router,
    private utility: UtilityService,
    private translationService: TranslationService
  ) {
    this.UserFlowTypes = UserFlowTypes;
    this.routerSub = this.router.events.subscribe((event) => {
      /**we clear game play data here when user navigate out from gamepaly window
       to some other route path with click on minimize options  */
      if (event instanceof NavigationStart) {
        if (
          event.url &&
          !event.url.includes(
            "/" + this.translationService.instant("url.game")
          ) &&
          !this.gamePlayService.isGameMinimized
        ) {
          if (!_.isEmpty(this.gamePlayService.getCurrentGameData())) {
            this.gamePlayService.broadCastIsGameLanuch({});
          }
        }
      }
      if (event instanceof NavigationEnd) {
        this.checkActivePageHandler(event.url);
      }
    });
    /**Because we need this active page check handler only when naivagtion changes
     * but we have a case when user is in game play window switches from destop top to mobile & visa versa
     * when this happens bottom navigation component getting toggles(hide & show)
     * because of this activepage gets undefined & UI is not updating
     * we have differnt UI based on this flag
     */
    this.checkActivePageHandler(this.utility.getDecodedCurrentPath());
    this.langCode = this.utility.getLangCode();
    this.langCodeSubscription = this.translationService.langCode$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );

    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (data) => {
        this.isLoggedIn = true;
      }
    );

    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
        this.isbalanceLoading = true;
      }
    );

    /**Here we are using BehaviorSubject on initialization also v'll get default value..
      //* we differentiate subject & BehaviorSubject with "Sb" at end of Event Name. */

    this.currencySymbolSubscription = this.userDetailsService.currencySymbolSb$.subscribe(
      (curSymbol) => {
        this.currencySymbol = curSymbol;
      }
    );

    if (this.userDetailsService.getUserTotalBalance()) {
      this.userTotalBalance = this.userDetailsService.getUserTotalBalance();
      this.isbalanceLoading = false;
    }
    this.userBalanceSubscription = this.userDetailsService.userBalanceUpdated$.subscribe(
      (balanceUpdateMessage) => {
        if (balanceUpdateMessage == "UserBalanceUpdated") {
          this.userTotalBalance = this.userDetailsService.getUserTotalBalance();
          this.isbalanceLoading = false;
        }
      }
    );

    this.favoriteStatusSub = this.gamePlayService.updateCurrentGameFavoriteStatus$.subscribe(
      (flag) => {
        this.isFavoriteGame = flag;
      }
    );

    /**we call this method onfresh of page...to check user login
     * session exist or not... */

    /**Here we are using BehaviorSubject on initialization also v'll get default value..
     * we differentiate subject & BehaviorSubject with "Sb" at end of Event Name.
     */
    this.currencySymbolSubscription = this.userDetailsService.currencySymbolSb$.subscribe(
      (curSymbol) => {
        this.currencySymbol = curSymbol;
      }
    );
    this.setBottomNavHeightInMobileLandscape();
  }

  checkActivePageHandler(url) {
    const tree: UrlTree = this.router.parseUrl(url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g ? g.segments : [];
    if (s && s[1] && s[1].path) {
      this.activePage = this.utility.getActiveLobby(s[1].path);
      if (this.activePage === "game") {
        this.isGamePlay = true;
      } else {
        this.isGamePlay = false;
      }
    } else {
      this.activePage = "";
      this.isGamePlay = false;
    }
  }

  openAccountMenuList() {
    this.goOutFullScreen();
    this.utility.openAccountComponent("menuOptions");
  }

  openAccountComponent(viewname) {
    this.commonService.broadcastQuickDepositToggle(false);
    this.utility.openAccountComponent(viewname);
  }

  initiateDeposit() {
    this.utility.initiateDeposit();
  }

  ngOnInit() {
    this.getWindowType();
    this.quickDepositToggleSubs = this.commonService.quickDepositToggle$.subscribe(
      (isQuickDeposit) => {
        this.isQuickDeposit = isQuickDeposit;
      }
    );
  }

  doLogout() {
    this.commonService.doLogout();
  }

  openLogin() {
    this.utility.initiateLogin();
  }

  openRegistrationPopup() {
    this.registrationService.openRegistrationHanlder();
  }

  initiateZimplerDeposit() {
    this.utility.initiateDeposit();
  }

  gotoHome() {
    this.router.navigate(["/"]);
  }

  getWindowType() {
    var ww = document.body.clientWidth;
    if (ww <= 1024) {
      this.windowType = "mobile";
    } else {
      this.windowType = "device";
    }
  }

  isFavoriteGame: boolean = false;
  toggleFavoriteGame() {
    let currentStatus = this.gamePlayService.getCuurentGameFavoriteStatus();
    const gameId = this.gamePlayService.getCurrentGameData()["gameCode"];
    let nextState = !currentStatus;
    if (gameId) {
      this.gamePlayService
        .toggleFavoriteGame(gameId, nextState)
        .subscribe((data) => {
          if (data && data["status"] === "SUCCESS") {
            this.isFavoriteGame = data["favoriteAddFlag"] ? true : false;
            this.gamePlayService.broadCastCurrentGameFavoriteStatus(
              this.isFavoriteGame
            );
            /**we make this call after every successful toggle
             * to get updated latest & cache it in our local */
            this.gameGroupsService.getFavoriteGames(true).subscribe();
          }
        });
    }
  }

  goOutFullScreen() {
    this.utility.exitFullScreen();
  }

  makeMinimizedGame() {
    this.gamePlayService.broadCastGameWindowMinimized(true);
    this.goOutFullScreen();
    setTimeout(() => {
      this.router.navigate([this.langCode + "/casino"]);
    }, 200);
  }

  openQuickDeposit() {
    this.isQuickDeposit = true;
    this.commonService.broadcastQuickDepositToggle(true);
    //   setTimeout(function() {
    //     $(document).scrollTop($(document).scrollTop())
    // }, 100);
  }

  setBottomNavHeightInMobileLandscape() {
    if (window.matchMedia("(orientation: landscape)").matches) {
      let landscapeScreenHeight = window.innerHeight;
      document.documentElement.style.setProperty(
        "--landscapeScreenHeight",
        `${landscapeScreenHeight}px`
      );
    }
  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
    this.loginCompleteSubscription.add(this.currencySymbolSubscription);
    this.loginCompleteSubscription.add(this.userBalanceSubscription);
    this.loginCompleteSubscription.add(this.loginStatusSubscription);
    this.loginCompleteSubscription.add(this.logOutCompleteSubscription);
    this.loginCompleteSubscription.unsubscribe();
    this.quickDepositToggleSubs.unsubscribe();
  }
}
