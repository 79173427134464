import {
  SimpleChange,
  Component,
  OnChanges,
  Input,
} from "@angular/core";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";

// Models
import { SwiperLazy } from "src/app/modules/game-groups/models/swiper/swiper-lazy.model";
import { ProviderPregmatic } from "src/app/modules/game-groups/models/provider.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Services
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { swiperLazyConfigurations } from "src/app/modules/game-groups/utilities/swiper.utility";

@Component({
  selector: "app-jackpot-games",
  templateUrl: "./jackpot-games.component.html",
  styleUrls: ["./jackpot-games.component.scss"],
})
export class JackpotGamesComponent implements OnChanges {
  // Inputs
  @Input() gameGroupData: ProviderPregmatic;
  @Input() callingFromlobby: string = "";

  // Booleans
  isLoading: boolean = false;

  // Arrays
  gamesList: GamePregmatic[] = [];

  // Swiper Configurations
  swiperJackpotCasino: SwiperConfigInterface = {
    slidesPerView: 6,
    slidesPerGroup: 6,
    spaceBetween: 16,
    freeMode: true,
    navigation: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    resistanceRatio: 0,
    breakpoints: {
      // breakpoints works like max-width css
      2000: {
        slidesPerView: 7,
        slidesPerGroup: 7,
        spaceBetween: 16,
      },
      1599: {
        slidesPerView: 6,
        slidesPerGroup: 6,
        spaceBetween: 16,
      },
      1365: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 16,
      },
      767: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 8,
      },
      480: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 8,
      },
    },
  };
  swiperLazyConfigurations: SwiperLazy = swiperLazyConfigurations;

  constructor(private utilityService: UtilityService) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["gameGroupData"] &&
      changes["gameGroupData"].previousValue !==
        changes["gameGroupData"].currentValue
    ) {
      this.gameGroupData = changes["gameGroupData"].currentValue;

      if (this.gameGroupData.group_type === "manual") {
        this.gamesList = this.utilityService.sortGameByCountryOrder(
          this.gameGroupData.games
        );
      } else {
        this.gamesList = this.gameGroupData.games;
      }

      if (this.gamesList && this.gamesList.length > 0) {
        this.swiperLazyConfigurations.totalNumberOfGamesExist = this.gamesList.length;
      }
    }

    if (
      changes["callingFromlobby"] &&
      changes["callingFromlobby"].previousValue !==
        changes["callingFromlobby"].currentValue
    ) {
      this.callingFromlobby = changes["callingFromlobby"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  /*
    Function for Lazy loading of games goes here
    on every next click of silde below function will be trigerred
    & calculated how many game have to load amount that total games present

    This works on bases of swiperLazyConfig defined above...
  */
  onSwiperNextEvent(): void {
    if (this.swiperLazyConfigurations.backwardslideIndex) {
      this.swiperLazyConfigurations.backwardslideIndex =
        this.swiperLazyConfigurations.backwardslideIndex - 1;
      return;
    }

    if (
      this.swiperLazyConfigurations.indexNumberOfGameTilesToshow <
      this.swiperLazyConfigurations.totalNumberOfGamesExist
    ) {
      this.swiperLazyConfigurations.indexNumberOfGameTilesToshow =
        this.swiperLazyConfigurations.indexNumberOfGameTilesToshow +
        this.swiperLazyConfigurations.numberOfExtraGamesToLoadForSlide;

      this.swiperLazyConfigurations.forwardslideIndex =
        this.swiperLazyConfigurations.forwardslideIndex + 1;
    }
  }

  onSwiperPrevEvent(): void {
    this.swiperLazyConfigurations.backwardslideIndex =
      this.swiperLazyConfigurations.backwardslideIndex + 1;
  }

  onNavigateAllGamesPage(): void {
    this.utilityService.navigateAllGamesPage(this.gameGroupData);
  }
}
