import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import {MenuConfigurations} from "src/app/models/configurations/menu/menu-configuration.model";

export const menuConfigurations = (): MenuConfigurations => {
  return {
    leftMenuConfig: [
      {
        icon: "icon-lobby icon-casino",
        linkText: marker("general.casino"),
        navigateTo: "casino",
        onlyAfterLogin: false,
      },
      {
        icon: "icon-lobby icon-live-casino",
        linkText: marker("general.live_casino"),
        navigateTo: "live-casino",
        onlyAfterLogin: false,
      },
      {
        icon: "icon-lobby icon-live-ivespins",
        linkText: marker("general.live_spins"),
        navigateTo: "livespins",
        onlyAfterLogin: false,
      },
      {
        icon: "icon-lobby icon-casino",
        linkText: marker("rewards.rewards"),
        navigateTo: "rewards",
        onlyAfterLogin: true,
      },
      {
        icon: "icon-lobby icon-casino",
        linkText: marker("promotion.promotions"),
        navigateTo: "promotions",
        onlyAfterLogin: false,
      },
      {
        icon: "icon-lobby icon-casino",
        linkText: marker("common.payment_methods"),
        navigateTo: "payments",
        onlyAfterLogin: false,
      },
    ],
    casinoLobbyConfig: [
      {
        lobbyName: "Popular",
        filterKey: "casino",
        linkText: marker("general.popular"),
        imageUrl: "/assets/img/game-nav/Popular.png",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Slots",
        filterKey: "slots",
        linkText: marker("general.slots"),
        imageUrl: "/assets/img/game-nav/slots.png",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Table Games",
        filterKey: "table-games",
        linkText: marker("general.table_Games"),
        imageUrl: "/assets/img/game-nav/table-game.png",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "All Games",
        filterKey: "all",
        linkText: marker("general.allgames"),
        imageUrl: "/assets/img/game-nav/all-games.png",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Livespins",
        filterKey: "livespins",
        linkText: marker("general.live_spins"),
        imageUrl: "/assets/img/game-nav/slots.png",
        onlyAfterLogin: false,
      },
    ],
    liveCasinolobbyConfig: [
      {
        lobbyName: "All",
        filterKey: "all-live",
        linkText: marker("general.all"),
        imageUrl: "/assets/img/game-nav/all-livecasino.png",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Play Again",
        filterKey: "play-again",
        linkText: marker("livecasino.play_again"),
        imageUrl: "/assets/img/game-nav/playagain-livecasino.png",
        onlyAfterLogin: true,
      },
      {
        lobbyName: "Roulette",
        filterKey: "roulette",
        linkText: marker("livecasino.roulette"),
        imageUrl: "/assets/img/game-nav/roulette-livecasino.png",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Games Shows",
        filterKey: "games-shows",
        linkText: marker("livecasino.game_shows"),
        imageUrl: "/assets/img/game-nav/gameshows-livecasino.png",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Blackjack",
        filterKey: "blackjack",
        linkText: marker("livecasino.blackjack"),
        imageUrl: "/assets/img/game-nav/blackjack-livecasino.png",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Baccarat",
        filterKey: "baccarat",
        linkText: marker("livecasino.baccarat"),
        imageUrl: "/assets/img/game-nav/baccarate-livecasino.png",
        onlyAfterLogin: false,
      },
    ],
    lobbyByPageName: {
      casinolobbies: ["casino", "slots", "table-games", "all", "livespins"],
      livecasinolobbies: [
        "all-live",
        "play-again",
        "roulette",
        "games-shows",
        "blackjack",
        "baccarat",
      ],
    },
  };
};
