import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  EventEmitter,
  SimpleChange,
  Component,
  OnDestroy,
  OnInit,
  Output,
  Input
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { CashbackPromoService } from "src/app/modules/rewards/services/cashback-promo.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-cashback-promo-reward",
  templateUrl: "./cashback-promo-reward.component.html",
  styleUrls: ["./cashback-promo-reward.component.scss"],
})
export class CashbackPromoRewardComponent implements OnInit, OnDestroy {
  promoRaceId;
  promoParentId;
  bonusType;
  promoConfigInfo;
  optinValidityDuration;
  @Input() bonusDetails;
  @Input() callingFrom;
  @Input() selectedBonus;
  @Output() callOpenRewardTCPopUp: EventEmitter<any> = new EventEmitter();
  isLoading: boolean = false;
  userSlabInfo;
  currencyCodeSub: Subscription;
  currencyCode: string;
  currencySymbolSubscription: Subscription;
  currencySymbol: string;
  isAbsolute;
  mediaUrlPath: string = environment.mediaUrlPath;
  imgixParams: string;
  isCashBackPopup: boolean = false;
  isDepositCashbackPromo: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  successResponse: boolean = false;
  @Output() callOnBonusSelected: EventEmitter<any> = new EventEmitter();
  @Input() rewardSize;
  @Output() callOnNavigateToDeposit: EventEmitter<any> = new EventEmitter();

  constructor(
    private cashBackPromoService: CashbackPromoService,
    private userDetailsService: UserDetailsService,
    private utility: UtilityService,
    private paynplayCashierService: PayNPlayCashierService
  ) {
    this.userDetailsService.currencyCodeSb$
      .pipe(takeUntil(this.destroy$))
      .subscribe((curreny) => {
        this.currencyCode = curreny;
      });
    this.imgixParams = this.utility.getImgixParams();
  }

  ngOnInit(): void {}

  getPromoConfigDetails() {
    this.isLoading = true;
    let requestData = {
      promoRaceId: this.promoRaceId,
      promoParentId: this.promoParentId,
    };
    this.cashBackPromoService
      .getPromoConfigDetails(requestData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((promoConfigInfo) => {
        if (promoConfigInfo && promoConfigInfo["status"] === "SUCCESS") {
          this.promoConfigInfo = promoConfigInfo["promoConfig"];
          let slabGroupInfo = this.promoConfigInfo["slabGroupInfo"][
            Object.keys(this.promoConfigInfo["slabGroupInfo"])[0]
          ];
          this.userSlabInfo = _.filter(slabGroupInfo["slabInfo"], {
            slabCurrency: this.currencyCode,
          })[0];
          this.isAbsolute = this.userSlabInfo["slabAward"][0][
            "slabAwardValue"
          ][0]["absolute"];
        }
        this.isLoading = false;
      });
  }

  navigateToNextStep() {
    /*Here we checking whether depositRequired for user or not
    based on that we are navigating to next step*/
    this.isLoading = true;
    let requestData = {
      promoId: this.promoParentId,
      promoRaceId: this.promoRaceId,
    };
    this.cashBackPromoService
      .getOptIn_url(requestData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((promoConfigInfo) => {
        this.isLoading = false;
        if (promoConfigInfo && promoConfigInfo["status"] === "SUCCESS") {
          this.isCashBackPopup = true;
          this.successResponse = true;
          this.optinValidityDuration = this.utility.getTimeFormat(
            this.promoConfigInfo["campaignEndDate"]
          );
          this.cashBackPromoService.broadCastPromoCounter();
        } else {
          this.isCashBackPopup = true;
          this.successResponse = false;
        }
      });
  }

  closeConfirmDialogue(bonusDetails) {
    if (bonusDetails && bonusDetails["depositRequired"]) {
      this.utility.openAccountComponent("deposit");
    } else {
      this.isCashBackPopup = false;
      this.utility.closeAccountComponent();
    }
  }

  openRewardTCPopUp(event) {
    this.callOpenRewardTCPopUp.emit(event);
  }

  addBonus(bonusDetails) {
    if (bonusDetails && !bonusDetails["playerOptinTime"]) {
      if (
        bonusDetails &&
        bonusDetails["promoRaceId"] &&
        bonusDetails["promoParentId"]
      ) {
        this.promoRaceId = bonusDetails["promoRaceId"];
        this.promoParentId = bonusDetails["promoParentId"];
        let requestData = {
          promoId: this.promoParentId,
          promoRaceId: this.promoRaceId,
        };
        this.cashBackPromoService
          .getOptIn_url(requestData)
          .pipe(takeUntil(this.destroy$))
          .subscribe((promoConfigInfo) => {
            if (promoConfigInfo && promoConfigInfo["status"] === "SUCCESS") {
              if (bonusDetails["depositRequired"]) {
                if (this.rewardSize === "shorter") {
                  this.callOnBonusSelected.emit();
                } else {
                  this.naviagateToDeposit();
                }
              } else {
                this.cashBackPromoService.broadCastPromoCounter();
              }
            }
          });
      }
    } else if (bonusDetails && bonusDetails["playerOptinTime"]) {
      let requestData = {
        promoRaceId: this.promoRaceId,
      };
      this.cashBackPromoService
        .getOptOut_url(requestData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((promoConfigInfo) => {
          if (promoConfigInfo && promoConfigInfo["status"] === "SUCCESS") {
            if (bonusDetails["depositRequired"]) {
              if (this.rewardSize === "shorter") {
                this.callOnBonusSelected.emit();
              } else {
                this.naviagateToDeposit();
              }
            } else {
              this.cashBackPromoService.broadCastPromoCounter();
            }
          }
        });
    }
  }

  naviagateToDeposit() {
    if (["pnp-cashier"].indexOf(this.callingFrom) > -1) {
      this.callOnNavigateToDeposit.emit();
    } else {
      this.initiateDeposit();
    }
  }

  initiateDeposit() {
    this.utility.closeAccountComponent();
    this.paynplayCashierService.broadCastInitiateDeposit({
      callingFrom: this.callingFrom,
    });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes["callingFrom"]) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
    if (changes["bonusDetails"]) {
      this.bonusDetails = changes["bonusDetails"].currentValue;
      this.promoRaceId = this.bonusDetails["promoRaceId"];
      this.promoParentId = this.bonusDetails["promoParentId"];
      if (this.promoRaceId && this.promoParentId) {
        this.getPromoConfigDetails();
      }
    }
    if (changes["selectedBonus"]) {
      this.selectedBonus = changes["selectedBonus"].currentValue;
    }
    if (changes["rewardSize"]) {
      this.rewardSize = changes["rewardSize"].currentValue;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
