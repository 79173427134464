import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-next-rewards",
  templateUrl: "./next-rewards.component.html",
  styleUrls: ["./next-rewards.component.scss"],
})
export class NextRewardsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
