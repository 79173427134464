import { Observable, Subject, of, Subscription, Subscriber } from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";

// Configurations
import { endpointConfigurations } from "src/app/configurations/endpoint.configurations";
import {
  launchGameURlBasedOnSubProvidersPrefixConfigurations,
  launchGameURLBasedOnMarketConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";
import * as $ from "jquery";

// Models
import { CommunicatorMessage } from "src/app/modules/game-groups/models/game-communicator/communicator-message.model";
import { NetEntExtensionMethods } from "src/app/modules/game-groups/models/netent/netent-extension-methods.model";
import { GameCommunicator } from "src/app/modules/game-groups/models/game-communicator/game-communicator.model";
import { DeviceDimensions } from "src/app/modules/game-groups/models/game-communicator/device-dimensions.model";
import { NetEntConfigurations } from "src/app/modules/game-groups/models/netent/netent-configuration.model";
import { NetEntDetails } from "src/app/modules/game-groups/models/netent/netent-details.model";
import { GamePlay } from "src/app/modules/game-groups/models/game-play/game-play.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";
import {
  GameDetailsResponse,
  GameDetailsError,
  GameDetails,
} from "src/app/modules/game-groups/models/game-details/game-details.model";

// Services
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { MainService } from "src/app/modules/shared/services/main.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { purpleTheme } from "src/app/modules/shared/utilities/theme.utilities";

@Injectable()
export class GamePlayService implements OnDestroy {
  // Numbers
  registrationPopUpTimePeriodInSeconds: number = 90;

  // Strings
  languageCode: string = environment.defaultLang;
  gameCalledFrom: string = "";
  gameVendorCode: string = "";
  dummy: string = "";

  // Booleans
  isCurrentGameFavoriteStatus: boolean = false;
  isgameplaywindowlaunched: boolean = false;
  isEventListenerAdded: boolean = false;
  isGameMinimized: boolean = false;
  isGameLoading: boolean = false;
  isLoggedIn: boolean = false;

  // Objects
  gameData?: GamePlay | GamePregmatic;
  gameDetails: GamePlay;

  // Subscription list
  gameRequestQueue: Subscriber<any>[] = [];

  // To Discover
  allGamesData = [];

  // Timeout
  registrationTimerInstanceTimeout: NodeJS.Timer;

  // -------------------------------------------------------------------//
  // Subjects and Behaviour Subjects
  // Logout
  public logOutComplete: Subject<boolean> = new Subject<boolean>();
  public logOutComplete$: Observable<
    boolean
  > = this.logOutComplete.asObservable();

  // is Game Window Launched
  private isGamewindowLanuched: Subject<GamePlay | GamePregmatic> = new Subject<
    GamePlay | GamePregmatic
  >();
  public isGamewindowLanuched$: Observable<
    GamePlay | GamePregmatic
  > = this.isGamewindowLanuched.asObservable();

  // is Game Window Minimized
  private isGameWindowMinimized: Subject<boolean> = new Subject<boolean>();
  public isGameWindowMinimized$: Observable<
    boolean
  > = this.isGameWindowMinimized.asObservable();

  // updated Current Game Favourite Status
  updateCurrentGameFavoriteStatus: Subject<boolean> = new Subject<boolean>();
  updateCurrentGameFavoriteStatus$: Observable<
    boolean
  > = this.updateCurrentGameFavoriteStatus.asObservable();

  // Relaunch Game
  private relaunchGame: Subject<boolean> = new Subject<boolean>();
  public relanuchGame$: Observable<boolean> = this.relaunchGame.asObservable();

  // -------------------------------------------------------------------//
  // Subscriptions
  subscriptions: Subscription[] = [];

  // -------------------------------------------------------------------//
  // Constructor
  constructor(
    private registrationService: RegistrationService,
    private translationService: TranslationService,
    private commonService: CommonService,
    private mainService: MainService,
    private utility: UtilityService,
    private router: Router
  ) {
    this.isLoggedIn = this.utility.isUserLoggedIn();

    this.subscriptions = [
      this.commonService.loginComplete$.subscribe(() => {
        this.isLoggedIn = true;
      }),
      this.commonService.logOutComplete$.subscribe(() => {
        this.isLoggedIn = false;
      }),
      this.translationService.langCodeSb$.subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      }),
      this.commonService.gameWindowRegilyPopUpTimerUpdate$.subscribe(() => {
        if (!this.isLoggedIn && !this.utility.isPnpFlow()) {
          this.updateRegilyTimer();
        }
      }),
    ];
  }

  // -------------------------------------------------------------------//
  // Get Methods
  getCurrentGameData(): GamePlay | GamePregmatic {
    return this.gameData;
  }

  getIsGameWindowMinimized(): boolean {
    return this.isGameMinimized;
  }

  getCuurentGameFavoriteStatus(): boolean {
    return this.isCurrentGameFavoriteStatus;
  }

  getGameCalledfrom(): string {
    return this.gameCalledFrom;
  }

  checkIsPlayNGo(response: GameDetails): GameDetails {
    if (response && response.url.includes("playngonetwork")) {
      let responseUrl: string = response.url;

      responseUrl = responseUrl
        .replace("lobby", "origin")
        .concat("&embedmode=iframe");

      if (!responseUrl.includes("origin")) {
        responseUrl = responseUrl.concat(`&origin=${window.location.origin}`);
      }

      response.url = responseUrl;

      return response;
    } else {
      return response;
    }
  }

  getDeviceWidthHeight(): DeviceDimensions {
    let clientWidth: number = document.body.clientWidth;

    let footerNaviagtionHeight: number = 0;

    let height: number = 0;

    let width: number = 0;

    if (document.getElementById("bottomNavigation")) {
      footerNaviagtionHeight = document.getElementById("bottomNavigation")
        .offsetHeight;
    }

    if (
      clientWidth <= 1024 &&
      window.matchMedia("(orientation: portrait)").matches
    ) {
      width = window.innerWidth;

      height = window.innerHeight - footerNaviagtionHeight;
    } else if (
      clientWidth <= 1024 &&
      window.matchMedia("(orientation: landscape)").matches &&
      this.isLoggedIn
    ) {
      width = window.innerWidth - footerNaviagtionHeight;

      height = window.innerHeight;
    } else {
      width = $("#gameIframeWrapper").outerWidth();

      height = $("#gameIframeWrapper").outerHeight();
    }

    return { width, height };
  }

  toggleFavoriteGame(gameId: string, gameState: boolean): Observable<any> {
    return this.mainService.psPost(
      endpointConfigurations.toggleFavoriteGame_url,
      {
        gameID: gameId,
        newState: gameState,
      }
    );
  }

  getGames(isForce: boolean = false): Observable<any> {
    if (isForce) {
      this.allGamesData = [];
    }

    if (this.allGamesData && this.allGamesData.length > 0) {
      return of(this.allGamesData);
    } else {
      return new Observable((observer) => {
        if (this.gameRequestQueue.length > 0) {
          this.gameRequestQueue.push(observer);
        } else {
          this.gameRequestQueue.push(observer);

          this.mainService
            .psGet(endpointConfigurations.getGames_url)
            .subscribe((data) => {
              if (data && data.length > 0) {
                this.allGamesData = data;
              }

              for (let i: number = 0; i < this.gameRequestQueue.length; i++) {
                this.gameRequestQueue[i].next(data);
              }

              this.gameRequestQueue = [];
            });
        }
      });
    }
  }

  getDeviceType(): "desktop" | "mobile" {
    if (document.body.clientWidth >= 1024) {
      return "desktop";
    } else {
      return "mobile";
    }
  }

  getGameVendorCode(): string {
    return this.gameVendorCode;
  }

  setGameLaunchURLLocale(): string {
    let launchGameLocaleURL: string = "";

    if (
      this.languageCode &&
      launchGameURLBasedOnMarketConfigurations &&
      launchGameURLBasedOnMarketConfigurations.hasOwnProperty(
        this.languageCode
      ) &&
      launchGameURLBasedOnMarketConfigurations[this.languageCode][
        this.gameVendorCode
      ]
    ) {
      launchGameLocaleURL =
        launchGameURLBasedOnMarketConfigurations[this.languageCode][
          this.gameVendorCode
        ];
    } else if (this.gameVendorCode.toLowerCase().startsWith("hub88")) {
      launchGameLocaleURL =
        launchGameURlBasedOnSubProvidersPrefixConfigurations[this.languageCode]
          .hub88;
    } else if (this.gameVendorCode.toLowerCase().startsWith("relax")) {
      launchGameLocaleURL =
        launchGameURlBasedOnSubProvidersPrefixConfigurations[this.languageCode]
          .relax;
    } else if (this.gameVendorCode.toLowerCase().startsWith("mgs")) {
      launchGameLocaleURL =
        launchGameURlBasedOnSubProvidersPrefixConfigurations[this.languageCode]
          .mgs;
    }

    return launchGameLocaleURL;
  }

  setGameUrlLanguage(URLresponse: string): string {
    if (this.gameVendorCode) {
      let gameURL: string | URL = URLresponse;

      let setLangParam: URLSearchParams | void;

      let langCodeURL: string = this.setGameLaunchURLLocale();

      if (
        this.gameVendorCode !== "playngo" &&
        URLresponse &&
        !URLresponse.includes("playngonetwork")
      ) {
        gameURL = decodeURIComponent(URLresponse);
      }

      gameURL = new URL(gameURL);

      if (gameURL.search && gameURL.search.includes("language")) {
        setLangParam = gameURL.searchParams.set("language", langCodeURL);
      } else if (gameURL.search && gameURL.search.includes("langIso")) {
        setLangParam = gameURL.searchParams.set("langIso", langCodeURL);
      } else if (gameURL.search && gameURL.search.includes("lang")) {
        setLangParam = gameURL.searchParams.set("lang", langCodeURL);

        if (
          this.gameVendorCode === "pushgaming" &&
          gameURL.search.includes("country")
        ) {
          if (this.languageCode === "pt-br") {
            setLangParam = gameURL.searchParams.set("country", "BR");
          } else {
            setLangParam = gameURL.searchParams.set("country", "ES");
          }
        }
      } else if (
        this.gameVendorCode.toLowerCase().startsWith("mgs_") &&
        gameURL.search &&
        gameURL.search.includes("ul")
      ) {
        setLangParam = gameURL.searchParams.set("ul", langCodeURL);
      }

      gameURL.search = gameURL.searchParams.toString();

      if (
        this.gameVendorCode === "playngo" &&
        URLresponse &&
        URLresponse.includes("playngonetwork")
      ) {
        gameURL = gameURL.toString();
      } else {
        gameURL = decodeURIComponent(gameURL.toString());
      }

      return gameURL;
    }
  }

  // -------------------------------------------------------------------//
  // Set Methods
  broadCastIsLoggedOut(islogin: boolean): void {
    this.logOutComplete.next(islogin);
  }

  broadCastIsGameLanuch(gameData?: GamePlay | GamePregmatic): void {
    this.setCurrentGameData(gameData);

    this.isGamewindowLanuched.next(gameData);
  }

  setCurrentGameData(gameData?: GamePlay | GamePregmatic): void {
    this.gameData = gameData;
  }

  broadCastGameWindowMinimized(isGameWindowMinimized: boolean): void {
    this.setIsGameWindowMinimized(isGameWindowMinimized);

    this.isGameWindowMinimized.next(isGameWindowMinimized);
  }

  setIsGameWindowMinimized(isGameMinimized: boolean): void {
    this.isGameMinimized = isGameMinimized;
  }

  broadCastCurrentGameFavoriteStatus(
    isCurrentGameFavoriteStatus: boolean
  ): void {
    this.setCurrentGameFavoriteStatus(isCurrentGameFavoriteStatus);

    this.updateCurrentGameFavoriteStatus.next(isCurrentGameFavoriteStatus);
  }

  setCurrentGameFavoriteStatus(isCurrentGameFavoriteStatus: boolean): void {
    this.isCurrentGameFavoriteStatus = isCurrentGameFavoriteStatus;
  }

  setGameCalledfrom(gameCalledFrom: string): void {
    this.gameCalledFrom = gameCalledFrom;
  }

  broadCastRelanuchGame(isRelaunchGame: boolean): void {
    this.relaunchGame.next(isRelaunchGame);
  }

  loadGame(gamePlay: GamePlay): void {
    this.isEventListenerAdded = false;

    this.gameDetails = gamePlay;

    $(".game-is-loading").css("display", "flex");

    const urlpath: string =
      gamePlay.gameType === "realgame"
        ? endpointConfigurations.getRealgame_url
        : endpointConfigurations.getFreeGame_url;

    this.mainService
      .psGet(urlpath, { gameSymbol: gamePlay.gameId })
      .subscribe((gameResponse: GameDetailsResponse) => {
        let gameDetails: GameDetails = gameResponse.gameDetails;

        this.loadIframe(gameDetails);
      });
  }

  // NB: This method requires to be rewritten
  loadIframe(response: GameDetails | string): void {
    /*
      we remove gameplay loading progress indicator here
    */
    $(".game-is-loading").hide();

    let $iframe;

    $("#gameIframeWrapper").html("");

    if ((response as GameDetails) && (response as GameDetails).netentDetails) {
      (response as GameDetails).netentDetails.language = (response as GameDetails).language;

      $("#gameIframeWrapper").append('<div id="netentgame"></div>');

      this.netentScript(
        (response as GameDetails).netentDetails,
        this.getDeviceType()
      );

      if (!this.isLoggedIn && !this.utility.isPnpFlow()) {
        this.setRegilyRegistrationPopTimer();
      }
    } else if ((response as GameDetails) && (response as GameDetails).url) {
      if (
        this.languageCode &&
        purpleTheme().indexOf(this.languageCode) > -1 &&
        this.gameVendorCode
      ) {
        (response as GameDetails).url = this.setGameUrlLanguage(
          (response as GameDetails).url
        );
      }

      response = this.checkIsPlayNGo(response as GameDetails);

      $iframe = $(
        `<iframe title="Game playground" name="gamePlayIframe" class="iframe" id="gamePlayIframe" src="${
          (response as GameDetails).url
        }"></iframe>`
      );

      if (!this.isLoggedIn && !this.utility.isPnpFlow()) {
        this.setRegilyRegistrationPopTimer();
      }
    } else if (response === "gamedata-not-found") {
      this.getErrorMessageiframe(response);
    } else {
      this.getErrorMessageiframe((response as GameDetails).error);
    }

    $("#gameIframeWrapper").append($iframe);

    this.isGameLoading = false;

    setTimeout(() => {
      $("#gamePlayIframe").on("load", () => {
        /*
          Blueprint vendor's postMessage listeners:
          CloseGame - On fatal Error redirecting it to Home page
          requestSize - Sending width and height of the iframe container
        */
        window.parent.addEventListener("message", (e: MessageEvent) => {
          if (e && e.data) {
            if (e.data.type == "requestSize") {
              this.blueprintPostMessage();
            }
          }
        });

        if (
          ((response as GameDetails) as GameDetails) &&
          (response as GameDetails).url.includes("playngonetwork")
        ) {
          this.establishCrossCommunication((response as GameDetails).url);
        }
      });

      window.onresize = () => {
        if (
          $("#gamePlayIframe").length > 0 &&
          (response as GameDetails) &&
          (response as GameDetails).game_code.includes("hub88bpg")
        ) {
          this.blueprintPostMessage();
        }
      };
    });
  }

  establishCrossCommunication(gameUrl: string): void {
    let gameCommunicator: GameCommunicator = {
      source: undefined,
      targetOrigin: undefined,
      init: (element: HTMLIFrameElement) => {
        this.addWindowEventListenersForGameCommunication();

        gameCommunicator.source = element.contentWindow;

        gameCommunicator.targetOrigin = gameUrl.split("?")[0];
      },
      postMessage: (data: CommunicatorMessage) => {
        gameCommunicator.source.postMessage(
          data,
          gameCommunicator.targetOrigin
        );
      },
    };

    gameCommunicator.init(document.getElementById("gamePlayIframe"));

    gameCommunicator.postMessage({
      messageType: "addEventListener",
      eventType: "backToLobby",
    });

    gameCommunicator.postMessage({
      messageType: "addEventListener",
      eventType: "reloadGame",
    });
  }

  addWindowEventListenersForGameCommunication(): void {
    if (!this.isEventListenerAdded) {
      this.isEventListenerAdded = true;

      window.addEventListener("message", (event: MessageEvent) => {
        this.eventListenerProcessMessage(event);
      });
    }
  }

  eventListenerProcessMessage(event: MessageEvent): void {
    if (event && event.data && event.data.type == "backToLobby") {
      this.router.navigate([`${this.languageCode}/casino`]);
    }

    if (
      event &&
      event.data &&
      event.data.type == "reloadGame" &&
      this.isGameLoading === false
    ) {
      this.isGameLoading = true;

      this.loadGame(this.gameDetails);
    }
  }

  /*
    Blue print providers don't handle client device resolution automatically
    so we have send clients device width & height as window post message when "requestResize" event is
    send by them
  */
  blueprintPostMessage(): void {
    let element: HTMLIFrameElement = document.getElementById(
      "gamePlayIframe"
    ) as HTMLIFrameElement;

    let deviceResolution: DeviceDimensions = this.getDeviceWidthHeight();

    let data: DeviceDimensions = { type: "resize", ...deviceResolution };

    element.contentWindow.postMessage(data, "*");
  }

  /*
    All the Erros which we encounters when we're trying fetch game url or  loading on iframe
    will be handle by below function handler.
  */
  getErrorMessageiframe(response: string | GameDetailsError): void {
    let $iframe: string = "";

    if ((response as string) && (response as string).length > 20) {
      $iframe = $(
        `<iframe title="Game playground"  name="gamePlayIframe" class="iframe" id="gamePlayIframe" src="${response}"></iframe>`
      );
    } else if ((response as string) == "session expired") {
      $iframe = `<div class='loading-failed'>${response}${this.translationService.instant(
        "gameplay.please_login"
      )}</div>`;
    } else if (
      (response as GameDetailsError) &&
      ((response as GameDetailsError).problem_loading ||
        (response as GameDetailsError).unable_to_find_game ||
        (response as GameDetailsError).no_response_url)
    ) {
      $iframe = `<div class='loading-failed'>${
        (response as GameDetailsError).problem_loading
      } ${(response as GameDetailsError).unable_to_find_game} ${
        (response as GameDetailsError).no_response_url
      }</div>`;
    } else if (
      (response as GameDetailsError) &&
      (response as GameDetailsError).errorCode &&
      (response as GameDetailsError).errorCode === 100431
    ) {
      $iframe =
        `<div class='loading-failed'>${this.translationService.instant("gameplay.game_code_missing")}</div>`;
    } else if ((response as string) === "gamedata-not-found") {
      $iframe = `<div class='loading-failed'>${this.translationService.instant("gameplay.gamenot_config")}</div>`;
    } else {
      $iframe = `<div class='loading-failed'>${this.translationService.instant("gameplay.gamenot_found")}</div>`;
    }

    $("#gameIframeWrapper").append($iframe);
  }

  navigateToGame(
    gameId: string,
    gameType: string,
    hasDemo: string | boolean
  ): void {
    this.router.navigate([
      `${this.languageCode}/${this.translationService.instant(
        "url.game"
      )}/${gameId}`,
    ]);
  }

  netentScript(response: NetEntDetails, device: string): void {
    if (!window["netent"]) {
      let gameServerUrl = response.staticServer;

      let netentScript: HTMLScriptElement = document.createElement("script");

      netentScript.onload = () => {
        this.loadNetentGames(response, device);
      };

      netentScript.setAttribute(
        "src",
        `${gameServerUrl}/gameinclusion/library/gameinclusion.js`
      );

      document.head.appendChild(netentScript);
    } else {
      this.loadNetentGames(response, device);
    }
  }

  loadNetentGames(response: NetEntDetails, device: string): void {
    if (device === "mobile") {
      this.loadMobileNetentGames(response);
    } else {
      this.loadDesktopNetentGames(response);
    }
  }

  // Desktop Netent Game Configurations
  loadDesktopNetentGames(response: NetEntDetails): void {
    let configurations: NetEntConfigurations = {
      gameId: response.gameId,
      staticServer: response.staticServer,
      gameServer: response.gameServer,
      sessionId: response.sessionId,
      casinoBrand: "oneupent",
      targetElement: "netentgame",
      walletMode: response.walletMode,
      language: response.language,
      lobbyURL: environment.siteUrl,
    };

    if (response.liveCasinoHost) {
      configurations.liveCasinoHost = response.liveCasinoHost;
    }

    // Game launch successful.
    let success: (netEntExtend: NetEntExtensionMethods) => void = (
      netEntExtend: NetEntExtensionMethods
    ) => {
      netEntExtend.resize(640, 480);

      netEntExtend.addEventListener("gameReady", () => {
        netEntExtend.get("volumeLevel", (volumeLevel) => {
          netEntExtend.set("volumeLevel", 50);
        });
      });
    };

    // Error handling here.
    let error: (e: Error) => void = (e: Error) => {
      console.log(e);
    };

    window["netent"].launch(configurations, success, error);
  }

  // Mobile Netent Game Configurations
  loadMobileNetentGames(response: NetEntDetails): void {
    let configurations: NetEntConfigurations = {
      gameId: response.gameId,
      staticServer: response.staticServer,
      gameServer: response.gameServer,
      sessionId: response.sessionId,
      walletMode: response.walletMode,
      lobbyURL: response.lobbyURL,
      keepAliveURL: "",
      keepAliveInterval: 15,
      casinoBrand: response.casinoBrand,
      launchType: "iframe",
      applicationType: response.applicationType,
      targetElement: "netentgame",
      iframeSandbox:
        "allow-scripts allow-popups allow-popups-to-escape-sandbox allow-top-navigation allow-top-navigation-by-user-activation allow-same-origin allow-forms allow-pointer-lock",
      allowHtmlEmbedFullScreen: true,
      width: "100%",
      height: "100%",
      enforceRatio: false,
      language: response.language,
    };

    if (response.liveCasinoHost) {
      configurations.liveCasinoHost = response.liveCasinoHost;
    }

    let success: (netEntExtend: NetEntExtensionMethods) => void = (
      netEntExtend: NetEntExtensionMethods
    ) => {
      // gameReady Listener - callback is used for iFrame Touch games launch
      netEntExtend.addEventListener("gameReady", function () {
        // Game ready handler to setup Operator specific code
      });
    };

    // Error handling here.
    let error: (e: Error) => void = (e: Error) => {
      console.log(e);
    };

    window["netent"].launch(configurations, success, error);
  }

  /*
    Below logic is to clean game window data which we in store game card componet
    on user game launch hit on every new game launch
  */
  clearGameWindowData(): void {
    let data: GamePlay | GamePregmatic = this.getCurrentGameData();

    if (!_.isEmpty(data)) {
      this.broadCastIsGameLanuch({});

      this.broadCastGameWindowMinimized(false);
    }
  }

  /*
    Below functionality will trigger registration popup at different time slots
    at 9mins 18mins 36mins(only three times)

    from the time when he start playing demo game without login or registration
    we pop out just to ask user to register & enjoy the real feel.
  */
  setRegilyRegistrationPopTimer(): void {
    this.registrationTimerInstanceTimeout = setTimeout(() => {
      if (
        !this.isLoggedIn &&
        !this.utility.isPnpFlow() &&
        this.isgameplaywindowlaunched
      ) {
        this.registrationService.openRegistrationHanlder();
      }
    }, this.registrationPopUpTimePeriodInSeconds * 1000);
  }

  updateRegilyTimer(): void {
    if (this.registrationPopUpTimePeriodInSeconds === 90) {
      this.registrationPopUpTimePeriodInSeconds = 180;

      this.setRegilyRegistrationPopTimer();
    } else if (this.registrationPopUpTimePeriodInSeconds === 180) {
      this.registrationPopUpTimePeriodInSeconds = 360;

      this.setRegilyRegistrationPopTimer();
    } else {
      this.clearRegistrationTimer();
    }
  }

  clearRegistrationTimer(): void {
    if (this.registrationTimerInstanceTimeout) {
      this.registrationPopUpTimePeriodInSeconds = 90;

      clearInterval(this.registrationTimerInstanceTimeout);
    }
  }

  setGameVendorCode(vendorCode: string): void {
    this.gameVendorCode = vendorCode;
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.getGameVendorCode()) {
      this.gameVendorCode = undefined;
    }

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
