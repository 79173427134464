import { FormGroup, FormBuilder } from "@angular/forms";
import { Component, OnInit } from "@angular/core";

// Services
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { CustomValidators } from "src/app/utility/custom-validator";

@Component({
  selector: "app-shared-transaction",
  templateUrl: "./shared-transaction.component.html",
  styleUrls: ["./shared-transaction.component.scss"],
})
export class SharedTransactionComponent implements OnInit {
  timeslap: number = 6;
  minDate = new Date("1970-01-01");
  maxDate = new Date();

  txnDetailsFrom = this.formBuilder.group({
    startDate: ["", [this.customValidators.required]],
    endDate: ["", [this.customValidators.required]],
  });

  mobi_desktop_startdate = {
    theme: "ios",
    cssClass: "whiteCalenderBg",
    select: "single",
    controls: ["calendar"],
    display: "bubble",
    buttons: false,
    onSet: (event, inst) => {
      this.dateChangeHandler(this.txnDetailsFrom, "startdate");
    },
  };
  mobi_desktop_enddate = {
    theme: "ios",
    cssClass: "whiteCalenderBg",
    select: "single",
    controls: ["calendar"],
    display: "bubble",
    buttons: false,
    onSet: (event, inst) => {
      this.dateChangeHandler(this.txnDetailsFrom, "enddate");
    },
  };
  mobis_mobile_startdate = {
    theme: "ios",
    display: "bottom",
    onSet: (event, inst) => {
      this.dateChangeHandler(this.txnDetailsFrom, "startdate");
    },
  };
  mobis_mobile_enddate = {
    theme: "ios",
    display: "bottom",
    onSet: (event, inst) => {
      this.dateChangeHandler(this.txnDetailsFrom, "enddate");
    },
  };

  constructor(
    public utility: UtilityService,
    public formBuilder: FormBuilder,
    public customValidators: CustomValidators
  ) {}

  ngOnInit() {}

  /* Function called on event : Date change through mobiscroll on mobile*/
  dateChangeHandler(form: FormGroup, target: string, deviceType?) {
    this.resetDate(form, target);
  }
  /*
   Function for resetting date : if user selects startdate then auto change the end date.
   If user selects the end date auto select the start date.
   This function is called by both device and mobile.
 */
  resetDate(form: FormGroup, target: string) {
    let startdate = new Date(form.controls["startDate"].value);
    let enddate = new Date(form.controls["endDate"].value);
    let dayDiff = this.getDays(startdate, enddate.setHours(23, 59, 59, 999));
    if (dayDiff > this.timeslap || dayDiff < 0) {
      if (target === "startdate") {
        let incrementedTime = new Date(
          startdate.getFullYear(),
          startdate.getMonth(),
          startdate.getDate() + this.timeslap
        );
        if (incrementedTime.getTime() < new Date().getTime()) {
          form.get("endDate").setValue(incrementedTime);
        } else {
          form.get("endDate").setValue(new Date());
        }
      } else if (target === "enddate") {
        let decrementTime = new Date(
          enddate.getFullYear(),
          enddate.getMonth(),
          enddate.getDate() - this.timeslap
        );
        if (decrementTime.getTime() < new Date().getTime()) {
          form.get("startDate").setValue(decrementTime);
        } else {
          form.get("startDate").setValue(new Date());
        }
      }
    }
  }

  getDays(startDate, endDate) {
    let startTime = new Date(startDate).getTime();
    let endTime = new Date(endDate).getTime();
    let diff = endTime - startTime;
    return Math.floor(diff / 1000 / 60 / 60 / 24);
  }
}
