import { Observable, Subscription, BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Libraries
import * as _ from "underscore";

// Models
import { JackpotFeed } from "src/app/modules/game-groups/models/jackpot/jackpot-feed.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { MainService } from "src/app/modules/shared/services/main.service";

@Injectable({
  providedIn: "root",
})
export class JackpotService {
  // Arrays
  microGamingJackpotFeed?: JackpotFeed[] = [];

  // Others
  mircroGameJackpots?: {
    [key: string]: number;
  } = {};

  // ---------------------------------------------------------//
  // Behaviour Subjects
  public mircoGamingJackpotData: BehaviorSubject<{
    [key: string]: number;
  }> = new BehaviorSubject<{
    [key: string]: number;
  }>(this.mircroGameJackpots);
  public mircoGamingJackpotData$: Observable<{
    [key: string]: number;
  }> = this.mircoGamingJackpotData.asObservable();

  // Subscriptions
  subscription: Subscription;

  constructor(
    private userDetailsService: UserDetailsService,
    private gameGroupsService: GameGroupsService,
    private mainService: MainService
  ) {
    this.getMgsJackpots();

    this.subscription = this.userDetailsService.userProfileUpdated$.subscribe(
      () => this.getMgsJackpots()
    );
  }

  getMgsJackpots(): void {
    this.getJackpotFeed().subscribe((jackpotfeed: JackpotFeed[]) => {
      this.microGamingJackpotFeed = jackpotfeed;

      this.mircroGameJackpots = {};

      if (jackpotfeed.length > 0) {
        this.gameGroupsService
          .getGameGroupGames()
          .subscribe((gamesList: GamePregmatic[]) => {
            let microgamingGames: GamePregmatic[] = _.where(gamesList, {
              gameType: "progressive_slots",
            });

            jackpotfeed.filter((jackpotFilter: JackpotFeed) => {
              let gameName: string = jackpotFilter.friendlyName
                .replace(/[\W_]+/g, " ")
                .trim();

              microgamingGames.filter((filterGamePregmatic: GamePregmatic) => {
                if (
                  filterGamePregmatic.name
                    .toUpperCase()
                    .indexOf(gameName.toUpperCase()) > -1 &&
                  jackpotFilter.gamePayId == 0
                ) {
                  this.mircroGameJackpots[filterGamePregmatic.gameCode] =
                    jackpotFilter.startAtValue;
                }
              });
            });

            this.broadCastMicroGamingJackpotSuccess();
          });
      }
    });
  }

  broadCastMicroGamingJackpotSuccess(): void {
    this.mircoGamingJackpotData.next(this.mircroGameJackpots);
  }

  getJackpotFeed(): Observable<JackpotFeed[]> {
    return this.mainService
      .microGamingJackpotFeed(this.userDetailsService.getCurrencyCode())
      .pipe(
        map((jackpotFeed: JackpotFeed[]) => {
          return jackpotFeed;
        })
      );
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
