import { Component, Input, SimpleChanges } from "@angular/core";

// Libraries
import { LiveScheduledStream } from "livespins/@livespins";

@Component({
  selector: "app-csn-livespins-schedule-room",
  templateUrl: "./csn-livespins-schedule-room.component.html",
  styleUrls: ["./csn-livespins-schedule-room.component.scss"],
})
export class CsnLivespinsScheduleRoomComponent {
  @Input() livespinsScheduleRoom?: LiveScheduledStream;
  
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes["livespinsScheduleRoom"].currentValue && (changes["livespinsScheduleRoom"].previousValue != changes["livespinsScheduleRoom"].currentValue)) {
      this.livespinsScheduleRoom = changes["livespinsScheduleRoom"].currentValue;
    }
  }
}
