import { Component, OnDestroy } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Configurations
import { mixPanelEventsConfigurations } from "src/app/configurations/main.configurations";

// Services
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { DeepLinksService } from "src/app/modules/shared/services/deep-links.service";
import { MixPanelService } from "src/app/modules/shared/services/mix-panel.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { LoginService } from "src/app/modules/auth/services/login.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { CustomValidators } from "src/app/utility/custom-validator";

@Component({
  selector: "app-login-from",
  templateUrl: "./login-from.component.html",
  styleUrls: ["./login-from.component.scss"],
})
export class LoginFromComponent extends FormValidationComponent
  implements OnDestroy {
  serverError;
  langCode: string;
  isPassword: boolean = true;
  langCodeSubscription: Subscription;
  doLoginSubScription: Subscription;
  isButtonLoader: boolean = false;
  userKycLevelSub: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private customValidators: CustomValidators,
    private commonService: CommonService,
    private loginService: LoginService,
    private gamePlayService: GamePlayService,
    private router: Router,
    private translationService: TranslationService,
    private registrationService: RegistrationService,
    private cashierService: CashierService,
    private mixpanelService: MixPanelService,
    private utils: UtilityService,
    private deepLinkService: DeepLinksService
  ) {
    super(utils);
    this.langCode = this.utils.getLangCode();
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
  }

  loginForm = this.formBuilder.group({
    txtEmail: [
      "",
      [
        this.customValidators.validateUniqueness(
          "txtEmail",
          this.commonService,
          false
        ),
      ],
    ],
    txtPassword: ["", [this.customValidators.required]],
  });

  doLogin() {
    this.isButtonLoader = true;
    this.serverError = undefined;
    if (this.loginForm.valid) {
      const credentials = {
        emailId: this.loginForm.controls["txtEmail"].value.trim(),
        password: this.loginForm.controls["txtPassword"].value,
      };
      this.doLoginSubScription = this.loginService
        .doLogin(credentials)
        .subscribe((loginData) => {
          this.isButtonLoader = false;
          if (loginData && loginData["success"] === true) {
            this.commonService.broadCastActiveAcountMenu("");
            this.deepLinkService.navigateAfterLogin();
          } else {
            this.loginFailure(loginData);
          }
        });
    }
  }

  loginFailure(loginData) {
    this.serverError = this.translationService.instant("common.error44");
    if (loginData && loginData["errors"]) {
      if (loginData["errors"]["accountLocked"]) {
        let msg = loginData["errors"]["accountLocked"];
        /**Below logic/work around is required to remove crossbrowser issue in safari, firefox & windows */
        let accountLockedDate = new Date(
          Date.parse(
            msg
              .substr(msg.indexOf(":") + 1)
              .trim()
              .replace(" ", "T") + "+02:00"
          )
        );
        const errorMessage = this.translationService.instant(
          "logincomponent.error_rg_cool_off"
        );
        // this.serverError = errorMessage + new Date(loginData["errors"]["accountLocked"].substr(loginData["errors"]["accountLocked"].indexOf(":")));
        this.serverError = errorMessage + accountLockedDate;
        return;
      }
      let errorCode;
      if (loginData["errors"]["errorCode"]) {
        errorCode = loginData["errors"]["errorCode"];
      } else if (loginData["errors"]["internalErrorCode"]) {
        errorCode = loginData["errors"]["internalErrorCode"];
      }
      if (errorCode) {
        this.displayLoginErrorMessage(errorCode);
      }
    }
  }

  displayLoginErrorMessage(errorCode) {
    switch (errorCode) {
      case "100152":
        this.serverError = this.translationService.instant(
          "logincomponent.error_100152_loginAccountdisabled"
        );
        break;
      case "100157":
        this.serverError = this.translationService.instant(
          "logincomponent.error_100157_rg_closed"
        );
        break;
      case "100173":
        this.serverError = this.translationService.instant(
          "logincomponent.error_100173_rg_cool_off"
        );
        break;
      case "100174":
        this.serverError = this.translationService.instant(
          "logincomponent.error_100174_login_attempts_exceeded"
        );
        break;
      case 100161:
        this.serverError = this.translationService.instant(
          "logincomponent.error_100161_wrong_password"
        );
        break;
      case 100166:
        this.serverError = this.translationService.instant(
          "logincomponent.error_100166_wrong_emailid"
        );
        break;

      default:
        this.serverError = this.translationService.instant("common.error44");
    }
  }

  closeComponent() {
    if (this.commonService.getNavigateAfterLogin()) {
      this.commonService.setNavigateAfterLogin(undefined);
    }
    this.utils.closeAccountComponent("");
  }

  gotoForgotPage() {
    this.closeComponent();
    this.commonService.broadCastActiveAcountMenu("forgot-password");
  }

  openRegistrationPopup() {
    this.closeComponent();
    this.registrationService.openRegistrationHanlder();
  }

  sendMixpanelEvent(gameDetails) {
    if (Object.keys(gameDetails).length > 0) {
      let eventProperties = {
        name: gameDetails["path"],
        playType: "realgame",
        location: gameDetails["gameGroupName"],
        category: gameDetails["lobbyName"],
      };
      if (this.mixpanelService.getVerticalByUrl()) {
        eventProperties["vertical"] = this.mixpanelService.getVerticalByUrl();
      }
      this.mixpanelService.trackMixpanelEvents(
        mixPanelEventsConfigurations.launched_game,
        eventProperties
      );
    }
  }

  ngOnDestroy() {
    let navigateData = this.commonService.getNavigateAfterLogin();
    if (
      navigateData &&
      (navigateData["type"] === "view" ||
        (navigateData["type"] === "url" &&
          navigateData["data"]["navigateTo"] != "promotions"))
    ) {
      this.commonService.setNavigateAfterLogin(undefined);
    }
    this.langCodeSubscription.add(this.doLoginSubScription);
    this.langCodeSubscription.unsubscribe();
  }
}
