import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

// Services
import { CashbackPromoService } from "src/app/modules/rewards/services/cashback-promo.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SocketService } from "src/app/modules/shared/services/socket.service";
import { MainService } from "src/app/modules/shared/services/main.service";
import { GtmService } from "src/app/modules/shared/services/gtm.service";

@Injectable({
  providedIn: "root",
})
export class PayNPlayCashierService {
  activeDepositBonus: any = {};
  userLoggedTime;
  userCountryCode;
  cashOutLimits = {
    minLimit: 0,
    maxLimit: 0,
  };

  private isUserTractionFetchingRequired: string = "";
  constructor(
    private gtmService: GtmService,
    private socketService: SocketService,
    private mainService: MainService,
    private commonService: CommonService,
    private cashBackPromoService: CashbackPromoService
  ) {}

  private initiateLogin = new Subject<any>();
  public initiateLogin$ = this.initiateLogin.asObservable();

  broadCastInitiateLogin(txnType) {
    this.initiateLogin.next(txnType);
  }

  private initiateDeposit = new Subject<any>();
  public initiateDespoit$ = this.initiateDeposit.asObservable();

  broadCastInitiateDeposit(data) {
    this.initiateDeposit.next(data);
  }

  private initiateWithdraw = new Subject<any>();
  public initiateWithdraw$ = this.initiateWithdraw.asObservable();

  broadCastInitiateWithdraw() {
    this.initiateWithdraw.next();
  }

  private processUserTransaction = new Subject<any>();
  public processUserTransaction$ = this.processUserTransaction.asObservable();

  broadCastProcessUserTranaction(type) {
    this.setUserTranactionRequired(type);
    this.processUserTransaction.next(type);
  }

  private zimplerProcessCompleted = new Subject<any>();
  public zimplerProcessCompleted$ = this.zimplerProcessCompleted.asObservable();

  broadCastZimplerProcessCompleted() {
    this.zimplerProcessCompleted.next();
  }

  setUserTranactionRequired(type) {
    this.isUserTractionFetchingRequired = type;
  }

  getUserTranactionRequired() {
    return this.isUserTractionFetchingRequired;
  }
  setUserLoggedTime(time) {
    this.userLoggedTime = time;
  }
  getUserLoggedTime() {
    return this.userLoggedTime;
  }

  depositLimits = {
    minLimit: 0,
    maxLimit: 0,
  };
  getPNPMinMaxTxnLimits(minMaxRequestData): Observable<any> {
    return this.mainService.getPNPMinMaxTxnLimits(minMaxRequestData).pipe(
      map((response) => {
        if (
          response["status"] === "SUCCESS" &&
          response["minMaxTxnLimitsData"] &&
          response["minMaxTxnLimitsData"][0]
        ) {
          this.depositLimits = {
            minLimit: response["minMaxTxnLimitsData"][0]["minTxnLimit"],
            maxLimit: response["minMaxTxnLimitsData"][0]["maxTxnLimit"],
          };
        }
        return this.depositLimits;
      })
    );
  }

  getCashoutTxnLimits(requestData): Observable<any> {
    return this.mainService.getCashoutTxnLimits(requestData).pipe(
      map((response) => {
        if (
          response &&
          response["cashierData"] &&
          response["cashierData"]["cashoutMethods"]
        ) {
          let cashoutData =
            response["cashierData"]["cashoutMethods"]["ZIMPLER_DIRECT"];
          this.cashOutLimits = {
            minLimit: parseInt(cashoutData["limits"]["minTxnLimit"]),
            maxLimit: parseInt(cashoutData["limits"]["maxTxnLimit"]),
          };
        }
        return this.cashOutLimits;
      })
    );
  }

  zimplerPayAndPlayInit(initRequestData): Observable<any> {
    return this.mainService.zimplerPayAndPlayInit(initRequestData);
  }

  getPNPUserFromTransaction(transactionData): Observable<any> {
    return this.mainService.getPNPUserFromTransaction(transactionData);
  }

  quickRegisterZimpler(transactionData): Observable<any> {
    return this.mainService.quickRegisterZimpler(transactionData);
  }
  getPNPThirdPartyLogin(transactionData): Observable<any> {
    return this.mainService.getPNPThirdPartyLogin(transactionData).pipe(
      map((response) => {
        if (response && response["status"] === "SUCCESS") {
          this.socketService.connectToScoket(
            response["pragmaticUrl"],
            response["pragmaticSessionId"],
            true
          );
          this.userLoggedTime = new Date();
          localStorage.setItem("loggedInTime", this.userLoggedTime);
          this.pushGtmLoginData(response);
          localStorage.setItem("hir_user", "true");
          this.commonService.broadCastLoginSuccess(true);
          if (response["lastAuthTime"]) {
            this.cashBackPromoService.setUserLastAuthTime(
              response["lastAuthTime"]
            );
          }
        }
        return response;
      })
    );
  }

  pushGtmLoginData(loginResponse) {
    Promise.resolve(this.commonService.getProfileBalanceCurrency()).then(
      (profileData) => {
        if (profileData) {
          if (profileData["profile"]) {
            loginResponse["countryCode"] = profileData["profile"]["country"];
          }
          this.gtmService.trackLoginGTMEvent("login-success", loginResponse);
        }
      }
    );
  }

  zimplerWithdrawInit(withdrawInitData) {
    return this.mainService.zimplerWithdrawInit(withdrawInitData);
  }

  getUserPSPTranstionStatus(requestData): Observable<any> {
    return this.mainService.getUserPSPTranstionStatus(requestData);
  }

  getPendingCashOuts(pendingWithdrawalObject): Observable<any> {
    return this.mainService.getPendingCashOuts(pendingWithdrawalObject);
  }

  revertWithdrawal(requestData): Observable<any> {
    return this.mainService.revertWithdrawal(requestData);
  }
}
