import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from "@angular/router";
import { Component, HostListener, OnDestroy } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

// Constants
import { UserFlowTypes } from "src/app/constants/costants";

// Environments
import { environment } from "src/environments/environment";

// Services
import { CustomBannerService } from "src/app/modules/banner/services/custom-banner.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { NewsTickerService } from "src/app/modules/news/services/news-ticker.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { LoginService } from "src/app/modules/auth/services/login.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnDestroy {
  isLoggedIn: boolean = false;
  isSticky: boolean = false;
  langCodeSubscription: Subscription;
  langCode: any;
  showSearchResult: boolean = false;
  windowType: string;
  hidden: boolean = false;
  bannerAvailabeSub: Subscription;
  isBannerAvialable: any = false;
  routerSub: Subscription;
  isSearchRequired: boolean = true;
  isLandingPage: boolean = false;
  UserFlowTypes: typeof UserFlowTypes;
  zimplerMarkets: string[];
  isNewsTickerBannerAvailable: any = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  @HostListener("window:resize") onResize() {
    this.getWindowType();
  }

  @HostListener("window:orientationchange") onRotate() {
    this.getWindowType();
  }

  constructor(
    private commonService: CommonService,
    private translationService: TranslationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utility: UtilityService,
    private customBannerService: CustomBannerService,
    private loginService: LoginService,
    private newsTickerService: NewsTickerService
  ) {
    this.UserFlowTypes = UserFlowTypes;
    this.zimplerMarkets = environment.zimplerMarkets;
    this.getWindowType();
    this.enableSearch();
    this.storageHandlers();
    this.routerSub = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.enableSearch();
      }
      if (event instanceof NavigationStart) {
        this.hidden = event.url.includes("livespins");
      }
    });
    /**
     * Below Logic is to get the affiliate information form URL which is passed by
     * affiliate system
     * & we save it in cookies & pass it to BE when user get registered after navigating
     * from affiliate system
     */
    this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      let affid = queryParams.get("affid");
      let tracker = queryParams.get("trackerid");
      let banner = queryParams.get("bannerid");
      this.utility.setAffCookies(affid, tracker, banner);
    });

    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
    this.bannerAvailabeSub = this.customBannerService.isBannerAvailable$.subscribe(
      (flag) => {
        this.isBannerAvialable = flag;
      }
    );

    this.newsTickerService.isNewsTickerBannerAvailable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((flag) => {
        this.isNewsTickerBannerAvailable = flag;
      });
    /**we call this method onfresh of page...To check user login
     * session exist or not and bases on it's response we land user on
     * login state or logout state  */
    this.getLoginStatus();
  }

  /**
   * This Function decides under which route search should be enabled in header
   *  section, Basically search is enable on all routes except casino, live casino
   *  reset-password & unsubscribe
   *
   * where on casino & live casino we have seach which is at different position
   * so we call search reusable component with in casino & live casino compoenets
   */

  enableSearch() {
    const urlSegments = window.location.pathname
      ? window.location.pathname.split("/")
      : "";
    if (
      urlSegments &&
      (urlSegments.length <= 2 ||
        (urlSegments.length === 3 &&
          (urlSegments[2] === "casino" ||
            urlSegments[2] === "live-casino" ||
            urlSegments[2] === "reset-password" ||
            urlSegments[2] === "unsubscribe")))
    ) {
      this.isSearchRequired = false;
    } else {
      this.isSearchRequired = true;
    }
  }

  getLoginStatus() {
    Promise.resolve(this.commonService.getLoginStatus()).then((data) => {});
  }

  navigateToHome() {
    if (this.utility.isUserLoggedIn() || this.utility.isPnpFlow()) {
      this.router.navigate([this.langCode + "/casino"]);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      this.router.navigate([this.langCode]);
    }
  }

  showSearchResults() {
    this.showSearchResult = true;
    this.utility.addClassToAppBody("overflow-hidden");
  }

  closeSearchResults() {
    this.showSearchResult = false;
    this.utility.removeClassFromAppBody("overflow-hidden");
  }

  navigateTo(path?) {
    if (path === "login") {
      this.utility.initiateLogin();
    } else if (path) {
      this.router.navigate([this.langCode + "/" + path]);
    } else {
      this.router.navigate([this.langCode + "/casino"]);
    }
  }

  getWindowType() {
    var ww = document.body.clientWidth;
    if (ww <= 1023) {
      this.windowType = "mobile";
    } else {
      this.windowType = "desktop";
    }
  }

  // make logo visible on landing page
  checkIsLandingPage() {
    const urlSegments = window.location.pathname
      ? window.location.pathname.split("/")
      : "";
    if (urlSegments && urlSegments.length <= 2) {
      this.isLandingPage = true;
    } else {
      this.isLandingPage = false;
    }
  }

  storageHandlers() {
    /**
     * This even will listen for removal of localstorage item in new Tabs
     * and logout user from all tabs
     */
    window.addEventListener("storage", (e) => {
      if (e.type === "storage" && e.key === "hir_user" && e.newValue === null) {
        this.commonService.doLogout();
      }
      /**
       * This will trigger login in all other Tabs
       */
      if (
        e.type === "storage" &&
        e.key === "hir_user" &&
        e.newValue === "true"
      ) {
        if (
          window.regily &&
          window.regily.signUp &&
          window.regily.signUp.isOpen
        ) {
          window.regily.signUp.close();
        }
        this.router.navigate([`${this.langCode}/casino`]);
        this.commonService.broadCastLoginSuccess(true);
        if (this.commonService.getActiveAccountMenu()) {
          this.commonService.broadCastActiveAcountMenu("");
        }
        this.loginService.navigateToUserProfileLang();
      }
    });
  } 

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    if (this.langCodeSubscription) this.langCodeSubscription.unsubscribe();
    if (this.bannerAvailabeSub) this.bannerAvailabeSub.unsubscribe();
  }
}
