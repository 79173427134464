import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  ViewEncapsulation,
  SimpleChange,
  EventEmitter,
  HostListener,
  ElementRef,
  Component,
  OnDestroy,
  OnChanges,
  ViewChild,
  Output,
  Input,
} from "@angular/core";

// Constants
import { UserFlowTypes } from "src/app/constants/costants";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import Player from "@vimeo/player";

// Services
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { CustomBannerService } from "src/app/modules/banner/services/custom-banner.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-custom-banner",
  templateUrl: "./custom-banner.component.html",
  styleUrls: ["./custom-banner.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CustomBannerComponent implements OnChanges, OnDestroy {
  @ViewChild("banner", { static: false }) cashier: ElementRef;
  @Input() zoneId;
  @Input() callingFrom;
  @Output() callOnBannerApiSuccess: EventEmitter<any> = new EventEmitter();
  activeBanners: any;
  langCode: string;
  isLoading: boolean = false;
  logOutCompleteBsSubscription: Subscription;
  loginCompleteBsSubscription: Subscription;
  langCodeSubscription: Subscription;
  isLoggedIn: any;
  reloadBannersSub: Subscription;
  freeSpinsTxt;

  userBalanceSubscription: Subscription;
  userTotalBalance: number = 0;
  currencyCodeSubscription: Subscription;
  currencyCode: string;
  UserFlowTypes: typeof UserFlowTypes;
  zimplerMarkets: string[];
  showBannerFromAffiliate: boolean = false;
  getAffiliateValue;
  mediaUrl: string = environment.mediaUrl;
  affiliateOfferJsonText;
  deviceType = "desktop";
  profileDetails: any;
  userAffiliateValue;

  @HostListener("window:resize") onResize() {
    this.checkDeviceType();
  }

  constructor(
    private router: Router,
    private customBannerService: CustomBannerService,
    private commonService: CommonService,
    private registrationService: RegistrationService,
    private utility: UtilityService,
    private emitterService: EmitterService,
    private translationService: TranslationService,
    private gamePlayService: GamePlayService,
    private userDetailsService: UserDetailsService
  ) {
    this.UserFlowTypes = UserFlowTypes;
    this.zimplerMarkets = environment.zimplerMarkets;
    this.userDetailsService.userProfileUpdated$.subscribe((data) => {
      if (!this.utility.isPnpFlow()) {
        /**Instead of calling inside login/logout sucess event, we call in side
         * profile update because this reloadbanner depends on usercountry(for login)
         * or geocountry(for logout)
         * Note: This event will be triggered automatically after
         * login & logout
         */
        this.loadAffiliateBanner(false);
        this.reloadBanner(true);
      }
    });
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteBsSubscription = this.commonService.loginComplete.subscribe(
      (isloggedIn) => {
        this.isLoggedIn = isloggedIn;
      }
    );
    this.logOutCompleteBsSubscription = this.commonService.logOutComplete.subscribe(
      (isloggedOut) => {
        this.isLoggedIn = false;
        this.loadAffiliateBanner(false);
      }
    );
    this.langCode = this.utility.getLangCode();
    this.langCodeSubscription = this.translationService.langCode$.subscribe(
      (langCode) => {
        this.langCode = langCode;
        this.loadAffiliateBanner(false);
      }
    );
    this.reloadBannersSub = this.emitterService.depositSuccessful$.subscribe(
      (flag) => {
        if (!this.utility.isPnpFlow()) {
          this.reloadBanner(true, true);
          this.reloadAffiliateBanner();
        }
      }
    );

    if (this.userDetailsService.getUserTotalBalance()) {
      this.userTotalBalance = this.userDetailsService.getUserTotalBalance();
    }
    this.userBalanceSubscription = this.userDetailsService.userBalanceUpdated$.subscribe(
      (balanceUpdateMessage) => {
        if (balanceUpdateMessage == "UserBalanceUpdated") {
          this.userTotalBalance = this.userDetailsService.getUserTotalBalance();
          this.utility.toggleBannerDepositBtn(this.userTotalBalance);
        }
      }
    );
    this.currencyCodeSubscription = this.userDetailsService.currencyCodeSb$.subscribe(
      (curCode) => {
        this.currencyCode = curCode;
      }
    );
    this.loadAffiliateBanner(false);
  }

  reloadAffiliateBanner() {
    if (
      this.userDetailsService.getUserProfileDetails() &&
      this.userDetailsService.getUserProfileDetails().userStatus === "play"
    ) {
      setTimeout(() => {
        this.loadAffiliateBanner(true);
      }, 5000);
    }
  }

  openRegistrationPopup() {
    this.registrationService.openRegistrationHanlder();
  }

  swiperBanner: SwiperConfigInterface = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    loop: false,
    resistanceRatio: 0,
    autoplay: {
      delay: 30000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".banner-pagination",
      clickable: true,
    },
    breakpoints: {
      767: {
        pagination: false,
      },
    },
  };

  reloadBanner(isforce = false, refreshProfileCache?) {
    if (this.customBannerService.getIsProfileRefreshRequired()) {
      refreshProfileCache = true;
      isforce = true;
      this.customBannerService.setIsProfileRefreshRequired(false);
    }
    if (this.zoneId) {
      const queryReq = refreshProfileCache
        ? { zoneId: this.zoneId, refreshProfileCache: true }
        : { zoneId: this.zoneId };
      this.getBannersByZoneId(queryReq, isforce);
    }
  }
  /**TODO */
  setZoneId() {
    const lobbies = this.utility.getBrandBasedConfig("lobbyByPageName");
    if (this.zoneId) {
      const zoneId = this.zoneId;
      if (
        lobbies &&
        lobbies["casinopage"].indexOf(zoneId.replace("b_", "")) < 0
      ) {
        this.zoneId = "b_casino";
      }
    }
  }

  loadAffiliateBanner(refreshProfileCache?) {
    if (this.isLoggedIn) {
      this.getUserProfileData(refreshProfileCache);
    } else {
      this.getUserAffiliateValue();
    }
  }

  getUserProfileData(refreshProfileCache?) {
    if (
      !refreshProfileCache &&
      this.userDetailsService.getUserProfileDetails()
    ) {
      this.getAffiliateValue = undefined;
      this.profileDetails = this.userDetailsService.getUserProfileDetails();
      this.getUserAffiliateValue();
    } else {
      Promise.resolve(this.commonService.getProfileBalanceCurrency()).then(
        (data) => {
          if (data && data["profile"]) {
            this.getAffiliateValue = undefined;
            this.profileDetails = data["profile"];
            this.getUserAffiliateValue();
          } else {
            this.showBannerFromAffiliate = false;
          }
        }
      );
    }
  }

  getUserAffiliateValue() {
    let affilateLang = this.utility.getAffCookies("affiliateLang");
    let affiliateValuesList = this.translationService
      .instant("affiliateids.affiliateValue")
      .split(",");
    if (this.utility.getAffCookies("affId")) {
      this.getAffiliateValue = this.utility
        .getAffCookies("affId")
        .split("_")[0];
    } else if (
      this.profileDetails &&
      this.profileDetails["userStatus"] === "play" &&
      this.profileDetails["affiliateLinkID"]
    ) {
      this.getAffiliateValue = this.profileDetails["affiliateLinkID"].split(
        "_"
      )[0];
    }
    console.log("===>et affiliate value", this.getAffiliateValue);
    if (
      (environment.zimplerMarkets.indexOf(this.utility.getLangCode()) <= -1 &&
        this.getAffiliateValue &&
        affilateLang &&
        affiliateValuesList &&
        affiliateValuesList.indexOf(this.getAffiliateValue) > -1 &&
        affilateLang === this.langCode) ||
      (this.isLoggedIn && this.getAffiliateValue)
    ) {
      this.showBannerFromAffiliate = true;
      this.renderAffiliteJsonOfferTextAsHtml();
    } else {
      this.showBannerFromAffiliate = false;
    }
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (
      changes["zoneId"] &&
      changes["zoneId"].previousValue != changes["zoneId"].currentValue
    ) {
      this.activeBanners = undefined;
      this.zoneId = changes["zoneId"].currentValue;
      this.zoneId = "b_" + this.zoneId;
      this.reloadBanner();
    }
    if (changes["callingFrom"]) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
  }

  getBannersByZoneId(bannerReqData, isforce?) {
    this.activeBanners = undefined;
    this.isLoading = true;

    Promise.resolve(
      this.customBannerService.getBannersp(bannerReqData, isforce)
    ).then((banners) => {
      this.isLoading = false;
      if (banners && banners.length > 0) {
        this.activeBanners = banners;
        this.customBannerService.broadCastIsBannerAvialable(true);
        if (this.activeBanners && this.activeBanners.length > 0) {
          setTimeout(() => {
            this.activeBanners.forEach((banner) => {
              banner.content = this.utility.replaceAll(banner.content, {
                "{{site_url}}": environment.siteUrl,
                "{{media_url}}": environment.mediaUrl,
              });
              /**adding click event to whole banner container inorder to listen all click
               * events within the banner...
               */

              setTimeout(() => {
                this.utility.toggleCMSButtons();
                this.utility.toggleBannerDepositBtn(this.userTotalBalance);
                const iframe = document.querySelector(
                  ".dynamicBannerContainer-" +
                    banner["id"] +
                    " iframe.videoIframe"
                );

                if (iframe) {
                  const player = new Player(iframe, {
                    background: 1,
                    muted: 1,
                    loop: 0,
                    title: "promotional-banners",
                  });
                }
                if (this.callingFrom === "allgames-page") {
                  this.callOnBannerApiSuccess.emit();
                }
              });
            });
          });
        }
        this.customBannerService.broadCastIsBannerAvialable(true);
      } else {
        this.activeBanners = [];
        this.customBannerService.broadCastIsBannerAvialable(false);
      }
    });
  }

  joinBanners(event) {
    let data,
      target = <HTMLInputElement>event.target,
      routerLink = "";
    routerLink = target.getAttribute("routerLink");
    if (routerLink === "deposit" && target.getAttribute("bonusCode")) {
      data = { bonusCode: target.getAttribute("bonusCode") };
    }
    /* * we are using this registration routing here because of circular
     * dependency
     * */
    if (
      routerLink &&
      (routerLink === "registration" || routerLink === "register")
    ) {
      if (!this.isLoggedIn && !this.utility.isPnpFlow()) {
        this.registrationService.openRegistrationHanlder();
      } else {
        this.utility.initiateDeposit();
      }
    } else if (routerLink) {
      if (
        routerLink &&
        this.utility.getActiveLobby(routerLink.split("/")[1]) === "game"
      ) {
        this.gamePlayService.clearGameWindowData();
      }
      /**
       * Below Utility Handler decide routerLink event action on banner
       * like registration button , login btn, game play btn deposit etc...
       */
      this.utility.cmsRoutingHandler(routerLink, data);
    }
  }

  renderAffiliteJsonOfferTextAsHtml() {
    this.affiliateOfferJsonText = this.translationService.instant(
      "affiliate." + this.getAffiliateValue + "_offer"
    );
  }

  checkDeviceType() {
    let ww = document.body.clientWidth;
    if (ww >= 1023) {
      this.deviceType = "desktop";
    } else {
      this.deviceType = "mobile";
    }
    if (
      (ww >= 500 && window.matchMedia("(orientation: landscape)").matches) ||
      (ww >= 768 && window.matchMedia("(orientation: portrait)").matches)
    ) {
      this.deviceType = "desktop";
    }
  }

  ngOnInit() {
    this.checkDeviceType();
  }

  ngOnDestroy() {
    this.loginCompleteBsSubscription.add(this.langCodeSubscription);
    this.loginCompleteBsSubscription.add(this.logOutCompleteBsSubscription);
    this.reloadBannersSub.unsubscribe();
    this.loginCompleteBsSubscription.unsubscribe();
    this.currencyCodeSubscription.unsubscribe();
    this.userBalanceSubscription.unsubscribe();
  }
}
