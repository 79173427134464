import { TitleCasePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";

// Models
import { UserData } from "src/app/modules/user/models/user-data.model";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";
import { languageToIntercomConfigurations } from "src/app/configurations/main.configurations";

declare global {
  interface Window {
    Intercom: Intercom_.IntercomStatic;
  }
}

@Injectable({
  providedIn: "root",
})
export class LiveChatService {
  // Objects
  profileDetails: UserData;

  // Subscriptions
  private userDataSubscription: Subscription;
  private subscription: Subscription;

  constructor(
    private userDetailsService: UserDetailsService,
    private rewardsService: RewardsService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private titleCasePipe: TitleCasePipe
  ) {
    this.subscription = this.commonService.loginComplete$.subscribe(() => {
      this.onUpdateUserDetailsAfterLogin();
    });
  }

  // -----------------------------------------------------------------
  // Get Methods
  getTitleCase(value: string): string {
    return this.titleCasePipe.transform(value);
  }

  // -----------------------------------------------------------------
  // Set Methods
  async onInitializeChat(defaultLanguage?: string): Promise<void> {
    if (window.Intercom) {
      await this.bootChat(defaultLanguage);
    }
  }

  showChat(): void {
    if (window.Intercom) {
      window.Intercom("show");
    }
  }

  private async bootChat(defaultLanguage?: string): Promise<void> { 
    const profileData = await this.onGetUserProfileData();
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "ugjtguoq",
      market: this.commonService.getCountryCode(),
      language_override: languageToIntercomConfigurations[defaultLanguage],
      ...(profileData ? this.convertUserDataToIntercomUserData(profileData) : {}),
    });
  }

  private convertUserDataToIntercomUserData(profileData: UserData): Intercom_.IntercomSettings {
    return {
      name:
        profileData.firstName &&
        `${this.getTitleCase(profileData.firstName)} ${this.getTitleCase(profileData.lastName)}`,
      email: profileData.email,
      user_id: profileData.playerID,
      user_hash: profileData.playerID,
      current_level: this.rewardsService.getUserCurrentLevelId(),
      login_status: "logged In",
      market: profileData.countryCode || profileData.country || this.commonService.getCountryCode(),
      language_override: languageToIntercomConfigurations[profileData.language]
    };
  }

  private onGetUserProfileData(): Promise<UserData> {
    if (!this.utilityService.isUserLoggedIn()) {
      return Promise.resolve(undefined);
    }
    if (this.userDetailsService.getUserProfileDetails()) {
      return Promise.resolve(this.userDetailsService.getUserProfileDetails());
    } else {
      return this.commonService.getUserData().toPromise();
    }
  }

  private async onUpdateUserDetailsAfterLogin(): Promise<void> {
    const profileDetails = await this.commonService.getUserData().toPromise();
    this.userDetailsService.setUserProfileDetails(profileDetails);
    this.onSetUserDetails();
  }

  private async onSetUserDetails(): Promise<void> {
    /*
      Please lines of code is used to set customer name & customer email Id
      which will be auto populated & visisble directly when customer
      opens chat
    */
    const profileData = await this.onGetUserProfileData();
    if (profileData) {
      this.onUpdateLoginSessionData(profileData);
    }
  }

  /*
    This set of code is used to create a user session on live chat,
    which help used in identifying  user session along
    with back on live chat back office
  */
  private onUpdateLoginSessionData(profileData: UserData): void {
    if (window.Intercom) {
      window.Intercom("update", this.convertUserDataToIntercomUserData(profileData));
    }
  }

  onUpdateLogoutSessionData(): void {
    if (window.Intercom) {
      window.Intercom("shutdown");
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.userDataSubscription) this.userDataSubscription.unsubscribe();

    if (this.subscription) this.subscription.unsubscribe();
  }
}
