import { LeaderBoardUser } from "./../models/leader-board/leader-board.model";
import { TranslationService } from "../services/translation.service";
import { Pipe, PipeTransform } from "@angular/core";
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Pipe({
  name: "leaderBoardUserName"
})
export class LeaderBoardUserNamePipe implements PipeTransform {
  constructor(private translationService: TranslationService) {}

  transform(leaderBoardUser: LeaderBoardUser, loggedUserId: string): string {
    if (!leaderBoardUser) return;
    const isLoggedUser = leaderBoardUser.id === loggedUserId;
    return isLoggedUser
      ? this.getTranslatedLoggedUserName().toUpperCase()
      : leaderBoardUser.userName;
  }

  getTranslatedLoggedUserName(): string {
    return this.translationService.instant(marker("general.you"));
  }
}
