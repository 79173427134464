import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";

@Component({
  selector: "app-cookie-msg",
  templateUrl: "./cookie-msg.component.html",
  styleUrls: ["./cookie-msg.component.scss"],
})
export class CookieMsgComponent implements OnInit {
  isButtonLoader: boolean = false;
  isPopupLoader: boolean = false;
  cookieMsgPopup: boolean = true;
  isPageAvailable: any;
  langCode;
  isCookieExist;

  constructor(
    private translationService: TranslationService,
    private router: Router
  ) {
    this.translationService.langCodeSb$.subscribe((langcode) => {
      this.langCode = langcode;
    });
  }

  ngOnInit() {
    this.isCookieExist = this.getCookieName("HIRCookiePolicyAccepted");
    if (this.isCookieExist) {
      this.cookieMsgPopup = false;
    }
  }

  openCookiePolicy() {
    let urlPath = this.translationService.instant("url.cookies-policy");
    this.isPopupLoader = true;
    this.router.navigate([this.langCode + "/" + urlPath]);
  }

  getCookieName(name) {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      if (name == cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  acceptedCookiePolicy() {
    this.cookieMsgPopup = false;
    this.isPageAvailable = false;
    this.setCookieExpiry();
  }

  setCookieExpiry() {
    var currentDate = new Date();
    currentDate.setTime(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);
    var expires = "expires=" + currentDate.toUTCString();
    document.cookie =
      "HIRCookiePolicyAccepted" + "=" + "true" + ";" + expires + ";path=/";
  }
}
