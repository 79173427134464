import { SimpleChange, Component, OnChanges, Input } from "@angular/core";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";

@Component({
  selector: "app-brand-promotion",
  templateUrl: "./brand-promotion.component.html",
  styleUrls: ["./brand-promotion.component.scss"],
})
export class BrandPromotionComponent implements OnChanges {
  @Input() callingFrom;
  videoData = {
    externalAsset: "",
  };
  constructor(private translationService: TranslationService) {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes["callingFrom"]) {
      this.callingFrom = changes["callingFrom"].currentValue;
      this.setExternalAsset();
    }
  }

  setExternalAsset() {
    if (this.callingFrom === "landing-page") {
      this.videoData["externalAsset"] = this.translationService.instant(
        "configdata.landingpage-videoId"
      );
    } else if (this.callingFrom === "app-component") {
      this.videoData["externalAsset"] = this.translationService.instant(
        "configdata.afterlogin-videoId"
      );
    }
  }
}
