import { Subscription } from "rxjs";
import {
  ViewEncapsulation,
  AfterViewInit,
  HostListener,
  Component,
  OnDestroy,
} from "@angular/core";

// Constants
import { UserFlowTypes } from "src/app/constants/costants";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { FilteredGamesBy } from "src/app/modules/game-groups/models/games/filtered-games-by.model";
import { LobbyGameGroup } from "src/app/modules/game-groups/models/lobby/lobby-game-group.model";
import { LobbyPregmatic } from "src/app/modules/game-groups/models/lobby/lobby.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Pipes
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { NewsTickerService } from "src/app/modules/news/services/news-ticker.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-live-casino",
  templateUrl: "./live-casino.component.html",
  styleUrls: ["./live-casino.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LiveCasinoComponent implements AfterViewInit, OnDestroy {
  // Strings
  activeLobbyName: string = "";
  languageCode: string = "";

  // Booleans
  isLastPlayedLiveGamesExist: boolean = false;
  isNewsTickerBannerAvailable: boolean = true;
  isLoggedIn: boolean = false;
  isLoading: boolean = false;

  // Arrays
  zimplerMarketsList: string[] = environment.zimplerMarkets;
  gameGGroupfilterByLobbyList: LobbyGameGroup[] = [];
  lobbyGameGroupsList: LobbyPregmatic[] = [];
  finalLobbyGamesList: GamePregmatic[] = [];
  finalGamesList: GamePregmatic[] = [];
  lastPlayedGamesList: number[] = [];
  gamesList: GamePregmatic[] = [];

  // Enums
  windowType: "desktop" | "mobile" = "desktop";
  UserFlowTypes = UserFlowTypes;

  // Node Timeout
  timeout: NodeJS.Timer;

  // Objects
  activeLobbyData: LobbyPregmatic | null;

  // Others
  activeLobbyProviderData:
    | LobbyGameGroup
    | {
        games?: GamePregmatic[];
      } = {
    games: [],
  };
  activeGroupData:
    | LobbyGameGroup
    | {
        games?: GamePregmatic[];
      } = {
    games: [],
  };
  filterByData: FilteredGamesBy = {
    typeOfGames: "live-game",
    unCheckedProviders: [],
  };

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private gameGroupsService: GameGroupsService,
    private newsTickerService: NewsTickerService,
    private gamesFilterPipe: GamesFilterPipe,
    private utilityService: UtilityService,
    private commonService: CommonService
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onAddThemeChangeClass();

    this.UserFlowTypes = UserFlowTypes;

    this.languageCode = this.utilityService.getLangCode();

    this.isLoggedIn = this.utilityService.isUserLoggedIn();

    this.subscriptions = [
      this.translationService.langCode$.subscribe((languageCode: string) => {
        this.languageCode = languageCode;

        this.onUpdateSEOContent();
      }),
      this.commonService.loginComplete$.subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;

        this.onGetLobbyGroupsAndGames(true);
      }),
      this.commonService.logOutComplete$.subscribe(() => {
        this.isLastPlayedLiveGamesExist = false;

        this.isLoggedIn = false;

        this.onGetLobbyGroupsAndGames(true);
      }),
      this.gameGroupsService.isLastPlayedLiveGamesExistSb$.subscribe(
        (isLastPlayedLiveGamesExist: boolean) => {
          this.isLastPlayedLiveGamesExist = isLastPlayedLiveGamesExist;
        }
      ),
      this.newsTickerService.isNewsTickerBannerAvailable$.subscribe(
        (isNewsTickerBannerAvailable: boolean) => {
          this.isNewsTickerBannerAvailable = isNewsTickerBannerAvailable;
        }
      ),
    ];
  }

  ngAfterViewInit(): void {
    this.onUpdateSEOContent();

    this.timeout = setTimeout(() => {
      this.getWindowType();
    }, 100);
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:orientationchange") onRotate(): void {
    this.getWindowType();
  }

  @HostListener("window:resize") onResize(): void {
    this.getWindowType();
  }

  // -----------------------------------------------------------------
  // Get Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      this.windowType = "desktop";
    } else {
      this.windowType = "mobile";
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  getFilterGroupByAssetType(
    lobbyGGroupsWithGames: LobbyGameGroup[],
    assetType: string
  ):
    | LobbyGameGroup
    | {
        games: GamePregmatic[];
      } {
    let groupData: LobbyGameGroup[] = _.filter(
      lobbyGGroupsWithGames,
      (data: LobbyGameGroup) => {
        if (data && data.game_asset_style === assetType) {
          return data;
        }
      }
    );

    return groupData.length > 0 ? groupData[0] : { games: [] };
  }

  getApplyFilters(games: GamePregmatic[]): GamePregmatic[] {
    return this.gamesFilterPipe.transform(games, this.filterByData);
  }

  // -----------------------------------------------------------------
  // Set Methods
  onUpdateSEOContent(): void {
    this.utilityService.setSEO({
      metaTitle: this.translationService.instant(
        "SEO.liveCasinoPage_metaTitle"
      ),
      metaDescription: this.translationService.instant(
        "SEO.liveCasinoPage_metaDescription"
      ),
      metaTags: this.translationService.instant("SEO.liveCasinoPage_metaTag"),
      title: this.translationService.instant("SEO.liveCasinoPage_title"),
    });

    this.commonService.setCanonicalURL("liveCasinoPage");
  }

  onGetLobbyGroupsAndGames(isForce: boolean = false): void {
    this.isLoading = true;

    this.gameGGroupfilterByLobbyList = [];

    let apiURL: any[] = [
      this.gameGroupsService.getLobbyListWithGameGroupsp(isForce),
      this.gameGroupsService.getGameGroupGamesp(isForce),
    ];

    if (this.isLoggedIn) {
      apiURL.push(this.gameGroupsService.getLastedPlayedGamesp(isForce));
    }

    Promise.all(apiURL).then(
      ([lobbyGameGroupsResponse, gamesResponse, lastPlayedResponse]) => {
        let lobbyGameGroupsList: LobbyPregmatic[] = lobbyGameGroupsResponse;

        let gamesList: GamePregmatic[] = gamesResponse;

        if (lastPlayedResponse) {
          let lastPlayedList: number[] = lastPlayedResponse;

          this.lastPlayedGamesList = _.clone(lastPlayedList);
        }

        /*
          To break object referance we using below syntax because _clone works only
          one level of nested Objects..
        */
        this.lobbyGameGroupsList = JSON.parse(
          JSON.stringify(lobbyGameGroupsList)
        );

        this.gamesList = JSON.parse(JSON.stringify(gamesList));

        this.onUpdateMetaTagBasedOnLobby();

        let lobbyGGroupsWithGamesList: LobbyGameGroup[] = [];

        if (this.lobbyGameGroupsList && this.lobbyGameGroupsList.length > 0) {
          this.gameGGroupfilterByLobbyList = this.utilityService.getGameGroupsByLobby(
            _.clone(this.lobbyGameGroupsList),
            this.activeLobbyName
          );
        }

        if (
          !_.isEmpty(this.gameGGroupfilterByLobbyList) &&
          this.activeLobbyName &&
          this.gamesList &&
          this.gamesList.length > 0
        ) {
          lobbyGGroupsWithGamesList = this.gameGroupsService.processGameGroupGames(
            this.gameGGroupfilterByLobbyList,
            this.gamesList,
            this.lastPlayedGamesList
          );
        }

        if (lobbyGGroupsWithGamesList && this.gamesList) {
          this.onSetGroupGameData(lobbyGGroupsWithGamesList, this.gamesList);

          this.onSetProviderLobbiesData(lobbyGGroupsWithGamesList);
        }
      }
    );
  }

  onUpdateMetaTagBasedOnLobby(): void {
    if (this.lobbyGameGroupsList && this.activeLobbyName) {
      this.activeLobbyData = this.utilityService.getLobbyDataByLobbyName(
        this.lobbyGameGroupsList,
        this.activeLobbyName
      );
    }
  }

  onSelectedLobby(activeLobbyName: string): void {
    this.activeLobbyName = activeLobbyName;

    if (this.activeLobbyName) {
      this.onGetLobbyGroupsAndGames();
    }
  }

  onSetGroupGameData(
    lobbyGGroupsWithGamesList: LobbyGameGroup[],
    gamesList: GamePregmatic[]
  ): void {
    this.activeGroupData = { games: [] };

    if (
      this.activeLobbyName === "all-live" &&
      gamesList &&
      gamesList.length > 0
    ) {
      this.activeGroupData.games = gamesList;
    } else if (
      lobbyGGroupsWithGamesList &&
      lobbyGGroupsWithGamesList.length > 0
    ) {
      if (this.activeLobbyName === "play-again") {
        this.activeGroupData = this.getFilterGroupByAssetType(
          lobbyGGroupsWithGamesList,
          "default-live-casino"
        );
      } else {
        this.activeGroupData = this.getFilterGroupByAssetType(
          lobbyGGroupsWithGamesList,
          "default-live-casino"
        );
      }
    } else {
      this.finalGamesList = [];

      this.activeGroupData.games = [];
    }

    this.onPrepareGroupGameData();
  }

  onPrepareGroupGameData(): void {
    if (
      this.activeGroupData &&
      this.activeGroupData.games &&
      this.activeGroupData.games.length > 0
    ) {
      this.finalGamesList = this.getApplyFilters(this.activeGroupData.games);

      if (this.activeLobbyName !== "all-live") {
        this.finalGamesList = this.utilityService.sortGameByCountryOrder(
          this.finalGamesList
        );
      }
    } else {
      this.finalGamesList = [];
    }

    this.isLoading = false;
  }

  onSetProviderLobbiesData(lobbyGGroupsWithGames: LobbyGameGroup[]): void {
    /*
      This active Lobby filter code is common
      for all the categories
    */
    if (lobbyGGroupsWithGames && lobbyGGroupsWithGames.length > 0) {
      this.activeLobbyProviderData = this.getFilterGroupByAssetType(
        lobbyGGroupsWithGames,
        "live-lobby-provider"
      );
    } else {
      this.activeLobbyProviderData = {};
    }

    this.onPrepareProviderLobbiesData();
  }

  onPrepareProviderLobbiesData(): void {
    if (
      this.activeLobbyProviderData &&
      this.activeLobbyProviderData.games &&
      this.activeLobbyProviderData.games.length > 0
    ) {
      this.finalLobbyGamesList = this.utilityService.sortGameByCountryOrder(
        this.getApplyFilters(this.activeLobbyProviderData.games)
      );
    } else {
      this.finalLobbyGamesList = [];
    }
  }

  onProviderListChanged(unCheckedProvidersList: string[]): void {
    this.filterByData.unCheckedProviders = unCheckedProvidersList;

    this.onPrepareGroupGameData();

    this.onPrepareProviderLobbiesData();
  }

  /*
    Yet to work on it still requirement is pending from CMS side  
  */
  onAddThemeChangeClass(): void {
    this.commonService.broadCastIsLiveCasinoPage(true);
  }

  onRemoveThemeChangeClass(): void {
    this.commonService.broadCastIsLiveCasinoPage(false);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.onRemoveThemeChangeClass();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );

    clearTimeout(this.timeout);
  }
}
