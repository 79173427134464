import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  @Input() text: string;
  @Input() confirmButtonText: string;

  @Output() close = new EventEmitter<void>();

  onClose(): void {
    this.close.emit();
  }
}
