import { BehaviorSubject, Subscription } from "rxjs";
import { Injectable } from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import {
  LivespinsPropertiesConfigurations,
  addScriptDynamically,
  LivespinsSDKClient,
  LivespinsSDK,
} from "livespins/@livespins";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Injectable({
  providedIn: "root",
})
export class LivespinsService {
  // Strings
  languageCode: string = "";

  // Booleans
  initStarted: boolean = false;

  // Others
  public static langCodeToLivespinsLanguageCode: Record<string, string> = {
    "en-row": "en",
    "en-ca": "en",
    "en-nz": "en",
    "en-eu": "en",
    "de-de": "de",
    "nb-no": "no",
    "fi-fi": "fi",
    "hu-hu": "hu",
    "et-ee": "et"
  };

  clientSubject: BehaviorSubject<LivespinsSDKClient | null> = new BehaviorSubject<LivespinsSDKClient | null>(
    null
  );
  sdkSubject: BehaviorSubject<LivespinsSDK | null> = new BehaviorSubject<LivespinsSDK | null>(
    null
  );

  // Subscriptions
  languageCodeSubscription: Subscription;
  subscription: Subscription;

  constructor(
    private translationService: TranslationService,
    private commonService: CommonService,
    private utility: UtilityService
  ) {
    this.languageCode = this.utility.getLangCode();

    this.languageCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (languageCode: string) => {
        this.languageCode = languageCode;
      }
    );
  }

  initialiseLivespinsSDK(): void {
    // This can be called multiple times, but we only need to initialise the
    // client once.
    this.initStarted = true;

    document.addEventListener("LIVESPINS_LOADED", () => {
      console.log("Livespins SDK module ready to be used 💪", window.livespins);

      let tenant: string = "";

      switch (this.languageCode) {
        case "fi-fi":
        case "en-nz":
        case "en-ca":
        case "et-ee": {
          tenant = "HIREE";
          break;
        }
        default: {
          tenant = "HIR";
          break;
        }
      }

      let livespinsConfigurations: LivespinsPropertiesConfigurations = {
        ...environment.livespins.configurations,
        tenant,
      };

      const livespinsSDK: LivespinsSDK = window.livespins.sdk({
        ...livespinsConfigurations,
      });

      this.sdkSubject.next(livespinsSDK);

      livespinsSDK.Client().then((client: LivespinsSDKClient) => {
        console.log({ client });

        this.clientSubject.next(client);
      });
    });

    addScriptDynamically("https://assets.livespins.com/sdk/js/livespins.js");
  }

  initialise(): void {
    if (!this.initStarted && this.utility.isUserLoggedIn()) {
      this.initialiseLivespinsSDK();
    }

    this.subscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        if (!this.initStarted && isLoggedIn) {
          this.initialiseLivespinsSDK();
        }
      }
    );
  }

  get client(): LivespinsSDKClient | null {
    return this.clientSubject.value;
  }

  get sdk(): LivespinsSDK | null {
    return this.sdkSubject.value;
  }

  ngOnDestroy(): void {
    if (this.languageCodeSubscription) this.languageCodeSubscription.unsubscribe();
    
    if (this.subscription) this.subscription.unsubscribe();
  }
}
