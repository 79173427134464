import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";
import {
  EventEmitter,
  ElementRef,
  Component,
  OnDestroy,
  ViewChild,
  OnInit,
  Output,
} from "@angular/core";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import intlTelInput from "intl-tel-input";

// Services
import { StaticPageService } from "src/app/modules/static-pages/services/static-page.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { CustomValidators } from "src/app/utility/custom-validator";
import { zimplerLanguageToCountryConfigurations } from "src/app/configurations/main.configurations";

@Component({
  selector: "app-subscribe-offers",
  templateUrl: "./subscribe-offers.component.html",
  styleUrls: ["./subscribe-offers.component.scss"],
})
export class SubscribeOffersComponent extends FormValidationComponent
  implements OnInit, OnDestroy {
  @ViewChild("dynamicContent", { static: false }) el: ElementRef;
  @Output() callOnEmailSubscriptionUpdate: EventEmitter<
    any
  > = new EventEmitter();

  isButtonLoader: boolean = false;
  isPopupLoader: boolean = false;
  emailUpdateSubscribeFrom = this.formBuilder.group({
    txtEmail: [
      "",
      [
        this.customValidators.validateUniqueness(
          "txtEmail",
          this.commonService,
          false
        ),
      ],
    ],
    areaCode: ["", [this.customValidators.required]],
    phone: ["", this.customValidators.phoneNumberValidator],
    emailandMobileSubscribed: [false],
    termsConditions: [false, [this.customValidators.required]],
  });
  updateUserSub: Subscription;
  errorMessage: string;
  intlTelInputInstance: any;
  openTermsPopup: boolean = false;
  isPageAvailable: any;
  userProfileDetailsSub: Subscription;

  constructor(
    private utils: UtilityService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private customValidators: CustomValidators,
    private staticPageService: StaticPageService,
    private translationService: TranslationService,
    private userDetailsService: UserDetailsService
  ) {
    super(utils);
  }

  ngOnInit() {
    this.initializeintlTelInput();
  }

  updateUserCredentials() {
    this.isButtonLoader = true;
    this.errorMessage = undefined;
    if (this.emailUpdateSubscribeFrom.valid) {
      const requestData = {
        email: this.emailUpdateSubscribeFrom.controls["txtEmail"].value,
        areaCode: this.emailUpdateSubscribeFrom.controls["areaCode"].value,
        phone: this.emailUpdateSubscribeFrom.controls["phone"].value,
        emailSubscribed: this.emailUpdateSubscribeFrom.controls[
          "emailandMobileSubscribed"
        ].value,
        mobileSubscribed: this.emailUpdateSubscribeFrom.controls[
          "emailandMobileSubscribed"
        ].value,
      };
      this.updateUserSub = this.commonService
        .updateUserCredentials(requestData)
        .subscribe((data) => {
          if (data && data["status"] === "SUCCESS") {
            this.userProfileDetailsSub = this.commonService
              .forceGetUserProfileDetails()
              .subscribe();
            this.callOnEmailSubscriptionUpdate.emit();
          } else {
            this.userUpdatedFailureHandler(data);
          }
        });
      setTimeout(() => {
        this.isButtonLoader = false;
      }, 2000);
    }
  }

  userUpdatedFailureHandler(data) {
    if (data && data["status"] === "FAILURE" && data["errorCode"] === 100170) {
      this.errorMessage = this.translationService.instant(
        "subscribeOffersModal.error_100170_emailAlreadyExit"
      );
    } else if (
      data &&
      data["errors"] &&
      data["errors"]["errorCode"] === "100461"
    ) {
      this.errorMessage = this.translationService.instant(
        "subscribeOffersModal.error_100461"
      );
    } else if (
      data &&
      data["errors"] &&
      data["errors"].hasOwnProperty("email")
    ) {
      this.errorMessage = this.translationService.instant(
        "subscribeOffersModal.valid_email"
      );
    } else if (
      data &&
      data["errors"] &&
      data["errors"].hasOwnProperty("password")
    ) {
      this.errorMessage = this.translationService.instant(
        "subscribeOffersModal.valid_password"
      );
    } else {
      this.errorMessage = this.translationService.instant(
        "subscribeOffersModal.something_went_wrong"
      );
    }
  }

  initializeintlTelInput(initialSelected?) {
    const langCode = this.utils.getLangCode();
    const countryCode = zimplerLanguageToCountryConfigurations[langCode];
    let excludedCountries = []; //can put any country to be excluded here
    initialSelected = initialSelected ? initialSelected : countryCode;
    const input = document.getElementById("mobileNumber");
    this.intlTelInputInstance = intlTelInput(input, {
      initialCountry: initialSelected,
      nationalMode: false,
      excludeCountries: excludedCountries,
      preferredCountries: [],
      autoPlaceholder: false,
      separateDialCode: true,
    });
    if (this.intlTelInputInstance.getSelectedCountryData()) {
      const countryData = this.intlTelInputInstance.getSelectedCountryData();
      this.emailUpdateSubscribeFrom.controls["areaCode"].setValue(
        countryData["dialCode"]
      );
    }
    input.addEventListener("countrychange", () => {
      var countryData = this.intlTelInputInstance.getSelectedCountryData();
      this.emailUpdateSubscribeFrom.controls["areaCode"].setValue(
        countryData["dialCode"]
      );
    });
  }

  openSubscribeTermsPopup() {
    let urlPath = this.translationService.instant("url.terms_conditions");
    this.openTermsPopup = true;
    this.isPopupLoader = true;
    this.getFaqStaticPage(urlPath);
  }
  subscribeOffersTcCloseBtn() {
    this.openTermsPopup = false;
    this.isPageAvailable = false;
  }

  getFaqStaticPage(urlPath) {
    Promise.resolve(this.staticPageService.getStaticPagep(urlPath, false)).then(
      (staticPageData) => {
        this.isPopupLoader = false;
        /**block to handle if no content is configured for this
         * specific path
         * */
        if (
          !Object.keys(staticPageData).length ||
          staticPageData["errorCode"]
        ) {
          this.isPageAvailable = false;
        } else {
          /**block to handle successfull content from cms.. */
          this.isPageAvailable = true;
          const staticContent = staticPageData["content"].replace(
            new RegExp("{{site_url}}", "g"),
            environment.siteUrl
          );
          setTimeout(() => {
            if (this.el && this.el.nativeElement) {
              this.el.nativeElement.innerHTML = staticContent;
            }
          });
        }
      }
    );
  }

  ngOnDestroy() {
    this.isPageAvailable = false;
    if (this.updateUserSub) {
      this.updateUserSub.unsubscribe();
    }
    if (this.intlTelInputInstance) {
      this.intlTelInputInstance.destroy();
    }
    if (this.userProfileDetailsSub) {
      this.userProfileDetailsSub.unsubscribe();
    }
  }
}
