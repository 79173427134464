import { Router, ActivatedRoute, Params } from "@angular/router";
import { Subscription } from "rxjs";
import {
  AfterViewInit,
  HostListener,
  Component,
  OnDestroy,
} from "@angular/core";

// Libraries
import * as _ from "underscore";

// Models
import { GameProviders } from "src/app/modules/game-groups/models/game-providers.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-provider-games",
  templateUrl: "./provider-games-display.component.html",
  styleUrls: ["./provider-games-display.component.scss"],
})
export class ProvideGamesDisplay implements AfterViewInit, OnDestroy {
  // Strings
  activeGroupName: string = "";
  providerName: string = "";
  languageCode: string = "";
  activeLobby: string = "";
  sortedBy?: string = "";

  // Booleans
  isGamesAvaialable: boolean = false;
  isPageHeaderBg: boolean = false;
  isLoggedIn: boolean = false;
  isLoading: boolean = true;

  // Enums
  deviceType: "desktop" | "mobile" = "desktop";

  // Arrays
  gamesBeforeSortedList: GamePregmatic[] = [];
  filteredGamesList: GamePregmatic[] = [];
  providersList: GameProviders[] = [];
  gamesList: GamePregmatic[] = [];

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private gameGroupsService: GameGroupsService,
    private utilityService: UtilityService,
    private activateRoute: ActivatedRoute,
    private commonService: CommonService,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.getWindowType();

    this.isLoggedIn = this.utilityService.isUserLoggedIn();

    this.onUpdateSEOContent();

    this.onSetSEOContent();

    this.subscriptions = [
      this.translationService.langCodeSb$.subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      }),
      this.commonService.loginComplete$.subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      }),
      this.commonService.logOutComplete$.subscribe(() => {
        this.isLoggedIn = false;
      }),
      this.activateRoute.params.subscribe((params: Params) => {
        if (params && params.providerName) {
          this.providerName = params.providerName;

          this.onGetGroupGames(false);
        }
      }),
    ];
  }

  ngAfterViewInit(): void {
    this.onOverflowHiddenClass();
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:scroll") onScroll(): void {
    this.isPageHeaderBg = this.utilityService.scrollAddClass();
  }

  @HostListener("window:resize") onResize(): void {
    this.getWindowType();

    this.onOverflowHiddenClass();
  }

  // -----------------------------------------------------------------
  // Get Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      this.deviceType = "desktop";
    } else {
      this.deviceType = "mobile";
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetGroupGames(isForce: boolean): void {
    this.isLoading = true;

    Promise.resolve(this.gameGroupsService.getGameGroupGamesp(isForce)).then(
      (gamesList: GamePregmatic[]) => {
        if (gamesList && gamesList.length > 0) {
          this.gamesList = gamesList;

          this.onGamesInProvider(this.providerName);
        } else {
          this.isLoading = false;

          this.filteredGamesList = [];
        }
      }
    );
  }

  onGamesInProvider(providerName: string): void {
    let selectedProviderData: GameProviders[] = [];

    if (providerName && this.gamesList) {
      this.providersList = this.utilityService.getProviderList(this.gamesList);

      if (this.providersList) {
        selectedProviderData = _.filter(
          this.providersList,
          (provider: GameProviders) => {
            if (
              this.utilityService.convertSpecialCharactersWithSpace(
                provider.vendorDisplayName
              ) === this.utilityService.convertSpecialCharactersWithSpace(providerName)
            ) {
              this.providerName = provider.vendorDisplayName;

              return true;
            }
          }
        );

        if (!_.isEmpty(selectedProviderData)) {
          this.filteredGamesList = this.utilityService.getGamesByProviderName(
            this.gamesList,
            selectedProviderData[0].vendorCode
          );

          this.gamesBeforeSortedList = this.filteredGamesList;
        }
      }
    }

    if (
      !this.filteredGamesList ||
      (this.filteredGamesList && this.filteredGamesList.length === 0)
    ) {
      this.filteredGamesList = [];
    }

    this.isLoading = false;
  }

  onOverflowHiddenClass(): void {
    setTimeout(() => {
      if (
        document.querySelector(".page-body") &&
        this.deviceType === "mobile"
      ) {
        this.utilityService.addClassToAppBody("overflow-hidden");
      } else {
        this.utilityService.removeClassFromAppBody("overflow-hidden");
      }
    });
  }

  onSortByGameName(): void {
    if (this.sortedBy !== "a-z") {
      this.sortedBy = "a-z";

      this.filteredGamesList = this.utilityService.sortByGameName(
        this.filteredGamesList,
        "name"
      );
    } else {
      this.sortedBy = undefined;

      this.filteredGamesList = this.gamesBeforeSortedList;
    }
  }

  onGoBack(): void {
    if (!this.commonService.previousURL) {
      this.router.navigate([this.utilityService.getLangCode()]);
    } else {
      this.router.navigate([this.commonService.previousURL]);
    }

    this.utilityService.removeClassFromAppBody("overflow-hidden");
  }

  onUpdateSEOContent(): void {
    let segments: string[] = this.utilityService.getDecodedCurrentPath().split("/");

    if (segments && segments.length === 4) {
      let providerName: string = segments[3].replace(" ", "-");

      this.utilityService.setSEO({
        metaTitle: this.translationService.instant(
          `SEO.${providerName}_metaTitle`
        ),
        metaDescription: this.translationService.instant(
          `SEO.${providerName}_metaDescription`
        ),
        metaTags: this.translationService.instant(
          `SEO.${providerName}_metaTag`
        ),
        title: this.utilityService.capitalizeFirstLetter(providerName),
      });
    }
  }

  onSetSEOContent(): void {
    this.commonService.setCanonicalURL("studiopage");
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.utilityService.removeClassFromAppBody("overflow-hidden");

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
