export enum AccountView {
  my_transacts_history = "my-transacts-history",
  confirm_identity = "confirmidentity",
  change_password = "change-password",
  update_profile = "update-profile",
  bet_history = "bet-history",
  preferences = "preferences",
  withdrawal = "withdrawal",
  time_out = "time-out",
  deposit = "deposit",
  rewards = "rewards",
  limits = "limits",
  bonus = "bonus",
}
