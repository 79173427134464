import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Subscription } from "rxjs";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as $ from "jquery";

// Services
import { CustomBannerService } from "src/app/modules/banner/services/custom-banner.service";
import { NewsTickerService } from "src/app/modules/news/services/news-ticker.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-news-ticker",
  templateUrl: "./news-ticker.component.html",
  styleUrls: ["./news-ticker.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NewsTickerComponent implements OnInit {
  isLoading: boolean;
  zoneId: string = "stickers";
  bannerContent: any;
  logOutCompleteBsSubscription: Subscription;
  loginCompleteBsSubscription: Subscription;
  langCodeSubscription: Subscription;
  isLoggedIn: any;

  constructor(
    private customBannerService: CustomBannerService,
    private commonService: CommonService,
    private utility: UtilityService,
    private newsTickerService: NewsTickerService
  ) {
    this.loginCompleteBsSubscription = this.commonService.loginComplete.subscribe(
      (isloggedIn) => {
        this.getBannersByZoneId({ zoneId: this.zoneId }, true);
      }
    );
    this.logOutCompleteBsSubscription = this.commonService.logOutComplete.subscribe(
      (isloggedOut) => {
        this.getBannersByZoneId({ zoneId: this.zoneId }, true);
      }
    );
  }

  ngOnInit() {
    this.getBannersByZoneId({ zoneId: this.zoneId }, true);
  }

  getBannersByZoneId(bannerReqData, isforce?) {
    this.isLoading = true;

    Promise.resolve(
      this.newsTickerService.getStickerBanners(bannerReqData, isforce)
    ).then((banners) => {
      this.isLoading = false;
      if (banners && banners.length > 0) {
        this.newsTickerService.broadCastIsNewsTickerBannerAvialable(true);
        this.bannerContent = banners[0]["content"].replace(
          new RegExp("{{site_url}}", "g"),
          environment.siteUrl
        );

        setTimeout(() => {
          if (document.getElementById("news-ticker")) {
            document.getElementById(
              "news-ticker"
            ).innerHTML = this.bannerContent;
            this.ticketCloneCreate();
          }
        });
      } else {
        this.newsTickerService.broadCastIsNewsTickerBannerAvialable(false);
      }
    });
  }

  ticketCloneCreate() {
    var newsTickerList = $(".ticker-list p").length;
    for (var i = 0; i < newsTickerList; i++) {
      $(".ticker-list").clone().prependTo(".newsTicker--list");
    }
  }
}
