import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  AfterViewInit,
  SimpleChange,
  Component,
  OnChanges,
  OnDestroy,
  Input,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { TriggerLoginGameDetails } from "src/app/modules/shared/models/trigger-login/trigger-login-game-details.model";
import { FreeGames } from "src/app/modules/game-groups/models/free-games/free-games.model";
import { ProviderPregmatic } from "src/app/modules/game-groups/models/provider.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { MixPanelService } from "src/app/modules/shared/services/mix-panel.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-show-case",
  templateUrl: "./show-case.component.html",
  styleUrls: ["./show-case.component.scss"],
})
export class ShowCaseComponent implements OnChanges, OnDestroy, AfterViewInit {
  // Inputs
  @Input() gameGroupData: ProviderPregmatic;
  @Input() callingFromlobby: string = "";

  // Strings
  gameId: string = "booming_5c9547cf2dd5bd000b45103b";
  mediaUrlPath: string = environment.mediaUrlPath;
  gamesUrlPath: string = environment.gamesUrlPath;
  websiteName: string = environment.websiteName;
  languageCode: string = "";
  imgixParams: string = "";

  // Booleans
  isLoggedIn: boolean = false;
  isVisible: boolean = false;

  // Objects
  gameURL: SafeResourceUrl;
  game: GamePregmatic;

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private gameGroupsService: GameGroupsService,
    private gamePlayService: GamePlayService,
    private mixpanelService: MixPanelService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private domSanitizer: DomSanitizer,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.isLoggedIn = this.utilityService.isUserLoggedIn();

    this.imgixParams = this.utilityService.getImgixParams();

    this.subscriptions = [
      this.translationService.langCodeSb$.subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      }),
      this.commonService.loginComplete$.subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      }),
      this.commonService.logOutComplete$.subscribe(() => {
        this.isLoggedIn = false;
      }),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["gameGroupData"] &&
      changes["gameGroupData"].previousValue !==
        changes["gameGroupData"].currentValue
    ) {
      this.gameGroupData = changes["gameGroupData"].currentValue;

      this.game = this.gameGroupData.games[0];

      if (this.game && this.game.hasDemo) {
        this.onGetFreeGame(this.game.gameCode);
      }
    }

    if (
      changes["callingFromlobby"] &&
      changes["callingFromlobby"].previousValue !==
        changes["callingFromlobby"].currentValue
    ) {
      this.callingFromlobby = changes["callingFromlobby"].currentValue;
    }
  }

  ngAfterViewInit(): void {
    this.isVisible = true;
  }

  // -----------------------------------------------------------------
  // Set Methods
  /*
    We always show user a demo game only here...
    user can click on the btn & navigate to game play window 
    to for real money
  */
  onGetFreeGame(gameId: string): void {
    this.gameGroupsService.getFreeGame(gameId).subscribe((data: FreeGames) => {
      if (data.gameDetails && data.gameDetails.url) {
        this.gameURL = this.domSanitizer.bypassSecurityTrustResourceUrl(
          data.gameDetails.url
        );
      }
    });
  }

  onGameLaunch(gameName: string): void {
    gameName = gameName.toLowerCase().split(" ").join("-");

    this.gamePlayService.clearGameWindowData();

    if (this.isLoggedIn) {
      this.gamePlayService.setGameCalledfrom("realgame");
    } else {
      this.gamePlayService.setGameCalledfrom("freegame");
    }

    setTimeout(() => {
      if (gameName && this.isLoggedIn) {
        this.mixpanelService.trackGameLanuchEvent({
          gameData: {
            name: gameName,
          },
          gameType: "realgame",
          groupName: this.gameGroupData ? this.gameGroupData.name : "",
          lobbyName: this.callingFromlobby,
          callingFrom: "showcase",
        });

        this.router.navigate([
          `${this.languageCode}/${this.translationService.instant(
            "url.game"
          )}/${gameName}`,
        ]);
      } else {
        this.onTriggerLogin(gameName);
      }
    });
  }

  onTriggerLogin(gameName: string): void {
    let gameDetails: TriggerLoginGameDetails = {
      navigateTo: "gameWindow",
      path: gameName,
    };

    if (this.gameGroupData) {
      gameDetails.gameGroupName = this.gameGroupData["name"];
    }

    if (this.callingFromlobby) {
      gameDetails.lobbyName = this.callingFromlobby;
    }

    this.utilityService.initiateLogin();

    this.commonService.setNavigateAfterLogin({
      type: "url",
      data: gameDetails,
    });
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
