import { Observable, of, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { filter } from "rxjs/operators";
import {
  ViewEncapsulation,
  AfterViewInit,
  Component,
  ViewChild,
  OnInit,
  Input,
} from "@angular/core";

// Configurations
import {
  livespinsExcludedMarketsConfigurations,
  livespinsLanguagesConfigurations,
} from "src/app/modules/livespins/configurations/livespins.configurations";

// Libraries
import { SwiperComponent } from "ngx-swiper-wrapper";
import {
  LiveScheduledStream,
  LivespinsSDKClient,
  LiveStream,
  take,
} from "livespins/@livespins";

// Models
import { livespinsSwiper } from "src/app/modules/livespins/models/livespins-swiper.model";

// Services
import { LivespinsService } from "src/app/modules/livespins/services/livespins-service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { livespinsByLanguage } from "src/app/modules/livespins/utilities/livespins-filter-by-language.utilities";
import { livespinsFilter } from "src/app/modules/livespins/utilities/livespins-filter.utilities";

@Component({
  selector: "app-livespins",
  templateUrl: "./livespins.component.html",
  styleUrls: ["./livespins.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LiveSpinsComponent implements OnInit, AfterViewInit {
  @Input() livespinsSwiper = livespinsSwiper();
  @Input() livespinsLanguages: string[] = [];
  @Input() isLiveSpinsEnabled: boolean;
  @Input() language: string;

  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;

  liveScheduledStream$: Observable<LiveScheduledStream[]> = of([]);
  liveStream$: Observable<LiveStream[]> = of([]);

  subscription: Subscription;

  constructor(
    private livespinsService: LivespinsService,
    private utility: UtilityService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.livespinsLanguages = livespinsLanguagesConfigurations(
      this.utility.getLangCode()
    );

    this.isLiveSpinsEnabled = !livespinsExcludedMarketsConfigurations.includes(
      this.utility.getLangCode()
    );

    if (!this.isLiveSpinsEnabled) {
      return;
    }

    this.subscription = this.livespinsService.clientSubject
      .pipe(filter((client: LivespinsSDKClient | null) => client != null))
      .subscribe((client: LivespinsSDKClient) => {
        this.liveStream$ = of(
          livespinsByLanguage<LiveStream>(
            client.getStreams(),
            this.utility.getLangCode()
          )
        );

        this.liveScheduledStream$ = livespinsFilter<LiveScheduledStream>(
          client.getScheduledStreams(),
          this.utility.getLangCode()
        );

        client.onUpdate(() => {
          this.liveStream$ = of(
            livespinsByLanguage<LiveStream>(
              client.getStreams(),
              this.utility.getLangCode()
            )
          );

          this.liveScheduledStream$ = livespinsFilter<LiveScheduledStream>(
            client.getScheduledStreams(),
            this.utility.getLangCode()
          );
        });
      });
  }

  onPlay(item: LiveStream): void {
    this.router.navigate([
      `${this.utility.getLangCode()}/livespins/${item.sessionId}`,
    ]);
  }

  ngAfterViewInit(): void {
    if (!this.isLiveSpinsEnabled) {
      return;
    }

    document.addEventListener("livespins-update", () => {
      this.componentRef.directiveRef.update();
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.subscription) this.subscription.unsubscribe();
  }

  get hasStreams(): boolean {
    if (!this.componentRef || !this.componentRef.directiveRef) {
      return false;
    }

    return this.componentRef.directiveRef.swiper().slides.length > 2;
  }

  livespinsStreamsTrackBy(index: number, livestream: LiveStream) {
    return livestream.sessionId;
  }
}
