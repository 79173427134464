import {
  EventEmitter,
  SimpleChange,
  ElementRef,
  OnChanges,
  Component,
  ViewChild,
  OnInit,
  Output,
  Input,
} from "@angular/core";

// Configurations
import { maxSupportedFileSizeInBytesConfigurations } from "src/app/configurations/main.configurations";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { CommonService } from "src/app/modules/shared/services/common.service";

@Component({
  selector: "app-address-proof",
  templateUrl: "./address-proof.component.html",
  styleUrls: ["./address-proof.component.scss"],
})
export class AddressProofComponent implements OnInit, OnChanges {
  @ViewChild("addressProofCamera", { static: false })
  addressProofCamera: ElementRef;
  @ViewChild("addressProof", { static: false }) addressProof: ElementRef;
  @Output() uploadAddressProofFiles: EventEmitter<any> = new EventEmitter();
  @Input() uploadResponseData;
  @Input() isButtonDisabled: boolean;

  residenceProofData;
  sizeExceeded: string;

  constructor(
    private translationService: TranslationService,
    private commonService: CommonService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["uploadResponseData"]) {
      this.uploadResponseData = changes["uploadResponseData"].currentValue;
      setTimeout(() => {
        this.uploadResponseData = undefined;
      }, 5000);
      this.clearField();
    } else if (changes["isButtonDisabled"]) {
      this.isButtonDisabled = changes["isButtonDisabled"].currentValue;
    }
  }

  upload(event) {
    this.sizeExceeded = undefined;
    if (event.target.files[0].size >= maxSupportedFileSizeInBytesConfigurations) {
      this.sizeExceeded = this.translationService.instant(
        "confirmidentity.file_size_alert"
      );
      this.clearField();
    } else {
      this.residenceProofData = {
        event: event,
        fileName: event.target.files[0].name,
        type: "utilitybill",
      };
    }
  }
  clearField() {
    this.residenceProofData = undefined;
    if (this.addressProofCamera) {
      this.addressProofCamera.nativeElement.value = "";
    }
    if (this.addressProof) {
      this.addressProof.nativeElement.value = "";
    }
    if (this.sizeExceeded) {
      setTimeout(() => {
        this.sizeExceeded = undefined;
      }, 5000);
    }
  }

  submitResidenceProof() {
    if (this.residenceProofData) {
      this.uploadAddressProofFiles.emit(this.residenceProofData);
    }
  }
}
