import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Services
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-form-label",
  templateUrl: "./form-label.component.html",
  styleUrls: ["./form-label.component.scss"],
})
export class FormLabelComponent extends FormValidationComponent
  implements OnInit {
  config;
  group: FormGroup;
  selectedMethodData: any;
  callingFrom: any;
  usedAccount: boolean;
  constructor(private utils: UtilityService) {
    super(utils);
  }

  ngOnInit() {}
}
