import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { LEADER_BOARD_DEFAULT_LIMIT } from "src/app/modules/shared/constants/leader-board.constant";
import {
  EnrolledDisplayMode,
  LeaderBoardUser,
  LeaderBoardUserStatus,
} from "src/app/modules/shared/models/leader-board/leader-board.model";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { LeaderBoardService } from "src/app/modules/shared/services/leader-board.service";
import { UserData } from "src/app/modules/user/models/user-data.model";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";

@Component({
  selector: "app-leader-board",
  templateUrl: "./leader-board.component.html",
  styleUrls: ["./leader-board.component.scss"],
})
export class LeaderBoardComponent implements OnInit, OnDestroy {
  @Input() buttonText: string;
  @Input() board: string;
  @Input() isXmas?: boolean;
  @Input() limit?: number = LEADER_BOARD_DEFAULT_LIMIT;
  @Input() urlToLeaderBoard?: string;
  @Input() enrolledDisplayMode?: EnrolledDisplayMode;

  topUsers: LeaderBoardUser[] = [];
  userPoints: LeaderBoardUser;
  userProfileDetails: UserData;
  shouldShowLeaderBoard = false;
  isUserEnrolled = false;

  destroy$ = new Subject<void>();

  readonly ENROLLED_DISPLAY_MODE = EnrolledDisplayMode;

  constructor(
    private commonService: CommonService,
    private leaderBoardService: LeaderBoardService,
    private userDetailsService: UserDetailsService
  ) {}

  ngOnInit(): void {
    this.onGetUserProfileData();
    this.onCheckUserStatus();
    this.onObserveUserId();
    this.onObserveUserStatus();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onEnrolUser(): void {
    const userName = this.getUserNameForLeaderBoard();
    this.leaderBoardService.enrolUser(this.board, this.userProfileDetails.playerID, userName).pipe(take(1)).subscribe();
  }

  private getUserNameForLeaderBoard(): string {
    if (!this.userProfileDetails) return;
    const firstName = this.userProfileDetails.firstName;
    const partOfFirstName = `${firstName[0].toUpperCase()}${firstName.substring(1, 3)}`;
    const firstLetterOfLastName = this.userProfileDetails.lastName[0].toUpperCase();
    return `${partOfFirstName} ${firstLetterOfLastName}`;
  }

  private onGetUserProfileData(): void {
    this.commonService
      .getUserDataCacheFirst()
      .pipe(take(1))
      .subscribe((userData: UserData) => {
        this.userProfileDetails = userData;
      });
  }

  private onObserveUserId(): void {
    this.userDetailsService.userProfileUpdated$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const userProfileDetails = this.userDetailsService.getUserProfileDetails();
      const isDifferentUser =
        this.userProfileDetails &&
        userProfileDetails &&
        this.userProfileDetails.playerID === userProfileDetails.playerID;
      this.userProfileDetails = userProfileDetails;
      if (isDifferentUser) this.onCheckUserStatus();
    });
  }

  private onCheckUserStatus(): void {
    if (!this.userProfileDetails) return;
    this.leaderBoardService.getUserStatus(this.board, this.userProfileDetails.playerID).pipe(take(1)).subscribe();
  }

  private onObserveUserStatus(): void {
    this.leaderBoardService.userStatus$.pipe(takeUntil(this.destroy$)).subscribe((userStatus) => {
      const isStatusForProperUser =
        userStatus && this.userProfileDetails && userStatus.userId === this.userProfileDetails.playerID;
      if (isStatusForProperUser) {
        this.shouldShowLeaderBoard = [LeaderBoardUserStatus.valid, LeaderBoardUserStatus.enrolled].includes(
          userStatus.status
        );
        this.isUserEnrolled = userStatus.status === LeaderBoardUserStatus.enrolled;
        if (this.isUserEnrolled && this.enrolledDisplayMode === EnrolledDisplayMode.Table) this.onGetLeaderBoard();
      } else {
        this.shouldShowLeaderBoard = false;
        this.isUserEnrolled = false;
      }
    });
  }

  private onGetLeaderBoard(): void {
    this.leaderBoardService
      .getBoard(this.board, this.userProfileDetails.playerID, this.limit)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ topUsers, userPoints }) => {
        this.topUsers = topUsers;
        this.userPoints = userPoints;
      });
  }
}
