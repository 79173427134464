import { Component, OnDestroy, HostListener } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";

// Components
import { SharedTransactionComponent } from "src/app/modules/transactions/components/shared-transaction/shared-transaction.component";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { AccountService } from "src/app/modules/auth/services/account.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { CustomValidators } from "src/app/utility/custom-validator";

@Component({
  selector: "app-bet-history",
  templateUrl: "./bet-history.component.html",
  styleUrls: ["./bet-history.component.scss"],
})
export class BetHistoryComponent extends SharedTransactionComponent
  implements OnDestroy {
  activeStep: string = "step1";
  titleText: string = "Game History";
  isLoading: boolean = false;
  isButtonLoader: boolean = false;
  betHistoryRecords: any;
  period: string;
  timeslap: number = 6;
  requestData = {
    size: 1000,
    index: 0,
    interval: {
      start: new Date().setHours(0, 0, 0, 0),
      end: new Date().getTime(),
    },
    type: "casino",
    productId: ["CASINO"],
  };
  windowType: string;
  @HostListener("window:resize") onResize() {
    this.getWindowType();
  }

  betTransactionSub: Subscription;
  constructor(
    public utility: UtilityService,
    public formBuilder: FormBuilder,
    private accountService: AccountService,
    public customValidators: CustomValidators,
    private translationService: TranslationService,
    private commonService: CommonService
  ) {
    super(utility, formBuilder, customValidators);
    this.titleText = this.translationService.instant(
      "bettransaction.game_history"
    );
    this.getWindowType();
  }

  fetchBetHistory(period: string) {
    this.period = period;
    if (this.period === "last-24h") {
      this.titleText = this.translationService.instant(
        "bettransaction.last_24_hours"
      );
      this.getBetTransaction();
      this.activeStep = "step2";
    } else if (this.period === "last-7d") {
      this.titleText = this.translationService.instant(
        "bettransaction.last_7_days"
      );
      this.getBetTransaction();
      this.activeStep = "step2";
    } else if (this.period === "select-date") {
      this.titleText = this.translationService.instant(
        "bettransaction.date_range"
      );
      this.activeStep = "step3";
    }
  }

  getWindowType() {
    var ww = document.body.clientWidth;
    if (ww <= 1024) {
      this.windowType = "mobile";
    } else {
      this.windowType = "device";
    }
  }

  goback() {
    this.titleText = this.translationService.instant(
      "bettransaction.last_24_hours"
    );
    if (this.activeStep === "step3") {
      this.closeComponent();
    } else if (this.activeStep === "step1") {
      this.utility.closeAccountComponent("menuOptions");
    } else if (this.activeStep === "step2") {
      this.activeStep = "step1";
      this.titleText = this.translationService.instant(
        "bettransaction.game_history"
      );
    } else {
      this.activeStep = "step1";
    }
    this.betHistoryRecords = undefined;
  }

  /**
   * Below function created a request object which we need to pass
   * backend based on applied filter last 24h last 7d or selected
   * date.
   */

  proccessRequest() {
    let d = new Date();
    if (this.period === "last-24h") {
      this.requestData["interval"] = {
        start: d.setHours(d.getHours() - 24),
        end: new Date().getTime(),
      };
    } else if (this.period === "last-7d") {
      this.requestData["interval"] = {
        start: new Date().setDate(d.getDate() - 7),
        end: new Date().getTime(),
      };
    } else if (this.period === "select-date") {
      this.requestData["interval"] = {
        start: this.txnDetailsFrom.get("startDate").value,
        end: this.txnDetailsFrom.get("endDate").value.setHours(23, 59, 59, 999),
      };
    }
    this.requestData["interval"]["start"] = Math.floor(
      this.requestData["interval"]["start"] / 1000
    );
    this.requestData["interval"]["end"] = Math.floor(
      this.requestData["interval"]["end"] / 1000
    );
    return this.requestData;
  }

  getBetTransaction() {
    this.isButtonLoader = true;
    this.betHistoryRecords = undefined;
    this.isLoading = true;
    let requestobj = this.proccessRequest();
    this.betTransactionSub = this.accountService
      .getBetTransactions(requestobj)
      .subscribe((responseData) => {
        this.isButtonLoader = false;
        this.isLoading = false;
        if (responseData && responseData.length > 0) {
          this.betHistoryRecords = responseData;
        } else {
          this.betHistoryRecords = [];
        }
      });
  }

  isButtonDisabled(formName) {
    return this.utility.isButtonDisabled(formName);
  }

  closeComponent(navigateTo?) {
    this.titleText = this.translationService.instant(
      "bettransaction.game_history"
    );
    this.activeStep = "step1";
    this.betHistoryRecords = undefined;
    this.utility.closeAccountComponent(navigateTo);
  }

  ngOnDestroy() {
    this.titleText = this.translationService.instant(
      "bettransaction.game_history"
    );
    this.activeStep = "step1";
    this.betHistoryRecords = undefined;
    if (this.betTransactionSub) {
      this.betTransactionSub.unsubscribe();
    }
  }
}
