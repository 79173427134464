import { Subscription } from "rxjs";
import {
  ActivatedRoute,
  NavigationEnd,
  RouterEvent,
  Router,
} from "@angular/router";
import {
  ViewEncapsulation,
  HostListener,
  EventEmitter,
  SimpleChange,
  Component,
  OnDestroy,
  OnChanges,
  Output,
  Input,
} from "@angular/core";

// Configurations
import { mixPanelEventsConfigurations } from "src/app/configurations/main.configurations";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";

// Models
import { LobbyByPageNameConfigurations } from "src/app/models/configurations/menu/lobby-by-page-name-configuration.model";
import { MenuConfigurationType } from "src/app/models/configurations/menu/menu-configuration-type.model";
import { NavigationData } from "src/app/modules/shared/models/navigation/navigation.model";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { MixPanelService } from "src/app/modules/shared/services/mix-panel.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-game-lobby",
  templateUrl: "./game-lobby.component.html",
  styleUrls: ["./game-lobby.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class GameLobbyComponent implements OnChanges, OnDestroy {
  // Outputs
  @Output() callOnSelectedLobby: EventEmitter<string> = new EventEmitter<
    string
  >();

  // Inputs
  @Input() callingFrom: string = "";

  // Strings
  activeLobby: string = "";
  langCode: string;

  // Booleans
  isLastPlayedLiveGamesExist: boolean = false;
  isbetRangeDropDownOpen: boolean = false;
  isShowSearchResult: boolean = false;
  isSearchRequired: boolean = true;
  isLoggedIn: boolean = false;

  // Objects
  menuConfigurationType: MenuConfigurationType;

  // Swiper
  swiperGameLobby: SwiperConfigInterface = {
    slidesPerView: "auto",
    slidesPerGroup: 2,
    freeMode: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    navigation: true,
    resistanceRatio: 0,
  };

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private gameGroupsService: GameGroupsService,
    private mixPanelService: MixPanelService,
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.isLoggedIn = this.utilityService.isUserLoggedIn();
    this.langCode = this.utilityService.getLangCode();
    this.activeLobby = this.commonService.getActiveLobby();

    this.subscriptions = [
      this.commonService.loginComplete$.subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      }),
      this.commonService.logOutComplete$.subscribe(() => {
        this.isLoggedIn = false;
      }),
      this.router.events.subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          const lobbyName: string = this.commonService.getActiveLobby();

          if (lobbyName) {
            this.onNavigateToLobby(lobbyName);
          }
        }
      }),
      this.commonService.updateActiveLobbyData$.subscribe(
        (data: NavigationData) => {
          if (data && data.lobby) {
            this.activeLobby = data.lobby;

            this.onNavigateToLobby(this.activeLobby);
          }
        }
      ),
      this.gameGroupsService.isLastPlayedLiveGamesExistSb$.subscribe(
        (isExist: boolean) => {
          this.isLastPlayedLiveGamesExist = isExist;
        }
      ),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["callingFrom"] &&
      changes["callingFrom"].previousValue !==
        changes["callingFrom"].currentValue
    ) {
      let lobby: string = "";

      this.callingFrom = changes["callingFrom"].currentValue;

      if (
        this.utilityService.getQueryParams() &&
        this.utilityService.getQueryParams()["lobby"] &&
        this.isLoggedIn
      ) {
        lobby = this.utilityService.getQueryParams().lobby;
      } else if (this.commonService.getActiveLobby()) {
        lobby = this.commonService.getActiveLobby();
      } else {
        lobby = this.callingFrom === "casinoLobby" ? "casino" : "all-live";
      }

      this.menuConfigurationType = this.getLobbyConfigurationData(this.callingFrom);

      this.onNavigateToLobby(lobby);
    }
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("document:touchend", ["$event"])
  @HostListener("document:click", ["$event"])
  onClick(event): void {
    if (event.target.classList.contains("betRange-dropdown--backdrop")) {
      if (this.isbetRangeDropDownOpen) {
        event.preventDefault();
      }

      this.isbetRangeDropDownOpen = false;
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  getLobbyConfigurationData(callingFrom: string): MenuConfigurationType {
    let menuConfigurationType: MenuConfigurationType;

    if (callingFrom === "casinoLobby") {
      menuConfigurationType = this.utilityService.getBrandBasedConfig(
        "casinoLobbyConfig"
      );
    } else if (callingFrom === "liveCasinoLobby") {
      menuConfigurationType = this.utilityService.getBrandBasedConfig(
        "liveCasinolobbyConfig"
      );
    }

    return menuConfigurationType;
  }

  getAssignProperLobby(name: string): string {
    const menuConfigurationType: MenuConfigurationType = this.utilityService.getBrandBasedConfig("lobbyByPageName");

    const lobbies: LobbyByPageNameConfigurations = menuConfigurationType as LobbyByPageNameConfigurations;
    if (
      this.router.url.includes("/casino") &&
      lobbies &&
      lobbies.casinolobbies &&
      lobbies.casinolobbies.indexOf(name) > -1
    ) {
      return name;
    } else if (
      this.router.url.includes("/live-casino") &&
      lobbies &&
      lobbies.livecasinolobbies &&
      lobbies.livecasinolobbies.indexOf(name) > -1
    ) {
      return name;
    } else if (this.router.url.includes("/casino")) {
      return "casino";
    } else if (this.router.url.includes("/live-casino")) {
      return "all-live";
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onOpenBetRangeFilter(): void {
    this.isbetRangeDropDownOpen = !this.isbetRangeDropDownOpen;
  }

  onNavigateToLobby(lobbyName: string, callingFrom?: string): void {
    if (
      callingFrom === "list" &&
      Object.keys(this.activatedRoute.snapshot.queryParams).length > 0
    ) {
      this.onClearQueryParams();
    }

    if (lobbyName === 'livespins') {
      this.router.navigate([this.langCode + "/livespins"]);
      return;
    }

    this.activeLobby = this.getAssignProperLobby(lobbyName);

    if (callingFrom === "list") {
      this.onSendMixpanelEvent();
    }

    this.commonService.setActiveLobby(this.activeLobby);

    this.callOnSelectedLobby.emit(this.activeLobby);
  }

  onClearQueryParams(): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {},
    });
  }

  onOpenSearchResultsAtDesktop(): void {
    const searchLobbyNavElem: HTMLCollectionOf<Element> = document.getElementsByClassName(
      "searchAtlobbynav"
    );

    if (searchLobbyNavElem && searchLobbyNavElem.length > 0) {
      const rect: DOMRect | ClientRect = searchLobbyNavElem[0].getBoundingClientRect();

      document.querySelector("html, body").scrollBy({
        top: rect.top ? rect.top - 20 : 0,
        behavior: "smooth",
      });
    }
  }

  onOpenSearchResultsAtMobile(): void {
    this.isShowSearchResult = true;

    this.utilityService.addClassToAppBody("overflow-hidden");
  }

  onCloseSearchResultsAtMobile(): void {
    this.isShowSearchResult = false;

    this.utilityService.removeClassFromAppBody("overflow-hidden");
  }

  onSendMixpanelEvent(): void {
    this.mixPanelService.trackMixpanelEvents(
      mixPanelEventsConfigurations.clicked_Game_Category,
      {
        name: this.activeLobby,
        vertical: this.mixPanelService.getVerticalByUrl(),
      }
    );
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
