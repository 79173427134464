import { FormBuilder } from "@angular/forms";
import { Component } from "@angular/core";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { AccountService } from "src/app/modules/auth/services/account.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { CustomValidators } from "src/app/utility/custom-validator";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent extends FormValidationComponent {
  isPassword: boolean = true;
  isoldPassword: boolean = true;
  isconfirmPassword: boolean = true;
  isButtonLoader: boolean = false;
  isPasswordSuccessfullyUpdated: boolean;
  changePasswordForm = this.formBuilder.group(
    {
      old: ["", [this.customValidators.required]],
      password: ["", [this.customValidators.validatePassword(8, 25)]],
      confirmPassword: ["", [this.customValidators.validatePassword(8, 25)]],
    },
    { validator: this.customValidators.passwordConfirming }
  );
  serverError: any;
  constructor(
    private utils: UtilityService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private customValidators: CustomValidators,
    private translationService: TranslationService,
    private accountService: AccountService
  ) {
    super(utils);
  }

  changePassword() {
    this.isButtonLoader = true;
    if (this.changePasswordForm.valid) {
      let data = this.utils.formControlToParams(this.changePasswordForm, {});
      this.accountService.changePasswordHandler(data).subscribe((respData) => {
        this.isButtonLoader = false;
        if (
          respData &&
          respData["success"] &&
          respData["success"]["status"] === "SUCCESS"
        ) {
          this.isPasswordSuccessfullyUpdated = true;
          this.utils.resetFormFields(this.changePasswordForm);
          this.utils.removeAllFormFieldsvalidation(this.changePasswordForm);
        } else {
          this.isPasswordSuccessfullyUpdated = false;
          if (
            respData &&
            respData["errors"] &&
            respData["errors"]["confirmPassword"]
          ) {
            this.serverError = respData["errors"]["confirmPassword"];
          } else if (
            respData &&
            respData["errors"] &&
            respData["errors"]["message"]
          ) {
            this.serverError = respData["errors"]["message"];
          } else {
            this.serverError = this.translationService.instant(
              "change_password.something_wrong_pass"
            );
          }
        }
      });
    }
  }
}
