import { map, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  Subscription,
  Observable,
  throwError,
  Subscriber,
  Subject,
  of,
} from "rxjs";

// Configurations
import { endpointConfigurations } from "src/app/configurations/endpoint.configurations";

// Enums
import { StatusResponse } from "src/app/models/api/status.response";

// Libraries
import * as _ from "underscore";

// Models
import { FavouriteGameList } from "src/app/modules/game-groups/models/favourite/favourite-game-list.model";
import { LastPlayedGames } from "src/app/modules/game-groups/models/games/last-played-games.model";
import { ProviderLevel } from "src/app/modules/game-groups/models/providers/provider-level.model";
import { LobbyGameGroup } from "src/app/modules/game-groups/models/lobby/lobby-game-group.model";
import { FreeGames } from "src/app/modules/game-groups/models/free-games/free-games.model";
import { Favourite } from "src/app/modules/game-groups/models/favourite/favourite.model";
import { LobbyPregmatic } from "src/app/modules/game-groups/models/lobby/lobby.model";
import { GameGroup } from "src/app/modules/game-groups/models/game-group.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Services
import { CommonService } from "src/app/modules/shared/services/common.service";
import { MainService } from "src/app/modules/shared/services/main.service";

@Injectable({
  providedIn: "root",
})
export class GameGroupsService {
  // Booleans
  isLoggedIn: boolean = false;

  // Arrays
  lobbyWithGameGroupsRespData: LobbyPregmatic[] = [];
  allAvialableGamesRespData: GamePregmatic[] = [];
  lastPlayedGamesData: number[] = [];

  // Objects
  allFavoriteGamesResponseData: Favourite;

  // To Discover
  allGamesData = [];

  // Array of Subscriptions and Promises
  lastPlayedGamesCallQueue: ((value: unknown) => void)[] = [];
  gameCallQueue: ((
    value: GamePregmatic[] | PromiseLike<GamePregmatic[]>
  ) => void)[] = [];
  lobbyCallQueue: ((
    value: LobbyPregmatic[] | PromiseLike<LobbyPregmatic[]>
  ) => void)[] = [];
  gameRequestQueue: Subscriber<any>[] = [];

  // Others
  providerLevelGames: {
    [key: string]: ProviderLevel;
  } = {};

  // Subject and Behaviour Subject
  private isPlayAginClicked: Subject<boolean> = new Subject<boolean>();
  public isPlayAginClicked$: Observable<
    boolean
  > = this.isPlayAginClicked.asObservable();

  private isLastPlayedLiveGamesExistSb: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(false);
  public isLastPlayedLiveGamesExistSb$: Observable<
    boolean
  > = this.isLastPlayedLiveGamesExistSb.asObservable();

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private commonService: CommonService,
    private mainService: MainService
  ) {
    this.subscriptions = [
      this.commonService.loginComplete$.subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      }),
      this.commonService.logOutComplete$.subscribe(() => {
        this.isLoggedIn = false;
      }),
    ];
  }

  getLobbyListWithGameGroups(isForce?: boolean): Observable<LobbyPregmatic[]> {
    if (!_.isEmpty(this.lobbyWithGameGroupsRespData) && !isForce) {
      return of(this.lobbyWithGameGroupsRespData);
    } else {
      return this.mainService.getLobbyGameGroups().pipe(
        map((response: LobbyPregmatic[]) => {
          if (response && response.length > 0) {
            this.lobbyWithGameGroupsRespData = response;
          }

          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
    }
  }

  getGameGroupGames(isForce?: boolean): Observable<GamePregmatic[]> {
    if (!_.isEmpty(this.allAvialableGamesRespData) && !isForce) {
      return of(this.allAvialableGamesRespData);
    } else {
      return this.mainService.getGameGroupGames().pipe(
        map((response: GamePregmatic[]) => {
          if (response && response.length > 0) {
            this.allAvialableGamesRespData = response;
          }

          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
    }
  }

  /*
    Mostly after we call toggleFavorite api call...we need to pass true flag
    to get updated data from backoffice...
  */
  getFavoriteGames(isForce: boolean = false): Observable<Favourite> {
    if (!_.isEmpty(this.allFavoriteGamesResponseData) && !isForce) {
      return of(this.allFavoriteGamesResponseData);
    } else {
      return this.mainService.getFavoriteGames().pipe(
        map((response: Favourite) => {
          if (
            response.favorite &&
            response.favorite.favoriteGamesList.length > 0
          ) {
            this.allFavoriteGamesResponseData = response;
          }

          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
    }
  }

  getLastPlayedGames(): Observable<number[]> {
    return this.mainService.getLastedPlayedGames().pipe(
      map((response: LastPlayedGames) => {
        if (
          response &&
          response.status === StatusResponse.SUCCESS &&
          response.gameDetails.length > 0
        ) {
          return response.gameDetails;
        } else {
          return [];
        }
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getFreeGame(gameId: string): Observable<FreeGames> {
    return this.mainService
      .psGet(endpointConfigurations.lanuchFreeGames_url, { gameSymbol: gameId })
      .pipe(
        map((freeGames: FreeGames) => {
          return freeGames;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getGames(isForce: boolean = false): Observable<any> {
    if (isForce) {
      this.allGamesData = [];
    }

    if (this.allGamesData && this.allGamesData.length > 0) {
      return of(this.allGamesData);
    } else {
      return new Observable((observer) => {
        if (this.gameRequestQueue.length > 0) {
          this.gameRequestQueue.push(observer);
        } else {
          this.gameRequestQueue.push(observer);

          this.mainService
            .psGet(endpointConfigurations.getGames_url)
            .subscribe((data) => {
              if (data && data.length > 0) {
                this.allGamesData = data;
              }
              for (let i = 0; i < this.gameRequestQueue.length; i++) {
                this.gameRequestQueue[i].next(data);
              }
              this.gameRequestQueue = [];
            });
        }
      });
    }
  }

  getLobbyListWithGameGroupsp(
    isForce: boolean = false
  ): Promise<LobbyPregmatic[]> {
    if (!_.isEmpty(this.lobbyWithGameGroupsRespData) && !isForce) {
      return new Promise((resolve) => {
        resolve(this.lobbyWithGameGroupsRespData);
      });
    } else {
      return new Promise((resolve, reject) => {
        if (this.lobbyCallQueue.length > 0) {
          this.lobbyCallQueue.push(resolve);
        } else {
          this.lobbyCallQueue.push(resolve);

          Promise.resolve(this.mainService.getLobbyGameGroupsp())
            .then((response: LobbyPregmatic[]) => {
              if (response && response.length > 0) {
                this.lobbyWithGameGroupsRespData = response;
              }

              for (let callback in this.lobbyCallQueue) {
                this.lobbyCallQueue[callback](response);
              }

              this.lobbyCallQueue = [];
            })
            .catch(() => {
              for (let callback in this.lobbyCallQueue) {
                this.lobbyCallQueue[callback]([]);
              }
              this.lobbyCallQueue = [];
            });
        }
      });
    }
  }

  getGameGroupGamesp(isForce: boolean = false): Promise<GamePregmatic[]> {
    if (!_.isEmpty(this.allAvialableGamesRespData) && !isForce) {
      return new Promise((resolve, reject) => {
        resolve(this.allAvialableGamesRespData);
      });
    } else {
      return new Promise((resolve) => {
        if (this.gameCallQueue.length > 0) {
          this.gameCallQueue.push(resolve);
        } else {
          this.gameCallQueue.push(resolve);

          Promise.resolve(this.mainService.getGameGroupGamesp())
            .then((response: GamePregmatic[]) => {
              if (response && response.length > 0) {
                this.allAvialableGamesRespData = response;
              }

              resolve(this.allAvialableGamesRespData);

              for (let callback in this.gameCallQueue) {
                this.gameCallQueue[callback](response);
              }

              this.gameCallQueue = [];
            })
            .catch(() => {
              for (let callback in this.gameCallQueue) {
                this.gameCallQueue[callback]([]);
              }

              this.gameCallQueue = [];
            });
        }
      });
    }
  }

  getLastedPlayedGamesp(isForce: boolean = false): Promise<number[]> {
    if (isForce) {
      this.lastPlayedGamesData = undefined;
    }

    if (!_.isEmpty(this.lastPlayedGamesData) && !isForce) {
      return new Promise((resolve) => {
        resolve(this.lastPlayedGamesData);
      });
    } else {
      return new Promise((resolve, reject) => {
        if (this.lastPlayedGamesCallQueue.length > 0) {
          this.lastPlayedGamesCallQueue.push(resolve);
        } else {
          this.lastPlayedGamesCallQueue.push(resolve);

          Promise.resolve(this.mainService.getLastedPlayedGamesp())
            .then((response: LastPlayedGames) => {
              if (
                response &&
                response.status === StatusResponse.SUCCESS &&
                response.gameDetails.length > 0
              ) {
                this.lastPlayedGamesData = response.gameDetails;
              } else {
                this.lastPlayedGamesData = [];
              }

              for (let callback in this.lastPlayedGamesCallQueue) {
                this.lastPlayedGamesCallQueue[callback](
                  this.lastPlayedGamesData
                );
              }

              this.lastPlayedGamesCallQueue = [];
            })
            .catch((error) => {
              for (let callback in this.lastPlayedGamesCallQueue) {
                this.lastPlayedGamesCallQueue[callback]([]);
              }

              this.lastPlayedGamesCallQueue = [];
            });
        }
      });
    }
  }

  /*****************************************Get Game group by lobby*********************************************** */
  /*
    Below Function Iternates through gameGroups vs games Data & filter each and every gameGroup related
    games & push into it related game group object
  
    So when we run this below code final output will be all the game groupes and it's related games as
    array with the group object will be returned.
  */
  processGameGroupGames(
    lobbyGameGroupList: LobbyGameGroup[],
    games: GamePregmatic[],
    lastPlayedGames: number[] = [],
    allFavoriteGames: FavouriteGameList[] = []
  ): LobbyGameGroup[] {
    if (lobbyGameGroupList && games) {
      _.each(
        lobbyGameGroupList,
        (lobbyGameGroup: LobbyGameGroup, indexLobbyGameGroup: number) => {
          if (lobbyGameGroup.group_type === "manual") {
            _.each(games, (game: GamePregmatic) => {
              let gameGroupList: GameGroup[] = game.gameGroupList as GameGroup[];

              if (game.gameGroupList && gameGroupList.length > 0) {
                _.each(
                  gameGroupList,
                  (gameGroup: GameGroup, indexGameGroup: number) => {
                    if (gameGroup.id === lobbyGameGroup.id) {
                      let clonedGame = _.clone(game);

                      clonedGame.gameGroupList =
                        game.gameGroupList[indexGameGroup];

                      if (!lobbyGameGroupList[indexLobbyGameGroup].games) {
                        lobbyGameGroupList[indexLobbyGameGroup].games = [];
                      }

                      lobbyGameGroupList[indexLobbyGameGroup].games.push(
                        clonedGame
                      );
                    }
                  }
                );
              }
            });
          } else if (
            lobbyGameGroup.group_type === "automatic" &&
            lobbyGameGroup.group_sub_type === "continue_playing"
          ) {
            if (
              this.isLoggedIn &&
              lastPlayedGames &&
              lastPlayedGames.length > 0
            ) {
              _.each(lastPlayedGames, (typeId: number) => {
                let game: GamePregmatic = _.findWhere(games, {
                  beGameTypeId: typeId,
                });

                if (!lobbyGameGroupList[indexLobbyGameGroup].games) {
                  lobbyGameGroupList[indexLobbyGameGroup].games = [];
                }

                if (game) {
                  lobbyGameGroupList[indexLobbyGameGroup].games.push(game);
                }
              });
            } else {
              lobbyGameGroupList[indexLobbyGameGroup].games = [];
            }
          } else if (
            lobbyGameGroup.group_type === "automatic" &&
            lobbyGameGroup.group_sub_type === "favourite"
          ) {
            if (
              this.isLoggedIn &&
              allFavoriteGames &&
              allFavoriteGames.length > 0
            ) {
              _.each(allFavoriteGames, (favoriteGame: FavouriteGameList) => {
                let game: GamePregmatic = _.findWhere(games, {
                  beGameTypeId: favoriteGame.gameTypeId,
                });

                if (!lobbyGameGroupList[indexLobbyGameGroup].games) {
                  lobbyGameGroupList[indexLobbyGameGroup].games = [];
                }

                if (game) {
                  lobbyGameGroupList[indexLobbyGameGroup].games.push(game);
                }
              });
            } else {
              lobbyGameGroupList[indexLobbyGameGroup].games = [];
            }
          }
        }
      );
    }

    return lobbyGameGroupList;
  }

  /*
    This Methods Give each provider level Live , Non-Live & All games List
  */
  getProviderLevelGames(): Promise<{
    [key: string]: ProviderLevel;
  }> {
    if (
      this.providerLevelGames &&
      Object.keys(this.providerLevelGames).length > 0
    ) {
      return new Promise((resolve, reject) => {
        resolve(this.providerLevelGames);
      });
    } else {
      return Promise.resolve(this.getGameGroupGamesp()).then(
        (games: GamePregmatic[]) => {
          if (games && games.length > 0) {
            _.each(games, (game: GamePregmatic) => {
              if (!this.providerLevelGames.hasOwnProperty(game.vendorCode)) {
                this.providerLevelGames[game.vendorCode] = {
                  vendorName: game.vendorName,
                  "non-live-games": [],
                  "live-games": [],
                  all: [],
                };
              }

              this.providerLevelGames[game.vendorCode].all.push(game);

              if (
                game.gameType === "live_dealer" ||
                game.gameType === "live_casino"
              ) {
                this.providerLevelGames[game.vendorCode]["live-games"].push(
                  game
                );
              } else {
                this.providerLevelGames[game.vendorCode][
                  "non-live-games"
                ].push(game);
              }
            });
          }
          
          return this.providerLevelGames;
        }
      );
    }
  }

  broadIsPlayAginClicked(isPlayAginClicked: boolean): void {
    this.isPlayAginClicked.next(isPlayAginClicked);
  }

  broadCastIsLastPlayedLiveGamesExist(isLastPlayedLiveGamesExistSb: boolean): void {
    this.isLastPlayedLiveGamesExistSb.next(isLastPlayedLiveGamesExistSb);
  }
  
  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
