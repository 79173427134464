import { Component, Input, OnChanges } from "@angular/core";
import { LeaderBoardUser } from "src/app/modules/shared/models/leader-board/leader-board.model";

@Component({
  selector: "app-leader-board-table",
  templateUrl: "./leader-board-table.component.html",
  styleUrls: ["./leader-board-table.component.scss"],
})
export class LeaderBoardTableComponent implements OnChanges {
  @Input() topUsers: LeaderBoardUser[] = [];
  @Input() userPoints: LeaderBoardUser;
  @Input() isXmas?: boolean;

  ngOnChanges(): void {
    this.injectUserToLeaderBoard();
  }

  private injectUserToLeaderBoard(): void {
    if (!this.userPoints) return;
    const isUserInTop = !!this.topUsers.find((user) => user.id === this.userPoints.id);
    if (isUserInTop) return;
    this.topUsers.push(this.userPoints);
  }
}
