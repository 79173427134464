import { Injectable } from "@angular/core";

// Libraries
import * as socketIo from "socket.io-client";

// Models
import { UserProfileBalance } from "src/app/modules/shared/models/profiles/user-profile-balance.model";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";

@Injectable({
  providedIn: "root",
})
export class SocketService {
  // Strings
  sessionId: string = "";
  url: string = "";

  // Socket IO
  socket: socketIo;

  constructor(
    private userDetailsService: UserDetailsService,
    private emitterService: EmitterService,
    private rewardsService: RewardsService
  ) {}

  connectToScoket(url: string, sessionId: string, force?: boolean): void {
    if (sessionId) {
      this.url = url;
      this.sessionId = sessionId;
      /*comment below condition to run local code in IE until issue resolved */
      if (!this.socket || (this.socket && !this.socket.connected) || force) {
        this.socket = socketIo(url);

        this.socket.on("connect", (esocket) => {
          this.socket.emit("setAuthCode", sessionId);

          this.socket.on("message", (data) => {
            /*To keep session active when user playing games */

            if (
              data &&
              data.messageName &&
              data.messageName.includes("PushRealBalance")
            ) {
              /*commenting below line because mutliple status Api calls are hitting
               when user playing games */

              // Promise.resolve(this.mainService.getLoginStatus()).then();
              setTimeout(() => {
                const socketBalanceData: UserProfileBalance = {
                  realCashBalance: Number(data.cashBalance),
                  crpBalance: data.crpBalance / 100,
                  wrpBalance: data.wrpBalance / 100,
                  drpBalance: data.drpBalance / 100,
                  rrpBalance: data.rrpBalance / 100,
                };

                this.userDetailsService.setUserBalanceDetails(
                  socketBalanceData
                );
              });
            } else if (
              data &&
              data.messageName &&
              data.messageName.includes("LEVELUP_LEVEL_UPGRADE")
            ) {
              data["dataFrom"] = "socket";
              
              this.rewardsService.broadLevelUpDetails(data);
            } else if (
              data &&
              data.messageName &&
              data.messageName.includes("LEVELUP_POINTS_UPDATE")
            ) {
              this.rewardsService.broadLevelUpPointDetails(data);
            } else if (
              data &&
              data.messageName.includes("BET_FROM_BONUS_EXCEEDED_LIMIT")
            ) {
              this.emitterService.broadCastMaxBetWarningTriggerred(data);
            } else if (
              data &&
              data.messageName &&
              data.messageName.includes("PushLogoutEvent")
            ) {
              this.emitterService.broadCastDoLogout(true);
            }
          });
        });
      }
    }
  }
}
